import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
// eslint-disable-next-line import/order
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TutoralizationSetting } from '@graphql/generated';

import useMe from '../../hooks/useMe';
import Avatar from '../Avatar/Avatar';
import ChatPopup from '../Popups/ChatPopup';
import ProjectListPopup from '../Popups/ProjectListPopup';
import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

type MainHeaderProps = {
  location: 'Chats' | 'Projects' | 'My Tasks' | 'Files';
};
const MainHeader: React.FC<MainHeaderProps> = ({ location }) => {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { me } = useMe();
  const { name, avatar } = me || { name: '' };
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (me?.email === 'user@example.com') {
      AsyncStorage.setItem('@tutoralizationAssignTask', '1');
      AsyncStorage.setItem('@tutoralizationTagMessage', '1');
      AsyncStorage.setItem('@tutoralizationHashtag', '1');
      return;
    }
    const tutoralizationAssignTask = me?.tutoralizationSetting?.find(
      (setting) => setting.settingType === TutoralizationSetting.AssignTask
    );
    const tutoralizationHashtag = me?.tutoralizationSetting?.find(
      (setting) => setting.settingType === TutoralizationSetting.Hashtag
    );
    const tutoralizationTagMessage = me?.tutoralizationSetting?.find(
      (setting) => setting.settingType === TutoralizationSetting.TagMessage
    );
    AsyncStorage.setItem(
      '@tutoralizationAssignTask',
      tutoralizationAssignTask?.close ? '1' : '0'
    );
    AsyncStorage.setItem(
      '@tutoralizationTagMessage',
      tutoralizationTagMessage?.close ? '1' : '0'
    );
    AsyncStorage.setItem(
      '@tutoralizationHashtag',
      tutoralizationHashtag?.close ? '1' : '0'
    );
  }, [me?.tutoralizationSetting]);

  const renderPopup = () => {
    switch (location) {
      case 'Chats':
        return <ChatPopup closePopFn={() => setModalOpen(!modalOpen)} />;
      case 'Projects':
        return <ProjectListPopup closePopFn={() => setModalOpen(!modalOpen)} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        animationType='fade'
        transparent={true}
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}>
        <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
          <Box style={styles.modal}></Box>
        </TouchableWithoutFeedback>
        <Box style={styles.popUp}>{renderPopup()}</Box>
      </Modal>
      <Box
        paddingHorizontal='m'
        paddingBottom='m'
        borderBottomColor='grey02'
        borderBottomWidth={1}
        style={{ paddingTop: insets.top }}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          marginTop='xs'>
          {location !== 'Chats' ? (
            <Text variant='heading1'>{location}</Text>
          ) : (
            <Icon name='Logo' width={106} height={32} color='textPrimary' />
          )}

          <Box
            flexDirection='row'
            accessibilityLabel='Profile'
            alignItems='center'>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('subscription-plan', { isSubscribe: true })
              }>
              {/* <Box
                paddingHorizontal='xs'
                paddingVertical='xxs'
                marginRight='m'
                borderRadius='xs'
                backgroundColor='purple'>
                <Box flexDirection='row' alignItems='center'>
                  <Icon name='Star' color='white' variant='s' />
                  <Text
                    marginLeft='xxs'
                    color='white'
                    variant='labelEmphasizedResponsive'>
                    Free trial
                  </Text>
                </Box>
              </Box> */}
            </TouchableOpacity>
            <Avatar
              avatar={avatar}
              label={name}
              size='small'
              skipCache={true}
              onPress={() => navigation.navigate('profile-stack')}
            />
          </Box>
        </Box>

        <Box
          marginTop='m'
          flexDirection='row'
          alignItems='center'
          style={{ height: 40 }}>
          <TouchableOpacity
            onPress={() => navigation.navigate('global-search')}
            style={{
              flex: 1,
            }}>
            <Box
              backgroundColor='grey01'
              borderRadius='xs'
              flexDirection='row'
              paddingHorizontal='m'
              alignItems='center'
              flex={1}>
              <Icon name='Search' variant='l' color='textSecondary' />
              <Text marginLeft='xs' variant='labelSmall'>
                {t('shared:search')}
              </Text>
            </Box>
          </TouchableOpacity>
          {location === 'Chats' && (
            <Icon
              name='MoreVertical'
              variant='l'
              color='textPrimary'
              marginLeft='m'
              accessibilityLabel='Options button'
              onPress={() => setModalOpen(!modalOpen)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  popUp: {
    zIndex: 100,
    position: 'absolute',
    top: 140, // Figma
    right: 16,
  },
});

export default MainHeader;
