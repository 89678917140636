import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { useUploadFiles } from '@hooks/useUploadFiles';

export const UploadFilesStatus: React.FC = () => {
  const { t } = useTranslation('models');
  const {
    uploadingFiles,
    uploadingErrorFiles,
    lastSuccessBatch,
    resendFiles,
    setUploadingErrorFiles,
    deleteFailedFiles,
  } = useUploadFiles();

  const hasSuccess = lastSuccessBatch.length > 0;
  const hasError = uploadingErrorFiles.length > 0;
  const hasUploading = uploadingFiles.length > 0;

  const successAnim = useRef(new Animated.Value(0)).current;
  const errorAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (hasSuccess) {
      Animated.timing(successAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start();

      const timeout = setTimeout(() => {
        Animated.timing(successAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }).start();
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [hasSuccess]);

  useEffect(() => {
    if (hasError) {
      Animated.timing(errorAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [hasError]);

  const dismissError = () => {
    Animated.timing(errorAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      setUploadingErrorFiles([]);
    });
  };

  return (
    <Box
      position='absolute'
      bottom={60}
      width='100%'
      paddingHorizontal='s'
      paddingBottom='s'
      pointerEvents='box-none'>
      {hasError && (
        <Animated.View
          style={{
            opacity: errorAnim,
            transform: [
              {
                translateY: errorAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0],
                }),
              },
            ],
          }}>
          <Box
            backgroundColor='alertRedMild'
            padding='s'
            borderRadius='s'
            marginBottom='s'
            style={{ opacity: 0.9 }}>
            <Box
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              marginBottom='xs'>
              <Text color='alertRed' variant='labelSmall' fontWeight='bold'>
                {t('files.uploadFilesError', {
                  count: uploadingErrorFiles.length,
                })}
              </Text>
              <TouchableOpacity onPress={dismissError}>
                <Icon
                  name='X2'
                  variant='m'
                  marginLeft='xs'
                  color='textPrimary'
                />
              </TouchableOpacity>
            </Box>
            {uploadingErrorFiles.map((item) => {
              const file = item;
              return (
                <Box
                  key={file?.id}
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'
                  marginBottom='xs'>
                  <Box flex={1} paddingRight='s'>
                    <Text
                      color='alertRed'
                      numberOfLines={1}
                      ellipsizeMode='tail'
                      variant='labelSmall'
                      style={{ flexShrink: 1 }}>
                      • {file?.name}
                    </Text>
                  </Box>
                  <TouchableOpacity
                    style={{ minWidth: 50, alignItems: 'flex-end' }}
                    onPress={() => resendFiles([file])}>
                    <Text
                      color='alertRed'
                      variant='labelSmall'
                      fontWeight='500'>
                      {t('files.retry')}
                    </Text>
                  </TouchableOpacity>
                </Box>
              );
            })}
            <Box flexDirection='row' justifyContent='flex-end' marginTop='s'>
              <TouchableOpacity
                onPress={() => resendFiles(uploadingErrorFiles)}
                style={{ marginRight: 16 }}>
                <Text color='alertRed' variant='labelSmall' fontWeight='500'>
                  {t('files.retryAll')}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  deleteFailedFiles(uploadingErrorFiles);
                  setUploadingErrorFiles([]);
                }}
                style={{}}>
                <Text color='alertRed' variant='labelSmall' fontWeight='500'>
                  {t('files.deleteAll')}
                </Text>
              </TouchableOpacity>
            </Box>
          </Box>
        </Animated.View>
      )}

      {hasSuccess && (
        <Animated.View
          style={{
            opacity: successAnim,
            transform: [
              {
                translateY: successAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0],
                }),
              },
            ],
          }}>
          <Box
            backgroundColor='greenSecondary'
            padding='s'
            borderRadius='s'
            marginBottom='s'
            style={{ opacity: 0.9 }}>
            <Text
              color='white'
              variant='labelSmall'
              fontWeight='bold'
              marginBottom='xs'>
              {t('files.uploadFilesSuccess', {
                count: lastSuccessBatch.length,
              })}
            </Text>
            {lastSuccessBatch.map((file) => (
              <Text key={file.id} color='white' variant='labelSmall'>
                • {file.name}
              </Text>
            ))}
          </Box>
        </Animated.View>
      )}

      {hasUploading && (
        <Box
          backgroundColor='black'
          padding='s'
          borderRadius='s'
          marginBottom='s'
          style={{ opacity: 0.9 }}>
          <Text color='white' variant='labelSmall' marginBottom='xs'>
            {t('files.uploadFilesStart', {
              count: uploadingFiles.length,
            })}
          </Text>
          {uploadingFiles.map((item) => (
            <Box key={item.file.id} marginBottom='xs'>
              <Text color='white' variant='labelSmall' numberOfLines={1}>
                {item.file.name}
              </Text>
              <Box
                backgroundColor='white'
                height={4}
                borderRadius='xs'
                marginTop='xs'
                overflow='hidden'>
                <Box
                  backgroundColor='greenSecondary'
                  width={`${Math.floor(item.progress * 100) || 0}%`}
                  height='100%'
                />
              </Box>
              <Text color='white' variant='labelSmall' textAlign='right'>
                {Math.floor(item.progress * 100) || 0}%
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
