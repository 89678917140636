'use strict';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { dayJsMod } from './helper';
import Month from './Month';
import theme from '../../../themes/theme';
import { Box, Text, ShadowBox } from '../Restyle';
import Button from '../shared/Button/Button';

export const DateRangePicker: React.FC<any> = (props) => {
  const [selectFlag, setSlectFlag] = useState(false);
  const [startDate, setStartDate] = useState(
    props.startDate && dayJsMod(props.startDate, 'YYYYMMDD', '')
  );
  const [untilDate, setUntilDate] = useState(
    props.untilDate && dayJsMod(props.untilDate, 'YYYYMMDD', '')
  );
  const [availableDates, setAvailableDates] = useState(
    props.availableDates || null
  );

  useEffect(() => {
    setAvailableDates(props.availableDates);
  }, [props.availableDates]);

  const onSelectDate = (date) => {
    let tempStartDate = null;
    let tempUntilDate = null;

    if (startDate && !untilDate) {
      if (
        date.format('YYYYMMDD') < startDate.format('YYYYMMDD') ||
        isInvalidRange(date)
      ) {
        tempStartDate = date;
      } else if (date.format('YYYYMMDD') > startDate.format('YYYYMMDD')) {
        tempStartDate = startDate;
        tempUntilDate = date;
      }
    } else if (!isInvalidRange(date)) {
      tempStartDate = date;
    }

    setStartDate(tempStartDate);
    setUntilDate(tempUntilDate);

    if (tempStartDate != null || tempUntilDate != null) {
      setSlectFlag(true);
    }

    props.onSelect(tempStartDate, tempUntilDate);
  };

  const isInvalidRange = (date) => {
    if (availableDates && availableDates.length > 0) {
      //select endDate condition
      if (startDate && !untilDate) {
        for (
          let i = startDate.format('YYYYMMDD');
          i <= date.format('YYYYMMDD');
          i = dayJsMod(i, 'YYYYMMDD', '').add(1, 'days').format('YYYYMMDD')
        ) {
          if (
            availableDates.indexOf(i) == -1 &&
            startDate.format('YYYYMMDD') != i
          )
            return true;
        }
      }
      //select startDate condition
      else if (availableDates.indexOf(date.format('YYYYMMDD')) == -1) {
        return true;
      }
    }

    return false;
  };

  const getMonthStack = () => {
    const res = [];
    const { maxMonth, initialMonth, isHistorical, lazyLoad } = props;
    let initMonth = dayJsMod(null, null, null);
    if (initialMonth && initialMonth != '')
      initMonth = dayJsMod(initialMonth, 'YYYYMM', '');
    for (let i = 2; i > 0; i--) {
      res.push(
        !isHistorical
          ? initMonth.clone().add(-i, 'month').format('YYYYMM')
          : initMonth.clone().subtract(-i, 'month').format('YYYYMM')
      );
    }
    const _renderMonth = lazyLoad ? 3 : maxMonth;
    for (let i = 0; i < _renderMonth; i++) {
      res.push(
        !isHistorical
          ? initMonth.clone().add(i, 'month').format('YYYYMM')
          : initMonth.clone().subtract(i, 'month').format('YYYYMM')
      );
    }
    return res;
  };

  const [monthList, setmonthList] = useState(getMonthStack());

  const loadMoreMonthStack = () => {
    const { isHistorical, lazyLoad } = props;
    if (lazyLoad) {
      const initMonth = dayJsMod(monthList[monthList.length - 1], 'YYYYMM', '');
      const res = monthList;
      for (let i = 1; i <= 3; i++) {
        res.push(
          !isHistorical
            ? initMonth.clone().add(i, 'month').format('YYYYMM')
            : initMonth.clone().subtract(i, 'month').format('YYYYMM')
        );
      }
      setmonthList(res);
    }
  };

  const onReset = () => {
    setStartDate(null);
    setUntilDate(null);

    props.onSelect(null, null);
  };

  const handleConfirmDate = () => {
    props.onConfirm?.(startDate, untilDate);

    props.onSelect(startDate, untilDate);
  };

  const handleRenderRow = (month: string) => {
    const {
      selectedBackgroundColor,
      selectedTextColor,
      todayColor,
      ignoreMinDate,
      minDate,
      maxDate,
      betweenBackgroundColor,
    } = props;
    let xavailableDates;
    if (availableDates && availableDates.length > 0) {
      xavailableDates = availableDates.filter(function (d) {
        if (d.indexOf(month) >= 0) return true;
      });
    } else {
      xavailableDates = availableDates;
    }

    return (
      <Month
        onSelectDate={onSelectDate}
        startDate={startDate}
        untilDate={untilDate}
        selectFlag={selectFlag}
        availableDates={xavailableDates}
        minDate={minDate ? dayJsMod(minDate, 'YYYYMMDD', '') : minDate}
        maxDate={maxDate ? dayJsMod(maxDate, 'YYYYMMDD', '') : maxDate}
        ignoreMinDate={ignoreMinDate}
        dayProps={{
          selectedBackgroundColor,
          selectedTextColor,
          todayColor,
          betweenBackgroundColor,
        }}
        month={month}
      />
    );
  };

  const flatList = useRef<FlatList>(null);

  return (
    <Box backgroundColor='background' zIndex={1000} flex={1}>
      <ShadowBox variant='thin' paddingBottom='xs' style={styles.margin_16}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-around'
          marginHorizontal='m'
          paddingTop='xs'>
          {props.dayHeadings.map((day) => {
            return (
              <Text variant='metadata' textAlign='center' key={day}>
                {day}
              </Text>
            );
          })}
        </Box>
      </ShadowBox>
      <FlatList
        ref={flatList}
        onEndReachedThreshold={0.2}
        onEndReached={() => {
          loadMoreMonthStack();
        }}
        initialScrollIndex={2}
        onScrollToIndexFailed={(info) => {
          const wait = new Promise((resolve) => setTimeout(resolve, 500));
          wait.then(() => {
            flatList.current?.scrollToIndex({
              index: info.index,
              animated: true,
            });
          });
        }}
        initialNumToRender={3}
        style={{ flex: 1 }}
        data={monthList}
        renderItem={({ item }) => {
          return handleRenderRow(item);
        }}
        keyExtractor={(index) => index.toString()}
        showsVerticalScrollIndicator={false}
      />

      {props.showButton ? (
        <Box
          flexDirection='row'
          justifyContent='space-around'
          position='absolute'
          bottom={20}
          left={0}
          right={0}
          marginHorizontal='m'>
          <Text> </Text>
          <Box flex={1}>
            <Button
              variant='editSelected'
              borderColor='grey03'
              padding='s'
              borderWidth={1}
              fullWidth
              accessibilityLabel='Clear'>
              <Text
                color='textSecondary'
                variant='buttonLabel'
                onPress={onReset}>
                Clear
              </Text>
            </Button>
          </Box>
          <Box flex={1} marginLeft='l'>
            <Button
              variant='primary'
              borderWidth={1}
              padding='s'
              fullWidth
              accessibilityLabel='Confirm'>
              <Text variant='buttonLabel' onPress={handleConfirmDate}>
                Confirm
              </Text>
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

DateRangePicker.propTypes = {
  initialMonth: PropTypes.string,
  dayHeadings: PropTypes.arrayOf(PropTypes.string),
  availableDates: PropTypes.arrayOf(PropTypes.string),
  maxMonth: PropTypes.number,
  buttonColor: PropTypes.string,
  buttonContainerStyle: PropTypes.object,
  startDate: PropTypes.string,
  untilDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  showReset: PropTypes.bool,
  showClose: PropTypes.bool,
  ignoreMinDate: PropTypes.bool,
  isHistorical: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onConfirm: PropTypes.func,
  placeHolderStart: PropTypes.string,
  placeHolderUntil: PropTypes.string,
  selectedBackgroundColor: PropTypes.string,
  betweenBackgroundColor: PropTypes.string,
  selectedTextColor: PropTypes.string,
  todayColor: PropTypes.string,
  infoText: PropTypes.string,
  infoStyle: PropTypes.object,
  infoContainerStyle: PropTypes.object,
  showSelectionInfo: PropTypes.bool,
  showButton: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  initialMonth: '',
  dayHeadings: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  maxMonth: 36,
  buttonColor: theme.colors.onBackground,
  buttonContainerStyle: {},
  showReset: true,
  showClose: true,
  ignoreMinDate: false,
  isHistorical: false,
  onClose: () => {},
  onSelect: () => {},
  onConfirm: () => {},
  placeHolderStart: 'Start Date',
  placeHolderUntil: 'Until Date',
  selectedBackgroundColor: theme.colors.onBackground,
  selectedTextColor: theme.colors.background,
  todayColor: theme.colors.onBackground,
  betweenBackgroundColor: theme.colors.grey02,
  startDate: '',
  untilDate: '',
  minDate: '',
  maxDate: '',
  infoText: '',
  infoStyle: { color: theme.colors.background, fontSize: 16 },
  infoContainerStyle: {
    marginRight: 20,
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: theme.colors.onBackground,
    borderRadius: 20,
    alignSelf: 'flex-end',
  },
  showSelectionInfo: true,
  showButton: true,
  lazyLoad: true,
};

const styles = StyleSheet.create({
  margin_16: {
    marginHorizontal: -16,
  },
});
