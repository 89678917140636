import { DocumentNode, useApolloClient } from '@apollo/client';
import { UploadProgressData } from 'expo-file-system';
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import {
  Document,
  DocumentAttributes,
  GetProjectDocument,
  GetTaskDocument,
  ListDraftProjectDocumentsDocument,
  ListDraftTaskDocumentsDocument,
  useAddProjectFilesMutation,
  useAddTaskFilesMutation,
  useListDraftProjectDocumentsQuery,
  useListDraftTaskDocumentsQuery,
} from '@graphql/generated';
import useFileProcessor from '@hooks/useFileProcessor';

type UploadingItem = {
  file: Document;
  progress: number;
};

type UploadFilesContext = {
  uploadingFiles: UploadingItem[];
  setUploadingFiles: React.Dispatch<React.SetStateAction<UploadingItem[]>>;
  uploadingErrorFiles: (Document | undefined)[];
  setUploadingErrorFiles: React.Dispatch<
    React.SetStateAction<(Document | undefined)[]>
  >;
  uploadingSuccessFiles: (Document | undefined)[];
  setUploadingSuccessFiles: React.Dispatch<
    React.SetStateAction<(Document | undefined)[]>
  >;
  lastSuccessBatch: Document[];
  setTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProjectId: React.Dispatch<React.SetStateAction<string | undefined>>;
  resendFiles: (documents: any[]) => Promise<void>;
  deleteFailedFiles: (documents: any[]) => void;
};

const uploadFilesContext = createContext<UploadFilesContext | undefined>(
  undefined
);

export const UploadFilesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = uploadFilesContext;
  const [uploadingFiles, setUploadingFiles] = useState<UploadingItem[]>([]);
  const [uploadingErrorFiles, setUploadingErrorFiles] = useState<
    (Document | undefined)[]
  >([]);
  const [uploadingSuccessFiles, setUploadingSuccessFiles] = useState<
    (Document | undefined)[]
  >([]);
  const [lastSuccessBatch, setLastSuccessBatch] = useState<Document[]>([]);

  const [taskId, setTaskId] = useState<string | undefined>();
  const [projectId, setProjectId] = useState<string | undefined>();

  const isProject = !!projectId;
  const queryDocument: DocumentNode = isProject
    ? ListDraftProjectDocumentsDocument
    : ListDraftTaskDocumentsDocument;

  const queryCacheKey = isProject
    ? 'listDraftProjectDocuments'
    : 'listDraftTaskDocuments';

  const queryVariables = useMemo(
    () => (isProject ? { projectId } : { taskId }),
    [projectId, taskId]
  );

  const client = useApolloClient();
  const draftRef = useRef<any[]>([]);

  const { data: draftProjectData } = useListDraftProjectDocumentsQuery({
    variables: { projectId: projectId || '' },
    fetchPolicy: 'cache-only',
    skip: !projectId,
  });

  const { data: draftTaskData } = useListDraftTaskDocumentsQuery({
    variables: { taskId: taskId || '' },
    fetchPolicy: 'cache-only',
    skip: !taskId,
  });

  const drafts = isProject
    ? draftProjectData?.listDraftProjectDocuments || []
    : draftTaskData?.listDraftTaskDocuments || [];

  const prevProjectId = useRef<string | undefined>();
  const prevTaskId = useRef<string | undefined>();

  useEffect(() => {
    const projectChanged =
      prevProjectId.current && prevProjectId.current !== projectId;
    const taskChanged = prevTaskId.current && prevTaskId.current !== taskId;

    if (projectChanged || taskChanged) {
      setUploadingErrorFiles([]);
      setUploadingSuccessFiles([]);
      setUploadingFiles([]);
      draftRef.current = [];
    }

    prevProjectId.current = projectId;
    prevTaskId.current = taskId;
  }, [projectId, taskId]);

  useEffect(() => {
    if (!drafts || drafts.length === 0) return;

    const newUploadableDrafts = drafts.filter(
      (d) => !d.failedAt && !draftRef.current.some((r) => r.id === d.id)
    );

    if (newUploadableDrafts.length > 0) {
      draftRef.current = [...draftRef.current, ...newUploadableDrafts];
      setUploadingFiles((prev: any) => {
        const existingIds = new Set(prev.map((item: any) => item.file.id));
        const newItems = newUploadableDrafts
          .filter((doc) => !existingIds.has(doc.id))
          .map((doc) => ({ file: doc, progress: 0 }));
        return [...prev, ...newItems];
      });

      uploadFiles(newUploadableDrafts);
    }

    const failedDrafts = drafts.filter((d) => d.failedAt != null);
    if (failedDrafts.length > 0) {
      setUploadingErrorFiles((prev: any) => {
        const existingIds = new Set(prev.map((doc: any) => doc?.id));
        const newDocs = failedDrafts.filter((doc) => !existingIds.has(doc.id));
        return [...prev, ...newDocs];
      });
    }
  }, [drafts]);

  useEffect(() => {
    if (lastSuccessBatch.length > 0) {
      const timeout = setTimeout(() => {
        setLastSuccessBatch([]);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [lastSuccessBatch]);

  const { processFiles } = useFileProcessor();

  const [addProjectFiles] = useAddProjectFilesMutation({
    refetchQueries: [
      'listProjectDocuments',
      { query: GetProjectDocument, variables: { id: projectId } },
    ],
  });

  const [addTaskFiles] = useAddTaskFilesMutation({
    refetchQueries: [
      'listTaskDocuments',
      { query: GetTaskDocument, variables: { id: taskId } },
    ],
  });

  const callback = (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => {
    const progress =
      uploadProgress.totalBytesSent / uploadProgress.totalBytesExpectedToSend;

    setUploadingFiles((prev) =>
      prev.map((item) =>
        item.file.clientId === documentClientId ? { ...item, progress } : item
      )
    );
  };

  const handleCompletedFiles = async (documents: any[]) => {
    const idsToRemove = documents.map((d) => d.id);
    client.cache.updateQuery(
      {
        query: queryDocument,
        variables: queryVariables,
      },
      (data) => {
        const currentList = data?.[queryCacheKey] || [];
        return {
          [queryCacheKey]: currentList.filter(
            (item: any) => !idsToRemove.includes(item.id)
          ),
        };
      }
    );
  };

  const handleFailedFiles = async (documents: any[]) => {
    const now = new Date().toISOString();
    const failedMap = new Map(documents.map((d) => [d.id, true]));

    client.cache.updateQuery(
      {
        query: queryDocument,
        variables: queryVariables,
      },
      (data) => {
        const currentList = data?.[queryCacheKey] || [];
        return {
          [queryCacheKey]: currentList.map((item: any) => {
            if (failedMap.has(item.id)) {
              return {
                ...item,
                failedAt: now,
              };
            }
            return item;
          }),
        };
      }
    );
  };

  const resendFiles = async (documents: any[]) => {
    const idsToResend = new Set(documents.map((d) => d.id));

    client.cache.updateQuery(
      {
        query: queryDocument,
        variables: queryVariables,
      },
      (data) => {
        const currentList = data?.[queryCacheKey] || [];
        return {
          [queryCacheKey]: currentList.map((item: any) => {
            if (idsToResend.has(item.id)) {
              return {
                ...item,
                failedAt: null,
              };
            }
            return item;
          }),
        };
      }
    );

    setUploadingErrorFiles((prev) =>
      prev.filter((doc) => !idsToResend.has(doc?.id))
    );

    draftRef.current = draftRef.current.filter(
      (doc) => !idsToResend.has(doc.id)
    );
  };

  const deleteFailedFiles = (documents: any[]) => {
    const idsToDelete = new Set(documents.map((d) => d.id));
    client?.cache.updateQuery(
      {
        query: queryDocument,
        variables: queryVariables,
      },
      (data) => {
        const currentList = data?.[queryCacheKey] || [];
        return {
          [queryCacheKey]: currentList.filter(
            (item: any) => !idsToDelete.has(item.id)
          ),
        };
      }
    );
  };

  const uploadFiles = async (documents: any[]) => {
    if (documents.length === 0) return;

    try {
      const response = await processFiles(documents, callback);

      const addFileFunc = projectId ? addProjectFiles : addTaskFiles;

      const attributes = documents.map((item) => {
        const {
          name,
          clientId,
          contentType,
          isImage = false,
          isAudio = false,
        } = item;

        const matched = response.find((r) => r.clientId === clientId);
        const blobId = matched?.blobId || '';

        if (!blobId) {
          console.warn(`⚠️ Missing blobId for file: ${name}`);
        }

        return {
          name,
          contentType,
          isImage,
          isAudio,
          blobId,
          clientId,
        } as DocumentAttributes;
      });

      addFileFunc({
        variables: {
          id: projectId ?? taskId ?? '',
          attributes,
        },
        onCompleted: () => {
          setUploadingSuccessFiles((prev) => [
            ...prev,
            ...documents.filter((doc) => !prev.some((d) => d?.id === doc.id)),
          ]);
          setUploadingFiles((prev) =>
            prev.filter((item) => !documents.some((d) => d.id === item.file.id))
          );
          setLastSuccessBatch(documents);
          handleCompletedFiles(documents);
        },
        onError: () => {
          setUploadingErrorFiles((prev) => [
            ...prev,
            ...documents.filter((doc) => !prev.some((d) => d?.id === doc.id)),
          ]);
          setUploadingFiles((prev) =>
            prev.filter((item) => !documents.some((d) => d.id === item.file.id))
          );
          handleFailedFiles(documents);
        },
      });
    } catch (err) {
      console.error('processFiles failed', err);
      setUploadingErrorFiles((prev) => [
        ...prev,
        ...documents.filter((doc) => !prev.some((d) => d?.id === doc.id)),
      ]);
      setUploadingFiles((prev) =>
        prev.filter((item) => !documents.some((d) => d.id === item.file.id))
      );
      handleFailedFiles(documents);
    }
  };

  return (
    <Provider
      value={{
        uploadingFiles,
        setUploadingFiles,
        uploadingErrorFiles,
        setUploadingErrorFiles,
        uploadingSuccessFiles,
        setUploadingSuccessFiles,
        lastSuccessBatch,
        setTaskId,
        setProjectId,
        resendFiles,
        deleteFailedFiles,
      }}>
      {children}
    </Provider>
  );
};

export const useUploadFiles = (): UploadFilesContext => {
  const context = useContext(uploadFilesContext);
  if (context === undefined) {
    throw new Error('useUploadFiles must be used within a Provider');
  }
  return context;
};
