import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import cloneDeep from 'lodash.clonedeep';
import mime from 'mime';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import uuid from 'react-native-uuid';

import { ModalHeader } from '@components/Headers/ModalHeader';
import Contact, { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  Document,
  DraftMessageAttributes,
  GetDraftMessageDocument,
  GetDraftMessageQuery,
  LocalFile,
  Message,
  MessageProject,
  MessageTask,
  Project,
  Task,
  useGetTaskQuery,
  useListChatIdentifiersQuery,
  useListMessagesQuery,
  useUpdateDraftMessageMutation,
} from '@graphql/generated';
import { ActiveChat } from '@hooks/useActiveChat';
import { useApolloClient } from '@hooks/useApolloClient';
import useMe from '@hooks/useMe';
import useSendMessage from '@hooks/useSendMessage';
import { useAppNavigation } from '@navigation/useAppNavigation';
import {
  isDocumentAttachment,
  isProjectAttachment,
  isTaskAttachment,
} from '@utils/tagCollectionService';

interface ForwardMessageContentProps {
  messageData:
    | Message
    | { message: null }
    | null
    | undefined
    | {
        attachments: Document[];
        body: string;
        isFromProjectMedia: boolean;
      };
  onCancelPress?: () => void;
  onForwardCompleted: () => void;
  taskId?: string;
}

let listChatsToContactType: (ContactType & {
  isGroupChat: boolean;
})[];

export const ForwardMessageContent: React.FC<ForwardMessageContentProps> = ({
  messageData,
  onCancelPress,
  onForwardCompleted,
  taskId,
}) => {
  const toast = useToast();
  const { isConnected } = useNetInfo();
  const navigation = useNavigation();
  const { navigateToChatDrawer } = useAppNavigation();
  const [filteredChats, setFilteredChats] = useState<
    (ContactType & {
      isGroupChat: boolean;
    })[]
  >();
  const { t } = useTranslation('shared');
  const [search, setSearch] = useState();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<
    ContactType & {
      isGroupChat: boolean;
    }
  >();
  const { client } = useApolloClient();
  const [updateDraftMessage] = useUpdateDraftMessageMutation();
  const { loading } = useListChatIdentifiersQuery({
    onCompleted: ({ listChats }) => {
      listChatsToContactType = listChats.map((c) => {
        return {
          displayName: c.name
            ? c.name
            : t('myDisplayName', { displayName: me?.name }),
          id: c.id,
          initials: c.name.charAt(0),
          avatar: c.avatar,
          isGroupChat: c.isGroupChat,
        };
      });
      setFilteredChats(listChatsToContactType);
    },
  });

  const { me } = useMe();
  const { sendDraftMessage } = useSendMessage();

  const { data: taskData } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  const { getTask: task } = taskData || {};

  const downloadImage = async (uri: string, doc1: Document) => {
    const RNFS = await import('react-native-fs');
    const filename = uri.split('/').pop();
    const localUri = `${FileSystem.cacheDirectory}${filename}`;
    await FileSystem.downloadAsync(uri, localUri);

    const clientId = uuid.v4().toString();
    let ext =
      doc1?.name?.split('.').pop() ??
      mime.getExtension(doc1?.contentType ?? '');
    if (!ext) ext = 'bin';

    const fileName = `${clientId}.${ext}`;
    const destPath = `${RNFS.DocumentDirectoryPath}/${fileName}`;

    try {
      RNFS.copyFile(localUri, destPath);
    } catch (err) {
      console.error('Cache file failed:', err);
    }
    return `file://${destPath}`;
  };

  const getBase64ImageFromUrl = async (imageUrl: string) => {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(new Error('Error: getBase64ImageFromUrl'));
      };
      reader.readAsDataURL(blob);
    });
  };

  const convertToLocalFileDocument = async (
    attachments: [Document],
    draftMessageId: string
  ) => {
    const newArray = new Array<Document>();
    await Promise.all(
      attachments.map(async (xdoc1: any) => {
        const doc1 = xdoc1 as Document;
        const url = doc1.file.url
          ? doc1.file.url
          : doc1.file.cdnBaseUrl + doc1.file.path;
        return Platform.OS === 'web'
          ? getBase64ImageFromUrl(url)
          : downloadImage(url, doc1);
      })
    )
      .then((fileurls) => {
        for (let x = 0; x < fileurls.length; x++) {
          const xitem = attachments[x] as Document;
          const copyItem = cloneDeep(xitem);
          copyItem.file.url = fileurls[x];
          newArray.push(copyItem);
        }
      })
      .catch((err) => {
        console.log('error when forward file to chat', err);
        Alert.alert(
          'Error happened when get file to forward to chat, Please try again.'
        );
      });

    return newArray.map((lf) => {
      const xclientId = uuid.v4().toString();

      const temp = {
        __typename: 'LocalFile',
        id: xclientId,
        name: lf.name,
        size: lf.size,
        clientId: xclientId,
        url: lf.file.url,
        cdnBaseUrl: '',
        path: '',
        contentType: lf.contentType,
        isImage: lf.isImage,
        isAudio: lf.isAudio,
        duration: lf.duration,
        isPreviewable: lf.file.isPreviewable,
        thumbnail: lf.file.thumbnail,
      };

      return {
        __typename: 'Document',
        id: xclientId,
        messageClientId: draftMessageId,
        messageCreateYn: false,
        name: temp.name,
        clientId: xclientId,
        contentType: lf.contentType,
        isImage: temp.isImage,
        size: temp.size,
        file: {
          ...temp,
          __typename: 'File',
          id: xclientId,
          cdnBaseUrl: '',
          url: temp.url,
        },
        isAudio: temp.isAudio,
        duration: temp.duration,
        isPreviewable: temp.isPreviewable,
        thumbnail: temp.thumbnail,
      };
    });
  };

  const checkData = (selectedChat?: ContactType) => {
    if (!messageData) {
      toast.show('Cache data may be lost', {
        duration: 3000,
      });
      return false;
    }

    if (!me) {
      toast.show('Please login again', {
        duration: 3000,
      });
      return false;
    }

    if (!selectedChat) return false;

    return true;
  };

  const { refetch } = useListMessagesQuery({
    variables: {
      chatId: selectedChat?.id ?? '',
      first: 20,
    },
    skip: !selectedChat,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    selectedChat &&
      refetch().then(() => {
        forwardMessageToChat(selectedChat);
      });
  }, [selectedChat]);

  const forwardMessageToChat = async (selectedChat?: ContactType) => {
    // make call to forward messageData

    const isOK = checkData(selectedChat);
    if (!isOK) {
      setShowLoading(false);
      return false;
    }

    const {
      attachments,
      body = '',
      isFromProjectMedia = false,
      isFromImagePreview = false,
    } = messageData;

    const draftMessageId = uuid.v4().toString();

    if (isFromImagePreview) {
      const draftMessage = {
        __typename: 'Message' as const,
        id: draftMessageId,
        chatId: selectedChat.id,
        authorId: me.id,
        clientId: draftMessageId,
        body,
        attachments: [
          ...((await convertToLocalFileDocument(attachments, draftMessageId)) ||
            []),
        ],
        author: me,
        createdAt: new Date().toISOString(),
        publishedAt: false,
        publish: false,
        isSender: true,
        chat: {
          ...selectedChat,
        },
        replyId: null,
        replyMessage: null,
        isDraft: true,
      };

      sendDraftMessage({ ...draftMessage });

      {
        Platform.OS === 'web'
          ? onForwardCompleted()
          : navigateToChatDrawer({
              screen: ChatDrawerScreen.details,
              chatId: selectedChat.id,
            });
      }
    } else if (isFromProjectMedia) {
      const params1 = {
        chatId: selectedChat.id,
        body: '',
        tagsCollection: [
          {
            tasks: [task],
            project: task?.project,
            author: me,
          },
        ],
        localFiles: [],
        replyMessage: null,
      };

      updateDraftMessage({
        variables: { chatId: selectedChat.id, attributes: params1 },
        onCompleted: () => {
          const toLocalFiles = attachments.map((doc: Document) => {
            const {
              file,
              clientId,
              contentType,
              duration,
              id,
              isAudio,
              isImage,
              name,
              size,
            } = doc;

            const result: LocalFile = {
              __typename: 'LocalFile',
              id,
              name,
              size,
              clientId,
              url: file.url,
              cdnBaseUrl: file.cdnBaseUrl,
              path: file.path,
              contentType: contentType!,
              isImage,
              duration: duration ?? null,
              isAudio: isAudio ?? false,
              thumbnail: file.thumbnail || '',
              isPreviewable: file.isPreviewable,
            };
            return result;
          });
          const { getDraftMessage: draftMessage } =
            client?.cache?.readQuery<GetDraftMessageQuery>({
              query: GetDraftMessageDocument,
              variables: {
                chatId: selectedChat.id,
              },
            }) || {
              getDraftMessage: {
                chatId: selectedChat.id,
                body,
                localFiles: [],
              },
            };

          const params: DraftMessageAttributes = {
            ...draftMessage,
            body: '',
            localFiles: [...(draftMessage?.localFiles ?? []), ...toLocalFiles],
            tagsCollection: [
              {
                tasks: [task],
                project: task?.project,
                author: me,
              },
            ],
            replyMessage: null,
          };

          updateDraftMessage({
            variables: { chatId: selectedChat.id, attributes: params },
            onCompleted: () => {
              Platform.OS === 'web'
                ? onForwardCompleted()
                : navigateToChatDrawer({
                    screen: ChatDrawerScreen.details,
                    chatId: selectedChat.id,
                  });
            },
            onError: (e) => console.log('err updateDraftMessage', e),
          });
        },
      });
    } else {
      const messageProjects = attachments?.filter(isProjectAttachment);
      const messageTasks = attachments?.filter(isTaskAttachment);
      const messageDocs = attachments?.filter(isDocumentAttachment);
      const projects = messageProjects?.map(
        (messageproject) => messageproject.project
      );
      const tasks = messageTasks?.map((messageTask) => messageTask.task);
      const projectIds = messageProjects?.map(
        (messageProject) => messageProject?.project?.id
      );
      const taskIds = messageTasks?.map((messageTask) => messageTask?.task?.id);

      const chatMessageTasks = tasks.map(
        (item: Task, index): MessageTask => ({
          __typename: 'MessageTask',
          id: `${draftMessageId}-${index}`,
          authorId: me.id,
          author: me,
          taskId: item.id,
          task: item,
        })
      );

      const chatMessageProjects = projects.map(
        (item: Project, index): MessageProject => ({
          __typename: 'MessageProject',
          id: `${draftMessageId}-${index}`,
          authorId: me.id,
          author: me,
          projectId: item.id,
          project: item,
        })
      );

      const chatMessageDocuments = await convertToLocalFileDocument(
        messageDocs,
        draftMessageId
      );

      const draftMessage = {
        __typename: 'Message' as const,
        id: draftMessageId,
        chatId: selectedChat.id,
        forwardingUserId: me.id,
        authorId: me.id,
        clientId: draftMessageId,
        body,
        tasks,
        taskIds,
        projects,
        projectIds,
        attachments: [
          ...(chatMessageProjects || []),
          ...(chatMessageTasks || []),
          ...(messageDocs.length > 0 ? chatMessageDocuments : []),
        ],
        author: me,
        createdAt: new Date().toISOString(),
        publishedAt: false,
        publish: false,
        isSender: true,
        readAt: null,
        readReceipts: null,
        chat: {
          ...selectedChat,
        },
        replyId: null,
        replyMessage: null,
        isDraft: true,
        isRetry: !isConnected,
        checkin: messageData?.checkin ?? null,
      };

      sendDraftMessage(draftMessage);

      Platform.OS !== 'web' ? navigation.goBack() : onForwardCompleted();

      navigateToChatDrawer({
        screen: ChatDrawerScreen.details,
        chatId: Platform.OS === 'web' ? '' : selectedChat.id,
        activeChat:
          Platform.OS === 'web' ? (selectedChat as ActiveChat) : undefined,
      });
    }

    setShowLoading(false);
  };

  useEffect(() => {
    if (search) {
      setFilteredChats(
        listChatsToContactType?.filter((i) =>
          i.displayName.toLowerCase().includes(search.trim().toLowerCase())
        )
      );
    } else {
      setFilteredChats(listChatsToContactType);
    }
  }, [search]);

  return (
    <Box
      overflow='hidden'
      height='100%'
      pt={Platform.OS === 'web' ? 'xs' : 'xxs'}>
      <Box marginHorizontal='m'>
        <ModalHeader
          title='Forward'
          showCancel
          onCancel={() =>
            Platform.OS === 'web' ? onCancelPress() : navigation.goBack()
          }
          cancelColor='greenSecondary'
        />
        <Box marginTop='xl' flexDirection='row' alignItems='center'>
          <SearchInput
            value={search}
            onTextChanged={(t: string) => setSearch(t)}
          />
        </Box>
      </Box>
      <Box marginTop='m' backgroundColor='grey01'>
        <Text
          variant='labelEmphasized'
          marginVertical='xs'
          marginHorizontal='m'>
          All chats
        </Text>
      </Box>
      {(loading || showLoading) && (
        <ActivityIndicatorLoading marginTop={125} top={125} />
      )}
      <FlatList
        data={filteredChats}
        ListHeaderComponent={() => <Box marginTop='m' />}
        renderItem={({ item, index }) => {
          return (
            <Contact
              contact={item}
              onPress={() => {
                if (showLoading) {
                  toast.show(
                    'Please wait until previous message is being forwarded',
                    {
                      duration: 3000,
                    }
                  );
                  return false;
                }

                if (filteredChats?.[index]) {
                  setShowLoading(true);
                  setSelectedChat(filteredChats?.[index]);
                }
              }}
              isContactGroup={item.isGroupChat}
              filterVal={search}
            />
          );
        }}
        ItemSeparatorComponent={() => <Box marginTop='l' />}
      />
    </Box>
  );
};
