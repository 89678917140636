import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;

export const typeDefs = gql`
  schema {
    query: Query
    mutation: Mutation
    subscription: Subscription
  }
  type AppVersions {
    currentAndroidBuild: String!
    currentIosBuild: String!
    message: String!
  }
  """
  Attachable objects such as Projects, Tasks, and Check-ins
  """
  union Attachment =
      Document
    | EntityUser
    | Folder
    | LocalFile
    | Message
    | MessageProject
    | MessageTask
    | MessageTaskAssignment
    | MessageTaskComplete
    | Project
    | ProjectUser
    | Task
    | TaskUser
    | UserRequest
  type Audit {
    attachment: Attachment
    createdAt: ISO8601DateTime!
    event: String!
    id: ID!
    projectRole: ProjectMemberRole
    user: User!
  }
  """
  The connection type for Audit.
  """
  type AuditConnection {
    """
    A list of edges.
    """
    edges: [AuditEdge]
    """
    A list of nodes.
    """
    nodes: [Audit]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  An edge in a connection.
  """
  type AuditEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Audit
  }
  """
  Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string.
  """
  scalar BigInt
  type Chat {
    audits: [Audit!]
    avatar: File
    createdAt: ISO8601DateTime!
    description: String
    friendRequestAccepted: Boolean
    groupChat: Boolean!
    id: ID!
    isGroupChat: Boolean!
    isNewChat: Boolean
    isUserBlocked: Boolean
    leaveOn: ISO8601DateTime
    messageBody: String
    messageIcon: String
    messagePublishedAt: ISO8601DateTime
    messagesCount: Int
    mute: Boolean @deprecated(reason: "Mute has been moved to the settings")
    name: String!
    owner: User!
    pin: Boolean @deprecated(reason: "Pin has been moved to the settings")
    recentActivityAt: ISO8601DateTime
    settings: UserChatSetting
    unreadCount: Int
    users: [User!]!
  }
  """
  Attributes for creating or updating a chat
  """
  input ChatAttributes {
    avatarBlobId: ID
    description: String
    groupChat: Boolean
    mute: Boolean
    name: String
    pin: Boolean
    userIds: [ID!]
  }
  """
  The connection type for Chat.
  """
  type ChatConnection {
    """
    A list of edges.
    """
    edges: [ChatEdge]
    """
    A list of nodes.
    """
    nodes: [Chat]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  An edge in a connection.
  """
  type ChatEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Chat
  }
  """
  Project type in chat tags
  """
  type ChatProjectTask {
    archivedAt: ISO8601DateTime
    createdAt: ISO8601DateTime!
    creator: User!
    currentUserRole: ProjectMemberRole!
    id: ID!
    name: String!
    tasks: [Task!]
  }
  """
  The connection type for ChatProjectTask.
  """
  type ChatProjectTaskConnection {
    """
    A list of edges.
    """
    edges: [ChatProjectTaskEdge]
    """
    A list of nodes.
    """
    nodes: [ChatProjectTask]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  An edge in a connection.
  """
  type ChatProjectTaskEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: ChatProjectTask
  }
  """
  A light version of ChatUpdatedType for subscriptions only.
  """
  type ChatUpdate {
    id: ID!
    messageBody: String
    name: String!
    recentActivityAt: ISO8601DateTime
    unreadCount: Int
  }
  """
  Type for CollectionMetadataType
  """
  type CollectionMetadata {
    """
    Current Page of loaded data
    """
    currentPage: Int!
    """
    The number of items per page
    """
    limitValue: Int!
    """
    The total number of items to be paginated
    """
    totalCount: Int!
    """
    The total number of pages in the pagination
    """
    totalPages: Int!
  }
  """
  Attributes for get contact invitation url
  """
  input CreateContactInvitationUrlAttributes {
    phoneNumber: [String!]!
  }
  """
  Autogenerated return type of CreateShareLink.
  """
  type CreateShareLinkPayload {
    sharedUrl: String
  }
  type Device {
    createdAt: ISO8601DateTime!
    id: ID!
    token: String!
    tokenType: DeviceToken
    user: User!
  }
  """
  Attributes for creating a user device
  """
  input DeviceAttributes {
    token: String!
    tokenType: DeviceToken
  }
  enum DeviceToken {
    EXPO
    FIREBASE
  }
  """
  Represents direct upload credentials
  """
  type DirectUpload {
    """
    Created blob record ID
    """
    blobId: ID!
    """
    Client-side value used to correlate file with blob ID
    """
    clientId: ID!
    """
    HTTP request headers (JSON-encoded)
    """
    headers: String!
    """
    Created blob record signed ID
    """
    signedBlobId: ID!
    """
    Upload URL
    """
    url: String!
  }
  """
  File information required to prepare a direct upload
  """
  input DirectUploadAttributes {
    """
    File size (bytes)
    """
    byteSize: Int!
    """
    MD5 file checksum as base64
    """
    checksum: String!
    """
    Client-side value used to correlate file with blob ID
    """
    clientId: ID!
    """
    File content type
    """
    contentType: String
    """
    Original file name
    """
    filename: String!
  }
  """
  Documents
  """
  type Document {
    clientId: String!
    contentType: String
    createdAt: ISO8601DateTime!
    duration: Int
    expired: Boolean!
    failedAt: ISO8601DateTime!
    file: File!
    folder: Folder
    id: ID!
    isAudio: Boolean!
    isImage: Boolean!
    isVideo: Boolean!
    messageClientId: String
    messageCreateYn: Boolean
    messageDocuments: [MessageDocument!]
    name: String!
    owner: User!
    projectDocuments: [ProjectDocument!]
    size: Int
    taskDocuments: [TaskDocument!]
    uuid: ID!
  }
  """
  Attributes for creating or updating a document
  """
  input DocumentAttributes {
    blobId: ID!
    clientId: ID!
    contentType: String
    duration: Int
    folderId: ID
    isAudio: Boolean!
    isImage: Boolean!
    name: String
    projectIds: [ID!]
    taskIds: [ID!]
  }
  """
  DocumentCollection type
  """
  type DocumentCollection {
    """
    A collection of paginated DocumentCollection
    """
    collection: [Document!]!
    """
    Pagination Metadata for navigating the Pagination
    """
    metadata: CollectionMetadata!
  }
  """
  The connection type for Document.
  """
  type DocumentConnection {
    """
    A list of edges.
    """
    edges: [DocumentEdge]
    """
    A list of nodes.
    """
    nodes: [Document]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  An edge in a connection.
  """
  type DocumentEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Document
  }
  enum DocumentGroup {
    FILE
    MEDIA
  }
  enum DocumentSortOption {
    DATE_ASC
    DATE_DESC
    NAME_ASC
    NAME_DESC
  }
  type DraftMessage {
    body: String
    chatId: ID!
    checkin: MessageCheckin
    localFiles: [LocalFile]!
    replyMessage: Message
    tagsCollection: [TaskTagCollection]!
  }
  input DraftMessageAttributes {
    body: String
    checkin: MessageCheckinAttributes
    localFiles: [LocalFileInput]!
    replyMessage: ReplyMessageInput
    tagsCollection: [TaskTagCollectionInput]!
  }
  """
  Generic type representing users removed from project or task
  """
  type EntityUser {
    id: ID!
    project: Project
    task: Task
    user: User!
  }
  """
  Autogenerated return type of ExportCheckins.
  """
  type ExportCheckinsPayload {
    filename: String!
    url: String!
  }
  """
  Features
  """
  type FeatureFlag {
    enabled: Boolean!
    name: String!
  }
  """
  File attachments
  """
  type File {
    cdnBaseUrl: String!
    id: ID!
    isPreviewable: Boolean!
    path: String!
    thumbnail: String
    url: String!
  }
  """
  Folders
  """
  type Folder {
    id: ID!
    name: String!
    owner: User!
    parent: Folder
    projects: [Project!]
    tasks: [Task!]
  }
  """
  Attributes for creating or updating a folder
  """
  input FolderAttributes {
    name: String
    parentId: ID
    projectIds: [ID!]
    taskIds: [ID!]
  }
  """
  Autogenerated return type of GenerateReport.
  """
  type GenerateReportPayload {
    uuid: String!
  }
  type Group {
    createdAt: ISO8601DateTime!
    currentUserTeamRole: TeamRole
    id: ID!
    name: String!
    team: Team
    users: [User!]
  }
  """
  Attributes for creating or updating a group
  """
  input GroupAttributes {
    accountId: ID!
    name: String!
    userIds: [ID!]
  }
  type Highlight {
    authorName: [String!]
    body: [String!]
    chatName: [String!]
    documentNames: [String!]
    email: [String!]
    name: [String!]
    projectNames: [String!]
    taskNames: [String!]
  }
  """
  An ISO 8601-encoded datetime
  """
  scalar ISO8601DateTime
  """
  Plan type
  """
  type Invoice {
    amount: Int!
    date: ISO8601DateTime!
    description: String!
    id: ID!
    invoiceUrl: String!
    payment: Payment
    status: String!
  }
  """
  Represents untyped JSON
  """
  scalar JSON
  """
  LocalFile is strictly for generating an extendable type via codegen
  """
  type LocalFile {
    cdnBaseUrl: String!
    clientId: String!
    contentType: String!
    duration: Int
    id: ID!
    isAudio: Boolean!
    isImage: Boolean!
    isPreviewable: Boolean!
    isVideo: Boolean!
    name: String!
    path: String!
    size: Int
    thumbnail: String!
    url: String!
  }
  input LocalFileInput {
    cdnBaseUrl: String!
    clientId: String!
    contentType: String!
    duration: Int
    id: ID!
    isAudio: Boolean!
    isImage: Boolean!
    isPreviewable: Boolean!
    isVideo: Boolean!
    name: String!
    path: String!
    size: Int
    thumbnail: String!
    url: String!
  }
  """
  Autogenerated return type of MeUpdated.
  """
  type MeUpdatedPayload {
    chats: [Chat!]
    profile: Profile
  }
  """
  Attributes for assigning membership to a user
  """
  input MemberAttributes {
    role: TeamRole!
    userId: ID!
  }
  """
  Message type
  """
  type Message implements Node {
    attachments: [Attachment!]
    author: User!
    authorId: ID!
    body: String
    chat: Chat!
    chatId: ID!
    checkin: MessageCheckin
    clientId: String!
    createdAt: ISO8601DateTime!
    cursor: String!
    failedAt: ISO8601DateTime!
    filesCount: Int
    forwardedId: Int
    id: ID!
    imagesCount: Int
    isDraft: Boolean!
    isRetry: Boolean!
    isSender: Boolean!
    localFiles: [LocalFile]
    projectIds: [ID!]
    projectsCount: Int
    publish: Boolean
    publishedAt: ISO8601DateTime
    reaction: [MessageReaction!]
    readAt: ISO8601DateTime
    readReceipts: [User!]
    replyId: ID
    replyMessage: Message
    taskIds: [ID!]
    tasksCount: Int
    userRequest: UserRequest
  }
  """
  Attributes for creating or updating a message
  """
  input MessageAttributes {
    body: String
    chatId: ID!
    checkin: MessageCheckinAttributes
    clientId: String
    documents: [DocumentAttributes!]
    folders: [FolderAttributes!]
    forwardedId: ID
    friendRequestAccepted: Boolean
    messageProjects: [MessageProjectAttributes!]
    messageTasks: [MessageTaskAttributes!]
    projectIds: [ID!]
    publish: Boolean
    replyId: ID
    taskIds: [ID!]
  }
  """
  MessageCheckin type
  """
  type MessageCheckin {
    address: String
    city: String
    country: String
    id: ID!
    latitude: Float
    longitude: Float
    message: User
    state: String
    user: User
    zipcode: String
  }
  """
  Attributes for creating checkin when create a message
  """
  input MessageCheckinAttributes {
    address: String!
    city: String
    country: String
    county: String
    latitude: Float!
    longitude: Float!
    state: String
    zipcode: String
  }
  """
  The connection type for Message.
  """
  type MessageConnection {
    """
    A list of edges.
    """
    edges: [MessageEdge]
    """
    A list of nodes.
    """
    nodes: [Message]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  MessageDocument type
  """
  type MessageDocument {
    document: Document
    documentId: ID!
    id: ID!
    message: Message
    owner: User
  }
  """
  An edge in a connection.
  """
  type MessageEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Message
  }
  """
  MessageProject type
  """
  type MessageProject {
    author: User
    authorId: ID!
    id: ID!
    message: Message
    project: Project
    projectId: ID!
  }
  """
  Attributes for creating or updating a message
  """
  input MessageProjectAttributes {
    authorId: ID!
    projectId: ID!
  }
  """
  MessageReaction type
  """
  type MessageReaction {
    count: Int!
    id: ID!
    message: Message
    reaction: String!
    reactionId: ID!
    user: [User!]
  }
  """
  Attributes for creating or updating message tags
  """
  input MessageTagAttributes {
    projectIds: [ID!]
    taskIds: [ID!]
  }
  """
  MessageTask type
  """
  type MessageTask {
    author: User
    authorId: ID!
    id: ID!
    message: Message
    task: Task
    taskId: ID!
  }
  """
  MessageTaskAssignment type
  """
  type MessageTaskAssignment {
    assignees: [User!]!
    id: ID!
    messageId: ID!
    task: Task
  }
  """
  Attributes for creating or updating a message
  """
  input MessageTaskAttributes {
    authorId: ID!
    taskId: ID!
  }
  """
  MessageTaskComplete type
  """
  type MessageTaskComplete {
    completor: User
    id: ID!
    messageId: ID!
  }
  type Mutation {
    """
    Accept Team Invitation
    """
    acceptTeamInvitation(invitationToken: String!): Team!
    """
    Upload files to a project
    """
    addProjectFiles(attributes: [DocumentAttributes!]!, id: ID!): Project!
    """
    Add checklist item to a task
    """
    addTaskChecklist(
      attributes: TaskChecklistAttributes!
      taskId: ID!
    ): [TaskChecklist!]!
    """
    Upload files to a task
    """
    addTaskFiles(attributes: [DocumentAttributes!]!, id: ID!): Task!
    """
    Archive a chat
    """
    archiveChat(id: ID!): Chat!
    """
    Archive a project
    """
    archiveProject(id: ID!): Project!
    """
    Assign an owner to a task
    """
    assignTaskOwner(id: ID!, newOwnerId: ID!): Task!
    """
    Assign users to a task created by or assigned to the current user
    """
    assignTaskUsers(
      chatId: ID = null
      id: ID!
      members: [TaskMemberAttributes!]
    ): Task!
    """
    Assign an owner to a team
    """
    assignTeamOwner(id: ID!, newOwnerId: ID!): Team!
    cancelStripeSubscription(id: ID!): Team
    """
    Block a user
    """
    chatBlockUnblockUser(userIdToBlock: ID!): User!
    """
    Toggle task completion by ID and current user
    """
    completeTask(chatId: ID = null, id: ID!): Task!
    """
    Create a new chat
    """
    createChat(attributes: ChatAttributes!): Chat!
    createContactInvitationUrl(
      attributes: CreateContactInvitationUrlAttributes!
    ): String
    createDevice(attributes: DeviceAttributes!): Device
    createDirectUploads(
      attributes: [DirectUploadAttributes!]!
    ): [DirectUpload!]!
    """
    Create a new draft message
    """
    createDraftMessage(attributes: MessageAttributes!): Message!
    """
    Create a new group
    """
    createGroup(attributes: GroupAttributes!): Group!
    createInvite(projectId: ID = null, taskId: ID = null): String
    """
    Create a new message
    """
    createMessageIndividual(attributes: MessageAttributes!): Message!
    """
    Create a message reaction
    """
    createMessageReaction(messageId: ID!, reaction: String!): MessageReaction!
    """
    Create a new phase
    """
    createPhase(attributes: PhaseAttributes!): Phase!
    createProject(attributes: ProjectAttributes!): Project
    createShareLink(documentIds: [ID!]!): CreateShareLinkPayload
    createStripeSubscription(
      stripeSubscriptionAttributes: StripeSubscriptionAttributes!
      teamAttributes: TeamAttributes
    ): PaymentIntent
    """
    Create a new task
    """
    createTask(attributes: TaskAttributes!): Task!
    """
    Create a new team
    """
    createTeam(
      attributes: TeamAttributes!
      stripeSubscriptionAttributes: StripeSubscriptionAttributes
    ): Team!
    """
    Create a team invitation
    """
    createTeamInvitation(id: ID!): String!
    """
    Create a new user request
    """
    createUserRequest(attributes: UserRequestAttributes!): UserRequest!
    deleteDevice(expoToken: String!): Device
    """
    User declined the friend request
    """
    deleteFriendRequest(friendRequest: Boolean!, id: ID!): Chat
    """
    Delete a contact group
    """
    deleteGroup(id: ID!): Group
    """
    User deleting the group chat after leaving the chat group
    """
    deleteGroupChat(id: ID!): Chat
    """
    Delete a new message
    """
    deleteMessage(id: ID!): Message!
    """
    Delete a project
    """
    deleteProject(id: ID!): Project!
    """
    Delete a project files
    """
    deleteProjectFiles(ids: [ID!]): [ProjectDocument!]!
    """
    Delete a task
    """
    deleteTask(id: ID!): Task
    """
    Delete a task files
    """
    deleteTaskFiles(ids: [ID!]): [TaskDocument!]!
    """
    Delete a team
    """
    deleteTeam(id: ID!): Team
    """
    Duplicate project with optional associated records
    """
    duplicateProject(
      id: ID!
      includeAdmins: Boolean
      includeEditors: Boolean
      includeFiles: Boolean
      includeMembers: Boolean
      includePhases: Boolean
      includeTasks: Boolean
      includeViewers: Boolean
    ): Project!
    """
    Duplicate task with optional associated records
    """
    duplicateTask(
      id: ID!
      includeAssignees: Boolean
      includeCheckLists: Boolean
      includeCreator: Boolean
      includeFiles: Boolean
      includeViewers: Boolean
    ): Task!
    """
    Duplicate task files from source to destination.
    """
    duplicateTaskFiles(destinationId: ID!, sourceId: ID!): [TaskDocument!]!
    editStripeSubscription(
      stripeSubscriptionAttributes: StripeSubscriptionAttributes!
    ): PaymentIntent
    """
    Export project checkins as CSV with optional date range filtering
    """
    exportCheckins(
      from: ISO8601DateTime
      projectId: ID!
      to: ISO8601DateTime
    ): ExportCheckinsPayload
    """
    Create a new chat with friend request
    """
    friendRequest(attributes: ChatAttributes!): Chat!
    generateProjectReport(projectId: ID!): GenerateReportPayload
    """
    Leave a group chat
    """
    leaveChat(id: ID!): Chat
    """
    Leave a project
    """
    leaveProject(id: ID!): Project
    leaveTask(id: ID!, userId: ID!): Task
    """
    Leave a team
    """
    leaveTeam(id: ID!): Team!
    """
    Create read receipts for all active log by ID
    """
    markAuditsRead(attributes: ReadReceiptAuditAttributes!): [Audit!]!
    """
    Create read receipts for all messages by chat ID
    """
    markChatsRead(attributes: ReadReceiptChatAttributes!): [Chat!]!
    """
    Removes current user and related data
    """
    removeMe: User!
    """
    Remove a message reaction
    """
    removeMessageReaction(id: ID!): MessageReaction!
    """
    Remove checklist item from a task
    """
    removeTaskChecklist(id: ID!): TaskChecklist!
    """
    Update a members role on a team
    """
    removeTeamMember(id: ID!, userId: ID!): Team!
    """
    Set user primary team
    """
    setPrimaryTeam(accountId: ID!): User!
    """
    Unarchive a chat
    """
    unarchiveChat(id: ID!): Chat!
    """
    Unarchive a project
    """
    unarchiveProject(id: ID!): Project!
    """
    Update billing Period
    """
    updateBillingPeriod(priceId: String!, teamId: ID!): PaymentIntent
    """
    Update a chat
    """
    updateChat(attributes: ChatAttributes!, id: ID!): Chat!
    """
    Update group chat admin
    """
    updateChatAdmin(id: ID!, userId: ID!): Chat!
    """
    Update the draft message for the given chat
    """
    updateDraftMessage(
      attributes: DraftMessageAttributes!
      chatId: ID!
    ): DraftMessage
    """
    update a group
    """
    updateGroup(attributes: GroupAttributes!, id: ID!): Group!
    """
    Update current user profile information
    """
    updateMe(attributes: UserAttributes!): User!
    """
    Update a message
    """
    updateMessageIndividual(
      attributes: MessageAttributes!
      clientId: String
      id: ID
    ): Message!
    """
    Update message tags
    """
    updateMessageTags(attributes: MessageTagAttributes!, id: ID!): Message!
    """
    Update notification setting
    """
    updateNotificationSetting(
      attributes: [NotificationSettingAttributes!]!
    ): [UserNotificationSetting!]!
    """
    Update payment proccessor's customer's payment method.
    """
    updatePaymentMethod(paymentMethodToken: String!, teamId: ID!): Team!
    updateProject(attributes: ProjectAttributes!, id: ID!): Project
    """
    Update project file tags
    """
    updateProjectFileTags(id: ID!, projectId: ID!, taskIds: [ID!]): Document!
    """
    Update project members
    """
    updateProjectMembers(
      attributes: [ProjectMemberAttributes!]!
      id: ID!
    ): Project!
    """
    Update a task created by the current user
    """
    updateTask(attributes: TaskAttributes!, id: ID!): Task!
    """
    Update a task checklist
    """
    updateTaskChecklist(
      attributes: TaskChecklistAttributes!
      id: ID!
    ): TaskChecklist!
    """
    Update a task checklist
    """
    updateTaskChecklistSort(
      attributes: [TaskChecklistAttributes!]!
      taskId: ID!
    ): [TaskChecklist!]!
    """
    Update a task members
    """
    updateTaskMembers(attributes: [TaskMemberAttributes!]!, id: ID!): Task!
    """
    Update a team
    """
    updateTeam(attributes: TeamAttributes!, id: ID!): Team!
    """
    Update a members role on a team
    """
    updateTeamMembers(attributes: [MemberAttributes!]!, teamId: ID!): Team!
    """
    Update tutoralization setting
    """
    updateTutoralizationSetting(
      attributes: [TutoralizationSettingAttributes!]!
    ): [UserTutoralizationSetting!]!
    """
    Approve or reject a user request
    """
    updateUserRequestStatus(id: ID!, status: String!): UserRequest!
    uploadContacts(attributes: UploadContactAttributes!): User
  }
  """
  An object with an ID.
  """
  interface Node {
    """
    ID of the object.
    """
    id: ID!
  }
  enum NotificationSetting {
    CHAT
    DAILY_DIGEST
    FILE
    PROJECT
    TASK
    WEEKLY_UPDATE
  }
  """
  Attributes for updating notification setting
  """
  input NotificationSettingAttributes {
    duration: NotificationSettingDuration
    mute: Boolean!
    settingType: NotificationSetting!
  }
  enum NotificationSettingDuration {
    DAY
    FOREVER
    WEEK
  }
  """
  Information about pagination in a connection.
  """
  type PageInfo {
    """
    When paginating forwards, the cursor to continue.
    """
    endCursor: String
    """
    When paginating forwards, are there more items?
    """
    hasNextPage: Boolean!
    """
    When paginating backwards, are there more items?
    """
    hasPreviousPage: Boolean!
    """
    When paginating backwards, the cursor to continue.
    """
    startCursor: String
  }
  type Payment {
    brand: String!
    expMonth: String
    expYear: String
    last4: String
  }
  """
  Stripe payment intent type
  """
  type PaymentIntent {
    clientSecret: String!
    id: String!
    intentType: String!
    team: Team!
  }
  type Phase {
    createdAt: ISO8601DateTime!
    id: ID!
    name: String!
    user: User!
  }
  """
  Attributes for creating a phase
  """
  input PhaseAttributes {
    name: String!
    taskIds: [ID!]
  }
  """
  Plan type
  """
  type Plan {
    amount: Int!
    expiration: ISO8601DateTime!
    id: ID!
    interval: String!
    isCanceled: Boolean!
    isResumable: Boolean!
    name: String!
    payment: Payment
    quantity: Int!
    status: String!
  }
  """
  Price type
  """
  type Price {
    amount: Int!
    externalId: String!
    id: ID!
    interval: String!
    intervalCount: Int!
  }
  """
  Product type
  """
  type Product {
    allowMembers: Boolean!
    externalId: String!
    features: JSON!
    guestsOnly: Boolean!
    id: ID!
    maxDocumentStorage: BigInt!
    maxNumberOfProjects: BigInt!
    name: String!
    position: Int!
    prices: [Price!]!
  }
  type Profile {
    address: String
    avatar: File
    email: String!
    firstName: String
    id: ID!
    lastName: String
    name: String
    phoneNumber: String!
    skills: [Skill!]
    teams: [Team!]
    timeZone: String
    userActiveTasksCount: Int!
  }
  """
  Project type
  """
  type Project {
    activeTasksCount: Int!
    address: String
    archivedAt: ISO8601DateTime
    audits: [Audit!]
    auditsCount: Int
    authorId: ID!
    chatProjectsCount: Int!
    color: String!
    completedTasksCount: Int!
    coverImage: File
    createdAt: ISO8601DateTime!
    creator: User!
    currentUserHasPendingMemberAdditionRequest: Boolean!
    currentUserRole: ProjectMemberRole
    currentUserTeamRole: TeamMemberRole
    description: String
    documentCount: Int!
    group: Group
    icon: String
    id: ID!
    imageCount: Int!
    initials: String!
    isTaskMember: Boolean!
    latitude: Float
    longitude: Float
    members: [ProjectMember!]!
    name: String!
    overdueTasksCount: Int!
    personal: Boolean!
    selectedUserProjectRole: ProjectMemberRole!
    tasks: [Task!]
      @deprecated(
        reason: "listTasks query accepts a projectId argument and paginates results"
      )
    tasksCount: Int!
    tasksTagList: [Task!]
    team: Team
    teamId: ID!
    unreadAudits: [Audit!]
    unreadAuditsCount: Int
    upcomingTasksCount: Int!
    usersCount: Int!
  }
  """
  Attributes for creating or updating a project
  """
  input ProjectAttributes {
    address: String
    color: String!
    description: String
    groupId: ID
    icon: String
    latitude: Float
    longitude: Float
    members: [ProjectMemberAttributes!]!
    name: String!
    teamId: ID!
  }
  """
  The connection type for Project.
  """
  type ProjectConnection {
    """
    A list of edges.
    """
    edges: [ProjectEdge]
    """
    A list of nodes.
    """
    nodes: [Project]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  ProjectDocument type
  """
  type ProjectDocument {
    document: Document
    id: ID!
    owner: User
    project: Project
  }
  """
  An edge in a connection.
  """
  type ProjectEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Project
  }
  type ProjectMember {
    id: ID!
    project: Project!
    role: ProjectMemberRole!
    user: User!
  }
  """
  Attributes for assigning project membership to a user
  """
  input ProjectMemberAttributes {
    groupId: ID
    role: ProjectMemberRole!
    userId: ID!
  }
  enum ProjectMemberRole {
    ADMIN
    EDITOR
    OWNER
    VIEWER
  }
  """
  Project overview type
  """
  type ProjectReportOverview {
    address: String
    createdAt: ISO8601DateTime!
    creator: User!
    description: String
    id: ID!
    members: [ProjectMember!]!
    name: String!
    teamName: String!
  }
  enum ProjectSortOption {
    ACTIVITY_DESC
    ID_ASC
    ID_DESC
    NAME_ASC
    NAME_DESC
    RECENT_TAGGED_MESSAGE_DESC
  }
  type ProjectTag {
    id: ID!
    name: String!
  }
  input ProjectTagInput {
    id: ID!
    name: String!
  }
  """
  Project type
  """
  type ProjectTask {
    archivedAt: ISO8601DateTime
    createdAt: ISO8601DateTime!
    creator: User!
    currentUserRole: ProjectMemberRole!
    id: ID!
    members: [ProjectMember!]!
    name: String!
    tasks: [Task!]
  }
  """
  The connection type for ProjectTask.
  """
  type ProjectTaskConnection {
    """
    A list of edges.
    """
    edges: [ProjectTaskEdge]
    """
    A list of nodes.
    """
    nodes: [ProjectTask]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  An edge in a connection.
  """
  type ProjectTaskEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: ProjectTask
  }
  type ProjectTasksReport {
    completedAt: ISO8601DateTime
    creator: User
    dueDate: ISO8601DateTime
    id: ID!
    members: [TaskMember!]!
    name: String!
    priority: TaskPriority
    startDate: ISO8601DateTime
    status: TaskStatus
  }
  """
  ProjectUser type
  """
  type ProjectUser {
    id: ID!
    project: Project!
    user: User!
  }
  type Query {
    """
    Return all app versions
    """
    appVersions: AppVersions!
    """
    Return documents by client_ids
    """
    documentsByClientIds(clientIds: [ID!]): [Document!]
    """
    Return chat by ID
    """
    getChat(id: ID!): Chat!
    """
    Return a draft message for a chat
    """
    getDraftMessage(chatId: ID!): DraftMessage
    """
    Return the flipper feature for the current user
    """
    getFeatureFlag(name: String!): FeatureFlag!
    """
    Return group by ID
    """
    getGroup(id: ID!): Group
    """
    Returns my information
    """
    getMe: User!
    """
    Return product by ID
    """
    getProduct(id: ID!): Product!
    """
    Returns user profile by ID
    """
    getProfile(id: ID!): Profile!
    """
    Return project by ID
    """
    getProject(id: ID!): Project!
    """
    Return project report by ID
    """
    getProjectReport(id: ID!): String
    """
    Get project task related activity by project report uuid
    """
    getProjectTaskActivityReport(
      limit: Int = null
      taskId: ID!
      uuid: ID!
    ): [Audit!]
    """
    Get project task details by project report uuid
    """
    getProjectTaskReport(taskId: ID!, uuid: ID!): ProjectTasksReport
    """
    Return task by ID
    """
    getTask(id: ID!): Task!
    """
    Return team by ID
    """
    getTeam(id: ID!, includePending: Boolean = false): Team!
    """
    Return a list of activities by project ID
    """
    listActivitiesByProject(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      projectId: ID!
    ): AuditConnection!
    """
    Return a list of activities by task ID
    """
    listActivitiesByTask(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      taskId: ID!
    ): AuditConnection!
    """
    Return blocked users of current user
    """
    listBlockedUsers: [User!]!
    """
    Return all projects and related tasks tagged in chat
    """
    listChatRelatedProjects(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      chatId: ID
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      sortBy: ProjectSortOption = ID_ASC
    ): ChatProjectTaskConnection!
    """
    Return all chats the user is participating in
    """
    listChats(archivesOnly: Boolean = false): [Chat!]!
    """
    Return all chats the user is participating in
    """
    listChatsPagination(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      archivesOnly: Boolean = false
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
    ): ChatConnection!
    """
    Return all user's contacts
    """
    listContactUsers: [User!]!
    """
    Return a list of draft Documents for a chat
    """
    listDraftDocuments(chatId: ID!): [Document!]!
    """
    Return all draft messages by chat ID
    """
    listDraftMessages: [Message!]!
    """
    Return a list of draft Documents for a project
    """
    listDraftProjectDocuments(projectId: ID!): [Document!]!
    """
    Return a list of draft Documents for a task
    """
    listDraftTaskDocuments(taskId: ID!): [Document!]!
    """
    Return all flipper features for the current user
    """
    listFeatureFlags: [FeatureFlag!]!
    """
    Return all contact groups
    """
    listGroups: [Group!]
    """
    Return all messages by chat ID
    """
    listMessages(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      chatId: ID!
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      includeDraft: Boolean = false
      includePublished: Boolean = true
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
    ): MessageConnection
    """
    Returns pending user requests for user requestable object
    """
    listPendingUserRequests(attributes: UserRequestAttributes!): [UserRequest!]!
    """
    Return all phases
    """
    listPhases: [Phase!]!
    """
    Return all products
    """
    listProducts: [Product!]!
    """
    Return all project documents
    """
    listProjectDocuments(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      includeFiles: Boolean = false
      includeMedia: Boolean = false
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      ownerIds: [ID!] = null
      projectIds: [ID!] = null
      sortBy: DocumentSortOption = NAME_ASC
    ): DocumentConnection!
    """
    list project tasks by project report uuid
    """
    listProjectReportTasks(uuid: ID!): [ProjectTasksReport!]
    """
    Return all projects
    """
    listProjects(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      archivesOnly: Boolean = false
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      filterTeamsProjects: Boolean = false
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      locationOnly: Boolean = false
      memberIds: [ID!]
      ownerIds: [ID!]
      role: ProjectMemberRole
      sortBy: ProjectSortOption = ID_ASC
      teamIds: [ID!]
      userId: ID = null
    ): ProjectConnection!
    """
    Return all projects and related tasks
    """
    listProjectsTasks(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      chatId: ID
      collaboratorsOnly: Boolean = false
      excludeCompleted: Boolean = false
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      sortBy: ProjectSortOption = ID_ASC
    ): ProjectTaskConnection!
    """
    Return all projects associated with documents
    """
    listProjectsWithDocuments: [Project!]!
    """
    Return all projects by execluding specified roles
    """
    listProjectsWithoutSpecifiedRoles(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      roles: [ProjectMemberRole!]!
      sortBy: ProjectSortOption = ID_ASC
    ): ProjectConnection!
    """
    Return recent projects and related tasks tagged in chat
    """
    listRecentProjectsTasks(
      chatId: ID!
      collaboratorsOnly: Boolean = false
      excludeCompleted: Boolean = false
    ): [RecentProjectTask!]!
    """
    Return recent updated tasks with project
    """
    listRecentTasks(
      excludeCompleted: Boolean = false
      projectCollaborators: Boolean = false
      projectIds: [ID!]
      sortBy: TaskSortOption = ID_ASC
    ): [RecentTask!]!
    """
    List all skills
    """
    listSkills: [Skill!]!
    """
    Return all tag groups
    """
    listTagGroups(limit: Int = 5): [TagGroup!]!
    """
    Return checklists by task ID
    """
    listTaskChecklist(id: ID!): [TaskChecklist!]!
    """
    Return all tasks documents
    """
    listTaskDocuments(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      includeFiles: Boolean = false
      includeMedia: Boolean = false
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      ownerIds: [ID!] = null
      projectIds: [ID!] = null
      sortBy: DocumentSortOption = NAME_ASC
      taskIds: [ID!] = null
    ): DocumentConnection!
    """
    Return all tasks the user is participating in
    """
    listTasks(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      chatId: ID
      collaboratorsOnly: Boolean = false
      dueDate: ISO8601DateTime
      """
      Start day of the due date when filtering tasks
      """
      dueDateBegin: ISO8601DateTime
      """
      End day of the due date when filtering tasks
      """
      dueDateEnd: ISO8601DateTime
      dueGroup: TaskGroup
      excludeCompleted: Boolean = false
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
      memberIds: [ID!]
      phaseId: ID
      priority: [TaskPriority!]
      projectIds: [ID!]
      sortBy: TaskSortOption = ID_ASC
      startDate: ISO8601DateTime
      status: TaskStatus
      taskCollaborators: Boolean = false
      teamIds: [ID!]
      userId: ID = null
      withoutPhase: Boolean = false
    ): TaskConnection
    """
    Return all teams user is associated with
    """
    listTeams(role: ProjectMemberRole = null): [Team!]!
    """
    Return all unread messages for the given user and his chats
    """
    listUnreadMessages(
      """
      Returns the elements in the list that come after the specified cursor.
      """
      after: String
      """
      Returns the elements in the list that come before the specified cursor.
      """
      before: String
      """
      Returns the first _n_ elements from the list.
      """
      first: Int
      """
      Returns the last _n_ elements from the list.
      """
      last: Int
    ): MessageConnection!
    """
    Return all users excluding current user
    """
    listUsers(size: Int = 10, term: String!): [User!]!
    """
    List users by resource and optional search term
    """
    listUsersByResource(teamId: ID!, term: String): [User!]!
    """
    Return all documents with pagination.
    """
    newListDocuments(
      chatId: ID = null
      includeMedia: Boolean = false
      limit: Int = 10
      page: Int = 1
      sortBy: DocumentSortOption = NAME_ASC
      taskIds: [ID!] = null
    ): DocumentCollection!
    """
    Get project report cover page
    """
    projectReportCoverPage(uuid: ID!): ReportCoverPage
    """
    Get project report overview by uuid
    """
    projectReportOverview(uuid: ID!): ProjectReportOverview
    """
    Return search results by term
    """
    search(
      archiveProjectsOnly: Boolean = false
      chatId: ID
      collaboratorsOnly: Boolean = false
      completedTasksOnly: Boolean = false
      documentGroup: DocumentGroup = null
      documentOwnerIds: [ID!] = null
      files: Boolean = false
      from: Int = 0
      includeActivities: Boolean = false
      includeChats: Boolean = true
      includeCompletedTask: Boolean = false
      includeContacts: Boolean = false
      includeDocuments: Boolean = true
      includeMessages: Boolean = true
      includeProjects: Boolean = true
      includeTasks: Boolean = true
      includeTasksTags: Boolean = false
      media: Boolean = false
      projectId: ID
      size: Int = 25
      tags: Boolean = false
      taskDueGroup: TaskGroup
      taskId: ID
      term: String!
    ): [Search!]!
    validateContentType(contentType: String!): Boolean!
  }
  """
  Attributes for updating read receipts for active log
  """
  input ReadReceiptAuditAttributes {
    auditIds: [ID!]
  }
  """
  Attributes for updating read receipts for chat messages
  """
  input ReadReceiptChatAttributes {
    chatIds: [ID!]
  }
  union RecentProjectTask = Project | Task
  """
  Recent Task type
  """
  type RecentTask implements Node {
    archivedAt: ISO8601DateTime
    authorId: ID!
    checklists: [TaskChecklist!]
    completedAt: ISO8601DateTime
    completor: User
    createdAt: ISO8601DateTime!
    creator: User!
    deletedAt: ISO8601DateTime
    dependencies: [Task!]
    description: String
    dueDate: ISO8601DateTime
    id: ID!
    members: [TaskMember!]!
    name: String!
    priority: TaskPriority
    project: Project!
    projectId: ID!
    startDate: ISO8601DateTime
    userIds: [ID!]!
  }
  input ReplyMessageInput {
    cacheIdentifier: String!
    id: ID!
  }
  type ReportCoverPage {
    createdAt: ISO8601DateTime!
    generatedBy: String!
    projectName: String
    team: Team
    title: String
  }
  type Search {
    highlight: Highlight
    record: SearchableResult!
    total: Int!
  }
  union SearchableResult =
      Audit
    | Chat
    | Document
    | Message
    | Project
    | Task
    | User
  type Skill {
    createdAt: ISO8601DateTime!
    id: ID!
    name: String!
  }
  """
  Attributes for creating a Stripe customer and subscription
  """
  input StripeSubscriptionAttributes {
    name: String!
    priceId: String!
    quantity: Int
    teamId: ID
  }
  type Subscription {
    """
    Subscribe to chat updates
    """
    chatUpdate: ChatUpdate
    """
    Subscribe to chat updates
    """
    chatUpdated: Chat
      @deprecated(reason: "chatUpdated is deprecated in favour of chatUpdate")
    """
    Subscribe to personal updates
    """
    meUpdated: MeUpdatedPayload!
    """
    Subscribe to message updates by chat ID
    """
    messageUpdate(chatId: ID!): Message
    """
    Subscribe to project updates
    """
    projectUpdated: Project
  }
  """
  TagGroups
  """
  type TagGroup {
    author: User!
    createdAt: ISO8601DateTime!
    documents: [Document!]
    id: ID!
    isImageGroup: Boolean!
    message: Message
    project: Project!
    task: Task
    updatedAt: ISO8601DateTime!
  }
  """
  Task type
  """
  type Task implements Node {
    archivedAt: ISO8601DateTime
    audits: [Audit!]
    auditsCount: Int
    authorId: ID!
    chatTasksCount: Int!
    checklists: [TaskChecklist!]
    completedAt: ISO8601DateTime
    completor: User
    createdAt: ISO8601DateTime!
    creator: User!
    currentUserProjectRole: ProjectMemberRole
    currentUserProjectTeamRole: TeamMemberRole
    currentUserRole: TaskMemberRole
    deletedAt: ISO8601DateTime
    dependencies: [Task!]
    description: String
    documents: [Document!]
    dueDate: ISO8601DateTime
    dueGroup: String
    fixedOrder: Boolean
    frequency: TaskFrequency
    id: ID!
    members: [TaskMember!]!
    name: String!
    phase: Phase
    phaseId: ID
    priority: TaskPriority
    project: Project!
    projectId: ID!
    recurring: Boolean
    skillIds: [ID!]
    skills: [Skill!]
    startDate: ISO8601DateTime
    status: TaskStatus
    unreadAudits: [Audit!]
    unreadAuditsCount: Int
    userIds: [ID!]!
  }
  """
  Attributes for creating or updating a task
  """
  input TaskAttributes {
    checklists: [TaskChecklistAttributes!]
    completedAt: ISO8601DateTime
    completedById: ID
    dependencyIds: [ID!]
    description: String
    dueDate: ISO8601DateTime
    fixedOrder: Boolean
    frequency: TaskFrequency
    members: [TaskMemberAttributes!]
    name: String!
    phaseId: ID
    priority: TaskPriority
    projectId: ID!
    recurring: Boolean
    skillIds: [ID!]
    startDate: ISO8601DateTime
    status: TaskStatus
    userIds: [ID!]
  }
  type TaskChecklist {
    completedAt: ISO8601DateTime
    createdAt: ISO8601DateTime!
    id: ID!
    name: String!
    sort: Int
    taskId: ID!
  }
  """
  Attributes for assigning task membership to a user
  """
  input TaskChecklistAttributes {
    completed: Boolean = false
    id: ID = 0
    name: String = ""
    sort: Int = 0
  }
  """
  The connection type for Task.
  """
  type TaskConnection {
    """
    A list of edges.
    """
    edges: [TaskEdge]
    """
    A list of nodes.
    """
    nodes: [Task]
    """
    Information to aid in pagination.
    """
    pageInfo: PageInfo!
  }
  """
  TaskDocument type
  """
  type TaskDocument {
    document: Document
    id: ID!
    owner: User
    task: Task
  }
  """
  An edge in a connection.
  """
  type TaskEdge {
    """
    A cursor for use in pagination.
    """
    cursor: String!
    """
    The item at the end of the edge.
    """
    node: Task
  }
  enum TaskFrequency {
    DAILY
    MONTHLY
    WEEKLY
  }
  enum TaskGroup {
    CURRENT
    OVERDUE
    TODAY
    UPCOMING
  }
  type TaskMember {
    id: ID!
    role: TaskMemberRole!
    subject: Task!
    user: User!
  }
  """
  Attributes for assigning task membership to a user
  """
  input TaskMemberAttributes {
    role: TaskMemberRole!
    userId: ID!
  }
  enum TaskMemberRole {
    ASSIGNEE
    OWNER
    VIEWER
  }
  enum TaskPriority {
    HIGH
    LOW
    MEDIUM
  }
  enum TaskSortOption {
    DUE_DATE
    DUE_DATE_ASC
    DUE_DATE_DESC
    ID_ASC
    ID_DESC
    NAME_ASC
    NAME_DESC
    PROJECT_NAME_ASC
    PROJECT_NAME_DESC
    UPDATED_AT_DESC
  }
  enum TaskStatus {
    COMPLETED
    NOT_STARTED
    ONGOING
    ON_HOLD
    OVERDUE
  }
  type TaskTag {
    id: ID!
    name: String!
    project: ProjectTag
    projectId: ID!
    status: TaskStatus
    userIds: [ID!]!
  }
  type TaskTagCollection {
    author: User!
    project: ProjectTag!
    tasks: [TaskTag]!
  }
  input TaskTagCollectionInput {
    author: TaskTagUserInput!
    project: ProjectTagInput!
    tasks: [TaskTagInput]!
  }
  input TaskTagInput {
    id: ID!
    name: String!
    project: ProjectTagInput
    projectId: ID!
    status: TaskStatus
    userIds: [ID!]!
  }
  input TaskTagUserInput {
    id: ID!
    name: String!
  }
  """
  TaskUser type
  """
  type TaskUser {
    id: ID!
    task: Task!
    user: User!
  }
  type Team {
    address: String
    avatar: String
      @deprecated(reason: "Please use avatarFile to retrieve Team avatars")
    avatarFile: File
    completedTasksCount: Int!
    createdAt: ISO8601DateTime!
    fileStorageUsed: BigInt!
    guestsOnly: Boolean!
    hasReachedMaxProjects: Boolean!
    id: ID!
    invoices: [Invoice!]
    latitude: Float
    longitude: Float
    maxDocumentStorage: BigInt!
    maxNumberOfProjects: Int!
    mediaStorageUsed: BigInt!
    name: String!
    numberOfProjectsUsed: Int!
    owner: User!
    personal: Boolean!
    plan: Plan
    product: Product
    projects: [Project!]!
    skills: [Skill!]!
    storageRemaining: BigInt!
    stripeCustomerId: String
    tasksCount: Int!
    users: [User!]!
    usersCount: Int!
  }
  """
  Attributes for creating or updating a team
  """
  input TeamAttributes {
    address: String
    avatarBlobId: ID
    emails: [String!]
    latitude: Float
    longitude: Float
    members: [MemberAttributes!]
    name: String!
    skillIds: [ID!]
  }
  enum TeamMemberRole {
    ADMIN
    MEMBER
    OWNER
  }
  enum TeamRole {
    ADMIN
    MEMBER
    OWNER
  }
  enum TutoralizationSetting {
    ASSIGN_TASK
    HASHTAG
    TAG_MESSAGE
  }
  """
  Attributes for updating tutoralization setting
  """
  input TutoralizationSettingAttributes {
    close: Boolean!
    settingType: TutoralizationSetting!
  }
  """
  Attributes for uploading user contacts
  """
  input UploadContactAttributes {
    phoneNumbers: [String!]
  }
  type User {
    address: String
    avatar: File
    blockedUsers: [User!]
    contactsCount: Int!
    contactsSyncedAt: ISO8601DateTime
    createdAt: ISO8601DateTime!
    devices: [Device!]
    email: String!
    firstName: String
    friendRequestPending: Boolean
    id: ID!
    inviteUrl: String!
    lastName: String
    latitude: Float
    longitude: Float
    name: String
    notificationSetting: [UserNotificationSetting!]
    phoneNumber: String!
    primaryTeamId: ID
    provider: String
    skills: [Skill!]
    teamRoles: [TeamRole!]
    teams: [Team!]
    timeZone: String
    tutoralizationSetting: [UserTutoralizationSetting!]
    uid: String
  }
  """
  Attributes for creating or updating a user
  """
  input UserAttributes {
    address: String
    avatarBlobId: ID
    latitude: Float
    longitude: Float
    name: String!
    phoneNumber: String!
    skillIds: [ID!]!
    timeZone: String
  }
  type UserChatSetting {
    archivedAt: ISO8601DateTime
    id: ID!
    mute: Boolean!
    pin: Boolean!
  }
  """
  Notification setting type
  """
  type UserNotificationSetting {
    duration: NotificationSettingDuration
    mute: Boolean!
    settingType: NotificationSetting!
  }
  """
  User Request type
  """
  type UserRequest {
    id: ID!
    requestType: UserRequestCategory!
    reviewedBy: User
    status: String!
    user: User!
    userRequestableId: ID!
    userRequestableType: UserRequestable!
  }
  """
  Attributes for creating user requests
  """
  input UserRequestAttributes {
    requestType: UserRequestCategory!
    userRequestableId: ID!
    userRequestableType: UserRequestable!
  }
  enum UserRequestCategory {
    MEMBER_ADDITION
  }
  enum UserRequestable {
    PROJECT
  }
  """
  Tutoralization setting type
  """
  type UserTutoralizationSetting {
    close: Boolean!
    settingType: TutoralizationSetting!
  }
`;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type AppVersions = {
  __typename?: 'AppVersions';
  currentAndroidBuild: Scalars['String'];
  currentIosBuild: Scalars['String'];
  message: Scalars['String'];
};

/** Attachable objects such as Projects, Tasks, and Check-ins */
export type Attachment =
  | Document
  | EntityUser
  | Folder
  | LocalFile
  | Message
  | MessageProject
  | MessageTask
  | MessageTaskAssignment
  | MessageTaskComplete
  | Project
  | ProjectUser
  | Task
  | TaskUser
  | UserRequest;

export type Audit = {
  __typename?: 'Audit';
  attachment?: Maybe<Attachment>;
  createdAt: Scalars['ISO8601DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  projectRole?: Maybe<ProjectMemberRole>;
  user: User;
};

/** The connection type for Audit. */
export type AuditConnection = {
  __typename?: 'AuditConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuditEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Audit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AuditEdge = {
  __typename?: 'AuditEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Audit>;
};

export type Chat = {
  __typename?: 'Chat';
  audits?: Maybe<Array<Audit>>;
  avatar?: Maybe<File>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  friendRequestAccepted?: Maybe<Scalars['Boolean']>;
  groupChat: Scalars['Boolean'];
  id: Scalars['ID'];
  isGroupChat: Scalars['Boolean'];
  isNewChat?: Maybe<Scalars['Boolean']>;
  isUserBlocked?: Maybe<Scalars['Boolean']>;
  leaveOn?: Maybe<Scalars['ISO8601DateTime']>;
  messageBody?: Maybe<Scalars['String']>;
  messageIcon?: Maybe<Scalars['String']>;
  messagePublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  messagesCount?: Maybe<Scalars['Int']>;
  /** @deprecated Mute has been moved to the settings */
  mute?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  owner: User;
  /** @deprecated Pin has been moved to the settings */
  pin?: Maybe<Scalars['Boolean']>;
  recentActivityAt?: Maybe<Scalars['ISO8601DateTime']>;
  settings?: Maybe<UserChatSetting>;
  unreadCount?: Maybe<Scalars['Int']>;
  users: Array<User>;
};

/** Attributes for creating or updating a chat */
export type ChatAttributes = {
  avatarBlobId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  groupChat?: InputMaybe<Scalars['Boolean']>;
  mute?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['Boolean']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** The connection type for Chat. */
export type ChatConnection = {
  __typename?: 'ChatConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Chat>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatEdge = {
  __typename?: 'ChatEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Chat>;
};

/** Project type in chat tags */
export type ChatProjectTask = {
  __typename?: 'ChatProjectTask';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  currentUserRole: ProjectMemberRole;
  id: Scalars['ID'];
  name: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
};

/** The connection type for ChatProjectTask. */
export type ChatProjectTaskConnection = {
  __typename?: 'ChatProjectTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatProjectTaskEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatProjectTask>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatProjectTaskEdge = {
  __typename?: 'ChatProjectTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatProjectTask>;
};

/** A light version of ChatUpdatedType for subscriptions only. */
export type ChatUpdate = {
  __typename?: 'ChatUpdate';
  id: Scalars['ID'];
  messageBody?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recentActivityAt?: Maybe<Scalars['ISO8601DateTime']>;
  unreadCount?: Maybe<Scalars['Int']>;
};

/** Type for CollectionMetadataType */
export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  /** Current Page of loaded data */
  currentPage: Scalars['Int'];
  /** The number of items per page */
  limitValue: Scalars['Int'];
  /** The total number of items to be paginated */
  totalCount: Scalars['Int'];
  /** The total number of pages in the pagination */
  totalPages: Scalars['Int'];
};

/** Attributes for get contact invitation url */
export type CreateContactInvitationUrlAttributes = {
  phoneNumber: Array<Scalars['String']>;
};

/** Autogenerated return type of CreateShareLink. */
export type CreateShareLinkPayload = {
  __typename?: 'CreateShareLinkPayload';
  sharedUrl?: Maybe<Scalars['String']>;
};

export type Device = {
  __typename?: 'Device';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  token: Scalars['String'];
  tokenType?: Maybe<DeviceToken>;
  user: User;
};

/** Attributes for creating a user device */
export type DeviceAttributes = {
  token: Scalars['String'];
  tokenType?: InputMaybe<DeviceToken>;
};

export enum DeviceToken {
  Expo = 'EXPO',
  Firebase = 'FIREBASE',
}

/** Represents direct upload credentials */
export type DirectUpload = {
  __typename?: 'DirectUpload';
  /** Created blob record ID */
  blobId: Scalars['ID'];
  /** Client-side value used to correlate file with blob ID */
  clientId: Scalars['ID'];
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID'];
  /** Upload URL */
  url: Scalars['String'];
};

/** File information required to prepare a direct upload */
export type DirectUploadAttributes = {
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** Client-side value used to correlate file with blob ID */
  clientId: Scalars['ID'];
  /** File content type */
  contentType?: InputMaybe<Scalars['String']>;
  /** Original file name */
  filename: Scalars['String'];
};

/** Documents */
export type Document = {
  __typename?: 'Document';
  clientId: Scalars['String'];
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration?: Maybe<Scalars['Int']>;
  expired: Scalars['Boolean'];
  failedAt: Scalars['ISO8601DateTime'];
  file: File;
  folder?: Maybe<Folder>;
  id: Scalars['ID'];
  isAudio: Scalars['Boolean'];
  isImage: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  messageClientId?: Maybe<Scalars['String']>;
  messageCreateYn?: Maybe<Scalars['Boolean']>;
  messageDocuments?: Maybe<Array<MessageDocument>>;
  name: Scalars['String'];
  owner: User;
  projectDocuments?: Maybe<Array<ProjectDocument>>;
  size?: Maybe<Scalars['Int']>;
  taskDocuments?: Maybe<Array<TaskDocument>>;
  uuid: Scalars['ID'];
};

/** Attributes for creating or updating a document */
export type DocumentAttributes = {
  blobId: Scalars['ID'];
  clientId: Scalars['ID'];
  contentType?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['ID']>;
  isAudio: Scalars['Boolean'];
  isImage: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** DocumentCollection type */
export type DocumentCollection = {
  __typename?: 'DocumentCollection';
  /** A collection of paginated DocumentCollection */
  collection: Array<Document>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export enum DocumentGroup {
  File = 'FILE',
  Media = 'MEDIA',
}

export enum DocumentSortOption {
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type DraftMessage = {
  __typename?: 'DraftMessage';
  body?: Maybe<Scalars['String']>;
  chatId: Scalars['ID'];
  checkin?: Maybe<MessageCheckin>;
  localFiles: Array<Maybe<LocalFile>>;
  replyMessage?: Maybe<Message>;
  tagsCollection: Array<Maybe<TaskTagCollection>>;
};

export type DraftMessageAttributes = {
  body?: InputMaybe<Scalars['String']>;
  checkin?: InputMaybe<MessageCheckinAttributes>;
  localFiles: Array<InputMaybe<LocalFileInput>>;
  replyMessage?: InputMaybe<ReplyMessageInput>;
  tagsCollection: Array<InputMaybe<TaskTagCollectionInput>>;
};

/** Generic type representing users removed from project or task */
export type EntityUser = {
  __typename?: 'EntityUser';
  id: Scalars['ID'];
  project?: Maybe<Project>;
  task?: Maybe<Task>;
  user: User;
};

/** Autogenerated return type of ExportCheckins. */
export type ExportCheckinsPayload = {
  __typename?: 'ExportCheckinsPayload';
  filename: Scalars['String'];
  url: Scalars['String'];
};

/** Features */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

/** File attachments */
export type File = {
  __typename?: 'File';
  cdnBaseUrl: Scalars['String'];
  id: Scalars['ID'];
  isPreviewable: Scalars['Boolean'];
  path: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** Folders */
export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: User;
  parent?: Maybe<Folder>;
  projects?: Maybe<Array<Project>>;
  tasks?: Maybe<Array<Task>>;
};

/** Attributes for creating or updating a folder */
export type FolderAttributes = {
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of GenerateReport. */
export type GenerateReportPayload = {
  __typename?: 'GenerateReportPayload';
  uuid: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  createdAt: Scalars['ISO8601DateTime'];
  currentUserTeamRole?: Maybe<TeamRole>;
  id: Scalars['ID'];
  name: Scalars['String'];
  team?: Maybe<Team>;
  users?: Maybe<Array<User>>;
};

/** Attributes for creating or updating a group */
export type GroupAttributes = {
  accountId: Scalars['ID'];
  name: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Highlight = {
  __typename?: 'Highlight';
  authorName?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Array<Scalars['String']>>;
  chatName?: Maybe<Array<Scalars['String']>>;
  documentNames?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Array<Scalars['String']>>;
  projectNames?: Maybe<Array<Scalars['String']>>;
  taskNames?: Maybe<Array<Scalars['String']>>;
};

/** Plan type */
export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Int'];
  date: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  invoiceUrl: Scalars['String'];
  payment?: Maybe<Payment>;
  status: Scalars['String'];
};

/** LocalFile is strictly for generating an extendable type via codegen */
export type LocalFile = {
  __typename?: 'LocalFile';
  cdnBaseUrl: Scalars['String'];
  clientId: Scalars['String'];
  contentType: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isAudio: Scalars['Boolean'];
  isImage: Scalars['Boolean'];
  isPreviewable: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  name: Scalars['String'];
  path: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  thumbnail: Scalars['String'];
  url: Scalars['String'];
};

export type LocalFileInput = {
  cdnBaseUrl: Scalars['String'];
  clientId: Scalars['String'];
  contentType: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isAudio: Scalars['Boolean'];
  isImage: Scalars['Boolean'];
  isPreviewable: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  name: Scalars['String'];
  path: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  thumbnail: Scalars['String'];
  url: Scalars['String'];
};

/** Autogenerated return type of MeUpdated. */
export type MeUpdatedPayload = {
  __typename?: 'MeUpdatedPayload';
  chats?: Maybe<Array<Chat>>;
  profile?: Maybe<Profile>;
};

/** Attributes for assigning membership to a user */
export type MemberAttributes = {
  role: TeamRole;
  userId: Scalars['ID'];
};

/** Message type */
export type Message = Node & {
  __typename?: 'Message';
  attachments?: Maybe<Array<Attachment>>;
  author: User;
  authorId: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
  chat: Chat;
  chatId: Scalars['ID'];
  checkin?: Maybe<MessageCheckin>;
  clientId: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  cursor: Scalars['String'];
  failedAt: Scalars['ISO8601DateTime'];
  filesCount?: Maybe<Scalars['Int']>;
  forwardedId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  imagesCount?: Maybe<Scalars['Int']>;
  isDraft: Scalars['Boolean'];
  isRetry: Scalars['Boolean'];
  isSender: Scalars['Boolean'];
  localFiles?: Maybe<Array<Maybe<LocalFile>>>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  projectsCount?: Maybe<Scalars['Int']>;
  publish?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  reaction?: Maybe<Array<MessageReaction>>;
  readAt?: Maybe<Scalars['ISO8601DateTime']>;
  readReceipts?: Maybe<Array<User>>;
  replyId?: Maybe<Scalars['ID']>;
  replyMessage?: Maybe<Message>;
  taskIds?: Maybe<Array<Scalars['ID']>>;
  tasksCount?: Maybe<Scalars['Int']>;
  userRequest?: Maybe<UserRequest>;
};

/** Attributes for creating or updating a message */
export type MessageAttributes = {
  body?: InputMaybe<Scalars['String']>;
  chatId: Scalars['ID'];
  checkin?: InputMaybe<MessageCheckinAttributes>;
  clientId?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<DocumentAttributes>>;
  folders?: InputMaybe<Array<FolderAttributes>>;
  forwardedId?: InputMaybe<Scalars['ID']>;
  friendRequestAccepted?: InputMaybe<Scalars['Boolean']>;
  messageProjects?: InputMaybe<Array<MessageProjectAttributes>>;
  messageTasks?: InputMaybe<Array<MessageTaskAttributes>>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  publish?: InputMaybe<Scalars['Boolean']>;
  replyId?: InputMaybe<Scalars['ID']>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** MessageCheckin type */
export type MessageCheckin = {
  __typename?: 'MessageCheckin';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  message?: Maybe<User>;
  state?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  zipcode?: Maybe<Scalars['String']>;
};

/** Attributes for creating checkin when create a message */
export type MessageCheckinAttributes = {
  address: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Message>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** MessageDocument type */
export type MessageDocument = {
  __typename?: 'MessageDocument';
  document?: Maybe<Document>;
  documentId: Scalars['ID'];
  id: Scalars['ID'];
  message?: Maybe<Message>;
  owner?: Maybe<User>;
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Message>;
};

/** MessageProject type */
export type MessageProject = {
  __typename?: 'MessageProject';
  author?: Maybe<User>;
  authorId: Scalars['ID'];
  id: Scalars['ID'];
  message?: Maybe<Message>;
  project?: Maybe<Project>;
  projectId: Scalars['ID'];
};

/** Attributes for creating or updating a message */
export type MessageProjectAttributes = {
  authorId: Scalars['ID'];
  projectId: Scalars['ID'];
};

/** MessageReaction type */
export type MessageReaction = {
  __typename?: 'MessageReaction';
  count: Scalars['Int'];
  id: Scalars['ID'];
  message?: Maybe<Message>;
  reaction: Scalars['String'];
  reactionId: Scalars['ID'];
  user?: Maybe<Array<User>>;
};

/** Attributes for creating or updating message tags */
export type MessageTagAttributes = {
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** MessageTask type */
export type MessageTask = {
  __typename?: 'MessageTask';
  author?: Maybe<User>;
  authorId: Scalars['ID'];
  id: Scalars['ID'];
  message?: Maybe<Message>;
  task?: Maybe<Task>;
  taskId: Scalars['ID'];
};

/** MessageTaskAssignment type */
export type MessageTaskAssignment = {
  __typename?: 'MessageTaskAssignment';
  assignees: Array<User>;
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  task?: Maybe<Task>;
};

/** Attributes for creating or updating a message */
export type MessageTaskAttributes = {
  authorId: Scalars['ID'];
  taskId: Scalars['ID'];
};

/** MessageTaskComplete type */
export type MessageTaskComplete = {
  __typename?: 'MessageTaskComplete';
  completor?: Maybe<User>;
  id: Scalars['ID'];
  messageId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept Team Invitation */
  acceptTeamInvitation: Team;
  /** Upload files to a project */
  addProjectFiles: Project;
  /** Add checklist item to a task */
  addTaskChecklist: Array<TaskChecklist>;
  /** Upload files to a task */
  addTaskFiles: Task;
  /** Archive a chat */
  archiveChat: Chat;
  /** Archive a project */
  archiveProject: Project;
  /** Assign an owner to a task */
  assignTaskOwner: Task;
  /** Assign users to a task created by or assigned to the current user */
  assignTaskUsers: Task;
  /** Assign an owner to a team */
  assignTeamOwner: Team;
  cancelStripeSubscription?: Maybe<Team>;
  /** Block a user */
  chatBlockUnblockUser: User;
  /** Toggle task completion by ID and current user */
  completeTask: Task;
  /** Create a new chat */
  createChat: Chat;
  createContactInvitationUrl?: Maybe<Scalars['String']>;
  createDevice?: Maybe<Device>;
  createDirectUploads: Array<DirectUpload>;
  /** Create a new draft message */
  createDraftMessage: Message;
  /** Create a new group */
  createGroup: Group;
  createInvite?: Maybe<Scalars['String']>;
  /** Create a new message */
  createMessageIndividual: Message;
  /** Create a message reaction */
  createMessageReaction: MessageReaction;
  /** Create a new phase */
  createPhase: Phase;
  createProject?: Maybe<Project>;
  createShareLink?: Maybe<CreateShareLinkPayload>;
  createStripeSubscription?: Maybe<PaymentIntent>;
  /** Create a new task */
  createTask: Task;
  /** Create a new team */
  createTeam: Team;
  /** Create a team invitation */
  createTeamInvitation: Scalars['String'];
  /** Create a new user request */
  createUserRequest: UserRequest;
  deleteDevice?: Maybe<Device>;
  /** User declined the friend request */
  deleteFriendRequest?: Maybe<Chat>;
  /** Delete a contact group */
  deleteGroup?: Maybe<Group>;
  /** User deleting the group chat after leaving the chat group */
  deleteGroupChat?: Maybe<Chat>;
  /** Delete a new message */
  deleteMessage: Message;
  /** Delete a project */
  deleteProject: Project;
  /** Delete a project files */
  deleteProjectFiles: Array<ProjectDocument>;
  /** Delete a task */
  deleteTask?: Maybe<Task>;
  /** Delete a task files */
  deleteTaskFiles: Array<TaskDocument>;
  /** Delete a team */
  deleteTeam?: Maybe<Team>;
  /** Duplicate project with optional associated records */
  duplicateProject: Project;
  /** Duplicate task with optional associated records */
  duplicateTask: Task;
  /** Duplicate task files from source to destination. */
  duplicateTaskFiles: Array<TaskDocument>;
  editStripeSubscription?: Maybe<PaymentIntent>;
  /** Export project checkins as CSV with optional date range filtering */
  exportCheckins?: Maybe<ExportCheckinsPayload>;
  /** Create a new chat with friend request */
  friendRequest: Chat;
  generateProjectReport?: Maybe<GenerateReportPayload>;
  /** Leave a group chat */
  leaveChat?: Maybe<Chat>;
  /** Leave a project */
  leaveProject?: Maybe<Project>;
  leaveTask?: Maybe<Task>;
  /** Leave a team */
  leaveTeam: Team;
  /** Create read receipts for all active log by ID */
  markAuditsRead: Array<Audit>;
  /** Create read receipts for all messages by chat ID */
  markChatsRead: Array<Chat>;
  /** Removes current user and related data */
  removeMe: User;
  /** Remove a message reaction */
  removeMessageReaction: MessageReaction;
  /** Remove checklist item from a task */
  removeTaskChecklist: TaskChecklist;
  /** Update a members role on a team */
  removeTeamMember: Team;
  /** Set user primary team */
  setPrimaryTeam: User;
  /** Unarchive a chat */
  unarchiveChat: Chat;
  /** Unarchive a project */
  unarchiveProject: Project;
  /** Update billing Period */
  updateBillingPeriod?: Maybe<PaymentIntent>;
  /** Update a chat */
  updateChat: Chat;
  /** Update group chat admin */
  updateChatAdmin: Chat;
  /** Update the draft message for the given chat */
  updateDraftMessage?: Maybe<DraftMessage>;
  /** update a group */
  updateGroup: Group;
  /** Update current user profile information */
  updateMe: User;
  /** Update a message */
  updateMessageIndividual: Message;
  /** Update message tags */
  updateMessageTags: Message;
  /** Update notification setting */
  updateNotificationSetting: Array<UserNotificationSetting>;
  /** Update payment proccessor's customer's payment method. */
  updatePaymentMethod: Team;
  updateProject?: Maybe<Project>;
  /** Update project file tags */
  updateProjectFileTags: Document;
  /** Update project members */
  updateProjectMembers: Project;
  /** Update a task created by the current user */
  updateTask: Task;
  /** Update a task checklist */
  updateTaskChecklist: TaskChecklist;
  /** Update a task checklist */
  updateTaskChecklistSort: Array<TaskChecklist>;
  /** Update a task members */
  updateTaskMembers: Task;
  /** Update a team */
  updateTeam: Team;
  /** Update a members role on a team */
  updateTeamMembers: Team;
  /** Update tutoralization setting */
  updateTutoralizationSetting: Array<UserTutoralizationSetting>;
  /** Approve or reject a user request */
  updateUserRequestStatus: UserRequest;
  uploadContacts?: Maybe<User>;
};

export type MutationAcceptTeamInvitationArgs = {
  invitationToken: Scalars['String'];
};

export type MutationAddProjectFilesArgs = {
  attributes: Array<DocumentAttributes>;
  id: Scalars['ID'];
};

export type MutationAddTaskChecklistArgs = {
  attributes: TaskChecklistAttributes;
  taskId: Scalars['ID'];
};

export type MutationAddTaskFilesArgs = {
  attributes: Array<DocumentAttributes>;
  id: Scalars['ID'];
};

export type MutationArchiveChatArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveProjectArgs = {
  id: Scalars['ID'];
};

export type MutationAssignTaskOwnerArgs = {
  id: Scalars['ID'];
  newOwnerId: Scalars['ID'];
};

export type MutationAssignTaskUsersArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  members?: InputMaybe<Array<TaskMemberAttributes>>;
};

export type MutationAssignTeamOwnerArgs = {
  id: Scalars['ID'];
  newOwnerId: Scalars['ID'];
};

export type MutationCancelStripeSubscriptionArgs = {
  id: Scalars['ID'];
};

export type MutationChatBlockUnblockUserArgs = {
  userIdToBlock: Scalars['ID'];
};

export type MutationCompleteTaskArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationCreateChatArgs = {
  attributes: ChatAttributes;
};

export type MutationCreateContactInvitationUrlArgs = {
  attributes: CreateContactInvitationUrlAttributes;
};

export type MutationCreateDeviceArgs = {
  attributes: DeviceAttributes;
};

export type MutationCreateDirectUploadsArgs = {
  attributes: Array<DirectUploadAttributes>;
};

export type MutationCreateDraftMessageArgs = {
  attributes: MessageAttributes;
};

export type MutationCreateGroupArgs = {
  attributes: GroupAttributes;
};

export type MutationCreateInviteArgs = {
  projectId?: InputMaybe<Scalars['ID']>;
  taskId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateMessageIndividualArgs = {
  attributes: MessageAttributes;
};

export type MutationCreateMessageReactionArgs = {
  messageId: Scalars['ID'];
  reaction: Scalars['String'];
};

export type MutationCreatePhaseArgs = {
  attributes: PhaseAttributes;
};

export type MutationCreateProjectArgs = {
  attributes: ProjectAttributes;
};

export type MutationCreateShareLinkArgs = {
  documentIds: Array<Scalars['ID']>;
};

export type MutationCreateStripeSubscriptionArgs = {
  stripeSubscriptionAttributes: StripeSubscriptionAttributes;
  teamAttributes?: InputMaybe<TeamAttributes>;
};

export type MutationCreateTaskArgs = {
  attributes: TaskAttributes;
};

export type MutationCreateTeamArgs = {
  attributes: TeamAttributes;
  stripeSubscriptionAttributes?: InputMaybe<StripeSubscriptionAttributes>;
};

export type MutationCreateTeamInvitationArgs = {
  id: Scalars['ID'];
};

export type MutationCreateUserRequestArgs = {
  attributes: UserRequestAttributes;
};

export type MutationDeleteDeviceArgs = {
  expoToken: Scalars['String'];
};

export type MutationDeleteFriendRequestArgs = {
  friendRequest: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGroupChatArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectFilesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTaskFilesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateProjectArgs = {
  id: Scalars['ID'];
  includeAdmins?: InputMaybe<Scalars['Boolean']>;
  includeEditors?: InputMaybe<Scalars['Boolean']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeMembers?: InputMaybe<Scalars['Boolean']>;
  includePhases?: InputMaybe<Scalars['Boolean']>;
  includeTasks?: InputMaybe<Scalars['Boolean']>;
  includeViewers?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDuplicateTaskArgs = {
  id: Scalars['ID'];
  includeAssignees?: InputMaybe<Scalars['Boolean']>;
  includeCheckLists?: InputMaybe<Scalars['Boolean']>;
  includeCreator?: InputMaybe<Scalars['Boolean']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeViewers?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDuplicateTaskFilesArgs = {
  destinationId: Scalars['ID'];
  sourceId: Scalars['ID'];
};

export type MutationEditStripeSubscriptionArgs = {
  stripeSubscriptionAttributes: StripeSubscriptionAttributes;
};

export type MutationExportCheckinsArgs = {
  from?: InputMaybe<Scalars['ISO8601DateTime']>;
  projectId: Scalars['ID'];
  to?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type MutationFriendRequestArgs = {
  attributes: ChatAttributes;
};

export type MutationGenerateProjectReportArgs = {
  projectId: Scalars['ID'];
};

export type MutationLeaveChatArgs = {
  id: Scalars['ID'];
};

export type MutationLeaveProjectArgs = {
  id: Scalars['ID'];
};

export type MutationLeaveTaskArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationLeaveTeamArgs = {
  id: Scalars['ID'];
};

export type MutationMarkAuditsReadArgs = {
  attributes: ReadReceiptAuditAttributes;
};

export type MutationMarkChatsReadArgs = {
  attributes: ReadReceiptChatAttributes;
};

export type MutationRemoveMessageReactionArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTaskChecklistArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTeamMemberArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetPrimaryTeamArgs = {
  accountId: Scalars['ID'];
};

export type MutationUnarchiveChatArgs = {
  id: Scalars['ID'];
};

export type MutationUnarchiveProjectArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateBillingPeriodArgs = {
  priceId: Scalars['String'];
  teamId: Scalars['ID'];
};

export type MutationUpdateChatArgs = {
  attributes: ChatAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateChatAdminArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationUpdateDraftMessageArgs = {
  attributes: DraftMessageAttributes;
  chatId: Scalars['ID'];
};

export type MutationUpdateGroupArgs = {
  attributes: GroupAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateMeArgs = {
  attributes: UserAttributes;
};

export type MutationUpdateMessageIndividualArgs = {
  attributes: MessageAttributes;
  clientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateMessageTagsArgs = {
  attributes: MessageTagAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateNotificationSettingArgs = {
  attributes: Array<NotificationSettingAttributes>;
};

export type MutationUpdatePaymentMethodArgs = {
  paymentMethodToken: Scalars['String'];
  teamId: Scalars['ID'];
};

export type MutationUpdateProjectArgs = {
  attributes: ProjectAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateProjectFileTagsArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationUpdateProjectMembersArgs = {
  attributes: Array<ProjectMemberAttributes>;
  id: Scalars['ID'];
};

export type MutationUpdateTaskArgs = {
  attributes: TaskAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateTaskChecklistArgs = {
  attributes: TaskChecklistAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateTaskChecklistSortArgs = {
  attributes: Array<TaskChecklistAttributes>;
  taskId: Scalars['ID'];
};

export type MutationUpdateTaskMembersArgs = {
  attributes: Array<TaskMemberAttributes>;
  id: Scalars['ID'];
};

export type MutationUpdateTeamArgs = {
  attributes: TeamAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateTeamMembersArgs = {
  attributes: Array<MemberAttributes>;
  teamId: Scalars['ID'];
};

export type MutationUpdateTutoralizationSettingArgs = {
  attributes: Array<TutoralizationSettingAttributes>;
};

export type MutationUpdateUserRequestStatusArgs = {
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type MutationUploadContactsArgs = {
  attributes: UploadContactAttributes;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

export enum NotificationSetting {
  Chat = 'CHAT',
  DailyDigest = 'DAILY_DIGEST',
  File = 'FILE',
  Project = 'PROJECT',
  Task = 'TASK',
  WeeklyUpdate = 'WEEKLY_UPDATE',
}

/** Attributes for updating notification setting */
export type NotificationSettingAttributes = {
  duration?: InputMaybe<NotificationSettingDuration>;
  mute: Scalars['Boolean'];
  settingType: NotificationSetting;
};

export enum NotificationSettingDuration {
  Day = 'DAY',
  Forever = 'FOREVER',
  Week = 'WEEK',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  brand: Scalars['String'];
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

/** Stripe payment intent type */
export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  clientSecret: Scalars['String'];
  id: Scalars['String'];
  intentType: Scalars['String'];
  team: Team;
};

export type Phase = {
  __typename?: 'Phase';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  user: User;
};

/** Attributes for creating a phase */
export type PhaseAttributes = {
  name: Scalars['String'];
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Plan type */
export type Plan = {
  __typename?: 'Plan';
  amount: Scalars['Int'];
  expiration: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  interval: Scalars['String'];
  isCanceled: Scalars['Boolean'];
  isResumable: Scalars['Boolean'];
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  quantity: Scalars['Int'];
  status: Scalars['String'];
};

/** Price type */
export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  interval: Scalars['String'];
  intervalCount: Scalars['Int'];
};

/** Product type */
export type Product = {
  __typename?: 'Product';
  allowMembers: Scalars['Boolean'];
  externalId: Scalars['String'];
  features: Scalars['JSON'];
  guestsOnly: Scalars['Boolean'];
  id: Scalars['ID'];
  maxDocumentStorage: Scalars['BigInt'];
  maxNumberOfProjects: Scalars['BigInt'];
  name: Scalars['String'];
  position: Scalars['Int'];
  prices: Array<Price>;
};

export type Profile = {
  __typename?: 'Profile';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  skills?: Maybe<Array<Skill>>;
  teams?: Maybe<Array<Team>>;
  timeZone?: Maybe<Scalars['String']>;
  userActiveTasksCount: Scalars['Int'];
};

/** Project type */
export type Project = {
  __typename?: 'Project';
  activeTasksCount: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  audits?: Maybe<Array<Audit>>;
  auditsCount?: Maybe<Scalars['Int']>;
  authorId: Scalars['ID'];
  chatProjectsCount: Scalars['Int'];
  color: Scalars['String'];
  completedTasksCount: Scalars['Int'];
  coverImage?: Maybe<File>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  currentUserHasPendingMemberAdditionRequest: Scalars['Boolean'];
  currentUserRole?: Maybe<ProjectMemberRole>;
  currentUserTeamRole?: Maybe<TeamMemberRole>;
  description?: Maybe<Scalars['String']>;
  documentCount: Scalars['Int'];
  group?: Maybe<Group>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageCount: Scalars['Int'];
  initials: Scalars['String'];
  isTaskMember: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  members: Array<ProjectMember>;
  name: Scalars['String'];
  overdueTasksCount: Scalars['Int'];
  personal: Scalars['Boolean'];
  selectedUserProjectRole: ProjectMemberRole;
  /** @deprecated listTasks query accepts a projectId argument and paginates results */
  tasks?: Maybe<Array<Task>>;
  tasksCount: Scalars['Int'];
  tasksTagList?: Maybe<Array<Task>>;
  team?: Maybe<Team>;
  teamId: Scalars['ID'];
  unreadAudits?: Maybe<Array<Audit>>;
  unreadAuditsCount?: Maybe<Scalars['Int']>;
  upcomingTasksCount: Scalars['Int'];
  usersCount: Scalars['Int'];
};

/** Attributes for creating or updating a project */
export type ProjectAttributes = {
  address?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['ID']>;
  icon?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  members: Array<ProjectMemberAttributes>;
  name: Scalars['String'];
  teamId: Scalars['ID'];
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Project>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** ProjectDocument type */
export type ProjectDocument = {
  __typename?: 'ProjectDocument';
  document?: Maybe<Document>;
  id: Scalars['ID'];
  owner?: Maybe<User>;
  project?: Maybe<Project>;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

export type ProjectMember = {
  __typename?: 'ProjectMember';
  id: Scalars['ID'];
  project: Project;
  role: ProjectMemberRole;
  user: User;
};

/** Attributes for assigning project membership to a user */
export type ProjectMemberAttributes = {
  groupId?: InputMaybe<Scalars['ID']>;
  role: ProjectMemberRole;
  userId: Scalars['ID'];
};

export enum ProjectMemberRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

/** Project overview type */
export type ProjectReportOverview = {
  __typename?: 'ProjectReportOverview';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  members: Array<ProjectMember>;
  name: Scalars['String'];
  teamName: Scalars['String'];
};

export enum ProjectSortOption {
  ActivityDesc = 'ACTIVITY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  RecentTaggedMessageDesc = 'RECENT_TAGGED_MESSAGE_DESC',
}

export type ProjectTag = {
  __typename?: 'ProjectTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectTagInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Project type */
export type ProjectTask = {
  __typename?: 'ProjectTask';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  currentUserRole: ProjectMemberRole;
  id: Scalars['ID'];
  members: Array<ProjectMember>;
  name: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
};

/** The connection type for ProjectTask. */
export type ProjectTaskConnection = {
  __typename?: 'ProjectTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectTaskEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProjectTask>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProjectTaskEdge = {
  __typename?: 'ProjectTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProjectTask>;
};

export type ProjectTasksReport = {
  __typename?: 'ProjectTasksReport';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  creator?: Maybe<User>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  members: Array<TaskMember>;
  name: Scalars['String'];
  priority?: Maybe<TaskPriority>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<TaskStatus>;
};

/** ProjectUser type */
export type ProjectUser = {
  __typename?: 'ProjectUser';
  id: Scalars['ID'];
  project: Project;
  user: User;
};

export type Query = {
  __typename?: 'Query';
  /** Return all app versions */
  appVersions: AppVersions;
  /** Return documents by client_ids */
  documentsByClientIds?: Maybe<Array<Document>>;
  /** Return chat by ID */
  getChat: Chat;
  /** Return a draft message for a chat */
  getDraftMessage?: Maybe<DraftMessage>;
  /** Return the flipper feature for the current user */
  getFeatureFlag: FeatureFlag;
  /** Return group by ID */
  getGroup?: Maybe<Group>;
  /** Returns my information */
  getMe: User;
  /** Return product by ID */
  getProduct: Product;
  /** Returns user profile by ID */
  getProfile: Profile;
  /** Return project by ID */
  getProject: Project;
  /** Return project report by ID */
  getProjectReport?: Maybe<Scalars['String']>;
  /** Get project task related activity by project report uuid */
  getProjectTaskActivityReport?: Maybe<Array<Audit>>;
  /** Get project task details by project report uuid */
  getProjectTaskReport?: Maybe<ProjectTasksReport>;
  /** Return task by ID */
  getTask: Task;
  /** Return team by ID */
  getTeam: Team;
  /** Return a list of activities by project ID */
  listActivitiesByProject: AuditConnection;
  /** Return a list of activities by task ID */
  listActivitiesByTask: AuditConnection;
  /** Return blocked users of current user */
  listBlockedUsers: Array<User>;
  /** Return all projects and related tasks tagged in chat */
  listChatRelatedProjects: ChatProjectTaskConnection;
  /** Return all chats the user is participating in */
  listChats: Array<Chat>;
  /** Return all chats the user is participating in */
  listChatsPagination: ChatConnection;
  /** Return all user's contacts */
  listContactUsers: Array<User>;
  /** Return a list of draft Documents for a chat */
  listDraftDocuments: Array<Document>;
  /** Return all draft messages by chat ID */
  listDraftMessages: Array<Message>;
  /** Return a list of draft Documents for a project */
  listDraftProjectDocuments: Array<Document>;
  /** Return a list of draft Documents for a task */
  listDraftTaskDocuments: Array<Document>;
  /** Return all flipper features for the current user */
  listFeatureFlags: Array<FeatureFlag>;
  /** Return all contact groups */
  listGroups?: Maybe<Array<Group>>;
  /** Return all messages by chat ID */
  listMessages?: Maybe<MessageConnection>;
  /** Returns pending user requests for user requestable object */
  listPendingUserRequests: Array<UserRequest>;
  /** Return all phases */
  listPhases: Array<Phase>;
  /** Return all products */
  listProducts: Array<Product>;
  /** Return all project documents */
  listProjectDocuments: DocumentConnection;
  /** list project tasks by project report uuid */
  listProjectReportTasks?: Maybe<Array<ProjectTasksReport>>;
  /** Return all projects */
  listProjects: ProjectConnection;
  /** Return all projects and related tasks */
  listProjectsTasks: ProjectTaskConnection;
  /** Return all projects associated with documents */
  listProjectsWithDocuments: Array<Project>;
  /** Return all projects by execluding specified roles */
  listProjectsWithoutSpecifiedRoles: ProjectConnection;
  /** Return recent projects and related tasks tagged in chat */
  listRecentProjectsTasks: Array<RecentProjectTask>;
  /** Return recent updated tasks with project */
  listRecentTasks: Array<RecentTask>;
  /** List all skills */
  listSkills: Array<Skill>;
  /** Return all tag groups */
  listTagGroups: Array<TagGroup>;
  /** Return checklists by task ID */
  listTaskChecklist: Array<TaskChecklist>;
  /** Return all tasks documents */
  listTaskDocuments: DocumentConnection;
  /** Return all tasks the user is participating in */
  listTasks?: Maybe<TaskConnection>;
  /** Return all teams user is associated with */
  listTeams: Array<Team>;
  /** Return all unread messages for the given user and his chats */
  listUnreadMessages: MessageConnection;
  /** Return all users excluding current user */
  listUsers: Array<User>;
  /** List users by resource and optional search term */
  listUsersByResource: Array<User>;
  /** Return all documents with pagination. */
  newListDocuments: DocumentCollection;
  /** Get project report cover page */
  projectReportCoverPage?: Maybe<ReportCoverPage>;
  /** Get project report overview by uuid */
  projectReportOverview?: Maybe<ProjectReportOverview>;
  /** Return search results by term */
  search: Array<Search>;
  validateContentType: Scalars['Boolean'];
};

export type QueryDocumentsByClientIdsArgs = {
  clientIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetChatArgs = {
  id: Scalars['ID'];
};

export type QueryGetDraftMessageArgs = {
  chatId: Scalars['ID'];
};

export type QueryGetFeatureFlagArgs = {
  name: Scalars['String'];
};

export type QueryGetGroupArgs = {
  id: Scalars['ID'];
};

export type QueryGetProductArgs = {
  id: Scalars['ID'];
};

export type QueryGetProfileArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectTaskActivityReportArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  taskId: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type QueryGetProjectTaskReportArgs = {
  taskId: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type QueryGetTaskArgs = {
  id: Scalars['ID'];
};

export type QueryGetTeamArgs = {
  id: Scalars['ID'];
  includePending?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListActivitiesByProjectArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['ID'];
};

export type QueryListActivitiesByTaskArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  taskId: Scalars['ID'];
};

export type QueryListChatRelatedProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProjectSortOption>;
};

export type QueryListChatsArgs = {
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListChatsPaginationArgs = {
  after?: InputMaybe<Scalars['String']>;
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryListDraftDocumentsArgs = {
  chatId: Scalars['ID'];
};

export type QueryListDraftProjectDocumentsArgs = {
  projectId: Scalars['ID'];
};

export type QueryListDraftTaskDocumentsArgs = {
  taskId: Scalars['ID'];
};

export type QueryListMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chatId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  includeDraft?: InputMaybe<Scalars['Boolean']>;
  includePublished?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryListPendingUserRequestsArgs = {
  attributes: UserRequestAttributes;
};

export type QueryListProjectDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']>>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<DocumentSortOption>;
};

export type QueryListProjectReportTasksArgs = {
  uuid: Scalars['ID'];
};

export type QueryListProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  filterTeamsProjects?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locationOnly?: InputMaybe<Scalars['Boolean']>;
  memberIds?: InputMaybe<Array<Scalars['ID']>>;
  ownerIds?: InputMaybe<Array<Scalars['ID']>>;
  role?: InputMaybe<ProjectMemberRole>;
  sortBy?: InputMaybe<ProjectSortOption>;
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryListProjectsTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['ID']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProjectSortOption>;
};

export type QueryListProjectsWithoutSpecifiedRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  roles: Array<ProjectMemberRole>;
  sortBy?: InputMaybe<ProjectSortOption>;
};

export type QueryListRecentProjectsTasksArgs = {
  chatId: Scalars['ID'];
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListRecentTasksArgs = {
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  projectCollaborators?: InputMaybe<Scalars['Boolean']>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<TaskSortOption>;
};

export type QueryListTagGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryListTaskChecklistArgs = {
  id: Scalars['ID'];
};

export type QueryListTaskDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']>>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<DocumentSortOption>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryListTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['ID']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  dueDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  dueDateBegin?: InputMaybe<Scalars['ISO8601DateTime']>;
  dueDateEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  dueGroup?: InputMaybe<TaskGroup>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  memberIds?: InputMaybe<Array<Scalars['ID']>>;
  phaseId?: InputMaybe<Scalars['ID']>;
  priority?: InputMaybe<Array<TaskPriority>>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<TaskSortOption>;
  startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  status?: InputMaybe<TaskStatus>;
  taskCollaborators?: InputMaybe<Scalars['Boolean']>;
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
  userId?: InputMaybe<Scalars['ID']>;
  withoutPhase?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListTeamsArgs = {
  role?: InputMaybe<ProjectMemberRole>;
};

export type QueryListUnreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryListUsersArgs = {
  size?: InputMaybe<Scalars['Int']>;
  term: Scalars['String'];
};

export type QueryListUsersByResourceArgs = {
  teamId: Scalars['ID'];
  term?: InputMaybe<Scalars['String']>;
};

export type QueryNewListDocumentsArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DocumentSortOption>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryProjectReportCoverPageArgs = {
  uuid: Scalars['ID'];
};

export type QueryProjectReportOverviewArgs = {
  uuid: Scalars['ID'];
};

export type QuerySearchArgs = {
  archiveProjectsOnly?: InputMaybe<Scalars['Boolean']>;
  chatId?: InputMaybe<Scalars['ID']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  completedTasksOnly?: InputMaybe<Scalars['Boolean']>;
  documentGroup?: InputMaybe<DocumentGroup>;
  documentOwnerIds?: InputMaybe<Array<Scalars['ID']>>;
  files?: InputMaybe<Scalars['Boolean']>;
  from?: InputMaybe<Scalars['Int']>;
  includeActivities?: InputMaybe<Scalars['Boolean']>;
  includeChats?: InputMaybe<Scalars['Boolean']>;
  includeCompletedTask?: InputMaybe<Scalars['Boolean']>;
  includeContacts?: InputMaybe<Scalars['Boolean']>;
  includeDocuments?: InputMaybe<Scalars['Boolean']>;
  includeMessages?: InputMaybe<Scalars['Boolean']>;
  includeProjects?: InputMaybe<Scalars['Boolean']>;
  includeTasks?: InputMaybe<Scalars['Boolean']>;
  includeTasksTags?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Boolean']>;
  taskDueGroup?: InputMaybe<TaskGroup>;
  taskId?: InputMaybe<Scalars['ID']>;
  term: Scalars['String'];
};

export type QueryValidateContentTypeArgs = {
  contentType: Scalars['String'];
};

/** Attributes for updating read receipts for active log */
export type ReadReceiptAuditAttributes = {
  auditIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Attributes for updating read receipts for chat messages */
export type ReadReceiptChatAttributes = {
  chatIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RecentProjectTask = Project | Task;

/** Recent Task type */
export type RecentTask = Node & {
  __typename?: 'RecentTask';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  authorId: Scalars['ID'];
  checklists?: Maybe<Array<TaskChecklist>>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  completor?: Maybe<User>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  dependencies?: Maybe<Array<Task>>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  members: Array<TaskMember>;
  name: Scalars['String'];
  priority?: Maybe<TaskPriority>;
  project: Project;
  projectId: Scalars['ID'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  userIds: Array<Scalars['ID']>;
};

export type ReplyMessageInput = {
  cacheIdentifier: Scalars['String'];
  id: Scalars['ID'];
};

export type ReportCoverPage = {
  __typename?: 'ReportCoverPage';
  createdAt: Scalars['ISO8601DateTime'];
  generatedBy: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  title?: Maybe<Scalars['String']>;
};

export type Search = {
  __typename?: 'Search';
  highlight?: Maybe<Highlight>;
  record: SearchableResult;
  total: Scalars['Int'];
};

export type SearchableResult =
  | Audit
  | Chat
  | Document
  | Message
  | Project
  | Task
  | User;

export type Skill = {
  __typename?: 'Skill';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Attributes for creating a Stripe customer and subscription */
export type StripeSubscriptionAttributes = {
  name: Scalars['String'];
  priceId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to chat updates */
  chatUpdate?: Maybe<ChatUpdate>;
  /**
   * Subscribe to chat updates
   * @deprecated chatUpdated is deprecated in favour of chatUpdate
   */
  chatUpdated?: Maybe<Chat>;
  /** Subscribe to personal updates */
  meUpdated: MeUpdatedPayload;
  /** Subscribe to message updates by chat ID */
  messageUpdate?: Maybe<Message>;
  /** Subscribe to project updates */
  projectUpdated?: Maybe<Project>;
};

export type SubscriptionMessageUpdateArgs = {
  chatId: Scalars['ID'];
};

/** TagGroups */
export type TagGroup = {
  __typename?: 'TagGroup';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  isImageGroup: Scalars['Boolean'];
  message?: Maybe<Message>;
  project: Project;
  task?: Maybe<Task>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Task type */
export type Task = Node & {
  __typename?: 'Task';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  audits?: Maybe<Array<Audit>>;
  auditsCount?: Maybe<Scalars['Int']>;
  authorId: Scalars['ID'];
  chatTasksCount: Scalars['Int'];
  checklists?: Maybe<Array<TaskChecklist>>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  completor?: Maybe<User>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  currentUserProjectRole?: Maybe<ProjectMemberRole>;
  currentUserProjectTeamRole?: Maybe<TeamMemberRole>;
  currentUserRole?: Maybe<TaskMemberRole>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  dependencies?: Maybe<Array<Task>>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Document>>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  dueGroup?: Maybe<Scalars['String']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<TaskFrequency>;
  id: Scalars['ID'];
  members: Array<TaskMember>;
  name: Scalars['String'];
  phase?: Maybe<Phase>;
  phaseId?: Maybe<Scalars['ID']>;
  priority?: Maybe<TaskPriority>;
  project: Project;
  projectId: Scalars['ID'];
  recurring?: Maybe<Scalars['Boolean']>;
  skillIds?: Maybe<Array<Scalars['ID']>>;
  skills?: Maybe<Array<Skill>>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<TaskStatus>;
  unreadAudits?: Maybe<Array<Audit>>;
  unreadAuditsCount?: Maybe<Scalars['Int']>;
  userIds: Array<Scalars['ID']>;
};

/** Attributes for creating or updating a task */
export type TaskAttributes = {
  checklists?: InputMaybe<Array<TaskChecklistAttributes>>;
  completedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  completedById?: InputMaybe<Scalars['ID']>;
  dependencyIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<TaskFrequency>;
  members?: InputMaybe<Array<TaskMemberAttributes>>;
  name: Scalars['String'];
  phaseId?: InputMaybe<Scalars['ID']>;
  priority?: InputMaybe<TaskPriority>;
  projectId: Scalars['ID'];
  recurring?: InputMaybe<Scalars['Boolean']>;
  skillIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  status?: InputMaybe<TaskStatus>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TaskChecklist = {
  __typename?: 'TaskChecklist';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  taskId: Scalars['ID'];
};

/** Attributes for assigning task membership to a user */
export type TaskChecklistAttributes = {
  completed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Task>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** TaskDocument type */
export type TaskDocument = {
  __typename?: 'TaskDocument';
  document?: Maybe<Document>;
  id: Scalars['ID'];
  owner?: Maybe<User>;
  task?: Maybe<Task>;
};

/** An edge in a connection. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Task>;
};

export enum TaskFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum TaskGroup {
  Current = 'CURRENT',
  Overdue = 'OVERDUE',
  Today = 'TODAY',
  Upcoming = 'UPCOMING',
}

export type TaskMember = {
  __typename?: 'TaskMember';
  id: Scalars['ID'];
  role: TaskMemberRole;
  subject: Task;
  user: User;
};

/** Attributes for assigning task membership to a user */
export type TaskMemberAttributes = {
  role: TaskMemberRole;
  userId: Scalars['ID'];
};

export enum TaskMemberRole {
  Assignee = 'ASSIGNEE',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export enum TaskPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum TaskSortOption {
  DueDate = 'DUE_DATE',
  DueDateAsc = 'DUE_DATE_ASC',
  DueDateDesc = 'DUE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ProjectNameAsc = 'PROJECT_NAME_ASC',
  ProjectNameDesc = 'PROJECT_NAME_DESC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Ongoing = 'ONGOING',
  OnHold = 'ON_HOLD',
  Overdue = 'OVERDUE',
}

export type TaskTag = {
  __typename?: 'TaskTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  project?: Maybe<ProjectTag>;
  projectId: Scalars['ID'];
  status?: Maybe<TaskStatus>;
  userIds: Array<Scalars['ID']>;
};

export type TaskTagCollection = {
  __typename?: 'TaskTagCollection';
  author: User;
  project: ProjectTag;
  tasks: Array<Maybe<TaskTag>>;
};

export type TaskTagCollectionInput = {
  author: TaskTagUserInput;
  project: ProjectTagInput;
  tasks: Array<InputMaybe<TaskTagInput>>;
};

export type TaskTagInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  project?: InputMaybe<ProjectTagInput>;
  projectId: Scalars['ID'];
  status?: InputMaybe<TaskStatus>;
  userIds: Array<Scalars['ID']>;
};

export type TaskTagUserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** TaskUser type */
export type TaskUser = {
  __typename?: 'TaskUser';
  id: Scalars['ID'];
  task: Task;
  user: User;
};

export type Team = {
  __typename?: 'Team';
  address?: Maybe<Scalars['String']>;
  /** @deprecated Please use avatarFile to retrieve Team avatars */
  avatar?: Maybe<Scalars['String']>;
  avatarFile?: Maybe<File>;
  completedTasksCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  fileStorageUsed: Scalars['BigInt'];
  guestsOnly: Scalars['Boolean'];
  hasReachedMaxProjects: Scalars['Boolean'];
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maxDocumentStorage: Scalars['BigInt'];
  maxNumberOfProjects: Scalars['Int'];
  mediaStorageUsed: Scalars['BigInt'];
  name: Scalars['String'];
  numberOfProjectsUsed: Scalars['Int'];
  owner: User;
  personal: Scalars['Boolean'];
  plan?: Maybe<Plan>;
  product?: Maybe<Product>;
  projects: Array<Project>;
  skills: Array<Skill>;
  storageRemaining: Scalars['BigInt'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  tasksCount: Scalars['Int'];
  users: Array<User>;
  usersCount: Scalars['Int'];
};

/** Attributes for creating or updating a team */
export type TeamAttributes = {
  address?: InputMaybe<Scalars['String']>;
  avatarBlobId?: InputMaybe<Scalars['ID']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  members?: InputMaybe<Array<MemberAttributes>>;
  name: Scalars['String'];
  skillIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum TeamMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum TeamRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum TutoralizationSetting {
  AssignTask = 'ASSIGN_TASK',
  Hashtag = 'HASHTAG',
  TagMessage = 'TAG_MESSAGE',
}

/** Attributes for updating tutoralization setting */
export type TutoralizationSettingAttributes = {
  close: Scalars['Boolean'];
  settingType: TutoralizationSetting;
};

/** Attributes for uploading user contacts */
export type UploadContactAttributes = {
  phoneNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  blockedUsers?: Maybe<Array<User>>;
  contactsCount: Scalars['Int'];
  contactsSyncedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  devices?: Maybe<Array<Device>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  friendRequestPending?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inviteUrl: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  notificationSetting?: Maybe<Array<UserNotificationSetting>>;
  phoneNumber: Scalars['String'];
  primaryTeamId?: Maybe<Scalars['ID']>;
  provider?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  teamRoles?: Maybe<Array<TeamRole>>;
  teams?: Maybe<Array<Team>>;
  timeZone?: Maybe<Scalars['String']>;
  tutoralizationSetting?: Maybe<Array<UserTutoralizationSetting>>;
  uid?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating a user */
export type UserAttributes = {
  address?: InputMaybe<Scalars['String']>;
  avatarBlobId?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  skillIds: Array<Scalars['ID']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type UserChatSetting = {
  __typename?: 'UserChatSetting';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  mute: Scalars['Boolean'];
  pin: Scalars['Boolean'];
};

/** Notification setting type */
export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  duration?: Maybe<NotificationSettingDuration>;
  mute: Scalars['Boolean'];
  settingType: NotificationSetting;
};

/** User Request type */
export type UserRequest = {
  __typename?: 'UserRequest';
  id: Scalars['ID'];
  requestType: UserRequestCategory;
  reviewedBy?: Maybe<User>;
  status: Scalars['String'];
  user: User;
  userRequestableId: Scalars['ID'];
  userRequestableType: UserRequestable;
};

/** Attributes for creating user requests */
export type UserRequestAttributes = {
  requestType: UserRequestCategory;
  userRequestableId: Scalars['ID'];
  userRequestableType: UserRequestable;
};

export enum UserRequestCategory {
  MemberAddition = 'MEMBER_ADDITION',
}

export enum UserRequestable {
  Project = 'PROJECT',
}

/** Tutoralization setting type */
export type UserTutoralizationSetting = {
  __typename?: 'UserTutoralizationSetting';
  close: Scalars['Boolean'];
  settingType: TutoralizationSetting;
};

type Attachments_Document_Fragment = {
  __typename?: 'Document';
  id: string;
  size?: number | null;
  name: string;
  contentType?: string | null;
  clientId: string;
  isImage: boolean;
  isAudio: boolean;
  duration?: number | null;
  file: {
    __typename?: 'File';
    id: string;
    url: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  };
};

type Attachments_EntityUser_Fragment = { __typename?: 'EntityUser' };

type Attachments_Folder_Fragment = { __typename?: 'Folder' };

type Attachments_LocalFile_Fragment = { __typename?: 'LocalFile' };

type Attachments_Message_Fragment = { __typename?: 'Message' };

type Attachments_MessageProject_Fragment = {
  __typename?: 'MessageProject';
  id: string;
  authorId: string;
  author?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  } | null;
  project?: { __typename?: 'Project'; id: string; name: string } | null;
};

type Attachments_MessageTask_Fragment = {
  __typename?: 'MessageTask';
  id: string;
  authorId: string;
  author?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  } | null;
  task?: { __typename?: 'Task'; id: string; name: string } | null;
};

type Attachments_MessageTaskAssignment_Fragment = {
  __typename?: 'MessageTaskAssignment';
};

type Attachments_MessageTaskComplete_Fragment = {
  __typename?: 'MessageTaskComplete';
};

type Attachments_Project_Fragment = { __typename?: 'Project' };

type Attachments_ProjectUser_Fragment = { __typename?: 'ProjectUser' };

type Attachments_Task_Fragment = { __typename?: 'Task' };

type Attachments_TaskUser_Fragment = { __typename?: 'TaskUser' };

type Attachments_UserRequest_Fragment = { __typename?: 'UserRequest' };

export type AttachmentsFragment =
  | Attachments_Document_Fragment
  | Attachments_EntityUser_Fragment
  | Attachments_Folder_Fragment
  | Attachments_LocalFile_Fragment
  | Attachments_Message_Fragment
  | Attachments_MessageProject_Fragment
  | Attachments_MessageTask_Fragment
  | Attachments_MessageTaskAssignment_Fragment
  | Attachments_MessageTaskComplete_Fragment
  | Attachments_Project_Fragment
  | Attachments_ProjectUser_Fragment
  | Attachments_Task_Fragment
  | Attachments_TaskUser_Fragment
  | Attachments_UserRequest_Fragment;

export type ChatDetailFragment = {
  __typename?: 'Chat';
  id: string;
  name: string;
  description?: string | null;
  isGroupChat: boolean;
  messageIcon?: string | null;
  messageBody?: string | null;
  recentActivityAt?: any | null;
  unreadCount?: number | null;
  createdAt: any;
  leaveOn?: any | null;
  avatar?: {
    __typename?: 'File';
    id: string;
    url: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  settings?: {
    __typename?: 'UserChatSetting';
    id: string;
    pin: boolean;
    mute: boolean;
    archivedAt?: any | null;
  } | null;
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  }>;
  owner: { __typename?: 'User'; id: string; name?: string | null };
};

export type DocumentDetailFragment = {
  __typename?: 'Document';
  id: string;
  name: string;
  contentType?: string | null;
  createdAt: any;
  size?: number | null;
  isImage: boolean;
  clientId: string;
  isAudio: boolean;
  duration?: number | null;
  file: {
    __typename?: 'File';
    id: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  };
  owner: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: any;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  };
};

export type DraftMessageDetailFragment = {
  __typename?: 'DraftMessage';
  chatId: string;
  body?: string | null;
  tagsCollection: Array<{
    __typename?: 'TaskTagCollection';
    tasks: Array<{
      __typename?: 'TaskTag';
      id: string;
      name: string;
      userIds: Array<string>;
      status?: TaskStatus | null;
      projectId: string;
      project?: { __typename?: 'ProjectTag'; id: string; name: string } | null;
    } | null>;
    project: { __typename?: 'ProjectTag'; id: string; name: string };
    author: { __typename?: 'User'; id: string; name?: string | null };
  } | null>;
  localFiles: Array<{
    __typename?: 'LocalFile';
    id: string;
    name: string;
    clientId: string;
    url: string;
    cdnBaseUrl: string;
    path: string;
    contentType: string;
    isImage: boolean;
    isVideo: boolean;
    size?: number | null;
    isAudio: boolean;
    duration?: number | null;
    isPreviewable: boolean;
    thumbnail: string;
  } | null>;
  replyMessage?: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    cursor: string;
    readAt?: any | null;
    isRetry: boolean;
    replyId?: string | null;
    readReceipts?: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }> | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
      };
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            deletedAt?: any | null;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
    reaction?: Array<{
      __typename?: 'MessageReaction';
      id: string;
      reaction: string;
      count: number;
      user?: Array<{ __typename?: 'User'; id: string }> | null;
    }> | null;
  } | null;
  checkin?: {
    __typename?: 'MessageCheckin';
    id: string;
    address?: string | null;
    city?: string | null;
    zipcode?: string | null;
    state?: string | null;
    country?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
};

export type FileDetailsFragment = {
  __typename?: 'File';
  id: string;
  url: string;
  cdnBaseUrl: string;
  path: string;
  isPreviewable: boolean;
  thumbnail?: string | null;
};

export type GetChatDetailFragment = {
  __typename?: 'Chat';
  id: string;
  name: string;
  description?: string | null;
  isGroupChat: boolean;
  messageIcon?: string | null;
  messageBody?: string | null;
  recentActivityAt?: any | null;
  unreadCount?: number | null;
  isNewChat?: boolean | null;
  isUserBlocked?: boolean | null;
  friendRequestAccepted?: boolean | null;
  createdAt: any;
  leaveOn?: any | null;
  avatar?: {
    __typename?: 'File';
    id: string;
    url: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  owner: { __typename?: 'User'; id: string; name?: string | null };
  settings?: {
    __typename?: 'UserChatSetting';
    id: string;
    pin: boolean;
    mute: boolean;
    archivedAt?: any | null;
  } | null;
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    phoneNumber: string;
    createdAt: any;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  }>;
};

export type LocalDocumentDetailFragment = {
  __typename?: 'Document';
  id: string;
  name: string;
  contentType?: string | null;
  createdAt: any;
  size?: number | null;
  isImage: boolean;
  clientId: string;
  isAudio: boolean;
  duration?: number | null;
  failedAt: any;
  file: {
    __typename?: 'File';
    id: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
    url: string;
  };
  owner: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: any;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  };
};

export type CheckinDetailFragment = {
  __typename?: 'MessageCheckin';
  id: string;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  state?: string | null;
  country?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};

export type MessageDetailForQueryFragment = {
  __typename?: 'Message';
  id: string;
  chatId: string;
  clientId: string;
  body?: string | null;
  createdAt: any;
  isSender: boolean;
  isDraft: boolean;
  publishedAt?: any | null;
  authorId: string;
  cursor: string;
  readAt?: any | null;
  isRetry: boolean;
  replyId?: string | null;
  readReceipts?: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  }> | null;
  checkin?: {
    __typename?: 'MessageCheckin';
    id: string;
    address?: string | null;
    city?: string | null;
    zipcode?: string | null;
    state?: string | null;
    country?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  author: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: any;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  };
  replyMessage?: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
    };
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
  } | null;
  attachments?: Array<
    | {
        __typename?: 'Document';
        id: string;
        name: string;
        contentType?: string | null;
        createdAt: any;
        size?: number | null;
        isImage: boolean;
        clientId: string;
        isAudio: boolean;
        duration?: number | null;
        file: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        };
        owner: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          createdAt: any;
        };
      }
    | { __typename?: 'EntityUser' }
    | { __typename?: 'Folder' }
    | { __typename?: 'LocalFile' }
    | { __typename?: 'Message' }
    | {
        __typename?: 'MessageProject';
        id: string;
        authorId: string;
        author?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        } | null;
        project?: { __typename?: 'Project'; id: string; name: string } | null;
      }
    | {
        __typename?: 'MessageTask';
        id: string;
        authorId: string;
        author?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        } | null;
        task?: {
          __typename?: 'Task';
          id: string;
          projectId: string;
          deletedAt?: any | null;
          name: string;
        } | null;
      }
    | {
        __typename?: 'MessageTaskAssignment';
        id: string;
        messageId: string;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            thumbnail?: string | null;
          } | null;
        }>;
        task?: {
          __typename?: 'Task';
          id: string;
          name: string;
          deletedAt?: any | null;
          completedAt?: any | null;
        } | null;
      }
    | {
        __typename?: 'MessageTaskComplete';
        id: string;
        messageId: string;
        completor?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            thumbnail?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: 'Project' }
    | { __typename?: 'ProjectUser' }
    | { __typename?: 'Task' }
    | { __typename?: 'TaskUser' }
    | { __typename?: 'UserRequest' }
  > | null;
  reaction?: Array<{
    __typename?: 'MessageReaction';
    id: string;
    reaction: string;
    count: number;
    user?: Array<{ __typename?: 'User'; id: string }> | null;
  }> | null;
};

export type MessageDetailFragment = {
  __typename?: 'Message';
  id: string;
  chatId: string;
  clientId: string;
  body?: string | null;
  createdAt: any;
  isSender: boolean;
  isDraft: boolean;
  publishedAt?: any | null;
  authorId: string;
  cursor: string;
  readAt?: any | null;
  isRetry: boolean;
  replyId?: string | null;
  readReceipts?: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  }> | null;
  checkin?: {
    __typename?: 'MessageCheckin';
    id: string;
    address?: string | null;
    city?: string | null;
    zipcode?: string | null;
    state?: string | null;
    country?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  author: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: any;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  };
  replyMessage?: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
    };
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
  } | null;
  attachments?: Array<
    | {
        __typename?: 'Document';
        id: string;
        name: string;
        contentType?: string | null;
        createdAt: any;
        size?: number | null;
        isImage: boolean;
        clientId: string;
        isAudio: boolean;
        duration?: number | null;
        file: {
          __typename?: 'File';
          id: string;
          url: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        };
        owner: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          createdAt: any;
        };
      }
    | { __typename?: 'EntityUser' }
    | { __typename?: 'Folder' }
    | { __typename?: 'LocalFile' }
    | { __typename?: 'Message' }
    | {
        __typename?: 'MessageProject';
        id: string;
        authorId: string;
        author?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        } | null;
        project?: { __typename?: 'Project'; id: string; name: string } | null;
      }
    | {
        __typename?: 'MessageTask';
        id: string;
        authorId: string;
        author?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        } | null;
        task?: {
          __typename?: 'Task';
          id: string;
          projectId: string;
          deletedAt?: any | null;
          name: string;
        } | null;
      }
    | {
        __typename?: 'MessageTaskAssignment';
        id: string;
        messageId: string;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            thumbnail?: string | null;
          } | null;
        }>;
        task?: {
          __typename?: 'Task';
          id: string;
          name: string;
          deletedAt?: any | null;
          completedAt?: any | null;
        } | null;
      }
    | {
        __typename?: 'MessageTaskComplete';
        id: string;
        messageId: string;
        completor?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            thumbnail?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: 'Project' }
    | { __typename?: 'ProjectUser' }
    | { __typename?: 'Task' }
    | { __typename?: 'TaskUser' }
    | { __typename?: 'UserRequest' }
  > | null;
  reaction?: Array<{
    __typename?: 'MessageReaction';
    id: string;
    reaction: string;
    count: number;
    user?: Array<{ __typename?: 'User'; id: string }> | null;
  }> | null;
};

export type PageInfoFragment = {
  __typename?: 'PageInfo';
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  endCursor?: string | null;
  startCursor?: string | null;
};

export type ProjectDetailFragment = {
  __typename?: 'Project';
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  archivedAt?: any | null;
  usersCount: number;
  tasksCount: number;
  currentUserRole?: ProjectMemberRole | null;
  currentUserTeamRole?: TeamMemberRole | null;
  isTaskMember: boolean;
  teamId: string;
  color: string;
  icon?: string | null;
  createdAt: any;
  description?: string | null;
  id: string;
  initials: string;
  name: string;
  unreadAuditsCount?: number | null;
  activeTasksCount: number;
  upcomingTasksCount: number;
  overdueTasksCount: number;
  completedTasksCount: number;
  creator: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
      url: string;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  };
  coverImage?: {
    __typename?: 'File';
    id: string;
    url: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  team?: {
    __typename?: 'Team';
    avatar?: string | null;
    createdAt: any;
    id: string;
    name: string;
    personal: boolean;
    hasReachedMaxProjects: boolean;
    plan?: {
      __typename?: 'Plan';
      id: string;
      isCanceled: boolean;
      expiration: any;
    } | null;
  } | null;
  members: Array<{
    __typename?: 'ProjectMember';
    id: string;
    role: ProjectMemberRole;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
  }>;
  audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
  unreadAudits?: Array<{
    __typename?: 'Audit';
    id: string;
    event: string;
  }> | null;
};

export type TaskDetailFragment = {
  __typename?: 'Task';
  id: string;
  name: string;
  status?: TaskStatus | null;
  priority?: TaskPriority | null;
  startDate?: any | null;
  dueDate?: any | null;
  completedAt?: any | null;
  createdAt: any;
  phaseId?: string | null;
  projectId: string;
  deletedAt?: any | null;
  skillIds?: Array<string> | null;
  userIds: Array<string>;
  dueGroup?: string | null;
  fixedOrder?: boolean | null;
  recurring?: boolean | null;
  description?: string | null;
  frequency?: TaskFrequency | null;
  currentUserRole?: TaskMemberRole | null;
  currentUserProjectRole?: ProjectMemberRole | null;
  currentUserProjectTeamRole?: TeamMemberRole | null;
  dependencies?: Array<{
    __typename?: 'Task';
    id: string;
    name: string;
  }> | null;
  checklists?: Array<{
    __typename?: 'TaskChecklist';
    id: string;
    name: string;
    completedAt?: any | null;
    createdAt: any;
  }> | null;
  members: Array<{
    __typename?: 'TaskMember';
    id: string;
    role: TaskMemberRole;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
  }>;
  creator: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
      url: string;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  };
  completor?: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  } | null;
  project: {
    __typename?: 'Project';
    id: string;
    name: string;
    color: string;
    icon?: string | null;
    currentUserTeamRole?: TeamMemberRole | null;
    currentUserRole?: ProjectMemberRole | null;
    initials: string;
    archivedAt?: any | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
    } | null;
  };
  phase?: { __typename?: 'Phase'; id: string; name: string } | null;
};

export type TaskDocumentDetailFragment = {
  __typename?: 'Task';
  id: string;
  name: string;
  startDate?: any | null;
  dueDate?: any | null;
  completedAt?: any | null;
  projectId: string;
  deletedAt?: any | null;
  currentUserRole?: TaskMemberRole | null;
  currentUserProjectRole?: ProjectMemberRole | null;
  currentUserProjectTeamRole?: TeamMemberRole | null;
  project: {
    __typename?: 'Project';
    id: string;
    name: string;
    archivedAt?: any | null;
  };
  documents?: Array<{
    __typename?: 'Document';
    id: string;
    name: string;
    contentType?: string | null;
    createdAt: any;
    size?: number | null;
    isImage: boolean;
    clientId: string;
    isAudio: boolean;
    duration?: number | null;
    owner: { __typename?: 'User'; id: string; name?: string | null };
    file: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    };
    messageDocuments?: Array<{
      __typename?: 'MessageDocument';
      id: string;
      message?: {
        __typename?: 'Message';
        id: string;
        clientId: string;
        chatId: string;
        createdAt: any;
        isSender: boolean;
        isDraft: boolean;
        publishedAt?: any | null;
        authorId: string;
      } | null;
    }> | null;
    projectDocuments?: Array<{
      __typename?: 'ProjectDocument';
      id: string;
      project?: { __typename?: 'Project'; id: string; name: string } | null;
      owner?: { __typename?: 'User'; id: string; name?: string | null } | null;
    }> | null;
    taskDocuments?: Array<{
      __typename?: 'TaskDocument';
      id: string;
      task?: {
        __typename?: 'Task';
        id: string;
        name: string;
        projectId: string;
        project: { __typename?: 'Project'; id: string; name: string };
      } | null;
      owner?: { __typename?: 'User'; id: string; name?: string | null } | null;
    }> | null;
  }> | null;
};

export type TeamDetailFragment = {
  __typename?: 'Team';
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  id: string;
  avatar?: string | null;
  createdAt: any;
  name: string;
  usersCount: number;
  fileStorageUsed: any;
  mediaStorageUsed: any;
  maxDocumentStorage: any;
  storageRemaining: any;
  hasReachedMaxProjects: boolean;
  numberOfProjectsUsed: number;
  maxNumberOfProjects: number;
  personal: boolean;
  tasksCount: number;
  completedTasksCount: number;
  avatarFile?: {
    __typename?: 'File';
    id: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  plan?: {
    __typename?: 'Plan';
    id: string;
    amount: number;
    expiration: any;
    interval: string;
    name: string;
    quantity: number;
    status: string;
    isCanceled: boolean;
    isResumable: boolean;
    payment?: {
      __typename?: 'Payment';
      brand: string;
      last4?: string | null;
      expMonth?: string | null;
      expYear?: string | null;
    } | null;
  } | null;
  owner: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  };
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    createdAt: any;
  }>;
  users: Array<{
    __typename?: 'User';
    teamRoles?: Array<TeamRole> | null;
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  }>;
  product?: {
    __typename?: 'Product';
    id: string;
    name: string;
    prices: Array<{
      __typename?: 'Price';
      id: string;
      interval: string;
      intervalCount: number;
      externalId: string;
    }>;
  } | null;
  invoices?: Array<{
    __typename?: 'Invoice';
    id: string;
    invoiceUrl: string;
    amount: number;
    date: any;
    description: string;
    status: string;
  }> | null;
};

export type UserDetailFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  primaryTeamId?: string | null;
  createdAt: any;
  inviteUrl: string;
  avatar?: {
    __typename?: 'File';
    id: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  skills?: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    createdAt: any;
  }> | null;
};

export type UserProfileFragment = {
  __typename?: 'Profile';
  address?: string | null;
  id: string;
  email: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  userActiveTasksCount: number;
  avatar?: {
    __typename?: 'File';
    id: string;
    cdnBaseUrl: string;
    path: string;
    isPreviewable: boolean;
    thumbnail?: string | null;
  } | null;
  skills?: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    createdAt: any;
  }> | null;
  teams?: Array<{
    __typename?: 'Team';
    id: string;
    name: string;
    usersCount: number;
    users: Array<{ __typename?: 'User'; id: string; name?: string | null }>;
  }> | null;
};

export type AcceptTeamInvitationMutationVariables = Exact<{
  invitationToken: Scalars['String'];
}>;

export type AcceptTeamInvitationMutation = {
  __typename?: 'Mutation';
  acceptTeamInvitation: {
    __typename?: 'Team';
    id: string;
    name: string;
    users: Array<{ __typename?: 'User'; id: string; name?: string | null }>;
  };
};

export type AddProjectFilesMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: Array<DocumentAttributes> | DocumentAttributes;
}>;

export type AddProjectFilesMutation = {
  __typename?: 'Mutation';
  addProjectFiles: { __typename?: 'Project'; id: string };
};

export type AddTaskChecklistMutationVariables = Exact<{
  taskId: Scalars['ID'];
  attributes: TaskChecklistAttributes;
}>;

export type AddTaskChecklistMutation = {
  __typename?: 'Mutation';
  addTaskChecklist: Array<{
    __typename?: 'TaskChecklist';
    taskId: string;
    id: string;
    name: string;
    completedAt?: any | null;
    createdAt: any;
    sort?: number | null;
  }>;
};

export type AddTaskFilesMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: Array<DocumentAttributes> | DocumentAttributes;
}>;

export type AddTaskFilesMutation = {
  __typename?: 'Mutation';
  addTaskFiles: { __typename?: 'Task'; id: string };
};

export type ArchiveChatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArchiveChatMutation = {
  __typename?: 'Mutation';
  archiveChat: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    users: Array<{ __typename?: 'User'; id: string }>;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      mute: boolean;
      pin: boolean;
      archivedAt?: any | null;
    } | null;
  };
};

export type ArchiveProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArchiveProjectMutation = {
  __typename?: 'Mutation';
  archiveProject: {
    __typename?: 'Project';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    archivedAt?: any | null;
    usersCount: number;
    tasksCount: number;
    currentUserRole?: ProjectMemberRole | null;
    currentUserTeamRole?: TeamMemberRole | null;
    isTaskMember: boolean;
    teamId: string;
    color: string;
    icon?: string | null;
    createdAt: any;
    description?: string | null;
    id: string;
    initials: string;
    name: string;
    unreadAuditsCount?: number | null;
    activeTasksCount: number;
    upcomingTasksCount: number;
    overdueTasksCount: number;
    completedTasksCount: number;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
      hasReachedMaxProjects: boolean;
      plan?: {
        __typename?: 'Plan';
        id: string;
        isCanceled: boolean;
        expiration: any;
      } | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
  };
};

export type AssignTaskOwnerMutationVariables = Exact<{
  id: Scalars['ID'];
  newOwnerId: Scalars['ID'];
}>;

export type AssignTaskOwnerMutation = {
  __typename?: 'Mutation';
  assignTaskOwner: { __typename?: 'Task'; id: string };
};

export type AssignTaskUsersMutationVariables = Exact<{
  id: Scalars['ID'];
  members: Array<TaskMemberAttributes> | TaskMemberAttributes;
  chatId?: InputMaybe<Scalars['ID']>;
}>;

export type AssignTaskUsersMutation = {
  __typename?: 'Mutation';
  assignTaskUsers: { __typename?: 'Task'; id: string };
};

export type AssignTeamOwnerMutationVariables = Exact<{
  id: Scalars['ID'];
  newOwnerId: Scalars['ID'];
}>;

export type AssignTeamOwnerMutation = {
  __typename?: 'Mutation';
  assignTeamOwner: { __typename?: 'Team'; id: string };
};

export type CancelStripeSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelStripeSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelStripeSubscription?: {
    __typename?: 'Team';
    id: string;
    plan?: { __typename?: 'Plan'; id: string; status: string } | null;
  } | null;
};

export type ChatBlockUnblockUserMutationVariables = Exact<{
  userIdToBlock: Scalars['ID'];
}>;

export type ChatBlockUnblockUserMutation = {
  __typename?: 'Mutation';
  chatBlockUnblockUser: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'File';
      cdnBaseUrl: string;
      id: string;
      path: string;
      url: string;
    } | null;
  };
};

export type CompleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  chatId?: InputMaybe<Scalars['ID']>;
}>;

export type CompleteTaskMutation = {
  __typename?: 'Mutation';
  completeTask: {
    __typename?: 'Task';
    id: string;
    status?: TaskStatus | null;
    completedAt?: any | null;
  };
};

export type CreateChatMutationVariables = Exact<{
  attributes: ChatAttributes;
}>;

export type CreateChatMutation = {
  __typename?: 'Mutation';
  createChat: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    createdAt: any;
    owner: { __typename?: 'User'; id: string };
    users: Array<{ __typename?: 'User'; id: string }>;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      mute: boolean;
      pin: boolean;
    } | null;
  };
};

export type CreateContactInvitationUrlMutationVariables = Exact<{
  attributes: CreateContactInvitationUrlAttributes;
}>;

export type CreateContactInvitationUrlMutation = {
  __typename?: 'Mutation';
  createContactInvitationUrl?: string | null;
};

export type CreateDeviceMutationVariables = Exact<{
  attributes: DeviceAttributes;
}>;

export type CreateDeviceMutation = {
  __typename?: 'Mutation';
  createDevice?: { __typename?: 'Device'; token: string } | null;
};

export type CreateDirectUploadsMutationVariables = Exact<{
  attributes: Array<DirectUploadAttributes> | DirectUploadAttributes;
}>;

export type CreateDirectUploadsMutation = {
  __typename?: 'Mutation';
  createDirectUploads: Array<{
    __typename?: 'DirectUpload';
    clientId: string;
    url: string;
    headers: string;
    blobId: string;
    signedBlobId: string;
  }>;
};

export type CreateGroupMutationVariables = Exact<{
  attributes: GroupAttributes;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    team?: { __typename?: 'Team'; id: string; name: string } | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }> | null;
  };
};

export type CreateInviteMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars['ID']>;
  taskId?: InputMaybe<Scalars['ID']>;
}>;

export type CreateInviteMutation = {
  __typename?: 'Mutation';
  createInvite?: string | null;
};

export type CreateMessageIndividualMutationVariables = Exact<{
  attributes: MessageAttributes;
}>;

export type CreateMessageIndividualMutation = {
  __typename?: 'Mutation';
  createMessageIndividual: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    author: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    chat: { __typename?: 'Chat'; id: string; name: string };
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      author: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            name: string;
          } | null;
        }
      | { __typename?: 'MessageTaskAssignment' }
      | { __typename?: 'MessageTaskComplete' }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
  };
};

export type CreateMessageReactionMutationVariables = Exact<{
  messageId: Scalars['ID'];
  reaction: Scalars['String'];
}>;

export type CreateMessageReactionMutation = {
  __typename?: 'Mutation';
  createMessageReaction: {
    __typename?: 'MessageReaction';
    count: number;
    id: string;
    reaction: string;
    reactionId: string;
  };
};

export type CreatePhaseMutationVariables = Exact<{
  attributes: PhaseAttributes;
}>;

export type CreatePhaseMutation = {
  __typename?: 'Mutation';
  createPhase: { __typename?: 'Phase'; id: string; name: string };
};

export type CreateProjectMutationVariables = Exact<{
  attributes: ProjectAttributes;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject?: {
    __typename?: 'Project';
    id: string;
    name: string;
    description?: string | null;
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    color: string;
    icon?: string | null;
    createdAt: any;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      avatar?: string | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  } | null;
};

export type CreateShareLinkMutationVariables = Exact<{
  documentIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type CreateShareLinkMutation = {
  __typename?: 'Mutation';
  createShareLink?: {
    __typename?: 'CreateShareLinkPayload';
    sharedUrl?: string | null;
  } | null;
};

export type CreateStripeSubscriptionMutationVariables = Exact<{
  teamAttributes?: InputMaybe<TeamAttributes>;
  stripeSubscriptionAttributes: StripeSubscriptionAttributes;
}>;

export type CreateStripeSubscriptionMutation = {
  __typename?: 'Mutation';
  createStripeSubscription?: {
    __typename?: 'PaymentIntent';
    clientSecret: string;
    id: string;
    intentType: string;
    team: { __typename?: 'Team'; id: string };
  } | null;
};

export type CreateTaskMutationVariables = Exact<{
  attributes: TaskAttributes;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    status?: TaskStatus | null;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    phaseId?: string | null;
    projectId: string;
    deletedAt?: any | null;
    skillIds?: Array<string> | null;
    userIds: Array<string>;
    dueGroup?: string | null;
    fixedOrder?: boolean | null;
    recurring?: boolean | null;
    description?: string | null;
    frequency?: TaskFrequency | null;
    currentUserRole?: TaskMemberRole | null;
    currentUserProjectRole?: ProjectMemberRole | null;
    currentUserProjectTeamRole?: TeamMemberRole | null;
    dependencies?: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
    }> | null;
    checklists?: Array<{
      __typename?: 'TaskChecklist';
      id: string;
      name: string;
      completedAt?: any | null;
      createdAt: any;
    }> | null;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
      icon?: string | null;
      currentUserTeamRole?: TeamMemberRole | null;
      currentUserRole?: ProjectMemberRole | null;
      initials: string;
      archivedAt?: any | null;
      team?: {
        __typename?: 'Team';
        avatar?: string | null;
        createdAt: any;
        id: string;
        name: string;
        personal: boolean;
      } | null;
    };
    phase?: { __typename?: 'Phase'; id: string; name: string } | null;
  };
};

export type CreateTeamMutationVariables = Exact<{
  attributes: TeamAttributes;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type CreateTeamInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateTeamInvitationMutation = {
  __typename?: 'Mutation';
  createTeamInvitation: string;
};

export type DeleteDeviceMutationVariables = Exact<{
  expoToken: Scalars['String'];
}>;

export type DeleteDeviceMutation = {
  __typename?: 'Mutation';
  deleteDevice?: { __typename?: 'Device'; id: string } | null;
};

export type DeleteFriendRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  friendRequest: Scalars['Boolean'];
}>;

export type DeleteFriendRequestMutation = {
  __typename?: 'Mutation';
  deleteFriendRequest?: { __typename?: 'Chat'; id: string } | null;
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteGroupMutation = {
  __typename?: 'Mutation';
  deleteGroup?: { __typename?: 'Group'; id: string } | null;
};

export type DeleteGroupChatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteGroupChatMutation = {
  __typename?: 'Mutation';
  deleteGroupChat?: { __typename?: 'Chat'; id: string } | null;
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMessageMutation = {
  __typename?: 'Mutation';
  deleteMessage: {
    __typename?: 'Message';
    id: string;
    clientId: string;
    authorId: string;
  };
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: { __typename?: 'Project'; id: string };
};

export type DeleteProjectFilesMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DeleteProjectFilesMutation = {
  __typename?: 'Mutation';
  deleteProjectFiles: Array<{ __typename?: 'ProjectDocument'; id: string }>;
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskMutation = {
  __typename?: 'Mutation';
  deleteTask?: { __typename?: 'Task'; id: string } | null;
};

export type DeleteTaskFilesMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DeleteTaskFilesMutation = {
  __typename?: 'Mutation';
  deleteTaskFiles: Array<{ __typename?: 'TaskDocument'; id: string }>;
};

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTeamMutation = {
  __typename?: 'Mutation';
  deleteTeam?: { __typename?: 'Team'; id: string } | null;
};

export type DuplicateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  includeTasks?: InputMaybe<Scalars['Boolean']>;
  includeMembers?: InputMaybe<Scalars['Boolean']>;
  includePhases?: InputMaybe<Scalars['Boolean']>;
  includeAdmins?: InputMaybe<Scalars['Boolean']>;
  includeEditors?: InputMaybe<Scalars['Boolean']>;
  includeViewers?: InputMaybe<Scalars['Boolean']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
}>;

export type DuplicateProjectMutation = {
  __typename?: 'Mutation';
  duplicateProject: {
    __typename?: 'Project';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    archivedAt?: any | null;
    usersCount: number;
    tasksCount: number;
    currentUserRole?: ProjectMemberRole | null;
    currentUserTeamRole?: TeamMemberRole | null;
    isTaskMember: boolean;
    teamId: string;
    color: string;
    icon?: string | null;
    createdAt: any;
    description?: string | null;
    id: string;
    initials: string;
    name: string;
    unreadAuditsCount?: number | null;
    activeTasksCount: number;
    upcomingTasksCount: number;
    overdueTasksCount: number;
    completedTasksCount: number;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
      hasReachedMaxProjects: boolean;
      plan?: {
        __typename?: 'Plan';
        id: string;
        isCanceled: boolean;
        expiration: any;
      } | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
  };
};

export type DuplicateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  includeCheckLists?: InputMaybe<Scalars['Boolean']>;
  includeCreator?: InputMaybe<Scalars['Boolean']>;
  includeAssignees?: InputMaybe<Scalars['Boolean']>;
  includeViewers?: InputMaybe<Scalars['Boolean']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
}>;

export type DuplicateTaskMutation = {
  __typename?: 'Mutation';
  duplicateTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    status?: TaskStatus | null;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    phaseId?: string | null;
    projectId: string;
    skillIds?: Array<string> | null;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
    };
  };
};

export type DuplicateTaskFilesMutationVariables = Exact<{
  sourceId: Scalars['ID'];
  destinationId: Scalars['ID'];
}>;

export type DuplicateTaskFilesMutation = {
  __typename?: 'Mutation';
  duplicateTaskFiles: Array<{ __typename?: 'TaskDocument'; id: string }>;
};

export type EditStripeSubscriptionMutationVariables = Exact<{
  stripeSubscriptionAttributes: StripeSubscriptionAttributes;
}>;

export type EditStripeSubscriptionMutation = {
  __typename?: 'Mutation';
  editStripeSubscription?: {
    __typename?: 'PaymentIntent';
    clientSecret: string;
    id: string;
    intentType: string;
    team: { __typename?: 'Team'; id: string };
  } | null;
};

export type ExportCheckinsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  from?: InputMaybe<Scalars['ISO8601DateTime']>;
  to?: InputMaybe<Scalars['ISO8601DateTime']>;
}>;

export type ExportCheckinsMutation = {
  __typename?: 'Mutation';
  exportCheckins?: {
    __typename?: 'ExportCheckinsPayload';
    url: string;
    filename: string;
  } | null;
};

export type FriendRequestMutationVariables = Exact<{
  attributes: ChatAttributes;
}>;

export type FriendRequestMutation = {
  __typename?: 'Mutation';
  friendRequest: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    createdAt: any;
    owner: { __typename?: 'User'; id: string };
    users: Array<{ __typename?: 'User'; id: string }>;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      mute: boolean;
      pin: boolean;
    } | null;
  };
};

export type LeaveChatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LeaveChatMutation = {
  __typename?: 'Mutation';
  leaveChat?: { __typename?: 'Chat'; id: string } | null;
};

export type LeaveProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LeaveProjectMutation = {
  __typename?: 'Mutation';
  leaveProject?: { __typename?: 'Project'; id: string } | null;
};

export type LeaveTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type LeaveTaskMutation = {
  __typename?: 'Mutation';
  leaveTask?: {
    __typename?: 'Task';
    id: string;
    name: string;
    status?: TaskStatus | null;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    phaseId?: string | null;
    projectId: string;
    skillIds?: Array<string> | null;
    userIds: Array<string>;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
    };
  } | null;
};

export type LeaveTeamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LeaveTeamMutation = {
  __typename?: 'Mutation';
  leaveTeam: { __typename?: 'Team'; id: string; name: string };
};

export type MarkAuditsReadMutationVariables = Exact<{
  attributes: ReadReceiptAuditAttributes;
}>;

export type MarkAuditsReadMutation = {
  __typename?: 'Mutation';
  markAuditsRead: Array<{ __typename?: 'Audit'; id: string; event: string }>;
};

export type MarkChatsReadMutationVariables = Exact<{
  attributes: ReadReceiptChatAttributes;
}>;

export type MarkChatsReadMutation = {
  __typename?: 'Mutation';
  markChatsRead: Array<{
    __typename?: 'Chat';
    id: string;
    unreadCount?: number | null;
  }>;
};

export type RemoveMeMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveMeMutation = {
  __typename?: 'Mutation';
  removeMe: { __typename?: 'User'; id: string };
};

export type RemoveMessageReactionMutationVariables = Exact<{
  messageReactionId: Scalars['ID'];
}>;

export type RemoveMessageReactionMutation = {
  __typename?: 'Mutation';
  removeMessageReaction: {
    __typename?: 'MessageReaction';
    id: string;
    reaction: string;
    count: number;
  };
};

export type RemoveProjectMembersMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: ProjectAttributes;
}>;

export type RemoveProjectMembersMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    id: string;
    usersCount: number;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  } | null;
};

export type RemoveTaskChecklistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveTaskChecklistMutation = {
  __typename?: 'Mutation';
  removeTaskChecklist: {
    __typename?: 'TaskChecklist';
    taskId: string;
    id: string;
    name: string;
    completedAt?: any | null;
    createdAt: any;
    sort?: number | null;
  };
};

export type RemoveTeamMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type RemoveTeamMemberMutation = {
  __typename?: 'Mutation';
  removeTeamMember: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type SetPrimaryTeamMutationVariables = Exact<{
  accountId: Scalars['ID'];
}>;

export type SetPrimaryTeamMutation = {
  __typename?: 'Mutation';
  setPrimaryTeam: { __typename?: 'User'; id: string; name?: string | null };
};

export type UnarchiveChatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnarchiveChatMutation = {
  __typename?: 'Mutation';
  unarchiveChat: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    users: Array<{ __typename?: 'User'; id: string }>;
  };
};

export type UnarchiveProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnarchiveProjectMutation = {
  __typename?: 'Mutation';
  unarchiveProject: {
    __typename?: 'Project';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    archivedAt?: any | null;
    usersCount: number;
    tasksCount: number;
    currentUserRole?: ProjectMemberRole | null;
    currentUserTeamRole?: TeamMemberRole | null;
    isTaskMember: boolean;
    teamId: string;
    color: string;
    icon?: string | null;
    createdAt: any;
    description?: string | null;
    id: string;
    initials: string;
    name: string;
    unreadAuditsCount?: number | null;
    activeTasksCount: number;
    upcomingTasksCount: number;
    overdueTasksCount: number;
    completedTasksCount: number;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
      hasReachedMaxProjects: boolean;
      plan?: {
        __typename?: 'Plan';
        id: string;
        isCanceled: boolean;
        expiration: any;
      } | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
  };
};

export type UpdateChatMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: ChatAttributes;
}>;

export type UpdateChatMutation = {
  __typename?: 'Mutation';
  updateChat: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      mute: boolean;
      pin: boolean;
    } | null;
  };
};

export type UpdateChatAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type UpdateChatAdminMutation = {
  __typename?: 'Mutation';
  updateChatAdmin: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    owner: { __typename?: 'User'; id: string };
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      mute: boolean;
      pin: boolean;
    } | null;
  };
};

export type UpdateDraftMessageMutationVariables = Exact<{
  chatId: Scalars['ID'];
  attributes: DraftMessageAttributes;
}>;

export type UpdateDraftMessageMutation = {
  __typename?: 'Mutation';
  updateDraftMessage?: {
    __typename?: 'DraftMessage';
    chatId: string;
    body?: string | null;
    tagsCollection: Array<{
      __typename?: 'TaskTagCollection';
      tasks: Array<{
        __typename?: 'TaskTag';
        id: string;
        name: string;
        userIds: Array<string>;
        status?: TaskStatus | null;
        projectId: string;
        project?: {
          __typename?: 'ProjectTag';
          id: string;
          name: string;
        } | null;
      } | null>;
      project: { __typename?: 'ProjectTag'; id: string; name: string };
      author: { __typename?: 'User'; id: string; name?: string | null };
    } | null>;
    localFiles: Array<{
      __typename?: 'LocalFile';
      id: string;
      name: string;
      clientId: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      contentType: string;
      isImage: boolean;
      isVideo: boolean;
      size?: number | null;
      isAudio: boolean;
      duration?: number | null;
      isPreviewable: boolean;
      thumbnail: string;
    } | null>;
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      cursor: string;
      readAt?: any | null;
      isRetry: boolean;
      replyId?: string | null;
      readReceipts?: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      }> | null;
      checkin?: {
        __typename?: 'MessageCheckin';
        id: string;
        address?: string | null;
        city?: string | null;
        zipcode?: string | null;
        state?: string | null;
        country?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
        avatar?: {
          __typename?: 'File';
          id: string;
          url: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
      };
      replyMessage?: {
        __typename?: 'Message';
        id: string;
        chatId: string;
        clientId: string;
        body?: string | null;
        createdAt: any;
        isSender: boolean;
        isDraft: boolean;
        publishedAt?: any | null;
        authorId: string;
        author: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          createdAt: any;
        };
        attachments?: Array<
          | {
              __typename?: 'Document';
              id: string;
              name: string;
              contentType?: string | null;
              createdAt: any;
              size?: number | null;
              isImage: boolean;
              clientId: string;
              isAudio: boolean;
              duration?: number | null;
              file: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              };
              owner: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
              };
            }
          | { __typename?: 'EntityUser' }
          | { __typename?: 'Folder' }
          | { __typename?: 'LocalFile' }
          | { __typename?: 'Message' }
          | {
              __typename?: 'MessageProject';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              project?: {
                __typename?: 'Project';
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTask';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              task?: {
                __typename?: 'Task';
                id: string;
                projectId: string;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTaskAssignment';
              id: string;
              messageId: string;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              }>;
              task?: {
                __typename?: 'Task';
                id: string;
                name: string;
                deletedAt?: any | null;
                completedAt?: any | null;
              } | null;
            }
          | {
              __typename?: 'MessageTaskComplete';
              id: string;
              messageId: string;
              completor?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  thumbnail?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Project' }
          | { __typename?: 'ProjectUser' }
          | { __typename?: 'Task' }
          | { __typename?: 'TaskUser' }
          | { __typename?: 'UserRequest' }
        > | null;
      } | null;
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              deletedAt?: any | null;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
      reaction?: Array<{
        __typename?: 'MessageReaction';
        id: string;
        reaction: string;
        count: number;
        user?: Array<{ __typename?: 'User'; id: string }> | null;
      }> | null;
    } | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  } | null;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: GroupAttributes;
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    team?: { __typename?: 'Team'; id: string } | null;
    users?: Array<{ __typename?: 'User'; id: string }> | null;
  };
};

export type UpdateMeMutationVariables = Exact<{
  attributes: UserAttributes;
}>;

export type UpdateMeMutation = {
  __typename?: 'Mutation';
  updateMe: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    timeZone?: string | null;
    skills?: Array<{ __typename?: 'Skill'; name: string }> | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  };
};

export type UpdateMessageIndividualMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['String']>;
  attributes: MessageAttributes;
}>;

export type UpdateMessageIndividualMutation = {
  __typename?: 'Mutation';
  updateMessageIndividual: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    cursor: string;
    readAt?: any | null;
    isRetry: boolean;
    replyId?: string | null;
    readReceipts?: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }> | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
      };
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            deletedAt?: any | null;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
    reaction?: Array<{
      __typename?: 'MessageReaction';
      id: string;
      reaction: string;
      count: number;
      user?: Array<{ __typename?: 'User'; id: string }> | null;
    }> | null;
  };
};

export type UpdateMessageTagsMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: MessageTagAttributes;
}>;

export type UpdateMessageTagsMutation = {
  __typename?: 'Mutation';
  updateMessageTags: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    cursor: string;
    readAt?: any | null;
    isRetry: boolean;
    replyId?: string | null;
    readReceipts?: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }> | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
      };
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            deletedAt?: any | null;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
    reaction?: Array<{
      __typename?: 'MessageReaction';
      id: string;
      reaction: string;
      count: number;
      user?: Array<{ __typename?: 'User'; id: string }> | null;
    }> | null;
  };
};

export type UpdateNotificationSettingMutationVariables = Exact<{
  attributes:
    | Array<NotificationSettingAttributes>
    | NotificationSettingAttributes;
}>;

export type UpdateNotificationSettingMutation = {
  __typename?: 'Mutation';
  updateNotificationSetting: Array<{
    __typename?: 'UserNotificationSetting';
    settingType: NotificationSetting;
    mute: boolean;
    duration?: NotificationSettingDuration | null;
  }>;
};

export type UpdatePaymentMethodMutationVariables = Exact<{
  teamId: Scalars['ID'];
  paymentMethodToken: Scalars['String'];
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: 'Mutation';
  updatePaymentMethod: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: ProjectAttributes;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    id: string;
    teamId: string;
    initials: string;
    name: string;
    description?: string | null;
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    color: string;
    icon?: string | null;
    createdAt: any;
    archivedAt?: any | null;
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      avatar?: string | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    tasks?: Array<{ __typename?: 'Task'; id: string }> | null;
  } | null;
};

export type UpdateProjectFileTagsMutationVariables = Exact<{
  id: Scalars['ID'];
  taskIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  projectId: Scalars['ID'];
}>;

export type UpdateProjectFileTagsMutation = {
  __typename?: 'Mutation';
  updateProjectFileTags: {
    __typename?: 'Document';
    id: string;
    clientId: string;
  };
};

export type UpdateProjectMembersMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: Array<ProjectMemberAttributes> | ProjectMemberAttributes;
}>;

export type UpdateProjectMembersMutation = {
  __typename?: 'Mutation';
  updateProjectMembers: {
    __typename?: 'Project';
    id: string;
    usersCount: number;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  };
};

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: TaskAttributes;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    status?: TaskStatus | null;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    phaseId?: string | null;
    projectId: string;
    deletedAt?: any | null;
    skillIds?: Array<string> | null;
    userIds: Array<string>;
    dueGroup?: string | null;
    fixedOrder?: boolean | null;
    recurring?: boolean | null;
    description?: string | null;
    frequency?: TaskFrequency | null;
    currentUserRole?: TaskMemberRole | null;
    currentUserProjectRole?: ProjectMemberRole | null;
    currentUserProjectTeamRole?: TeamMemberRole | null;
    dependencies?: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
    }> | null;
    checklists?: Array<{
      __typename?: 'TaskChecklist';
      id: string;
      name: string;
      completedAt?: any | null;
      createdAt: any;
    }> | null;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
      icon?: string | null;
      currentUserTeamRole?: TeamMemberRole | null;
      currentUserRole?: ProjectMemberRole | null;
      initials: string;
      archivedAt?: any | null;
      team?: {
        __typename?: 'Team';
        avatar?: string | null;
        createdAt: any;
        id: string;
        name: string;
        personal: boolean;
      } | null;
    };
    phase?: { __typename?: 'Phase'; id: string; name: string } | null;
  };
};

export type UpdateTaskChecklistMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: TaskChecklistAttributes;
}>;

export type UpdateTaskChecklistMutation = {
  __typename?: 'Mutation';
  updateTaskChecklist: {
    __typename?: 'TaskChecklist';
    taskId: string;
    id: string;
    name: string;
    completedAt?: any | null;
    createdAt: any;
    sort?: number | null;
  };
};

export type UpdateTaskChecklistSortMutationVariables = Exact<{
  taskId: Scalars['ID'];
  attributes: Array<TaskChecklistAttributes> | TaskChecklistAttributes;
}>;

export type UpdateTaskChecklistSortMutation = {
  __typename?: 'Mutation';
  updateTaskChecklistSort: Array<{
    __typename?: 'TaskChecklist';
    taskId: string;
    id: string;
    name: string;
    completedAt?: any | null;
    createdAt: any;
    sort?: number | null;
  }>;
};

export type UpdateTaskMembersMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: Array<TaskMemberAttributes> | TaskMemberAttributes;
}>;

export type UpdateTaskMembersMutation = {
  __typename?: 'Mutation';
  updateTaskMembers: {
    __typename?: 'Task';
    id: string;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  };
};

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: TeamAttributes;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type UpdateTeamMembersMutationVariables = Exact<{
  teamId: Scalars['ID'];
  attributes: Array<MemberAttributes> | MemberAttributes;
}>;

export type UpdateTeamMembersMutation = {
  __typename?: 'Mutation';
  updateTeamMembers: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type UpdateTutoralizationSettingMutationVariables = Exact<{
  attributes:
    | Array<TutoralizationSettingAttributes>
    | TutoralizationSettingAttributes;
}>;

export type UpdateTutoralizationSettingMutation = {
  __typename?: 'Mutation';
  updateTutoralizationSetting: Array<{
    __typename?: 'UserTutoralizationSetting';
    close: boolean;
    settingType: TutoralizationSetting;
  }>;
};

export type UploadContactsMutationVariables = Exact<{
  attributes: UploadContactAttributes;
}>;

export type UploadContactsMutation = {
  __typename?: 'Mutation';
  uploadContacts?: { __typename?: 'User'; phoneNumber: string } | null;
};

export type DocumentsByClientIdsQueryVariables = Exact<{
  clientIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DocumentsByClientIdsQuery = {
  __typename?: 'Query';
  documentsByClientIds?: Array<{
    __typename?: 'Document';
    clientId: string;
    file: { __typename?: 'File'; id: string; url: string };
  }> | null;
};

export type GetChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetChatQuery = {
  __typename?: 'Query';
  getChat: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    messageIcon?: string | null;
    messageBody?: string | null;
    recentActivityAt?: any | null;
    unreadCount?: number | null;
    isNewChat?: boolean | null;
    isUserBlocked?: boolean | null;
    friendRequestAccepted?: boolean | null;
    createdAt: any;
    leaveOn?: any | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    owner: { __typename?: 'User'; id: string; name?: string | null };
    settings?: {
      __typename?: 'UserChatSetting';
      id: string;
      pin: boolean;
      mute: boolean;
      archivedAt?: any | null;
    } | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      phoneNumber: string;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }>;
  };
};

export type GetDraftMessageQueryVariables = Exact<{
  chatId: Scalars['ID'];
}>;

export type GetDraftMessageQuery = {
  __typename?: 'Query';
  getDraftMessage?: {
    __typename?: 'DraftMessage';
    chatId: string;
    body?: string | null;
    tagsCollection: Array<{
      __typename?: 'TaskTagCollection';
      tasks: Array<{
        __typename?: 'TaskTag';
        id: string;
        name: string;
        userIds: Array<string>;
        status?: TaskStatus | null;
        projectId: string;
        project?: {
          __typename?: 'ProjectTag';
          id: string;
          name: string;
        } | null;
      } | null>;
      project: { __typename?: 'ProjectTag'; id: string; name: string };
      author: { __typename?: 'User'; id: string; name?: string | null };
    } | null>;
    localFiles: Array<{
      __typename?: 'LocalFile';
      id: string;
      name: string;
      clientId: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      contentType: string;
      isImage: boolean;
      isVideo: boolean;
      size?: number | null;
      isAudio: boolean;
      duration?: number | null;
      isPreviewable: boolean;
      thumbnail: string;
    } | null>;
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      cursor: string;
      readAt?: any | null;
      isRetry: boolean;
      replyId?: string | null;
      readReceipts?: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      }> | null;
      checkin?: {
        __typename?: 'MessageCheckin';
        id: string;
        address?: string | null;
        city?: string | null;
        zipcode?: string | null;
        state?: string | null;
        country?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
        avatar?: {
          __typename?: 'File';
          id: string;
          url: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
      };
      replyMessage?: {
        __typename?: 'Message';
        id: string;
        chatId: string;
        clientId: string;
        body?: string | null;
        createdAt: any;
        isSender: boolean;
        isDraft: boolean;
        publishedAt?: any | null;
        authorId: string;
        author: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          createdAt: any;
        };
        attachments?: Array<
          | {
              __typename?: 'Document';
              id: string;
              name: string;
              contentType?: string | null;
              createdAt: any;
              size?: number | null;
              isImage: boolean;
              clientId: string;
              isAudio: boolean;
              duration?: number | null;
              file: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              };
              owner: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
              };
            }
          | { __typename?: 'EntityUser' }
          | { __typename?: 'Folder' }
          | { __typename?: 'LocalFile' }
          | { __typename?: 'Message' }
          | {
              __typename?: 'MessageProject';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              project?: {
                __typename?: 'Project';
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTask';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              task?: {
                __typename?: 'Task';
                id: string;
                projectId: string;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTaskAssignment';
              id: string;
              messageId: string;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              }>;
              task?: {
                __typename?: 'Task';
                id: string;
                name: string;
                deletedAt?: any | null;
                completedAt?: any | null;
              } | null;
            }
          | {
              __typename?: 'MessageTaskComplete';
              id: string;
              messageId: string;
              completor?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  thumbnail?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Project' }
          | { __typename?: 'ProjectUser' }
          | { __typename?: 'Task' }
          | { __typename?: 'TaskUser' }
          | { __typename?: 'UserRequest' }
        > | null;
      } | null;
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              deletedAt?: any | null;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
      reaction?: Array<{
        __typename?: 'MessageReaction';
        id: string;
        reaction: string;
        count: number;
        user?: Array<{ __typename?: 'User'; id: string }> | null;
      }> | null;
    } | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  } | null;
};

export type GetGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGroupQuery = {
  __typename?: 'Query';
  getGroup?: {
    __typename?: 'Group';
    id: string;
    name: string;
    currentUserTeamRole?: TeamRole | null;
    team?: {
      __typename?: 'Team';
      id: string;
      avatar?: string | null;
      name: string;
      personal: boolean;
    } | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  getMe: {
    __typename?: 'User';
    inviteUrl: string;
    contactsCount: number;
    contactsSyncedAt?: any | null;
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    timeZone?: string | null;
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    notificationSetting?: Array<{
      __typename?: 'UserNotificationSetting';
      duration?: NotificationSettingDuration | null;
      mute: boolean;
      settingType: NotificationSetting;
    }> | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
    tutoralizationSetting?: Array<{
      __typename?: 'UserTutoralizationSetting';
      settingType: TutoralizationSetting;
      close: boolean;
    }> | null;
  };
};

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'Product';
    allowMembers: boolean;
    externalId: string;
    guestsOnly: boolean;
    id: string;
    maxDocumentStorage: any;
    maxNumberOfProjects: any;
    name: string;
  };
};

export type GetProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileQuery = {
  __typename?: 'Query';
  getProfile: {
    __typename?: 'Profile';
    address?: string | null;
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    userActiveTasksCount: number;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
    teams?: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      usersCount: number;
      users: Array<{ __typename?: 'User'; id: string; name?: string | null }>;
    }> | null;
  };
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    archivedAt?: any | null;
    usersCount: number;
    tasksCount: number;
    currentUserRole?: ProjectMemberRole | null;
    currentUserTeamRole?: TeamMemberRole | null;
    isTaskMember: boolean;
    teamId: string;
    color: string;
    icon?: string | null;
    createdAt: any;
    description?: string | null;
    id: string;
    initials: string;
    name: string;
    unreadAuditsCount?: number | null;
    activeTasksCount: number;
    upcomingTasksCount: number;
    overdueTasksCount: number;
    completedTasksCount: number;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
      hasReachedMaxProjects: boolean;
      plan?: {
        __typename?: 'Plan';
        id: string;
        isCanceled: boolean;
        expiration: any;
      } | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
  };
};

export type GetProjectMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProjectMembersQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    id: string;
    teamId: string;
    name: string;
    address?: string | null;
    description?: string | null;
    color: string;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  };
};

export type GetProjectTasksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProjectTasksQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    id: string;
    name: string;
    tasks?: Array<{ __typename?: 'Task'; id: string; name: string }> | null;
  };
};

export type GetTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTaskQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    status?: TaskStatus | null;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    phaseId?: string | null;
    projectId: string;
    deletedAt?: any | null;
    skillIds?: Array<string> | null;
    userIds: Array<string>;
    dueGroup?: string | null;
    fixedOrder?: boolean | null;
    recurring?: boolean | null;
    description?: string | null;
    frequency?: TaskFrequency | null;
    currentUserRole?: TaskMemberRole | null;
    currentUserProjectRole?: ProjectMemberRole | null;
    currentUserProjectTeamRole?: TeamMemberRole | null;
    auditsCount?: number | null;
    dependencies?: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
    }> | null;
    checklists?: Array<{
      __typename?: 'TaskChecklist';
      id: string;
      name: string;
      completedAt?: any | null;
      createdAt: any;
    }> | null;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
      currentUserTeamRole?: TeamMemberRole | null;
      currentUserRole?: ProjectMemberRole | null;
      initials: string;
      archivedAt?: any | null;
      team?: {
        __typename?: 'Team';
        avatar?: string | null;
        createdAt: any;
        id: string;
        name: string;
        personal: boolean;
      } | null;
    };
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
    phase?: { __typename?: 'Phase'; id: string; name: string } | null;
  };
};

export type GetTaskMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTaskMembersQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
  };
};

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
  includePending?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTeamQuery = {
  __typename?: 'Query';
  getTeam: {
    __typename?: 'Team';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    id: string;
    avatar?: string | null;
    createdAt: any;
    name: string;
    usersCount: number;
    fileStorageUsed: any;
    mediaStorageUsed: any;
    maxDocumentStorage: any;
    storageRemaining: any;
    hasReachedMaxProjects: boolean;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    personal: boolean;
    tasksCount: number;
    completedTasksCount: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      amount: number;
      expiration: any;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }>;
    users: Array<{
      __typename?: 'User';
      teamRoles?: Array<TeamRole> | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      prices: Array<{
        __typename?: 'Price';
        id: string;
        interval: string;
        intervalCount: number;
        externalId: string;
      }>;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      id: string;
      invoiceUrl: string;
      amount: number;
      date: any;
      description: string;
      status: string;
    }> | null;
  };
};

export type ListActivitiesByProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListActivitiesByProjectQuery = {
  __typename?: 'Query';
  listActivitiesByProject: {
    __typename?: 'AuditConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'AuditEdge';
      cursor: string;
      node?: {
        __typename?: 'Audit';
        event: string;
        createdAt: any;
        id: string;
        projectRole?: ProjectMemberRole | null;
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        };
        attachment?:
          | {
              __typename?: 'Document';
              id: string;
              name: string;
              contentType?: string | null;
              createdAt: any;
              size?: number | null;
              isImage: boolean;
              clientId: string;
              isAudio: boolean;
              duration?: number | null;
              file: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              };
              owner: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              };
            }
          | {
              __typename?: 'EntityUser';
              id: string;
              user: {
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              };
              entityTask?: {
                __typename?: 'Task';
                id: string;
                name: string;
                deletedAt?: any | null;
                members: Array<{
                  __typename?: 'TaskMember';
                  id: string;
                  role: TaskMemberRole;
                  user: {
                    __typename?: 'User';
                    id: string;
                    email: string;
                    name?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    phoneNumber: string;
                    primaryTeamId?: string | null;
                    createdAt: any;
                    inviteUrl: string;
                    avatar?: {
                      __typename?: 'File';
                      id: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    } | null;
                    skills?: Array<{
                      __typename?: 'Skill';
                      id: string;
                      name: string;
                      createdAt: any;
                    }> | null;
                  };
                }>;
                project: { __typename?: 'Project'; id: string; name: string };
              } | null;
              entityProject?: {
                __typename?: 'Project';
                id: string;
                name: string;
              } | null;
            }
          | { __typename?: 'Folder' }
          | { __typename?: 'LocalFile' }
          | {
              __typename?: 'Message';
              id: string;
              chatId: string;
              clientId: string;
              body?: string | null;
              createdAt: any;
              isSender: boolean;
              isDraft: boolean;
              publishedAt?: any | null;
              authorId: string;
              cursor: string;
              readAt?: any | null;
              isRetry: boolean;
              replyId?: string | null;
              readReceipts?: Array<{
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              }> | null;
              checkin?: {
                __typename?: 'MessageCheckin';
                id: string;
                address?: string | null;
                city?: string | null;
                zipcode?: string | null;
                state?: string | null;
                country?: string | null;
                latitude?: number | null;
                longitude?: number | null;
              } | null;
              author: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              };
              replyMessage?: {
                __typename?: 'Message';
                id: string;
                chatId: string;
                clientId: string;
                body?: string | null;
                createdAt: any;
                isSender: boolean;
                isDraft: boolean;
                publishedAt?: any | null;
                authorId: string;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                };
                attachments?: Array<
                  | {
                      __typename?: 'Document';
                      id: string;
                      name: string;
                      contentType?: string | null;
                      createdAt: any;
                      size?: number | null;
                      isImage: boolean;
                      clientId: string;
                      isAudio: boolean;
                      duration?: number | null;
                      file: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      };
                      owner: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        firstName?: string | null;
                        lastName?: string | null;
                        createdAt: any;
                      };
                    }
                  | { __typename?: 'EntityUser' }
                  | { __typename?: 'Folder' }
                  | { __typename?: 'LocalFile' }
                  | { __typename?: 'Message' }
                  | {
                      __typename?: 'MessageProject';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      project?: {
                        __typename?: 'Project';
                        id: string;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTask';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        projectId: string;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskAssignment';
                      id: string;
                      messageId: string;
                      assignees: Array<{
                        __typename?: 'User';
                        id: string;
                        email: string;
                        name?: string | null;
                        firstName?: string | null;
                        lastName?: string | null;
                        phoneNumber: string;
                        primaryTeamId?: string | null;
                        createdAt: any;
                        inviteUrl: string;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                        skills?: Array<{
                          __typename?: 'Skill';
                          id: string;
                          name: string;
                          createdAt: any;
                        }> | null;
                      }>;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        name: string;
                        deletedAt?: any | null;
                        completedAt?: any | null;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskComplete';
                      id: string;
                      messageId: string;
                      completor?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                    }
                  | { __typename?: 'Project' }
                  | { __typename?: 'ProjectUser' }
                  | { __typename?: 'Task' }
                  | { __typename?: 'TaskUser' }
                  | { __typename?: 'UserRequest' }
                > | null;
              } | null;
              attachments?: Array<
                | {
                    __typename?: 'Document';
                    id: string;
                    name: string;
                    contentType?: string | null;
                    createdAt: any;
                    size?: number | null;
                    isImage: boolean;
                    clientId: string;
                    isAudio: boolean;
                    duration?: number | null;
                    file: {
                      __typename?: 'File';
                      id: string;
                      url: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    };
                    owner: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      firstName?: string | null;
                      lastName?: string | null;
                      createdAt: any;
                    };
                  }
                | { __typename?: 'EntityUser' }
                | { __typename?: 'Folder' }
                | { __typename?: 'LocalFile' }
                | { __typename?: 'Message' }
                | {
                    __typename?: 'MessageProject';
                    id: string;
                    authorId: string;
                    author?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                    project?: {
                      __typename?: 'Project';
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTask';
                    id: string;
                    authorId: string;
                    author?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                    task?: {
                      __typename?: 'Task';
                      id: string;
                      projectId: string;
                      deletedAt?: any | null;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTaskAssignment';
                    id: string;
                    messageId: string;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        thumbnail?: string | null;
                      } | null;
                    }>;
                    task?: {
                      __typename?: 'Task';
                      id: string;
                      name: string;
                      deletedAt?: any | null;
                      completedAt?: any | null;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTaskComplete';
                    id: string;
                    messageId: string;
                    completor?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                  }
                | { __typename?: 'Project' }
                | { __typename?: 'ProjectUser' }
                | { __typename?: 'Task' }
                | { __typename?: 'TaskUser' }
                | { __typename?: 'UserRequest' }
              > | null;
              reaction?: Array<{
                __typename?: 'MessageReaction';
                id: string;
                reaction: string;
                count: number;
                user?: Array<{ __typename?: 'User'; id: string }> | null;
              }> | null;
            }
          | { __typename?: 'MessageProject' }
          | { __typename?: 'MessageTask' }
          | { __typename?: 'MessageTaskAssignment' }
          | { __typename?: 'MessageTaskComplete' }
          | { __typename?: 'Project'; id: string; name: string }
          | { __typename?: 'ProjectUser' }
          | {
              __typename?: 'Task';
              id: string;
              name: string;
              projectId: string;
              deletedAt?: any | null;
              project: { __typename?: 'Project'; id: string; name: string };
            }
          | {
              __typename?: 'TaskUser';
              id: string;
              task: {
                __typename?: 'Task';
                id: string;
                name: string;
                project: { __typename?: 'Project'; id: string; name: string };
              };
              user: {
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              };
            }
          | { __typename?: 'UserRequest' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type ListActivitiesByTaskQueryVariables = Exact<{
  taskId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListActivitiesByTaskQuery = {
  __typename?: 'Query';
  listActivitiesByTask: {
    __typename?: 'AuditConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'AuditEdge';
      cursor: string;
      node?: {
        __typename?: 'Audit';
        event: string;
        createdAt: any;
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        };
        attachment?:
          | {
              __typename?: 'Document';
              id: string;
              name: string;
              contentType?: string | null;
              createdAt: any;
              size?: number | null;
              isImage: boolean;
              clientId: string;
              isAudio: boolean;
              duration?: number | null;
              file: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              };
              owner: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              };
            }
          | {
              __typename?: 'EntityUser';
              id: string;
              user: {
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              };
              entityTask?: {
                __typename?: 'Task';
                id: string;
                name: string;
                deletedAt?: any | null;
                members: Array<{
                  __typename?: 'TaskMember';
                  id: string;
                  role: TaskMemberRole;
                  user: {
                    __typename?: 'User';
                    id: string;
                    email: string;
                    name?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    phoneNumber: string;
                    primaryTeamId?: string | null;
                    createdAt: any;
                    inviteUrl: string;
                    avatar?: {
                      __typename?: 'File';
                      id: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    } | null;
                    skills?: Array<{
                      __typename?: 'Skill';
                      id: string;
                      name: string;
                      createdAt: any;
                    }> | null;
                  };
                }>;
                project: { __typename?: 'Project'; id: string; name: string };
              } | null;
              entityProject?: {
                __typename?: 'Project';
                id: string;
                name: string;
              } | null;
            }
          | { __typename?: 'Folder' }
          | { __typename?: 'LocalFile' }
          | {
              __typename?: 'Message';
              id: string;
              chatId: string;
              clientId: string;
              body?: string | null;
              createdAt: any;
              isSender: boolean;
              isDraft: boolean;
              publishedAt?: any | null;
              authorId: string;
              cursor: string;
              readAt?: any | null;
              isRetry: boolean;
              replyId?: string | null;
              readReceipts?: Array<{
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              }> | null;
              checkin?: {
                __typename?: 'MessageCheckin';
                id: string;
                address?: string | null;
                city?: string | null;
                zipcode?: string | null;
                state?: string | null;
                country?: string | null;
                latitude?: number | null;
                longitude?: number | null;
              } | null;
              author: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              };
              replyMessage?: {
                __typename?: 'Message';
                id: string;
                chatId: string;
                clientId: string;
                body?: string | null;
                createdAt: any;
                isSender: boolean;
                isDraft: boolean;
                publishedAt?: any | null;
                authorId: string;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                };
                attachments?: Array<
                  | {
                      __typename?: 'Document';
                      id: string;
                      name: string;
                      contentType?: string | null;
                      createdAt: any;
                      size?: number | null;
                      isImage: boolean;
                      clientId: string;
                      isAudio: boolean;
                      duration?: number | null;
                      file: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      };
                      owner: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        firstName?: string | null;
                        lastName?: string | null;
                        createdAt: any;
                      };
                    }
                  | { __typename?: 'EntityUser' }
                  | { __typename?: 'Folder' }
                  | { __typename?: 'LocalFile' }
                  | { __typename?: 'Message' }
                  | {
                      __typename?: 'MessageProject';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      project?: {
                        __typename?: 'Project';
                        id: string;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTask';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        projectId: string;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskAssignment';
                      id: string;
                      messageId: string;
                      assignees: Array<{
                        __typename?: 'User';
                        id: string;
                        email: string;
                        name?: string | null;
                        firstName?: string | null;
                        lastName?: string | null;
                        phoneNumber: string;
                        primaryTeamId?: string | null;
                        createdAt: any;
                        inviteUrl: string;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                        skills?: Array<{
                          __typename?: 'Skill';
                          id: string;
                          name: string;
                          createdAt: any;
                        }> | null;
                      }>;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        name: string;
                        deletedAt?: any | null;
                        completedAt?: any | null;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskComplete';
                      id: string;
                      messageId: string;
                      completor?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                    }
                  | { __typename?: 'Project' }
                  | { __typename?: 'ProjectUser' }
                  | { __typename?: 'Task' }
                  | { __typename?: 'TaskUser' }
                  | { __typename?: 'UserRequest' }
                > | null;
              } | null;
              attachments?: Array<
                | {
                    __typename?: 'Document';
                    id: string;
                    name: string;
                    contentType?: string | null;
                    createdAt: any;
                    size?: number | null;
                    isImage: boolean;
                    clientId: string;
                    isAudio: boolean;
                    duration?: number | null;
                    file: {
                      __typename?: 'File';
                      id: string;
                      url: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    };
                    owner: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      firstName?: string | null;
                      lastName?: string | null;
                      createdAt: any;
                    };
                  }
                | { __typename?: 'EntityUser' }
                | { __typename?: 'Folder' }
                | { __typename?: 'LocalFile' }
                | { __typename?: 'Message' }
                | {
                    __typename?: 'MessageProject';
                    id: string;
                    authorId: string;
                    author?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                    project?: {
                      __typename?: 'Project';
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTask';
                    id: string;
                    authorId: string;
                    author?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                    task?: {
                      __typename?: 'Task';
                      id: string;
                      projectId: string;
                      deletedAt?: any | null;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTaskAssignment';
                    id: string;
                    messageId: string;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        thumbnail?: string | null;
                      } | null;
                    }>;
                    task?: {
                      __typename?: 'Task';
                      id: string;
                      name: string;
                      deletedAt?: any | null;
                      completedAt?: any | null;
                    } | null;
                  }
                | {
                    __typename?: 'MessageTaskComplete';
                    id: string;
                    messageId: string;
                    completor?: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        thumbnail?: string | null;
                      } | null;
                    } | null;
                  }
                | { __typename?: 'Project' }
                | { __typename?: 'ProjectUser' }
                | { __typename?: 'Task' }
                | { __typename?: 'TaskUser' }
                | { __typename?: 'UserRequest' }
              > | null;
              reaction?: Array<{
                __typename?: 'MessageReaction';
                id: string;
                reaction: string;
                count: number;
                user?: Array<{ __typename?: 'User'; id: string }> | null;
              }> | null;
            }
          | { __typename?: 'MessageProject' }
          | { __typename?: 'MessageTask' }
          | { __typename?: 'MessageTaskAssignment' }
          | { __typename?: 'MessageTaskComplete' }
          | { __typename?: 'Project'; id: string; name: string }
          | { __typename?: 'ProjectUser' }
          | {
              __typename?: 'Task';
              id: string;
              name: string;
              projectId: string;
              deletedAt?: any | null;
              completedAt?: any | null;
              project: { __typename?: 'Project'; id: string; name: string };
              completor?: {
                __typename?: 'User';
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  url: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'TaskUser';
              id: string;
              task: {
                __typename?: 'Task';
                id: string;
                name: string;
                project: { __typename?: 'Project'; id: string; name: string };
              };
              user: {
                __typename?: 'User';
                id: string;
                email: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                phoneNumber: string;
                primaryTeamId?: string | null;
                createdAt: any;
                inviteUrl: string;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
                skills?: Array<{
                  __typename?: 'Skill';
                  id: string;
                  name: string;
                  createdAt: any;
                }> | null;
              };
            }
          | { __typename?: 'UserRequest' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type ListBlockedUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListBlockedUsersQuery = {
  __typename?: 'Query';
  listBlockedUsers: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  }>;
};

export type ListChatIdentifiersQueryVariables = Exact<{ [key: string]: never }>;

export type ListChatIdentifiersQuery = {
  __typename?: 'Query';
  listChats: Array<{
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    messageIcon?: string | null;
    messageBody?: string | null;
    recentActivityAt?: any | null;
    unreadCount?: number | null;
    createdAt: any;
    leaveOn?: any | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
      url: string;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      id: string;
      pin: boolean;
      mute: boolean;
      archivedAt?: any | null;
    } | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }>;
    owner: { __typename?: 'User'; id: string; name?: string | null };
  }>;
};

export type ListChatRelatedProjectsQueryVariables = Exact<{
  chatId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type ListChatRelatedProjectsQuery = {
  __typename?: 'Query';
  listChatRelatedProjects: {
    __typename?: 'ChatProjectTaskConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ChatProjectTaskEdge';
      cursor: string;
      node?: {
        __typename?: 'ChatProjectTask';
        id: string;
        name: string;
        currentUserRole: ProjectMemberRole;
        archivedAt?: any | null;
        tasks?: Array<{
          __typename?: 'Task';
          id: string;
          name: string;
          status?: TaskStatus | null;
          priority?: TaskPriority | null;
          deletedAt?: any | null;
          dueDate?: any | null;
          completedAt?: any | null;
          projectId: string;
          userIds: Array<string>;
          currentUserRole?: TaskMemberRole | null;
          project: { __typename?: 'Project'; id: string; name: string };
          members: Array<{
            __typename?: 'TaskMember';
            id: string;
            role: TaskMemberRole;
            user: {
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            };
          }>;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type ListChatsQueryVariables = Exact<{
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListChatsQuery = {
  __typename?: 'Query';
  listChats: Array<{
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    messageIcon?: string | null;
    messageBody?: string | null;
    recentActivityAt?: any | null;
    friendRequestAccepted?: boolean | null;
    unreadCount?: number | null;
    createdAt: any;
    leaveOn?: any | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    users: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'UserChatSetting';
      id: string;
      pin: boolean;
      mute: boolean;
      archivedAt?: any | null;
    } | null;
    owner: { __typename?: 'User'; id: string; name?: string | null };
  }>;
};

export type ListChatsPaginationQueryVariables = Exact<{
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListChatsPaginationQuery = {
  __typename?: 'Query';
  listChatsPagination: {
    __typename?: 'ChatConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ChatEdge';
      cursor: string;
      node?: {
        __typename?: 'Chat';
        id: string;
        name: string;
        description?: string | null;
        isGroupChat: boolean;
        messageIcon?: string | null;
        messageBody?: string | null;
        recentActivityAt?: any | null;
        friendRequestAccepted?: boolean | null;
        unreadCount?: number | null;
        createdAt: any;
        leaveOn?: any | null;
        avatar?: {
          __typename?: 'File';
          id: string;
          url: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        users: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'UserChatSetting';
          id: string;
          pin: boolean;
          mute: boolean;
          archivedAt?: any | null;
        } | null;
        owner: { __typename?: 'User'; id: string; name?: string | null };
      } | null;
    } | null> | null;
  };
};

export type ListContactUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListContactUsersQuery = {
  __typename?: 'Query';
  listContactUsers: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    primaryTeamId?: string | null;
    createdAt: any;
    inviteUrl: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    skills?: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      createdAt: any;
    }> | null;
  }>;
};

export type ListDraftDocumentsQueryVariables = Exact<{
  chatId: Scalars['ID'];
}>;

export type ListDraftDocumentsQuery = {
  __typename?: 'Query';
  listDraftDocuments: Array<{
    __typename?: 'Document';
    messageClientId?: string | null;
    messageCreateYn?: boolean | null;
    id: string;
    name: string;
    contentType?: string | null;
    createdAt: any;
    size?: number | null;
    isImage: boolean;
    clientId: string;
    isAudio: boolean;
    duration?: number | null;
    file: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    };
    owner: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
  }>;
};

export type ListDraftMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type ListDraftMessagesQuery = {
  __typename?: 'Query';
  listDraftMessages: Array<{
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    failedAt: any;
    isRetry: boolean;
    authorId: string;
    replyId?: string | null;
    author: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    chat: {
      __typename?: 'Chat';
      id: string;
      name: string;
      description?: string | null;
      isGroupChat: boolean;
      messageIcon?: string | null;
      messageBody?: string | null;
      recentActivityAt?: any | null;
      unreadCount?: number | null;
      createdAt: any;
      leaveOn?: any | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      settings?: {
        __typename?: 'UserChatSetting';
        id: string;
        pin: boolean;
        mute: boolean;
        archivedAt?: any | null;
      } | null;
      users: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'File';
          id: string;
          url: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
      }>;
      owner: { __typename?: 'User'; id: string; name?: string | null };
    };
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
      };
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          clientId: string;
          name: string;
          contentType?: string | null;
          isImage: boolean;
          size?: number | null;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            projectId: string;
          } | null;
        }
      | { __typename?: 'MessageTaskAssignment' }
      | { __typename?: 'MessageTaskComplete' }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
  }>;
};

export type ListDraftProjectDocumentsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type ListDraftProjectDocumentsQuery = {
  __typename?: 'Query';
  listDraftProjectDocuments: Array<{
    __typename?: 'Document';
    id: string;
    name: string;
    contentType?: string | null;
    createdAt: any;
    size?: number | null;
    isImage: boolean;
    clientId: string;
    isAudio: boolean;
    duration?: number | null;
    failedAt: any;
    file: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
      url: string;
    };
    owner: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
  }>;
};

export type ListDraftTaskDocumentsQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;

export type ListDraftTaskDocumentsQuery = {
  __typename?: 'Query';
  listDraftTaskDocuments: Array<{
    __typename?: 'Document';
    id: string;
    name: string;
    contentType?: string | null;
    createdAt: any;
    size?: number | null;
    isImage: boolean;
    clientId: string;
    isAudio: boolean;
    duration?: number | null;
    failedAt: any;
    file: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
      url: string;
    };
    owner: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
  }>;
};

export type ListFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type ListFeatureFlagsQuery = {
  __typename?: 'Query';
  listFeatureFlags: Array<{
    __typename?: 'FeatureFlag';
    name: string;
    enabled: boolean;
  }>;
};

export type ListGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ListGroupsQuery = {
  __typename?: 'Query';
  listGroups?: Array<{
    __typename?: 'Group';
    id: string;
    name: string;
    currentUserTeamRole?: TeamRole | null;
    team?: {
      __typename?: 'Team';
      id: string;
      avatar?: string | null;
      name: string;
      personal: boolean;
    } | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }> | null;
  }> | null;
};

export type ListMessagesQueryVariables = Exact<{
  chatId: Scalars['ID'];
  includeDraft?: InputMaybe<Scalars['Boolean']>;
  includePublished?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListMessagesQuery = {
  __typename?: 'Query';
  listMessages?: {
    __typename?: 'MessageConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'MessageEdge';
      cursor: string;
      node?: {
        __typename?: 'Message';
        id: string;
        chatId: string;
        clientId: string;
        body?: string | null;
        createdAt: any;
        isSender: boolean;
        isDraft: boolean;
        publishedAt?: any | null;
        authorId: string;
        cursor: string;
        readAt?: any | null;
        isRetry: boolean;
        replyId?: string | null;
        readReceipts?: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        }> | null;
        checkin?: {
          __typename?: 'MessageCheckin';
          id: string;
          address?: string | null;
          city?: string | null;
          zipcode?: string | null;
          state?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        author: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          createdAt: any;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        };
        replyMessage?: {
          __typename?: 'Message';
          id: string;
          chatId: string;
          clientId: string;
          body?: string | null;
          createdAt: any;
          isSender: boolean;
          isDraft: boolean;
          publishedAt?: any | null;
          authorId: string;
          author: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
          attachments?: Array<
            | {
                __typename?: 'Document';
                id: string;
                name: string;
                contentType?: string | null;
                createdAt: any;
                size?: number | null;
                isImage: boolean;
                clientId: string;
                isAudio: boolean;
                duration?: number | null;
                file: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                };
                owner: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                };
              }
            | { __typename?: 'EntityUser' }
            | { __typename?: 'Folder' }
            | { __typename?: 'LocalFile' }
            | { __typename?: 'Message' }
            | {
                __typename?: 'MessageProject';
                id: string;
                authorId: string;
                author?: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                } | null;
                project?: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: 'MessageTask';
                id: string;
                authorId: string;
                author?: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                } | null;
                task?: {
                  __typename?: 'Task';
                  id: string;
                  projectId: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: 'MessageTaskAssignment';
                id: string;
                messageId: string;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                }>;
                task?: {
                  __typename?: 'Task';
                  id: string;
                  name: string;
                  deletedAt?: any | null;
                  completedAt?: any | null;
                } | null;
              }
            | {
                __typename?: 'MessageTaskComplete';
                id: string;
                messageId: string;
                completor?: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    thumbnail?: string | null;
                  } | null;
                } | null;
              }
            | { __typename?: 'Project' }
            | { __typename?: 'ProjectUser' }
            | { __typename?: 'Task' }
            | { __typename?: 'TaskUser' }
            | { __typename?: 'UserRequest' }
          > | null;
        } | null;
        attachments?: Array<
          | {
              __typename?: 'Document';
              id: string;
              name: string;
              contentType?: string | null;
              createdAt: any;
              size?: number | null;
              isImage: boolean;
              clientId: string;
              isAudio: boolean;
              duration?: number | null;
              file: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              };
              owner: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                createdAt: any;
              };
            }
          | { __typename?: 'EntityUser' }
          | { __typename?: 'Folder' }
          | { __typename?: 'LocalFile' }
          | { __typename?: 'Message' }
          | {
              __typename?: 'MessageProject';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              project?: {
                __typename?: 'Project';
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTask';
              id: string;
              authorId: string;
              author?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              } | null;
              task?: {
                __typename?: 'Task';
                id: string;
                projectId: string;
                deletedAt?: any | null;
                name: string;
              } | null;
            }
          | {
              __typename?: 'MessageTaskAssignment';
              id: string;
              messageId: string;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  thumbnail?: string | null;
                } | null;
              }>;
              task?: {
                __typename?: 'Task';
                id: string;
                name: string;
                deletedAt?: any | null;
                completedAt?: any | null;
              } | null;
            }
          | {
              __typename?: 'MessageTaskComplete';
              id: string;
              messageId: string;
              completor?: {
                __typename?: 'User';
                id: string;
                name?: string | null;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  thumbnail?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Project' }
          | { __typename?: 'ProjectUser' }
          | { __typename?: 'Task' }
          | { __typename?: 'TaskUser' }
          | { __typename?: 'UserRequest' }
        > | null;
        reaction?: Array<{
          __typename?: 'MessageReaction';
          id: string;
          reaction: string;
          count: number;
          user?: Array<{ __typename?: 'User'; id: string }> | null;
        }> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ListProductsQuery = {
  __typename?: 'Query';
  listProducts: Array<{
    __typename?: 'Product';
    id: string;
    name: string;
    allowMembers: boolean;
    features: any;
    prices: Array<{
      __typename?: 'Price';
      id: string;
      amount: number;
      externalId: string;
      interval: string;
      intervalCount: number;
    }>;
  }>;
};

export type ListProjectDocumentsQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sortBy?: InputMaybe<DocumentSortOption>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListProjectDocumentsQuery = {
  __typename?: 'Query';
  listProjectDocuments: {
    __typename?: 'DocumentConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'DocumentEdge';
      cursor: string;
      node?: {
        __typename?: 'Document';
        id: string;
        name: string;
        contentType?: string | null;
        createdAt: any;
        size?: number | null;
        isImage: boolean;
        isVideo: boolean;
        clientId: string;
        owner: { __typename?: 'User'; id: string; name?: string | null };
        file: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        };
        messageDocuments?: Array<{
          __typename?: 'MessageDocument';
          id: string;
          message?: {
            __typename?: 'Message';
            id: string;
            clientId: string;
            chatId: string;
            createdAt: any;
            isSender: boolean;
            isDraft: boolean;
            publishedAt?: any | null;
            authorId: string;
          } | null;
        }> | null;
        projectDocuments?: Array<{
          __typename?: 'ProjectDocument';
          id: string;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
          owner?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
        }> | null;
        taskDocuments?: Array<{
          __typename?: 'TaskDocument';
          id: string;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            projectId: string;
            project: { __typename?: 'Project'; id: string; name: string };
          } | null;
          owner?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type ListProjectTagsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListProjectTagsQuery = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ProjectConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ProjectEdge';
      cursor: string;
      node?: {
        __typename?: 'Project';
        id: string;
        name: string;
        usersCount: number;
        tasksCount: number;
        chatProjectsCount: number;
      } | null;
    } | null> | null;
  };
};

export type ListProjectsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<ProjectSortOption>;
  role?: InputMaybe<ProjectMemberRole>;
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
  locationOnly?: InputMaybe<Scalars['Boolean']>;
  filterTeamsProjects?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  memberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  teamIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type ListProjectsQuery = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ProjectConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ProjectEdge';
      cursor: string;
      node?: {
        __typename?: 'Project';
        id: string;
        name: string;
        initials: string;
        description?: string | null;
        address?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        archivedAt?: any | null;
        usersCount: number;
        tasksCount: number;
        teamId: string;
        color: string;
        icon?: string | null;
        createdAt: any;
        team?: {
          __typename?: 'Team';
          avatar?: string | null;
          createdAt: any;
          id: string;
          name: string;
          personal: boolean;
        } | null;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        };
        members: Array<{
          __typename?: 'ProjectMember';
          id: string;
          role: ProjectMemberRole;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
        }>;
      } | null;
    } | null> | null;
  };
};

export type ListProjectsBasicInfoQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<ProjectSortOption>;
  role?: InputMaybe<ProjectMemberRole>;
  archivesOnly?: InputMaybe<Scalars['Boolean']>;
  locationOnly?: InputMaybe<Scalars['Boolean']>;
  filterTeamsProjects?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type ListProjectsBasicInfoQuery = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ProjectConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ProjectEdge';
      cursor: string;
      node?: {
        __typename?: 'Project';
        id: string;
        name: string;
        initials: string;
        teamId: string;
        color: string;
      } | null;
    } | null> | null;
  };
};

export type ListProjectsRecentQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProjectSortOption>;
  role?: InputMaybe<ProjectMemberRole>;
}>;

export type ListProjectsRecentQuery = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ProjectConnection';
    edges?: Array<{
      __typename?: 'ProjectEdge';
      node?: {
        __typename?: 'Project';
        id: string;
        name: string;
        initials: string;
        description?: string | null;
        address?: string | null;
        color: string;
        icon?: string | null;
        createdAt: any;
        coverImage?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          path: string;
        } | null;
        team?: {
          __typename?: 'Team';
          avatar?: string | null;
          id: string;
          name: string;
          personal: boolean;
        } | null;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type ListProjectsTasksQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  chatId?: InputMaybe<Scalars['ID']>;
}>;

export type ListProjectsTasksQuery = {
  __typename?: 'Query';
  listProjectsTasks: {
    __typename?: 'ProjectTaskConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'ProjectTaskEdge';
      cursor: string;
      node?: {
        __typename?: 'ProjectTask';
        id: string;
        name: string;
        archivedAt?: any | null;
        currentUserRole: ProjectMemberRole;
        members: Array<{
          __typename?: 'ProjectMember';
          user: { __typename?: 'User'; id: string };
        }>;
        tasks?: Array<{
          __typename?: 'Task';
          id: string;
          name: string;
          status?: TaskStatus | null;
          priority?: TaskPriority | null;
          deletedAt?: any | null;
          completedAt?: any | null;
          dueDate?: any | null;
          projectId: string;
          userIds: Array<string>;
          currentUserRole?: TaskMemberRole | null;
          project: { __typename?: 'Project'; id: string; name: string };
          members: Array<{
            __typename?: 'TaskMember';
            id: string;
            role: TaskMemberRole;
            user: {
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            };
          }>;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type ListProjectsWithDocumentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListProjectsWithDocumentsQuery = {
  __typename?: 'Query';
  listProjectsWithDocuments: Array<{
    __typename?: 'Project';
    id: string;
    address?: string | null;
    name: string;
    initials: string;
    icon?: string | null;
    color: string;
    teamId: string;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
    } | null;
    tasks?: Array<{ __typename?: 'Task'; id: string; name: string }> | null;
  }>;
};

export type ListProjectsWithoutSpecifiedRolesQueryVariables = Exact<{
  roles: Array<ProjectMemberRole> | ProjectMemberRole;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProjectSortOption>;
}>;

export type ListProjectsWithoutSpecifiedRolesQuery = {
  __typename?: 'Query';
  listProjectsWithoutSpecifiedRoles: {
    __typename?: 'ProjectConnection';
    edges?: Array<{
      __typename?: 'ProjectEdge';
      cursor: string;
      node?: {
        __typename?: 'Project';
        id: string;
        name: string;
        members: Array<{
          __typename?: 'ProjectMember';
          user: { __typename?: 'User'; id: string };
        }>;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type ListRecentProjectsTasksQueryVariables = Exact<{
  chatId: Scalars['ID'];
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListRecentProjectsTasksQuery = {
  __typename?: 'Query';
  listRecentProjectsTasks: Array<
    | {
        __typename?: 'Project';
        id: string;
        name: string;
        archivedAt?: any | null;
        createdAt: any;
        members: Array<{
          __typename?: 'ProjectMember';
          user: { __typename?: 'User'; id: string };
        }>;
      }
    | {
        __typename?: 'Task';
        id: string;
        name: string;
        status?: TaskStatus | null;
        priority?: TaskPriority | null;
        completedAt?: any | null;
        deletedAt?: any | null;
        dueDate?: any | null;
        projectId: string;
        userIds: Array<string>;
        project: { __typename?: 'Project'; id: string; name: string };
        members: Array<{
          __typename?: 'TaskMember';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
        }>;
      }
  >;
};

export type ListRecentTasksQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  projectCollaborators?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListRecentTasksQuery = {
  __typename?: 'Query';
  listRecentTasks: Array<{
    __typename?: 'RecentTask';
    id: string;
    name: string;
    priority?: TaskPriority | null;
    startDate?: any | null;
    dueDate?: any | null;
    completedAt?: any | null;
    createdAt: any;
    projectId: string;
    deletedAt?: any | null;
    userIds: Array<string>;
    description?: string | null;
    checklists?: Array<{
      __typename?: 'TaskChecklist';
      id: string;
      name: string;
      completedAt?: any | null;
      createdAt: any;
    }> | null;
    members: Array<{
      __typename?: 'TaskMember';
      id: string;
      role: TaskMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    completor?: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    } | null;
    project: {
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
    };
  }>;
};

export type ListSkillsQueryVariables = Exact<{ [key: string]: never }>;

export type ListSkillsQuery = {
  __typename?: 'Query';
  listSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
};

export type ListTagGroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ListTagGroupsQuery = {
  __typename?: 'Query';
  listTagGroups: Array<{
    __typename?: 'TagGroup';
    id: string;
    isImageGroup: boolean;
    updatedAt: any;
    createdAt: any;
    author: { __typename?: 'User'; id: string; name?: string | null };
    project: { __typename?: 'Project'; id: string; name: string };
    task?: { __typename?: 'Task'; id: string; name: string } | null;
    documents?: Array<{
      __typename?: 'Document';
      id: string;
      name: string;
      contentType?: string | null;
      createdAt: any;
      size?: number | null;
      isImage: boolean;
      clientId: string;
      isAudio: boolean;
      duration?: number | null;
      file: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        path: string;
      };
      projectDocuments?: Array<{
        __typename?: 'ProjectDocument';
        id: string;
        project?: { __typename?: 'Project'; id: string; name: string } | null;
      }> | null;
      taskDocuments?: Array<{
        __typename?: 'TaskDocument';
        id: string;
        task?: {
          __typename?: 'Task';
          id: string;
          name: string;
          projectId: string;
          project: { __typename?: 'Project'; id: string; name: string };
        } | null;
      }> | null;
      owner: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        createdAt: any;
        inviteUrl: string;
        contactsCount: number;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
        } | null;
      };
    }> | null;
  }>;
};

export type ListTaskChecklistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ListTaskChecklistQuery = {
  __typename?: 'Query';
  listTaskChecklist: Array<{
    __typename?: 'TaskChecklist';
    id: string;
    taskId: string;
    name: string;
    createdAt: any;
    completedAt?: any | null;
    sort?: number | null;
  }>;
};

export type ListTaskDocumentsQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  taskIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sortBy?: InputMaybe<DocumentSortOption>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListTaskDocumentsQuery = {
  __typename?: 'Query';
  listTaskDocuments: {
    __typename?: 'DocumentConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'DocumentEdge';
      cursor: string;
      node?: {
        __typename?: 'Document';
        id: string;
        name: string;
        contentType?: string | null;
        createdAt: any;
        size?: number | null;
        isImage: boolean;
        isVideo: boolean;
        clientId: string;
        owner: { __typename?: 'User'; id: string; name?: string | null };
        file: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
        };
        messageDocuments?: Array<{
          __typename?: 'MessageDocument';
          id: string;
          message?: {
            __typename?: 'Message';
            id: string;
            clientId: string;
            chatId: string;
            createdAt: any;
            isSender: boolean;
            isDraft: boolean;
            publishedAt?: any | null;
            authorId: string;
          } | null;
        }> | null;
        projectDocuments?: Array<{
          __typename?: 'ProjectDocument';
          id: string;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
          owner?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
        }> | null;
        taskDocuments?: Array<{
          __typename?: 'TaskDocument';
          id: string;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            projectId: string;
            project: { __typename?: 'Project'; id: string; name: string };
          } | null;
          owner?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type ListTaskTagsQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  chatId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<TaskSortOption>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  taskCollaborators?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListTaskTagsQuery = {
  __typename?: 'Query';
  listTasks?: {
    __typename?: 'TaskConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'TaskEdge';
      cursor: string;
      node?: {
        __typename?: 'Task';
        id: string;
        userIds: Array<string>;
        projectId: string;
        name: string;
        status?: TaskStatus | null;
        priority?: TaskPriority | null;
        dueDate?: any | null;
        currentUserRole?: TaskMemberRole | null;
        currentUserProjectRole?: ProjectMemberRole | null;
        deletedAt?: any | null;
        completedAt?: any | null;
        chatTasksCount: number;
        project: { __typename?: 'Project'; id: string; name: string };
        members: Array<{
          __typename?: 'TaskMember';
          id: string;
          role: TaskMemberRole;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
        }>;
      } | null;
    } | null> | null;
  } | null;
};

export type ListTasksQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  teamIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  memberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  chatId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<TaskSortOption>;
  status?: InputMaybe<TaskStatus>;
  dueGroup?: InputMaybe<TaskGroup>;
  dueDateBegin?: InputMaybe<Scalars['ISO8601DateTime']>;
  dueDateEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  withoutPhase?: InputMaybe<Scalars['Boolean']>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Array<TaskPriority> | TaskPriority>;
}>;

export type ListTasksQuery = {
  __typename?: 'Query';
  listTasks?: {
    __typename?: 'TaskConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'TaskEdge';
      cursor: string;
      node?: {
        __typename?: 'Task';
        id: string;
        name: string;
        status?: TaskStatus | null;
        priority?: TaskPriority | null;
        startDate?: any | null;
        dueDate?: any | null;
        completedAt?: any | null;
        createdAt: any;
        phaseId?: string | null;
        projectId: string;
        deletedAt?: any | null;
        skillIds?: Array<string> | null;
        userIds: Array<string>;
        dueGroup?: string | null;
        fixedOrder?: boolean | null;
        recurring?: boolean | null;
        description?: string | null;
        frequency?: TaskFrequency | null;
        currentUserRole?: TaskMemberRole | null;
        currentUserProjectRole?: ProjectMemberRole | null;
        currentUserProjectTeamRole?: TeamMemberRole | null;
        dependencies?: Array<{
          __typename?: 'Task';
          id: string;
          name: string;
        }> | null;
        checklists?: Array<{
          __typename?: 'TaskChecklist';
          id: string;
          name: string;
          completedAt?: any | null;
          createdAt: any;
        }> | null;
        members: Array<{
          __typename?: 'TaskMember';
          id: string;
          role: TaskMemberRole;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
              url: string;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
        }>;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
            url: string;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        };
        completor?: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          primaryTeamId?: string | null;
          createdAt: any;
          inviteUrl: string;
          avatar?: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
            createdAt: any;
          }> | null;
        } | null;
        project: {
          __typename?: 'Project';
          id: string;
          name: string;
          color: string;
          icon?: string | null;
          currentUserTeamRole?: TeamMemberRole | null;
          currentUserRole?: ProjectMemberRole | null;
          initials: string;
          archivedAt?: any | null;
          team?: {
            __typename?: 'Team';
            avatar?: string | null;
            createdAt: any;
            id: string;
            name: string;
            personal: boolean;
          } | null;
        };
        phase?: { __typename?: 'Phase'; id: string; name: string } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListTeamInvoicesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ListTeamInvoicesQuery = {
  __typename?: 'Query';
  getTeam: {
    __typename?: 'Team';
    id: string;
    invoices?: Array<{
      __typename?: 'Invoice';
      amount: number;
      date: any;
      description: string;
      id: string;
      invoiceUrl: string;
      status: string;
    }> | null;
    plan?: {
      __typename?: 'Plan';
      amount: number;
      expiration: any;
      id: string;
      interval: string;
      name: string;
      quantity: number;
      status: string;
    } | null;
  };
};

export type ListTeamsQueryVariables = Exact<{
  role?: InputMaybe<ProjectMemberRole>;
}>;

export type ListTeamsQuery = {
  __typename?: 'Query';
  listTeams: Array<{
    __typename?: 'Team';
    id: string;
    avatar?: string | null;
    usersCount: number;
    personal: boolean;
    name: string;
    numberOfProjectsUsed: number;
    maxNumberOfProjects: number;
    avatarFile?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    projects: Array<{
      __typename?: 'Project';
      id: string;
      name: string;
      color: string;
      icon?: string | null;
      initials: string;
      address?: string | null;
      teamId: string;
      team?: {
        __typename?: 'Team';
        avatar?: string | null;
        createdAt: any;
        id: string;
        name: string;
        personal: boolean;
      } | null;
    }>;
    product?: {
      __typename?: 'Product';
      allowMembers: boolean;
      externalId: string;
      features: any;
      guestsOnly: boolean;
      id: string;
      maxDocumentStorage: any;
      maxNumberOfProjects: any;
      name: string;
      position: number;
      prices: Array<{
        __typename?: 'Price';
        amount: number;
        externalId: string;
        id: string;
        interval: string;
        intervalCount: number;
      }>;
    } | null;
    plan?: {
      __typename?: 'Plan';
      amount: number;
      expiration: any;
      id: string;
      interval: string;
      name: string;
      quantity: number;
      status: string;
      isCanceled: boolean;
      isResumable: boolean;
      payment?: {
        __typename?: 'Payment';
        brand: string;
        last4?: string | null;
        expMonth?: string | null;
        expYear?: string | null;
      } | null;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      amount: number;
      date: any;
      description: string;
      id: string;
      invoiceUrl: string;
      status: string;
    }> | null;
  }>;
};

export type ListUsersQueryVariables = Exact<{
  term: Scalars['String'];
}>;

export type ListUsersQuery = {
  __typename?: 'Query';
  listUsers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    friendRequestPending?: boolean | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
  }>;
};

export type NewListDocumentsQueryVariables = Exact<{
  chatId?: InputMaybe<Scalars['ID']>;
  includeMedia?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type NewListDocumentsQuery = {
  __typename?: 'Query';
  newListDocuments: {
    __typename?: 'DocumentCollection';
    metadata: {
      __typename?: 'CollectionMetadata';
      currentPage: number;
      limitValue: number;
      totalCount: number;
      totalPages: number;
    };
    collection: Array<{
      __typename?: 'Document';
      id: string;
      name: string;
      contentType?: string | null;
      createdAt: any;
      size?: number | null;
      isImage: boolean;
      isVideo: boolean;
      clientId: string;
      owner: { __typename?: 'User'; id: string; name?: string | null };
      file: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      };
      messageDocuments?: Array<{
        __typename?: 'MessageDocument';
        id: string;
        message?: {
          __typename?: 'Message';
          id: string;
          clientId: string;
          chatId: string;
          createdAt: any;
          isSender: boolean;
          isDraft: boolean;
          publishedAt?: any | null;
          authorId: string;
        } | null;
      }> | null;
      projectDocuments?: Array<{
        __typename?: 'ProjectDocument';
        id: string;
        project?: { __typename?: 'Project'; id: string; name: string } | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
        } | null;
      }> | null;
      taskDocuments?: Array<{
        __typename?: 'TaskDocument';
        id: string;
        task?: {
          __typename?: 'Task';
          id: string;
          name: string;
          projectId: string;
          project: { __typename?: 'Project'; id: string; name: string };
        } | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
        } | null;
      }> | null;
    }>;
  };
};

export type SearchQueryVariables = Exact<{
  term: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  includeChats?: InputMaybe<Scalars['Boolean']>;
  includeMessages?: InputMaybe<Scalars['Boolean']>;
  includeProjects?: InputMaybe<Scalars['Boolean']>;
  includeTasks?: InputMaybe<Scalars['Boolean']>;
  includeDocuments?: InputMaybe<Scalars['Boolean']>;
  includeContacts?: InputMaybe<Scalars['Boolean']>;
  includeCompletedTask?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['ID']>;
  taskId?: InputMaybe<Scalars['ID']>;
  chatId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Scalars['Boolean']>;
  files?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  includeActivities?: InputMaybe<Scalars['Boolean']>;
  documentOwnerIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  archiveProjectsOnly?: InputMaybe<Scalars['Boolean']>;
  taskDueGroup?: InputMaybe<TaskGroup>;
  completedTasksOnly?: InputMaybe<Scalars['Boolean']>;
  documentGroup?: InputMaybe<DocumentGroup>;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search: Array<{
    __typename?: 'Search';
    total: number;
    highlight?: {
      __typename?: 'Highlight';
      authorName?: Array<string> | null;
      body?: Array<string> | null;
      chatName?: Array<string> | null;
      name?: Array<string> | null;
      projectNames?: Array<string> | null;
      taskNames?: Array<string> | null;
    } | null;
    record:
      | {
          __typename?: 'Audit';
          event: string;
          createdAt: any;
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
          attachment?:
            | {
                __typename?: 'Document';
                id: string;
                name: string;
                contentType?: string | null;
                createdAt: any;
                size?: number | null;
                isImage: boolean;
                clientId: string;
                isAudio: boolean;
                duration?: number | null;
                file: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                };
                owner: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                };
              }
            | {
                __typename?: 'EntityUser';
                id: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                };
                entityTask?: {
                  __typename?: 'Task';
                  id: string;
                  name: string;
                  deletedAt?: any | null;
                  members: Array<{
                    __typename?: 'TaskMember';
                    id: string;
                    role: TaskMemberRole;
                    user: {
                      __typename?: 'User';
                      id: string;
                      email: string;
                      name?: string | null;
                      firstName?: string | null;
                      lastName?: string | null;
                      phoneNumber: string;
                      primaryTeamId?: string | null;
                      createdAt: any;
                      inviteUrl: string;
                      avatar?: {
                        __typename?: 'File';
                        id: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                        url: string;
                      } | null;
                      skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        name: string;
                        createdAt: any;
                      }> | null;
                    };
                  }>;
                  project: { __typename?: 'Project'; id: string; name: string };
                } | null;
                entityProject?: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                } | null;
              }
            | { __typename?: 'Folder' }
            | { __typename?: 'LocalFile' }
            | {
                __typename?: 'Message';
                id: string;
                chatId: string;
                clientId: string;
                body?: string | null;
                createdAt: any;
                isSender: boolean;
                isDraft: boolean;
                publishedAt?: any | null;
                authorId: string;
                cursor: string;
                readAt?: any | null;
                isRetry: boolean;
                replyId?: string | null;
                readReceipts?: Array<{
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                }> | null;
                checkin?: {
                  __typename?: 'MessageCheckin';
                  id: string;
                  address?: string | null;
                  city?: string | null;
                  zipcode?: string | null;
                  state?: string | null;
                  country?: string | null;
                  latitude?: number | null;
                  longitude?: number | null;
                } | null;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    url: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                };
                replyMessage?: {
                  __typename?: 'Message';
                  id: string;
                  chatId: string;
                  clientId: string;
                  body?: string | null;
                  createdAt: any;
                  isSender: boolean;
                  isDraft: boolean;
                  publishedAt?: any | null;
                  authorId: string;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    createdAt: any;
                  };
                  attachments?: Array<
                    | {
                        __typename?: 'Document';
                        id: string;
                        name: string;
                        contentType?: string | null;
                        createdAt: any;
                        size?: number | null;
                        isImage: boolean;
                        clientId: string;
                        isAudio: boolean;
                        duration?: number | null;
                        file: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        };
                        owner: {
                          __typename?: 'User';
                          id: string;
                          name?: string | null;
                          firstName?: string | null;
                          lastName?: string | null;
                          createdAt: any;
                        };
                      }
                    | { __typename?: 'EntityUser' }
                    | { __typename?: 'Folder' }
                    | { __typename?: 'LocalFile' }
                    | { __typename?: 'Message' }
                    | {
                        __typename?: 'MessageProject';
                        id: string;
                        authorId: string;
                        author?: {
                          __typename?: 'User';
                          id: string;
                          name?: string | null;
                          avatar?: {
                            __typename?: 'File';
                            id: string;
                            url: string;
                            cdnBaseUrl: string;
                            path: string;
                            isPreviewable: boolean;
                            thumbnail?: string | null;
                          } | null;
                        } | null;
                        project?: {
                          __typename?: 'Project';
                          id: string;
                          name: string;
                        } | null;
                      }
                    | {
                        __typename?: 'MessageTask';
                        id: string;
                        authorId: string;
                        author?: {
                          __typename?: 'User';
                          id: string;
                          name?: string | null;
                          avatar?: {
                            __typename?: 'File';
                            id: string;
                            url: string;
                            cdnBaseUrl: string;
                            path: string;
                            isPreviewable: boolean;
                            thumbnail?: string | null;
                          } | null;
                        } | null;
                        task?: {
                          __typename?: 'Task';
                          id: string;
                          projectId: string;
                          name: string;
                        } | null;
                      }
                    | {
                        __typename?: 'MessageTaskAssignment';
                        id: string;
                        messageId: string;
                        assignees: Array<{
                          __typename?: 'User';
                          id: string;
                          email: string;
                          name?: string | null;
                          firstName?: string | null;
                          lastName?: string | null;
                          phoneNumber: string;
                          primaryTeamId?: string | null;
                          createdAt: any;
                          inviteUrl: string;
                          avatar?: {
                            __typename?: 'File';
                            id: string;
                            cdnBaseUrl: string;
                            path: string;
                            isPreviewable: boolean;
                            thumbnail?: string | null;
                          } | null;
                          skills?: Array<{
                            __typename?: 'Skill';
                            id: string;
                            name: string;
                            createdAt: any;
                          }> | null;
                        }>;
                        task?: {
                          __typename?: 'Task';
                          id: string;
                          name: string;
                          deletedAt?: any | null;
                          completedAt?: any | null;
                        } | null;
                      }
                    | {
                        __typename?: 'MessageTaskComplete';
                        id: string;
                        messageId: string;
                        completor?: {
                          __typename?: 'User';
                          id: string;
                          name?: string | null;
                          avatar?: {
                            __typename?: 'File';
                            id: string;
                            url: string;
                            cdnBaseUrl: string;
                            path: string;
                            thumbnail?: string | null;
                          } | null;
                        } | null;
                      }
                    | { __typename?: 'Project' }
                    | { __typename?: 'ProjectUser' }
                    | { __typename?: 'Task' }
                    | { __typename?: 'TaskUser' }
                    | { __typename?: 'UserRequest' }
                  > | null;
                } | null;
                attachments?: Array<
                  | {
                      __typename?: 'Document';
                      id: string;
                      name: string;
                      contentType?: string | null;
                      createdAt: any;
                      size?: number | null;
                      isImage: boolean;
                      clientId: string;
                      isAudio: boolean;
                      duration?: number | null;
                      file: {
                        __typename?: 'File';
                        id: string;
                        url: string;
                        cdnBaseUrl: string;
                        path: string;
                        isPreviewable: boolean;
                        thumbnail?: string | null;
                      };
                      owner: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        firstName?: string | null;
                        lastName?: string | null;
                        createdAt: any;
                      };
                    }
                  | { __typename?: 'EntityUser' }
                  | { __typename?: 'Folder' }
                  | { __typename?: 'LocalFile' }
                  | { __typename?: 'Message' }
                  | {
                      __typename?: 'MessageProject';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      project?: {
                        __typename?: 'Project';
                        id: string;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTask';
                      id: string;
                      authorId: string;
                      author?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          isPreviewable: boolean;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        projectId: string;
                        deletedAt?: any | null;
                        name: string;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskAssignment';
                      id: string;
                      messageId: string;
                      assignees: Array<{
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          thumbnail?: string | null;
                        } | null;
                      }>;
                      task?: {
                        __typename?: 'Task';
                        id: string;
                        name: string;
                        deletedAt?: any | null;
                        completedAt?: any | null;
                      } | null;
                    }
                  | {
                      __typename?: 'MessageTaskComplete';
                      id: string;
                      messageId: string;
                      completor?: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                        avatar?: {
                          __typename?: 'File';
                          id: string;
                          url: string;
                          cdnBaseUrl: string;
                          path: string;
                          thumbnail?: string | null;
                        } | null;
                      } | null;
                    }
                  | { __typename?: 'Project' }
                  | { __typename?: 'ProjectUser' }
                  | { __typename?: 'Task' }
                  | { __typename?: 'TaskUser' }
                  | { __typename?: 'UserRequest' }
                > | null;
                reaction?: Array<{
                  __typename?: 'MessageReaction';
                  id: string;
                  reaction: string;
                  count: number;
                  user?: Array<{ __typename?: 'User'; id: string }> | null;
                }> | null;
              }
            | { __typename?: 'MessageProject' }
            | { __typename?: 'MessageTask' }
            | { __typename?: 'MessageTaskAssignment' }
            | { __typename?: 'MessageTaskComplete' }
            | { __typename?: 'Project'; id: string; name: string }
            | { __typename?: 'ProjectUser' }
            | {
                __typename?: 'Task';
                id: string;
                name: string;
                projectId: string;
                deletedAt?: any | null;
                project: { __typename?: 'Project'; id: string; name: string };
              }
            | {
                __typename?: 'TaskUser';
                id: string;
                task: {
                  __typename?: 'Task';
                  id: string;
                  name: string;
                  project: { __typename?: 'Project'; id: string; name: string };
                };
                user: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                };
              }
            | { __typename?: 'UserRequest' }
            | null;
        }
      | { __typename?: 'Chat'; id: string; name: string }
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          owner: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
          file: {
            __typename?: 'File';
            id: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          messageDocuments?: Array<{
            __typename?: 'MessageDocument';
            id: string;
            message?: {
              __typename?: 'Message';
              id: string;
              clientId: string;
              authorId: string;
              chat: { __typename?: 'Chat'; id: string; name: string };
            } | null;
          }> | null;
          projectDocuments?: Array<{
            __typename?: 'ProjectDocument';
            id: string;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
              team?: {
                __typename?: 'Team';
                avatar?: string | null;
                createdAt: any;
                id: string;
                name: string;
                personal: boolean;
              } | null;
            } | null;
          }> | null;
        }
      | {
          __typename?: 'Message';
          id: string;
          cursor: string;
          body?: string | null;
          publishedAt?: any | null;
          isSender: boolean;
          clientId: string;
          authorId: string;
          chatId: string;
          createdAt: any;
          isDraft: boolean;
          author: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
          chat: {
            __typename?: 'Chat';
            id: string;
            name: string;
            description?: string | null;
            isGroupChat: boolean;
            messageIcon?: string | null;
            messageBody?: string | null;
            recentActivityAt?: any | null;
            unreadCount?: number | null;
            createdAt: any;
            leaveOn?: any | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            settings?: {
              __typename?: 'UserChatSetting';
              id: string;
              pin: boolean;
              mute: boolean;
              archivedAt?: any | null;
            } | null;
            users: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            }>;
            owner: { __typename?: 'User'; id: string; name?: string | null };
          };
          reaction?: Array<{
            __typename?: 'MessageReaction';
            id: string;
            reaction: string;
            count: number;
            user?: Array<{ __typename?: 'User'; id: string }> | null;
          }> | null;
          attachments?: Array<
            | {
                __typename?: 'Document';
                id: string;
                isImage: boolean;
                isAudio: boolean;
                clientId: string;
                duration?: number | null;
                name: string;
                createdAt: any;
                contentType?: string | null;
                size?: number | null;
                file: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                  url: string;
                };
                owner: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  createdAt: any;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                };
              }
            | { __typename?: 'EntityUser' }
            | { __typename?: 'Folder' }
            | { __typename?: 'LocalFile' }
            | { __typename?: 'Message' }
            | {
                __typename?: 'MessageProject';
                id: string;
                author?: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                } | null;
                project?: {
                  __typename?: 'Project';
                  id: string;
                  initials: string;
                  name: string;
                  description?: string | null;
                  address?: string | null;
                  color: string;
                  createdAt: any;
                  creator: {
                    __typename?: 'User';
                    id: string;
                    email: string;
                    name?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    phoneNumber: string;
                    primaryTeamId?: string | null;
                    createdAt: any;
                    inviteUrl: string;
                    avatar?: {
                      __typename?: 'File';
                      id: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    } | null;
                    skills?: Array<{
                      __typename?: 'Skill';
                      id: string;
                      name: string;
                      createdAt: any;
                    }> | null;
                  };
                } | null;
              }
            | {
                __typename?: 'MessageTask';
                id: string;
                author?: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  name?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  phoneNumber: string;
                  primaryTeamId?: string | null;
                  createdAt: any;
                  inviteUrl: string;
                  avatar?: {
                    __typename?: 'File';
                    id: string;
                    cdnBaseUrl: string;
                    path: string;
                    isPreviewable: boolean;
                    thumbnail?: string | null;
                  } | null;
                  skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    name: string;
                    createdAt: any;
                  }> | null;
                } | null;
                task?: {
                  __typename?: 'Task';
                  id: string;
                  name: string;
                  status?: TaskStatus | null;
                  priority?: TaskPriority | null;
                  dueDate?: any | null;
                  projectId: string;
                  userIds: Array<string>;
                  deletedAt?: any | null;
                  creator: {
                    __typename?: 'User';
                    id: string;
                    email: string;
                    name?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    phoneNumber: string;
                    primaryTeamId?: string | null;
                    createdAt: any;
                    inviteUrl: string;
                    avatar?: {
                      __typename?: 'File';
                      id: string;
                      cdnBaseUrl: string;
                      path: string;
                      isPreviewable: boolean;
                      thumbnail?: string | null;
                    } | null;
                    skills?: Array<{
                      __typename?: 'Skill';
                      id: string;
                      name: string;
                      createdAt: any;
                    }> | null;
                  };
                  project: {
                    __typename?: 'Project';
                    id: string;
                    name: string;
                    color: string;
                  };
                } | null;
              }
            | { __typename?: 'MessageTaskAssignment' }
            | { __typename?: 'MessageTaskComplete' }
            | { __typename?: 'Project' }
            | { __typename?: 'ProjectUser' }
            | { __typename?: 'Task' }
            | { __typename?: 'TaskUser' }
            | { __typename?: 'UserRequest' }
          > | null;
        }
      | {
          __typename?: 'Project';
          id: string;
          initials: string;
          name: string;
          description?: string | null;
          address?: string | null;
          color: string;
          createdAt: any;
          icon?: string | null;
          team?: {
            __typename?: 'Team';
            avatar?: string | null;
            createdAt: any;
            id: string;
            name: string;
            personal: boolean;
          } | null;
          creator: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
        }
      | {
          __typename?: 'Task';
          id: string;
          name: string;
          status?: TaskStatus | null;
          priority?: TaskPriority | null;
          dueDate?: any | null;
          projectId: string;
          userIds: Array<string>;
          completedAt?: any | null;
          deletedAt?: any | null;
          currentUserRole?: TaskMemberRole | null;
          creator: {
            __typename?: 'User';
            id: string;
            email: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            primaryTeamId?: string | null;
            createdAt: any;
            inviteUrl: string;
            avatar?: {
              __typename?: 'File';
              id: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
              createdAt: any;
            }> | null;
          };
          members: Array<{
            __typename?: 'TaskMember';
            id: string;
            role: TaskMemberRole;
            user: {
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
                url: string;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            };
          }>;
          project: {
            __typename?: 'Project';
            id: string;
            name: string;
            color: string;
            team?: {
              __typename?: 'Team';
              name: string;
              personal: boolean;
            } | null;
          };
        }
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
          fullName?: string | null;
          avatar?: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          } | null;
        };
  }>;
};

export type SearchTagsQueryVariables = Exact<{
  term: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  includeChats?: InputMaybe<Scalars['Boolean']>;
  includeMessages?: InputMaybe<Scalars['Boolean']>;
  includeProjects?: InputMaybe<Scalars['Boolean']>;
  includeTasks?: InputMaybe<Scalars['Boolean']>;
  includeDocuments?: InputMaybe<Scalars['Boolean']>;
  includeContacts?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['ID']>;
  taskId?: InputMaybe<Scalars['ID']>;
  chatId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Scalars['Boolean']>;
  media?: InputMaybe<Scalars['Boolean']>;
  files?: InputMaybe<Scalars['Boolean']>;
  collaboratorsOnly?: InputMaybe<Scalars['Boolean']>;
  includeTasksTags?: InputMaybe<Scalars['Boolean']>;
  includeCompletedTask?: InputMaybe<Scalars['Boolean']>;
}>;

export type SearchTagsQuery = {
  __typename?: 'Query';
  search: Array<{
    __typename?: 'Search';
    total: number;
    highlight?: {
      __typename?: 'Highlight';
      authorName?: Array<string> | null;
      name?: Array<string> | null;
      projectNames?: Array<string> | null;
      taskNames?: Array<string> | null;
    } | null;
    record:
      | { __typename?: 'Audit' }
      | { __typename?: 'Chat' }
      | { __typename?: 'Document' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'Project';
          id: string;
          name: string;
          createdAt: any;
          currentUserRole?: ProjectMemberRole | null;
          members: Array<{
            __typename?: 'ProjectMember';
            user: { __typename?: 'User'; id: string };
          }>;
          tasksTagList?: Array<{
            __typename?: 'Task';
            id: string;
            name: string;
            status?: TaskStatus | null;
            dueDate?: any | null;
            priority?: TaskPriority | null;
            projectId: string;
            deletedAt?: any | null;
            userIds: Array<string>;
            completedAt?: any | null;
            currentUserRole?: TaskMemberRole | null;
            currentUserProjectRole?: ProjectMemberRole | null;
            members: Array<{
              __typename?: 'TaskMember';
              id: string;
              role: TaskMemberRole;
              user: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
                name?: string | null;
                phoneNumber: string;
                createdAt: any;
                avatar?: {
                  __typename?: 'File';
                  id: string;
                  cdnBaseUrl: string;
                  path: string;
                  isPreviewable: boolean;
                  thumbnail?: string | null;
                } | null;
              };
            }>;
            project: { __typename?: 'Project'; id: string; name: string };
          }> | null;
        }
      | { __typename?: 'Task' }
      | { __typename?: 'User' };
  }>;
};

export type ValidateContentTypeQueryVariables = Exact<{
  contentType: Scalars['String'];
}>;

export type ValidateContentTypeQuery = {
  __typename?: 'Query';
  validateContentType: boolean;
};

export type ChatUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ChatUpdateSubscription = {
  __typename?: 'Subscription';
  chatUpdate?: {
    __typename?: 'ChatUpdate';
    id: string;
    name: string;
    messageBody?: string | null;
    recentActivityAt?: any | null;
    unreadCount?: number | null;
  } | null;
};

export type ChatUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ChatUpdatedSubscription = {
  __typename?: 'Subscription';
  chatUpdated?: {
    __typename?: 'Chat';
    id: string;
    name: string;
    description?: string | null;
    isGroupChat: boolean;
    messageIcon?: string | null;
    messageBody?: string | null;
    recentActivityAt?: any | null;
    unreadCount?: number | null;
    createdAt: any;
    leaveOn?: any | null;
    avatar?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    settings?: {
      __typename?: 'UserChatSetting';
      id: string;
      pin: boolean;
      mute: boolean;
      archivedAt?: any | null;
    } | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    }>;
    owner: { __typename?: 'User'; id: string; name?: string | null };
  } | null;
};

export type MeUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MeUpdatedSubscription = {
  __typename?: 'Subscription';
  meUpdated: {
    __typename?: 'MeUpdatedPayload';
    profile?: {
      __typename?: 'Profile';
      address?: string | null;
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      userActiveTasksCount: number;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
      teams?: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        usersCount: number;
        users: Array<{ __typename?: 'User'; id: string; name?: string | null }>;
      }> | null;
    } | null;
    chats?: Array<{
      __typename?: 'Chat';
      id: string;
      unreadCount?: number | null;
      settings?: {
        __typename?: 'UserChatSetting';
        id: string;
        mute: boolean;
        pin: boolean;
      } | null;
    }> | null;
  };
};

export type MessageUpdateSubscriptionVariables = Exact<{
  chatId: Scalars['ID'];
}>;

export type MessageUpdateSubscription = {
  __typename?: 'Subscription';
  messageUpdate?: {
    __typename?: 'Message';
    id: string;
    chatId: string;
    clientId: string;
    body?: string | null;
    createdAt: any;
    isSender: boolean;
    isDraft: boolean;
    publishedAt?: any | null;
    authorId: string;
    cursor: string;
    readAt?: any | null;
    isRetry: boolean;
    replyId?: string | null;
    readReceipts?: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    }> | null;
    checkin?: {
      __typename?: 'MessageCheckin';
      id: string;
      address?: string | null;
      city?: string | null;
      zipcode?: string | null;
      state?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    author: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: any;
      avatar?: {
        __typename?: 'File';
        id: string;
        url: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
      } | null;
    };
    replyMessage?: {
      __typename?: 'Message';
      id: string;
      chatId: string;
      clientId: string;
      body?: string | null;
      createdAt: any;
      isSender: boolean;
      isDraft: boolean;
      publishedAt?: any | null;
      authorId: string;
      author: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
      };
      attachments?: Array<
        | {
            __typename?: 'Document';
            id: string;
            name: string;
            contentType?: string | null;
            createdAt: any;
            size?: number | null;
            isImage: boolean;
            clientId: string;
            isAudio: boolean;
            duration?: number | null;
            file: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            };
            owner: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              createdAt: any;
            };
          }
        | { __typename?: 'EntityUser' }
        | { __typename?: 'Folder' }
        | { __typename?: 'LocalFile' }
        | { __typename?: 'Message' }
        | {
            __typename?: 'MessageProject';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            project?: {
              __typename?: 'Project';
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTask';
            id: string;
            authorId: string;
            author?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
            } | null;
            task?: {
              __typename?: 'Task';
              id: string;
              projectId: string;
              name: string;
            } | null;
          }
        | {
            __typename?: 'MessageTaskAssignment';
            id: string;
            messageId: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              email: string;
              name?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              primaryTeamId?: string | null;
              createdAt: any;
              inviteUrl: string;
              avatar?: {
                __typename?: 'File';
                id: string;
                cdnBaseUrl: string;
                path: string;
                isPreviewable: boolean;
                thumbnail?: string | null;
              } | null;
              skills?: Array<{
                __typename?: 'Skill';
                id: string;
                name: string;
                createdAt: any;
              }> | null;
            }>;
            task?: {
              __typename?: 'Task';
              id: string;
              name: string;
              deletedAt?: any | null;
              completedAt?: any | null;
            } | null;
          }
        | {
            __typename?: 'MessageTaskComplete';
            id: string;
            messageId: string;
            completor?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              avatar?: {
                __typename?: 'File';
                id: string;
                url: string;
                cdnBaseUrl: string;
                path: string;
                thumbnail?: string | null;
              } | null;
            } | null;
          }
        | { __typename?: 'Project' }
        | { __typename?: 'ProjectUser' }
        | { __typename?: 'Task' }
        | { __typename?: 'TaskUser' }
        | { __typename?: 'UserRequest' }
      > | null;
    } | null;
    attachments?: Array<
      | {
          __typename?: 'Document';
          id: string;
          name: string;
          contentType?: string | null;
          createdAt: any;
          size?: number | null;
          isImage: boolean;
          clientId: string;
          isAudio: boolean;
          duration?: number | null;
          file: {
            __typename?: 'File';
            id: string;
            url: string;
            cdnBaseUrl: string;
            path: string;
            isPreviewable: boolean;
            thumbnail?: string | null;
          };
          owner: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            createdAt: any;
          };
        }
      | { __typename?: 'EntityUser' }
      | { __typename?: 'Folder' }
      | { __typename?: 'LocalFile' }
      | { __typename?: 'Message' }
      | {
          __typename?: 'MessageProject';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          project?: { __typename?: 'Project'; id: string; name: string } | null;
        }
      | {
          __typename?: 'MessageTask';
          id: string;
          authorId: string;
          author?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              isPreviewable: boolean;
              thumbnail?: string | null;
            } | null;
          } | null;
          task?: {
            __typename?: 'Task';
            id: string;
            projectId: string;
            deletedAt?: any | null;
            name: string;
          } | null;
        }
      | {
          __typename?: 'MessageTaskAssignment';
          id: string;
          messageId: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          }>;
          task?: {
            __typename?: 'Task';
            id: string;
            name: string;
            deletedAt?: any | null;
            completedAt?: any | null;
          } | null;
        }
      | {
          __typename?: 'MessageTaskComplete';
          id: string;
          messageId: string;
          completor?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: {
              __typename?: 'File';
              id: string;
              url: string;
              cdnBaseUrl: string;
              path: string;
              thumbnail?: string | null;
            } | null;
          } | null;
        }
      | { __typename?: 'Project' }
      | { __typename?: 'ProjectUser' }
      | { __typename?: 'Task' }
      | { __typename?: 'TaskUser' }
      | { __typename?: 'UserRequest' }
    > | null;
    reaction?: Array<{
      __typename?: 'MessageReaction';
      id: string;
      reaction: string;
      count: number;
      user?: Array<{ __typename?: 'User'; id: string }> | null;
    }> | null;
  } | null;
};

export type ProjectUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ProjectUpdatedSubscription = {
  __typename?: 'Subscription';
  projectUpdated?: {
    __typename?: 'Project';
    address?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    archivedAt?: any | null;
    usersCount: number;
    tasksCount: number;
    currentUserRole?: ProjectMemberRole | null;
    currentUserTeamRole?: TeamMemberRole | null;
    isTaskMember: boolean;
    teamId: string;
    color: string;
    icon?: string | null;
    createdAt: any;
    description?: string | null;
    id: string;
    initials: string;
    name: string;
    unreadAuditsCount?: number | null;
    activeTasksCount: number;
    upcomingTasksCount: number;
    overdueTasksCount: number;
    completedTasksCount: number;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      primaryTeamId?: string | null;
      createdAt: any;
      inviteUrl: string;
      avatar?: {
        __typename?: 'File';
        id: string;
        cdnBaseUrl: string;
        path: string;
        isPreviewable: boolean;
        thumbnail?: string | null;
        url: string;
      } | null;
      skills?: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        createdAt: any;
      }> | null;
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      url: string;
      cdnBaseUrl: string;
      path: string;
      isPreviewable: boolean;
      thumbnail?: string | null;
    } | null;
    team?: {
      __typename?: 'Team';
      avatar?: string | null;
      createdAt: any;
      id: string;
      name: string;
      personal: boolean;
      hasReachedMaxProjects: boolean;
      plan?: {
        __typename?: 'Plan';
        id: string;
        isCanceled: boolean;
        expiration: any;
      } | null;
    } | null;
    members: Array<{
      __typename?: 'ProjectMember';
      id: string;
      role: ProjectMemberRole;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        name?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        primaryTeamId?: string | null;
        createdAt: any;
        inviteUrl: string;
        avatar?: {
          __typename?: 'File';
          id: string;
          cdnBaseUrl: string;
          path: string;
          isPreviewable: boolean;
          thumbnail?: string | null;
          url: string;
        } | null;
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
          createdAt: any;
        }> | null;
      };
    }>;
    audits?: Array<{ __typename?: 'Audit'; id: string; event: string }> | null;
    unreadAudits?: Array<{
      __typename?: 'Audit';
      id: string;
      event: string;
    }> | null;
  } | null;
};

export const FileDetailsFragmentDoc = gql`
  fragment FileDetails on File {
    id
    url
    cdnBaseUrl
    path
    isPreviewable
    thumbnail
  }
`;
export const AttachmentsFragmentDoc = gql`
  fragment Attachments on Attachment {
    ... on MessageTask {
      id
      authorId
      author {
        id
        firstName
        lastName
        avatar {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
      }
      task {
        id
        name
      }
    }
    ... on MessageProject {
      id
      authorId
      author {
        id
        firstName
        lastName
        avatar {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
      }
      project {
        id
        name
      }
    }
    ... on Document {
      id
      size
      name
      contentType
      clientId
      isImage
      isAudio
      duration
      file {
        ...FileDetails
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export const ChatDetailFragmentDoc = gql`
  fragment ChatDetail on Chat {
    id
    name
    description
    avatar {
      ...FileDetails
    }
    isGroupChat
    messageIcon
    messageBody
    recentActivityAt
    unreadCount
    createdAt
    leaveOn
    settings {
      id
      pin
      mute
      archivedAt
    }
    users {
      id
      name
      firstName
      lastName
      avatar {
        ...FileDetails
      }
    }
    owner {
      id
      name
    }
  }
  ${FileDetailsFragmentDoc}
`;
export const DocumentDetailFragmentDoc = gql`
  fragment DocumentDetail on Document {
    id
    name
    contentType
    createdAt
    size
    isImage
    clientId
    isAudio
    duration
    file {
      id
      cdnBaseUrl
      path
      isPreviewable
      thumbnail
    }
    owner {
      id
      name
      firstName
      lastName
      createdAt
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
  }
`;
export const UserDetailFragmentDoc = gql`
  fragment UserDetail on User {
    id
    email
    name
    firstName
    lastName
    phoneNumber
    primaryTeamId
    createdAt
    inviteUrl
    avatar {
      id
      cdnBaseUrl
      path
      isPreviewable
      thumbnail
    }
    skills {
      id
      name
      createdAt
    }
  }
`;
export const CheckinDetailFragmentDoc = gql`
  fragment checkinDetail on MessageCheckin {
    id
    address
    city
    zipcode
    state
    country
    latitude
    longitude
  }
`;
export const MessageDetailFragmentDoc = gql`
  fragment MessageDetail on Message {
    id
    chatId
    clientId
    body
    createdAt
    isSender
    isDraft
    publishedAt
    authorId
    cursor
    readAt
    isRetry
    readReceipts {
      ...UserDetail
    }
    checkin {
      ...checkinDetail
    }
    author {
      id
      name
      firstName
      lastName
      createdAt
      avatar {
        ...FileDetails
      }
    }
    replyId
    replyMessage {
      id
      chatId
      clientId
      body
      createdAt
      isSender
      isDraft
      publishedAt
      authorId
      author {
        id
        name
        firstName
        lastName
        createdAt
      }
      attachments {
        ... on MessageTask {
          id
          authorId
          author {
            id
            name
            avatar {
              ...FileDetails
            }
          }
          task {
            id
            projectId
            name
          }
        }
        ... on MessageProject {
          id
          authorId
          author {
            id
            name
            avatar {
              ...FileDetails
            }
          }
          project {
            id
            name
          }
        }
        ... on Document {
          id
          name
          contentType
          createdAt
          size
          isImage
          clientId
          isAudio
          duration
          file {
            ...FileDetails
          }
          owner {
            id
            name
            firstName
            lastName
            createdAt
          }
        }
        ... on MessageTaskAssignment {
          id
          messageId
          assignees {
            ...UserDetail
          }
          task {
            id
            name
            deletedAt
            completedAt
          }
        }
        ... on MessageTaskComplete {
          id
          messageId
          completor {
            id
            name
            avatar {
              id
              url
              cdnBaseUrl
              path
              thumbnail
            }
          }
        }
      }
    }
    attachments {
      ... on MessageTask {
        id
        authorId
        author {
          id
          name
          avatar {
            ...FileDetails
          }
        }
        task {
          id
          projectId
          deletedAt
          name
        }
      }
      ... on MessageProject {
        id
        authorId
        author {
          id
          name
          avatar {
            ...FileDetails
          }
        }
        project {
          id
          name
        }
      }
      ... on Document {
        id
        name
        contentType
        createdAt
        size
        isImage
        clientId
        isAudio
        duration
        file {
          ...FileDetails
        }
        owner {
          id
          name
          firstName
          lastName
          createdAt
        }
      }
      ... on MessageTaskAssignment {
        id
        messageId
        assignees {
          id
          name
          avatar {
            id
            url
            cdnBaseUrl
            path
            thumbnail
          }
        }
        task {
          id
          name
          deletedAt
          completedAt
        }
      }
      ... on MessageTaskComplete {
        id
        messageId
        completor {
          id
          name
          avatar {
            id
            url
            cdnBaseUrl
            path
            thumbnail
          }
        }
      }
    }
    reaction {
      ... on MessageReaction {
        id
        reaction
        count
        user {
          id
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${CheckinDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
`;
export const DraftMessageDetailFragmentDoc = gql`
  fragment DraftMessageDetail on DraftMessage {
    chatId
    body
    tagsCollection {
      tasks {
        id
        name
        userIds
        status
        projectId
        project {
          id
          name
        }
      }
      project {
        id
        name
      }
      author {
        id
        name
      }
    }
    localFiles {
      id
      name
      clientId
      url
      cdnBaseUrl
      path
      contentType
      isImage
      isVideo
      size
      isAudio
      duration
      isPreviewable
      thumbnail
    }
    replyMessage {
      ...MessageDetail
    }
    checkin {
      ...checkinDetail
    }
  }
  ${MessageDetailFragmentDoc}
  ${CheckinDetailFragmentDoc}
`;
export const GetChatDetailFragmentDoc = gql`
  fragment GetChatDetail on Chat {
    id
    name
    description
    avatar {
      ...FileDetails
    }
    isGroupChat
    messageIcon
    messageBody
    recentActivityAt
    unreadCount
    isNewChat
    isUserBlocked
    friendRequestAccepted
    createdAt
    leaveOn
    owner {
      id
      name
    }
    settings {
      id
      pin
      mute
      archivedAt
    }
    users {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      createdAt
      avatar {
        ...FileDetails
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export const LocalDocumentDetailFragmentDoc = gql`
  fragment LocalDocumentDetail on Document {
    id
    name
    contentType
    createdAt
    size
    isImage
    clientId
    isAudio
    duration
    failedAt
    file {
      id
      cdnBaseUrl
      path
      isPreviewable
      thumbnail
      url
    }
    owner {
      id
      name
      firstName
      lastName
      createdAt
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
  }
`;
export const MessageDetailForQueryFragmentDoc = gql`
  fragment MessageDetailForQuery on Message {
    id
    chatId
    clientId
    body
    createdAt
    isSender
    isDraft
    publishedAt
    authorId
    cursor
    readAt
    isRetry
    readReceipts {
      ...UserDetail
    }
    checkin {
      ...checkinDetail
    }
    author {
      id
      name
      firstName
      lastName
      createdAt
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
    replyId
    replyMessage {
      id
      chatId
      clientId
      body
      createdAt
      isSender
      isDraft
      publishedAt
      authorId
      author {
        id
        name
        firstName
        lastName
        createdAt
      }
      attachments {
        ... on MessageTask {
          id
          authorId
          author {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              isPreviewable
              thumbnail
            }
          }
          task {
            id
            projectId
            name
          }
        }
        ... on MessageProject {
          id
          authorId
          author {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              isPreviewable
              thumbnail
            }
          }
          project {
            id
            name
          }
        }
        ... on Document {
          id
          name
          contentType
          createdAt
          size
          isImage
          clientId
          isAudio
          duration
          file {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
          owner {
            id
            name
            firstName
            lastName
            createdAt
          }
        }
        ... on MessageTaskAssignment {
          id
          messageId
          assignees {
            ...UserDetail
          }
          task {
            id
            name
            deletedAt
            completedAt
          }
        }
        ... on MessageTaskComplete {
          id
          messageId
          completor {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              thumbnail
            }
          }
        }
      }
    }
    attachments {
      ... on MessageTask {
        id
        authorId
        author {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
        }
        task {
          id
          projectId
          deletedAt
          name
        }
      }
      ... on MessageProject {
        id
        authorId
        author {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
        }
        project {
          id
          name
        }
      }
      ... on Document {
        id
        name
        contentType
        createdAt
        size
        isImage
        clientId
        isAudio
        duration
        file {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
        owner {
          id
          name
          firstName
          lastName
          createdAt
        }
      }
      ... on MessageTaskAssignment {
        id
        messageId
        assignees {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            thumbnail
          }
        }
        task {
          id
          name
          deletedAt
          completedAt
        }
      }
      ... on MessageTaskComplete {
        id
        messageId
        completor {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            thumbnail
          }
        }
      }
    }
    reaction {
      ... on MessageReaction {
        id
        reaction
        count
        user {
          id
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${CheckinDetailFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
`;
export const ProjectDetailFragmentDoc = gql`
  fragment ProjectDetail on Project {
    address
    latitude
    longitude
    archivedAt
    usersCount
    tasksCount
    currentUserRole
    currentUserTeamRole
    isTaskMember
    teamId
    color
    icon
    createdAt
    creator {
      ...UserDetail
      avatar {
        ...FileDetails
      }
    }
    description
    id
    coverImage {
      ...FileDetails
    }
    initials
    name
    team {
      avatar
      createdAt
      id
      name
      personal
      hasReachedMaxProjects
      plan {
        id
        isCanceled
        expiration
      }
    }
    members {
      id
      role
      user {
        ...UserDetail
        avatar {
          ...FileDetails
        }
      }
    }
    audits {
      id
      event
    }
    unreadAudits {
      id
      event
    }
    unreadAuditsCount
    activeTasksCount
    upcomingTasksCount
    overdueTasksCount
    completedTasksCount
  }
  ${UserDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
`;
export const TaskDetailFragmentDoc = gql`
  fragment TaskDetail on Task {
    id
    name
    status
    priority
    startDate
    dueDate
    completedAt
    createdAt
    phaseId
    projectId
    deletedAt
    skillIds
    userIds
    dueGroup
    dependencies {
      id
      name
    }
    fixedOrder
    recurring
    description
    frequency
    currentUserRole
    currentUserProjectRole
    currentUserProjectTeamRole
    checklists {
      id
      name
      completedAt
      createdAt
    }
    members {
      id
      role
      user {
        ...UserDetail
        avatar {
          ...FileDetails
        }
      }
    }
    creator {
      ...UserDetail
      avatar {
        ...FileDetails
      }
    }
    completor {
      ...UserDetail
    }
    project {
      id
      name
      color
      icon
      currentUserTeamRole
      currentUserRole
      team {
        avatar
        createdAt
        id
        name
        personal
      }
      initials
      archivedAt
    }
    phase {
      id
      name
    }
  }
  ${UserDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
`;
export const TaskDocumentDetailFragmentDoc = gql`
  fragment TaskDocumentDetail on Task {
    id
    name
    startDate
    dueDate
    completedAt
    projectId
    deletedAt
    currentUserRole
    currentUserProjectRole
    currentUserProjectTeamRole
    project {
      id
      name
      archivedAt
    }
    documents {
      id
      name
      contentType
      createdAt
      size
      isImage
      clientId
      isAudio
      duration
      owner {
        id
        name
      }
      file {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
      messageDocuments {
        id
        message {
          id
          clientId
          chatId
          createdAt
          isSender
          isDraft
          publishedAt
          authorId
        }
      }
      projectDocuments {
        id
        project {
          id
          name
        }
        owner {
          id
          name
        }
      }
      taskDocuments {
        id
        task {
          id
          name
          projectId
          project {
            id
            name
          }
        }
        owner {
          id
          name
        }
      }
    }
  }
`;
export const TeamDetailFragmentDoc = gql`
  fragment TeamDetail on Team {
    address
    latitude
    longitude
    id
    avatar
    avatarFile {
      id
      cdnBaseUrl
      path
      isPreviewable
      thumbnail
    }
    createdAt
    name
    usersCount
    fileStorageUsed
    mediaStorageUsed
    maxDocumentStorage
    storageRemaining
    hasReachedMaxProjects
    numberOfProjectsUsed
    maxNumberOfProjects
    personal
    tasksCount
    completedTasksCount
    plan {
      id
      amount
      expiration
      interval
      name
      quantity
      status
      isCanceled
      isResumable
      payment {
        brand
        last4
        expMonth
        expYear
      }
    }
    owner {
      ...UserDetail
    }
    skills {
      id
      name
      createdAt
    }
    users {
      ...UserDetail
      teamRoles
    }
    product {
      id
      name
      prices {
        id
        interval
        intervalCount
        externalId
      }
    }
    invoices {
      id
      invoiceUrl
      amount
      date
      description
      status
    }
  }
  ${UserDetailFragmentDoc}
`;
export const UserProfileFragmentDoc = gql`
  fragment UserProfile on Profile {
    address
    id
    email
    name
    firstName
    lastName
    phoneNumber
    userActiveTasksCount
    avatar {
      id
      cdnBaseUrl
      path
      isPreviewable
      thumbnail
    }
    skills {
      id
      name
      createdAt
    }
    teams {
      id
      name
      users {
        id
        name
      }
      usersCount
    }
  }
`;
export const AcceptTeamInvitationDocument = gql`
  mutation acceptTeamInvitation($invitationToken: String!) {
    acceptTeamInvitation(invitationToken: $invitationToken) {
      id
      name
      users {
        id
        name
      }
    }
  }
`;
export type AcceptTeamInvitationMutationFn = Apollo.MutationFunction<
  AcceptTeamInvitationMutation,
  AcceptTeamInvitationMutationVariables
>;

/**
 * __useAcceptTeamInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptTeamInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTeamInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTeamInvitationMutation, { data, loading, error }] = useAcceptTeamInvitationMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useAcceptTeamInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTeamInvitationMutation,
    AcceptTeamInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTeamInvitationMutation,
    AcceptTeamInvitationMutationVariables
  >(AcceptTeamInvitationDocument, options);
}
export type AcceptTeamInvitationMutationHookResult = ReturnType<
  typeof useAcceptTeamInvitationMutation
>;
export type AcceptTeamInvitationMutationResult =
  Apollo.MutationResult<AcceptTeamInvitationMutation>;
export type AcceptTeamInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptTeamInvitationMutation,
  AcceptTeamInvitationMutationVariables
>;
export const AddProjectFilesDocument = gql`
  mutation addProjectFiles($id: ID!, $attributes: [DocumentAttributes!]!) {
    addProjectFiles(id: $id, attributes: $attributes) {
      id
    }
  }
`;
export type AddProjectFilesMutationFn = Apollo.MutationFunction<
  AddProjectFilesMutation,
  AddProjectFilesMutationVariables
>;

/**
 * __useAddProjectFilesMutation__
 *
 * To run a mutation, you first call `useAddProjectFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectFilesMutation, { data, loading, error }] = useAddProjectFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddProjectFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectFilesMutation,
    AddProjectFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectFilesMutation,
    AddProjectFilesMutationVariables
  >(AddProjectFilesDocument, options);
}
export type AddProjectFilesMutationHookResult = ReturnType<
  typeof useAddProjectFilesMutation
>;
export type AddProjectFilesMutationResult =
  Apollo.MutationResult<AddProjectFilesMutation>;
export type AddProjectFilesMutationOptions = Apollo.BaseMutationOptions<
  AddProjectFilesMutation,
  AddProjectFilesMutationVariables
>;
export const AddTaskChecklistDocument = gql`
  mutation addTaskChecklist(
    $taskId: ID!
    $attributes: TaskChecklistAttributes!
  ) {
    addTaskChecklist(taskId: $taskId, attributes: $attributes) {
      taskId
      id
      name
      completedAt
      createdAt
      sort
    }
  }
`;
export type AddTaskChecklistMutationFn = Apollo.MutationFunction<
  AddTaskChecklistMutation,
  AddTaskChecklistMutationVariables
>;

/**
 * __useAddTaskChecklistMutation__
 *
 * To run a mutation, you first call `useAddTaskChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskChecklistMutation, { data, loading, error }] = useAddTaskChecklistMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddTaskChecklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTaskChecklistMutation,
    AddTaskChecklistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTaskChecklistMutation,
    AddTaskChecklistMutationVariables
  >(AddTaskChecklistDocument, options);
}
export type AddTaskChecklistMutationHookResult = ReturnType<
  typeof useAddTaskChecklistMutation
>;
export type AddTaskChecklistMutationResult =
  Apollo.MutationResult<AddTaskChecklistMutation>;
export type AddTaskChecklistMutationOptions = Apollo.BaseMutationOptions<
  AddTaskChecklistMutation,
  AddTaskChecklistMutationVariables
>;
export const AddTaskFilesDocument = gql`
  mutation addTaskFiles($id: ID!, $attributes: [DocumentAttributes!]!) {
    addTaskFiles(id: $id, attributes: $attributes) {
      id
    }
  }
`;
export type AddTaskFilesMutationFn = Apollo.MutationFunction<
  AddTaskFilesMutation,
  AddTaskFilesMutationVariables
>;

/**
 * __useAddTaskFilesMutation__
 *
 * To run a mutation, you first call `useAddTaskFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskFilesMutation, { data, loading, error }] = useAddTaskFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddTaskFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTaskFilesMutation,
    AddTaskFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTaskFilesMutation,
    AddTaskFilesMutationVariables
  >(AddTaskFilesDocument, options);
}
export type AddTaskFilesMutationHookResult = ReturnType<
  typeof useAddTaskFilesMutation
>;
export type AddTaskFilesMutationResult =
  Apollo.MutationResult<AddTaskFilesMutation>;
export type AddTaskFilesMutationOptions = Apollo.BaseMutationOptions<
  AddTaskFilesMutation,
  AddTaskFilesMutationVariables
>;
export const ArchiveChatDocument = gql`
  mutation archiveChat($id: ID!) {
    archiveChat(id: $id) {
      id
      name
      description
      users {
        id
      }
      avatar {
        ...FileDetails
      }
      settings {
        mute
        pin
        archivedAt
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type ArchiveChatMutationFn = Apollo.MutationFunction<
  ArchiveChatMutation,
  ArchiveChatMutationVariables
>;

/**
 * __useArchiveChatMutation__
 *
 * To run a mutation, you first call `useArchiveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveChatMutation, { data, loading, error }] = useArchiveChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveChatMutation,
    ArchiveChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveChatMutation, ArchiveChatMutationVariables>(
    ArchiveChatDocument,
    options
  );
}
export type ArchiveChatMutationHookResult = ReturnType<
  typeof useArchiveChatMutation
>;
export type ArchiveChatMutationResult =
  Apollo.MutationResult<ArchiveChatMutation>;
export type ArchiveChatMutationOptions = Apollo.BaseMutationOptions<
  ArchiveChatMutation,
  ArchiveChatMutationVariables
>;
export const ArchiveProjectDocument = gql`
  mutation archiveProject($id: ID!) {
    archiveProject(id: $id) {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragmentDoc}
`;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveProjectMutation,
    ArchiveProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveProjectMutation,
    ArchiveProjectMutationVariables
  >(ArchiveProjectDocument, options);
}
export type ArchiveProjectMutationHookResult = ReturnType<
  typeof useArchiveProjectMutation
>;
export type ArchiveProjectMutationResult =
  Apollo.MutationResult<ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>;
export const AssignTaskOwnerDocument = gql`
  mutation assignTaskOwner($id: ID!, $newOwnerId: ID!) {
    assignTaskOwner(id: $id, newOwnerId: $newOwnerId) {
      id
    }
  }
`;
export type AssignTaskOwnerMutationFn = Apollo.MutationFunction<
  AssignTaskOwnerMutation,
  AssignTaskOwnerMutationVariables
>;

/**
 * __useAssignTaskOwnerMutation__
 *
 * To run a mutation, you first call `useAssignTaskOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskOwnerMutation, { data, loading, error }] = useAssignTaskOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newOwnerId: // value for 'newOwnerId'
 *   },
 * });
 */
export function useAssignTaskOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaskOwnerMutation,
    AssignTaskOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignTaskOwnerMutation,
    AssignTaskOwnerMutationVariables
  >(AssignTaskOwnerDocument, options);
}
export type AssignTaskOwnerMutationHookResult = ReturnType<
  typeof useAssignTaskOwnerMutation
>;
export type AssignTaskOwnerMutationResult =
  Apollo.MutationResult<AssignTaskOwnerMutation>;
export type AssignTaskOwnerMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskOwnerMutation,
  AssignTaskOwnerMutationVariables
>;
export const AssignTaskUsersDocument = gql`
  mutation assignTaskUsers(
    $id: ID!
    $members: [TaskMemberAttributes!]!
    $chatId: ID
  ) {
    assignTaskUsers(id: $id, members: $members, chatId: $chatId) {
      id
    }
  }
`;
export type AssignTaskUsersMutationFn = Apollo.MutationFunction<
  AssignTaskUsersMutation,
  AssignTaskUsersMutationVariables
>;

/**
 * __useAssignTaskUsersMutation__
 *
 * To run a mutation, you first call `useAssignTaskUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskUsersMutation, { data, loading, error }] = useAssignTaskUsersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      members: // value for 'members'
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useAssignTaskUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaskUsersMutation,
    AssignTaskUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignTaskUsersMutation,
    AssignTaskUsersMutationVariables
  >(AssignTaskUsersDocument, options);
}
export type AssignTaskUsersMutationHookResult = ReturnType<
  typeof useAssignTaskUsersMutation
>;
export type AssignTaskUsersMutationResult =
  Apollo.MutationResult<AssignTaskUsersMutation>;
export type AssignTaskUsersMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskUsersMutation,
  AssignTaskUsersMutationVariables
>;
export const AssignTeamOwnerDocument = gql`
  mutation assignTeamOwner($id: ID!, $newOwnerId: ID!) {
    assignTeamOwner(id: $id, newOwnerId: $newOwnerId) {
      id
    }
  }
`;
export type AssignTeamOwnerMutationFn = Apollo.MutationFunction<
  AssignTeamOwnerMutation,
  AssignTeamOwnerMutationVariables
>;

/**
 * __useAssignTeamOwnerMutation__
 *
 * To run a mutation, you first call `useAssignTeamOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTeamOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTeamOwnerMutation, { data, loading, error }] = useAssignTeamOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newOwnerId: // value for 'newOwnerId'
 *   },
 * });
 */
export function useAssignTeamOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTeamOwnerMutation,
    AssignTeamOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignTeamOwnerMutation,
    AssignTeamOwnerMutationVariables
  >(AssignTeamOwnerDocument, options);
}
export type AssignTeamOwnerMutationHookResult = ReturnType<
  typeof useAssignTeamOwnerMutation
>;
export type AssignTeamOwnerMutationResult =
  Apollo.MutationResult<AssignTeamOwnerMutation>;
export type AssignTeamOwnerMutationOptions = Apollo.BaseMutationOptions<
  AssignTeamOwnerMutation,
  AssignTeamOwnerMutationVariables
>;
export const CancelStripeSubscriptionDocument = gql`
  mutation cancelStripeSubscription($id: ID!) {
    cancelStripeSubscription(id: $id) {
      id
      plan {
        id
        status
      }
    }
  }
`;
export type CancelStripeSubscriptionMutationFn = Apollo.MutationFunction<
  CancelStripeSubscriptionMutation,
  CancelStripeSubscriptionMutationVariables
>;

/**
 * __useCancelStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionMutation, { data, loading, error }] = useCancelStripeSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelStripeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelStripeSubscriptionMutation,
    CancelStripeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelStripeSubscriptionMutation,
    CancelStripeSubscriptionMutationVariables
  >(CancelStripeSubscriptionDocument, options);
}
export type CancelStripeSubscriptionMutationHookResult = ReturnType<
  typeof useCancelStripeSubscriptionMutation
>;
export type CancelStripeSubscriptionMutationResult =
  Apollo.MutationResult<CancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CancelStripeSubscriptionMutation,
    CancelStripeSubscriptionMutationVariables
  >;
export const ChatBlockUnblockUserDocument = gql`
  mutation chatBlockUnblockUser($userIdToBlock: ID!) {
    chatBlockUnblockUser(userIdToBlock: $userIdToBlock) {
      id
      email
      firstName
      lastName
      avatar {
        cdnBaseUrl
        id
        path
        url
      }
    }
  }
`;
export type ChatBlockUnblockUserMutationFn = Apollo.MutationFunction<
  ChatBlockUnblockUserMutation,
  ChatBlockUnblockUserMutationVariables
>;

/**
 * __useChatBlockUnblockUserMutation__
 *
 * To run a mutation, you first call `useChatBlockUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatBlockUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatBlockUnblockUserMutation, { data, loading, error }] = useChatBlockUnblockUserMutation({
 *   variables: {
 *      userIdToBlock: // value for 'userIdToBlock'
 *   },
 * });
 */
export function useChatBlockUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatBlockUnblockUserMutation,
    ChatBlockUnblockUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatBlockUnblockUserMutation,
    ChatBlockUnblockUserMutationVariables
  >(ChatBlockUnblockUserDocument, options);
}
export type ChatBlockUnblockUserMutationHookResult = ReturnType<
  typeof useChatBlockUnblockUserMutation
>;
export type ChatBlockUnblockUserMutationResult =
  Apollo.MutationResult<ChatBlockUnblockUserMutation>;
export type ChatBlockUnblockUserMutationOptions = Apollo.BaseMutationOptions<
  ChatBlockUnblockUserMutation,
  ChatBlockUnblockUserMutationVariables
>;
export const CompleteTaskDocument = gql`
  mutation completeTask($id: ID!, $chatId: ID) {
    completeTask(id: $id, chatId: $chatId) {
      id
      status
      completedAt
    }
  }
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useCompleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTaskMutation,
    CompleteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteTaskMutation,
    CompleteTaskMutationVariables
  >(CompleteTaskDocument, options);
}
export type CompleteTaskMutationHookResult = ReturnType<
  typeof useCompleteTaskMutation
>;
export type CompleteTaskMutationResult =
  Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;
export const CreateChatDocument = gql`
  mutation CreateChat($attributes: ChatAttributes!) {
    createChat(attributes: $attributes) {
      id
      name
      description
      isGroupChat
      createdAt
      owner {
        id
      }
      users {
        id
      }
      avatar {
        ...FileDetails
      }
      settings {
        mute
        pin
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type CreateChatMutationFn = Apollo.MutationFunction<
  CreateChatMutation,
  CreateChatMutationVariables
>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatMutation,
    CreateChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(
    CreateChatDocument,
    options
  );
}
export type CreateChatMutationHookResult = ReturnType<
  typeof useCreateChatMutation
>;
export type CreateChatMutationResult =
  Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMutation,
  CreateChatMutationVariables
>;
export const CreateContactInvitationUrlDocument = gql`
  mutation createContactInvitationUrl(
    $attributes: CreateContactInvitationUrlAttributes!
  ) {
    createContactInvitationUrl(attributes: $attributes)
  }
`;
export type CreateContactInvitationUrlMutationFn = Apollo.MutationFunction<
  CreateContactInvitationUrlMutation,
  CreateContactInvitationUrlMutationVariables
>;

/**
 * __useCreateContactInvitationUrlMutation__
 *
 * To run a mutation, you first call `useCreateContactInvitationUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactInvitationUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactInvitationUrlMutation, { data, loading, error }] = useCreateContactInvitationUrlMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateContactInvitationUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactInvitationUrlMutation,
    CreateContactInvitationUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactInvitationUrlMutation,
    CreateContactInvitationUrlMutationVariables
  >(CreateContactInvitationUrlDocument, options);
}
export type CreateContactInvitationUrlMutationHookResult = ReturnType<
  typeof useCreateContactInvitationUrlMutation
>;
export type CreateContactInvitationUrlMutationResult =
  Apollo.MutationResult<CreateContactInvitationUrlMutation>;
export type CreateContactInvitationUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateContactInvitationUrlMutation,
    CreateContactInvitationUrlMutationVariables
  >;
export const CreateDeviceDocument = gql`
  mutation createDevice($attributes: DeviceAttributes!) {
    createDevice(attributes: $attributes) {
      token
    }
  }
`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<
  CreateDeviceMutation,
  CreateDeviceMutationVariables
>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
  >(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<
  typeof useCreateDeviceMutation
>;
export type CreateDeviceMutationResult =
  Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<
  CreateDeviceMutation,
  CreateDeviceMutationVariables
>;
export const CreateDirectUploadsDocument = gql`
  mutation createDirectUploads($attributes: [DirectUploadAttributes!]!) {
    createDirectUploads(attributes: $attributes) {
      clientId
      url
      headers
      blobId
      signedBlobId
    }
  }
`;
export type CreateDirectUploadsMutationFn = Apollo.MutationFunction<
  CreateDirectUploadsMutation,
  CreateDirectUploadsMutationVariables
>;

/**
 * __useCreateDirectUploadsMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadsMutation, { data, loading, error }] = useCreateDirectUploadsMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateDirectUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectUploadsMutation,
    CreateDirectUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDirectUploadsMutation,
    CreateDirectUploadsMutationVariables
  >(CreateDirectUploadsDocument, options);
}
export type CreateDirectUploadsMutationHookResult = ReturnType<
  typeof useCreateDirectUploadsMutation
>;
export type CreateDirectUploadsMutationResult =
  Apollo.MutationResult<CreateDirectUploadsMutation>;
export type CreateDirectUploadsMutationOptions = Apollo.BaseMutationOptions<
  CreateDirectUploadsMutation,
  CreateDirectUploadsMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation createGroup($attributes: GroupAttributes!) {
    createGroup(attributes: $attributes) {
      id
      name
      team {
        id
        name
      }
      users {
        id
        name
        avatar {
          ...FileDetails
        }
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const CreateInviteDocument = gql`
  mutation createInvite($projectId: ID, $taskId: ID) {
    createInvite(taskId: $taskId, projectId: $projectId)
  }
`;
export type CreateInviteMutationFn = Apollo.MutationFunction<
  CreateInviteMutation,
  CreateInviteMutationVariables
>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInviteMutation,
    CreateInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInviteMutation,
    CreateInviteMutationVariables
  >(CreateInviteDocument, options);
}
export type CreateInviteMutationHookResult = ReturnType<
  typeof useCreateInviteMutation
>;
export type CreateInviteMutationResult =
  Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<
  CreateInviteMutation,
  CreateInviteMutationVariables
>;
export const CreateMessageIndividualDocument = gql`
  mutation createMessageIndividual($attributes: MessageAttributes!) {
    createMessageIndividual(attributes: $attributes) {
      id
      chatId
      clientId
      body
      createdAt
      isSender
      isDraft
      publishedAt
      authorId
      author {
        ...UserDetail
        avatar {
          ...FileDetails
        }
      }
      chat {
        id
        name
      }
      replyMessage {
        id
        chatId
        clientId
        body
        createdAt
        isSender
        isDraft
        publishedAt
        authorId
        author {
          ...UserDetail
        }
      }
      attachments {
        ... on MessageTask {
          id
          authorId
          author {
            ...UserDetail
          }
          task {
            id
            projectId
            name
          }
        }
        ... on MessageProject {
          id
          authorId
          author {
            ...UserDetail
          }
          project {
            id
            name
          }
        }
        ... on Document {
          ...DocumentDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
  ${DocumentDetailFragmentDoc}
`;
export type CreateMessageIndividualMutationFn = Apollo.MutationFunction<
  CreateMessageIndividualMutation,
  CreateMessageIndividualMutationVariables
>;

/**
 * __useCreateMessageIndividualMutation__
 *
 * To run a mutation, you first call `useCreateMessageIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageIndividualMutation, { data, loading, error }] = useCreateMessageIndividualMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateMessageIndividualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageIndividualMutation,
    CreateMessageIndividualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageIndividualMutation,
    CreateMessageIndividualMutationVariables
  >(CreateMessageIndividualDocument, options);
}
export type CreateMessageIndividualMutationHookResult = ReturnType<
  typeof useCreateMessageIndividualMutation
>;
export type CreateMessageIndividualMutationResult =
  Apollo.MutationResult<CreateMessageIndividualMutation>;
export type CreateMessageIndividualMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageIndividualMutation,
  CreateMessageIndividualMutationVariables
>;
export const CreateMessageReactionDocument = gql`
  mutation createMessageReaction($messageId: ID!, $reaction: String!) {
    createMessageReaction(messageId: $messageId, reaction: $reaction) {
      count
      id
      reaction
      reactionId
    }
  }
`;
export type CreateMessageReactionMutationFn = Apollo.MutationFunction<
  CreateMessageReactionMutation,
  CreateMessageReactionMutationVariables
>;

/**
 * __useCreateMessageReactionMutation__
 *
 * To run a mutation, you first call `useCreateMessageReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageReactionMutation, { data, loading, error }] = useCreateMessageReactionMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useCreateMessageReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageReactionMutation,
    CreateMessageReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageReactionMutation,
    CreateMessageReactionMutationVariables
  >(CreateMessageReactionDocument, options);
}
export type CreateMessageReactionMutationHookResult = ReturnType<
  typeof useCreateMessageReactionMutation
>;
export type CreateMessageReactionMutationResult =
  Apollo.MutationResult<CreateMessageReactionMutation>;
export type CreateMessageReactionMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageReactionMutation,
  CreateMessageReactionMutationVariables
>;
export const CreatePhaseDocument = gql`
  mutation createPhase($attributes: PhaseAttributes!) {
    createPhase(attributes: $attributes) {
      id
      name
    }
  }
`;
export type CreatePhaseMutationFn = Apollo.MutationFunction<
  CreatePhaseMutation,
  CreatePhaseMutationVariables
>;

/**
 * __useCreatePhaseMutation__
 *
 * To run a mutation, you first call `useCreatePhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhaseMutation, { data, loading, error }] = useCreatePhaseMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreatePhaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePhaseMutation,
    CreatePhaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePhaseMutation, CreatePhaseMutationVariables>(
    CreatePhaseDocument,
    options
  );
}
export type CreatePhaseMutationHookResult = ReturnType<
  typeof useCreatePhaseMutation
>;
export type CreatePhaseMutationResult =
  Apollo.MutationResult<CreatePhaseMutation>;
export type CreatePhaseMutationOptions = Apollo.BaseMutationOptions<
  CreatePhaseMutation,
  CreatePhaseMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($attributes: ProjectAttributes!) {
    createProject(attributes: $attributes) {
      id
      name
      description
      address
      latitude
      longitude
      color
      icon
      createdAt
      creator {
        ...UserDetail
      }
      team {
        id
        name
        avatar
      }
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateShareLinkDocument = gql`
  mutation createShareLink($documentIds: [ID!]!) {
    createShareLink(documentIds: $documentIds) {
      sharedUrl
    }
  }
`;
export type CreateShareLinkMutationFn = Apollo.MutationFunction<
  CreateShareLinkMutation,
  CreateShareLinkMutationVariables
>;

/**
 * __useCreateShareLinkMutation__
 *
 * To run a mutation, you first call `useCreateShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareLinkMutation, { data, loading, error }] = useCreateShareLinkMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useCreateShareLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShareLinkMutation,
    CreateShareLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShareLinkMutation,
    CreateShareLinkMutationVariables
  >(CreateShareLinkDocument, options);
}
export type CreateShareLinkMutationHookResult = ReturnType<
  typeof useCreateShareLinkMutation
>;
export type CreateShareLinkMutationResult =
  Apollo.MutationResult<CreateShareLinkMutation>;
export type CreateShareLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateShareLinkMutation,
  CreateShareLinkMutationVariables
>;
export const CreateStripeSubscriptionDocument = gql`
  mutation createStripeSubscription(
    $teamAttributes: TeamAttributes
    $stripeSubscriptionAttributes: StripeSubscriptionAttributes!
  ) {
    createStripeSubscription(
      teamAttributes: $teamAttributes
      stripeSubscriptionAttributes: $stripeSubscriptionAttributes
    ) {
      clientSecret
      id
      intentType
      team {
        id
      }
    }
  }
`;
export type CreateStripeSubscriptionMutationFn = Apollo.MutationFunction<
  CreateStripeSubscriptionMutation,
  CreateStripeSubscriptionMutationVariables
>;

/**
 * __useCreateStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSubscriptionMutation, { data, loading, error }] = useCreateStripeSubscriptionMutation({
 *   variables: {
 *      teamAttributes: // value for 'teamAttributes'
 *      stripeSubscriptionAttributes: // value for 'stripeSubscriptionAttributes'
 *   },
 * });
 */
export function useCreateStripeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeSubscriptionMutation,
    CreateStripeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeSubscriptionMutation,
    CreateStripeSubscriptionMutationVariables
  >(CreateStripeSubscriptionDocument, options);
}
export type CreateStripeSubscriptionMutationHookResult = ReturnType<
  typeof useCreateStripeSubscriptionMutation
>;
export type CreateStripeSubscriptionMutationResult =
  Apollo.MutationResult<CreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStripeSubscriptionMutation,
    CreateStripeSubscriptionMutationVariables
  >;
export const CreateTaskDocument = gql`
  mutation createTask($attributes: TaskAttributes!) {
    createTask(attributes: $attributes) {
      ...TaskDetail
    }
  }
  ${TaskDetailFragmentDoc}
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation createTeam($attributes: TeamAttributes!) {
    createTeam(attributes: $attributes) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<
  typeof useCreateTeamMutation
>;
export type CreateTeamMutationResult =
  Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const CreateTeamInvitationDocument = gql`
  mutation createTeamInvitation($id: ID!) {
    createTeamInvitation(id: $id)
  }
`;
export type CreateTeamInvitationMutationFn = Apollo.MutationFunction<
  CreateTeamInvitationMutation,
  CreateTeamInvitationMutationVariables
>;

/**
 * __useCreateTeamInvitationMutation__
 *
 * To run a mutation, you first call `useCreateTeamInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamInvitationMutation, { data, loading, error }] = useCreateTeamInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateTeamInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamInvitationMutation,
    CreateTeamInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTeamInvitationMutation,
    CreateTeamInvitationMutationVariables
  >(CreateTeamInvitationDocument, options);
}
export type CreateTeamInvitationMutationHookResult = ReturnType<
  typeof useCreateTeamInvitationMutation
>;
export type CreateTeamInvitationMutationResult =
  Apollo.MutationResult<CreateTeamInvitationMutation>;
export type CreateTeamInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamInvitationMutation,
  CreateTeamInvitationMutationVariables
>;
export const DeleteDeviceDocument = gql`
  mutation deleteDevice($expoToken: String!) {
    deleteDevice(expoToken: $expoToken) {
      id
    }
  }
`;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<
  DeleteDeviceMutation,
  DeleteDeviceMutationVariables
>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      expoToken: // value for 'expoToken'
 *   },
 * });
 */
export function useDeleteDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDeviceMutation,
    DeleteDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDeviceMutation,
    DeleteDeviceMutationVariables
  >(DeleteDeviceDocument, options);
}
export type DeleteDeviceMutationHookResult = ReturnType<
  typeof useDeleteDeviceMutation
>;
export type DeleteDeviceMutationResult =
  Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<
  DeleteDeviceMutation,
  DeleteDeviceMutationVariables
>;
export const DeleteFriendRequestDocument = gql`
  mutation DeleteFriendRequest($id: ID!, $friendRequest: Boolean!) {
    deleteFriendRequest(id: $id, friendRequest: $friendRequest) {
      id
    }
  }
`;
export type DeleteFriendRequestMutationFn = Apollo.MutationFunction<
  DeleteFriendRequestMutation,
  DeleteFriendRequestMutationVariables
>;

/**
 * __useDeleteFriendRequestMutation__
 *
 * To run a mutation, you first call `useDeleteFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFriendRequestMutation, { data, loading, error }] = useDeleteFriendRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      friendRequest: // value for 'friendRequest'
 *   },
 * });
 */
export function useDeleteFriendRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFriendRequestMutation,
    DeleteFriendRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFriendRequestMutation,
    DeleteFriendRequestMutationVariables
  >(DeleteFriendRequestDocument, options);
}
export type DeleteFriendRequestMutationHookResult = ReturnType<
  typeof useDeleteFriendRequestMutation
>;
export type DeleteFriendRequestMutationResult =
  Apollo.MutationResult<DeleteFriendRequestMutation>;
export type DeleteFriendRequestMutationOptions = Apollo.BaseMutationOptions<
  DeleteFriendRequestMutation,
  DeleteFriendRequestMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const DeleteGroupChatDocument = gql`
  mutation deleteGroupChat($id: ID!) {
    deleteGroupChat(id: $id) {
      id
    }
  }
`;
export type DeleteGroupChatMutationFn = Apollo.MutationFunction<
  DeleteGroupChatMutation,
  DeleteGroupChatMutationVariables
>;

/**
 * __useDeleteGroupChatMutation__
 *
 * To run a mutation, you first call `useDeleteGroupChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupChatMutation, { data, loading, error }] = useDeleteGroupChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupChatMutation,
    DeleteGroupChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupChatMutation,
    DeleteGroupChatMutationVariables
  >(DeleteGroupChatDocument, options);
}
export type DeleteGroupChatMutationHookResult = ReturnType<
  typeof useDeleteGroupChatMutation
>;
export type DeleteGroupChatMutationResult =
  Apollo.MutationResult<DeleteGroupChatMutation>;
export type DeleteGroupChatMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupChatMutation,
  DeleteGroupChatMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation deleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      id
      clientId
      authorId
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<
  typeof useDeleteMessageMutation
>;
export type DeleteMessageMutationResult =
  Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteProjectFilesDocument = gql`
  mutation deleteProjectFiles($ids: [ID!]) {
    deleteProjectFiles(ids: $ids) {
      id
    }
  }
`;
export type DeleteProjectFilesMutationFn = Apollo.MutationFunction<
  DeleteProjectFilesMutation,
  DeleteProjectFilesMutationVariables
>;

/**
 * __useDeleteProjectFilesMutation__
 *
 * To run a mutation, you first call `useDeleteProjectFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectFilesMutation, { data, loading, error }] = useDeleteProjectFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProjectFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectFilesMutation,
    DeleteProjectFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectFilesMutation,
    DeleteProjectFilesMutationVariables
  >(DeleteProjectFilesDocument, options);
}
export type DeleteProjectFilesMutationHookResult = ReturnType<
  typeof useDeleteProjectFilesMutation
>;
export type DeleteProjectFilesMutationResult =
  Apollo.MutationResult<DeleteProjectFilesMutation>;
export type DeleteProjectFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectFilesMutation,
  DeleteProjectFilesMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DeleteTaskFilesDocument = gql`
  mutation deleteTaskFiles($ids: [ID!]) {
    deleteTaskFiles(ids: $ids) {
      id
    }
  }
`;
export type DeleteTaskFilesMutationFn = Apollo.MutationFunction<
  DeleteTaskFilesMutation,
  DeleteTaskFilesMutationVariables
>;

/**
 * __useDeleteTaskFilesMutation__
 *
 * To run a mutation, you first call `useDeleteTaskFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskFilesMutation, { data, loading, error }] = useDeleteTaskFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTaskFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskFilesMutation,
    DeleteTaskFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskFilesMutation,
    DeleteTaskFilesMutationVariables
  >(DeleteTaskFilesDocument, options);
}
export type DeleteTaskFilesMutationHookResult = ReturnType<
  typeof useDeleteTaskFilesMutation
>;
export type DeleteTaskFilesMutationResult =
  Apollo.MutationResult<DeleteTaskFilesMutation>;
export type DeleteTaskFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskFilesMutation,
  DeleteTaskFilesMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      id
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options
  );
}
export type DeleteTeamMutationHookResult = ReturnType<
  typeof useDeleteTeamMutation
>;
export type DeleteTeamMutationResult =
  Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const DuplicateProjectDocument = gql`
  mutation duplicateProject(
    $id: ID!
    $includeTasks: Boolean
    $includeMembers: Boolean
    $includePhases: Boolean
    $includeAdmins: Boolean
    $includeEditors: Boolean
    $includeViewers: Boolean
    $includeFiles: Boolean
  ) {
    duplicateProject(
      id: $id
      includeTasks: $includeTasks
      includeMembers: $includeMembers
      includePhases: $includePhases
      includeAdmins: $includeAdmins
      includeEditors: $includeEditors
      includeViewers: $includeViewers
      includeFiles: $includeFiles
    ) {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragmentDoc}
`;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      includeTasks: // value for 'includeTasks'
 *      includeMembers: // value for 'includeMembers'
 *      includePhases: // value for 'includePhases'
 *      includeAdmins: // value for 'includeAdmins'
 *      includeEditors: // value for 'includeEditors'
 *      includeViewers: // value for 'includeViewers'
 *      includeFiles: // value for 'includeFiles'
 *   },
 * });
 */
export function useDuplicateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >(DuplicateProjectDocument, options);
}
export type DuplicateProjectMutationHookResult = ReturnType<
  typeof useDuplicateProjectMutation
>;
export type DuplicateProjectMutationResult =
  Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>;
export const DuplicateTaskDocument = gql`
  mutation duplicateTask(
    $id: ID!
    $includeCheckLists: Boolean
    $includeCreator: Boolean
    $includeAssignees: Boolean
    $includeViewers: Boolean
    $includeFiles: Boolean
  ) {
    duplicateTask(
      id: $id
      includeCheckLists: $includeCheckLists
      includeCreator: $includeCreator
      includeAssignees: $includeAssignees
      includeViewers: $includeViewers
      includeFiles: $includeFiles
    ) {
      id
      name
      status
      priority
      startDate
      dueDate
      completedAt
      createdAt
      phaseId
      projectId
      skillIds
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
      skills {
        id
        name
      }
      creator {
        ...UserDetail
      }
      completor {
        ...UserDetail
      }
      project {
        id
        name
        color
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type DuplicateTaskMutationFn = Apollo.MutationFunction<
  DuplicateTaskMutation,
  DuplicateTaskMutationVariables
>;

/**
 * __useDuplicateTaskMutation__
 *
 * To run a mutation, you first call `useDuplicateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTaskMutation, { data, loading, error }] = useDuplicateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      includeCheckLists: // value for 'includeCheckLists'
 *      includeCreator: // value for 'includeCreator'
 *      includeAssignees: // value for 'includeAssignees'
 *      includeViewers: // value for 'includeViewers'
 *      includeFiles: // value for 'includeFiles'
 *   },
 * });
 */
export function useDuplicateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateTaskMutation,
    DuplicateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateTaskMutation,
    DuplicateTaskMutationVariables
  >(DuplicateTaskDocument, options);
}
export type DuplicateTaskMutationHookResult = ReturnType<
  typeof useDuplicateTaskMutation
>;
export type DuplicateTaskMutationResult =
  Apollo.MutationResult<DuplicateTaskMutation>;
export type DuplicateTaskMutationOptions = Apollo.BaseMutationOptions<
  DuplicateTaskMutation,
  DuplicateTaskMutationVariables
>;
export const DuplicateTaskFilesDocument = gql`
  mutation duplicateTaskFiles($sourceId: ID!, $destinationId: ID!) {
    duplicateTaskFiles(sourceId: $sourceId, destinationId: $destinationId) {
      id
    }
  }
`;
export type DuplicateTaskFilesMutationFn = Apollo.MutationFunction<
  DuplicateTaskFilesMutation,
  DuplicateTaskFilesMutationVariables
>;

/**
 * __useDuplicateTaskFilesMutation__
 *
 * To run a mutation, you first call `useDuplicateTaskFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTaskFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTaskFilesMutation, { data, loading, error }] = useDuplicateTaskFilesMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useDuplicateTaskFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateTaskFilesMutation,
    DuplicateTaskFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateTaskFilesMutation,
    DuplicateTaskFilesMutationVariables
  >(DuplicateTaskFilesDocument, options);
}
export type DuplicateTaskFilesMutationHookResult = ReturnType<
  typeof useDuplicateTaskFilesMutation
>;
export type DuplicateTaskFilesMutationResult =
  Apollo.MutationResult<DuplicateTaskFilesMutation>;
export type DuplicateTaskFilesMutationOptions = Apollo.BaseMutationOptions<
  DuplicateTaskFilesMutation,
  DuplicateTaskFilesMutationVariables
>;
export const EditStripeSubscriptionDocument = gql`
  mutation editStripeSubscription(
    $stripeSubscriptionAttributes: StripeSubscriptionAttributes!
  ) {
    editStripeSubscription(
      stripeSubscriptionAttributes: $stripeSubscriptionAttributes
    ) {
      clientSecret
      id
      intentType
      team {
        id
      }
    }
  }
`;
export type EditStripeSubscriptionMutationFn = Apollo.MutationFunction<
  EditStripeSubscriptionMutation,
  EditStripeSubscriptionMutationVariables
>;

/**
 * __useEditStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useEditStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStripeSubscriptionMutation, { data, loading, error }] = useEditStripeSubscriptionMutation({
 *   variables: {
 *      stripeSubscriptionAttributes: // value for 'stripeSubscriptionAttributes'
 *   },
 * });
 */
export function useEditStripeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditStripeSubscriptionMutation,
    EditStripeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditStripeSubscriptionMutation,
    EditStripeSubscriptionMutationVariables
  >(EditStripeSubscriptionDocument, options);
}
export type EditStripeSubscriptionMutationHookResult = ReturnType<
  typeof useEditStripeSubscriptionMutation
>;
export type EditStripeSubscriptionMutationResult =
  Apollo.MutationResult<EditStripeSubscriptionMutation>;
export type EditStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  EditStripeSubscriptionMutation,
  EditStripeSubscriptionMutationVariables
>;
export const ExportCheckinsDocument = gql`
  mutation exportCheckins(
    $projectId: ID!
    $from: ISO8601DateTime
    $to: ISO8601DateTime
  ) {
    exportCheckins(projectId: $projectId, from: $from, to: $to) {
      url
      filename
    }
  }
`;
export type ExportCheckinsMutationFn = Apollo.MutationFunction<
  ExportCheckinsMutation,
  ExportCheckinsMutationVariables
>;

/**
 * __useExportCheckinsMutation__
 *
 * To run a mutation, you first call `useExportCheckinsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCheckinsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCheckinsMutation, { data, loading, error }] = useExportCheckinsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useExportCheckinsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportCheckinsMutation,
    ExportCheckinsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportCheckinsMutation,
    ExportCheckinsMutationVariables
  >(ExportCheckinsDocument, options);
}
export type ExportCheckinsMutationHookResult = ReturnType<
  typeof useExportCheckinsMutation
>;
export type ExportCheckinsMutationResult =
  Apollo.MutationResult<ExportCheckinsMutation>;
export type ExportCheckinsMutationOptions = Apollo.BaseMutationOptions<
  ExportCheckinsMutation,
  ExportCheckinsMutationVariables
>;
export const FriendRequestDocument = gql`
  mutation FriendRequest($attributes: ChatAttributes!) {
    friendRequest(attributes: $attributes) {
      id
      name
      description
      isGroupChat
      createdAt
      owner {
        id
      }
      users {
        id
      }
      avatar {
        ...FileDetails
      }
      settings {
        mute
        pin
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type FriendRequestMutationFn = Apollo.MutationFunction<
  FriendRequestMutation,
  FriendRequestMutationVariables
>;

/**
 * __useFriendRequestMutation__
 *
 * To run a mutation, you first call `useFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [friendRequestMutation, { data, loading, error }] = useFriendRequestMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useFriendRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FriendRequestMutation,
    FriendRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FriendRequestMutation,
    FriendRequestMutationVariables
  >(FriendRequestDocument, options);
}
export type FriendRequestMutationHookResult = ReturnType<
  typeof useFriendRequestMutation
>;
export type FriendRequestMutationResult =
  Apollo.MutationResult<FriendRequestMutation>;
export type FriendRequestMutationOptions = Apollo.BaseMutationOptions<
  FriendRequestMutation,
  FriendRequestMutationVariables
>;
export const LeaveChatDocument = gql`
  mutation leaveChat($id: ID!) {
    leaveChat(id: $id) {
      id
    }
  }
`;
export type LeaveChatMutationFn = Apollo.MutationFunction<
  LeaveChatMutation,
  LeaveChatMutationVariables
>;

/**
 * __useLeaveChatMutation__
 *
 * To run a mutation, you first call `useLeaveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChatMutation, { data, loading, error }] = useLeaveChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveChatMutation,
    LeaveChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveChatMutation, LeaveChatMutationVariables>(
    LeaveChatDocument,
    options
  );
}
export type LeaveChatMutationHookResult = ReturnType<
  typeof useLeaveChatMutation
>;
export type LeaveChatMutationResult = Apollo.MutationResult<LeaveChatMutation>;
export type LeaveChatMutationOptions = Apollo.BaseMutationOptions<
  LeaveChatMutation,
  LeaveChatMutationVariables
>;
export const LeaveProjectDocument = gql`
  mutation leaveProject($id: ID!) {
    leaveProject(id: $id) {
      id
    }
  }
`;
export type LeaveProjectMutationFn = Apollo.MutationFunction<
  LeaveProjectMutation,
  LeaveProjectMutationVariables
>;

/**
 * __useLeaveProjectMutation__
 *
 * To run a mutation, you first call `useLeaveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveProjectMutation, { data, loading, error }] = useLeaveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveProjectMutation,
    LeaveProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveProjectMutation,
    LeaveProjectMutationVariables
  >(LeaveProjectDocument, options);
}
export type LeaveProjectMutationHookResult = ReturnType<
  typeof useLeaveProjectMutation
>;
export type LeaveProjectMutationResult =
  Apollo.MutationResult<LeaveProjectMutation>;
export type LeaveProjectMutationOptions = Apollo.BaseMutationOptions<
  LeaveProjectMutation,
  LeaveProjectMutationVariables
>;
export const LeaveTaskDocument = gql`
  mutation leaveTask($id: ID!, $userId: ID!) {
    leaveTask(id: $id, userId: $userId) {
      id
      name
      status
      priority
      startDate
      dueDate
      completedAt
      createdAt
      phaseId
      projectId
      skillIds
      userIds
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
      skills {
        id
        name
      }
      creator {
        ...UserDetail
      }
      completor {
        ...UserDetail
      }
      project {
        id
        name
        color
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type LeaveTaskMutationFn = Apollo.MutationFunction<
  LeaveTaskMutation,
  LeaveTaskMutationVariables
>;

/**
 * __useLeaveTaskMutation__
 *
 * To run a mutation, you first call `useLeaveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTaskMutation, { data, loading, error }] = useLeaveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLeaveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveTaskMutation,
    LeaveTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveTaskMutation, LeaveTaskMutationVariables>(
    LeaveTaskDocument,
    options
  );
}
export type LeaveTaskMutationHookResult = ReturnType<
  typeof useLeaveTaskMutation
>;
export type LeaveTaskMutationResult = Apollo.MutationResult<LeaveTaskMutation>;
export type LeaveTaskMutationOptions = Apollo.BaseMutationOptions<
  LeaveTaskMutation,
  LeaveTaskMutationVariables
>;
export const LeaveTeamDocument = gql`
  mutation leaveTeam($id: ID!) {
    leaveTeam(id: $id) {
      id
      name
    }
  }
`;
export type LeaveTeamMutationFn = Apollo.MutationFunction<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;

/**
 * __useLeaveTeamMutation__
 *
 * To run a mutation, you first call `useLeaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamMutation, { data, loading, error }] = useLeaveTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveTeamMutation,
    LeaveTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveTeamMutation, LeaveTeamMutationVariables>(
    LeaveTeamDocument,
    options
  );
}
export type LeaveTeamMutationHookResult = ReturnType<
  typeof useLeaveTeamMutation
>;
export type LeaveTeamMutationResult = Apollo.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = Apollo.BaseMutationOptions<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;
export const MarkAuditsReadDocument = gql`
  mutation markAuditsRead($attributes: ReadReceiptAuditAttributes!) {
    markAuditsRead(attributes: $attributes) {
      id
      event
    }
  }
`;
export type MarkAuditsReadMutationFn = Apollo.MutationFunction<
  MarkAuditsReadMutation,
  MarkAuditsReadMutationVariables
>;

/**
 * __useMarkAuditsReadMutation__
 *
 * To run a mutation, you first call `useMarkAuditsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAuditsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAuditsReadMutation, { data, loading, error }] = useMarkAuditsReadMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useMarkAuditsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAuditsReadMutation,
    MarkAuditsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAuditsReadMutation,
    MarkAuditsReadMutationVariables
  >(MarkAuditsReadDocument, options);
}
export type MarkAuditsReadMutationHookResult = ReturnType<
  typeof useMarkAuditsReadMutation
>;
export type MarkAuditsReadMutationResult =
  Apollo.MutationResult<MarkAuditsReadMutation>;
export type MarkAuditsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAuditsReadMutation,
  MarkAuditsReadMutationVariables
>;
export const MarkChatsReadDocument = gql`
  mutation markChatsRead($attributes: ReadReceiptChatAttributes!) {
    markChatsRead(attributes: $attributes) {
      id
      unreadCount
    }
  }
`;
export type MarkChatsReadMutationFn = Apollo.MutationFunction<
  MarkChatsReadMutation,
  MarkChatsReadMutationVariables
>;

/**
 * __useMarkChatsReadMutation__
 *
 * To run a mutation, you first call `useMarkChatsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChatsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChatsReadMutation, { data, loading, error }] = useMarkChatsReadMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useMarkChatsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkChatsReadMutation,
    MarkChatsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkChatsReadMutation,
    MarkChatsReadMutationVariables
  >(MarkChatsReadDocument, options);
}
export type MarkChatsReadMutationHookResult = ReturnType<
  typeof useMarkChatsReadMutation
>;
export type MarkChatsReadMutationResult =
  Apollo.MutationResult<MarkChatsReadMutation>;
export type MarkChatsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkChatsReadMutation,
  MarkChatsReadMutationVariables
>;
export const RemoveMeDocument = gql`
  mutation removeMe {
    removeMe {
      id
    }
  }
`;
export type RemoveMeMutationFn = Apollo.MutationFunction<
  RemoveMeMutation,
  RemoveMeMutationVariables
>;

/**
 * __useRemoveMeMutation__
 *
 * To run a mutation, you first call `useRemoveMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMeMutation, { data, loading, error }] = useRemoveMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMeMutation,
    RemoveMeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMeMutation, RemoveMeMutationVariables>(
    RemoveMeDocument,
    options
  );
}
export type RemoveMeMutationHookResult = ReturnType<typeof useRemoveMeMutation>;
export type RemoveMeMutationResult = Apollo.MutationResult<RemoveMeMutation>;
export type RemoveMeMutationOptions = Apollo.BaseMutationOptions<
  RemoveMeMutation,
  RemoveMeMutationVariables
>;
export const RemoveMessageReactionDocument = gql`
  mutation removeMessageReaction($messageReactionId: ID!) {
    removeMessageReaction(id: $messageReactionId) {
      id
      reaction
      count
    }
  }
`;
export type RemoveMessageReactionMutationFn = Apollo.MutationFunction<
  RemoveMessageReactionMutation,
  RemoveMessageReactionMutationVariables
>;

/**
 * __useRemoveMessageReactionMutation__
 *
 * To run a mutation, you first call `useRemoveMessageReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageReactionMutation, { data, loading, error }] = useRemoveMessageReactionMutation({
 *   variables: {
 *      messageReactionId: // value for 'messageReactionId'
 *   },
 * });
 */
export function useRemoveMessageReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMessageReactionMutation,
    RemoveMessageReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMessageReactionMutation,
    RemoveMessageReactionMutationVariables
  >(RemoveMessageReactionDocument, options);
}
export type RemoveMessageReactionMutationHookResult = ReturnType<
  typeof useRemoveMessageReactionMutation
>;
export type RemoveMessageReactionMutationResult =
  Apollo.MutationResult<RemoveMessageReactionMutation>;
export type RemoveMessageReactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveMessageReactionMutation,
  RemoveMessageReactionMutationVariables
>;
export const RemoveProjectMembersDocument = gql`
  mutation removeProjectMembers($id: ID!, $attributes: ProjectAttributes!) {
    updateProject(id: $id, attributes: $attributes) {
      id
      usersCount
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type RemoveProjectMembersMutationFn = Apollo.MutationFunction<
  RemoveProjectMembersMutation,
  RemoveProjectMembersMutationVariables
>;

/**
 * __useRemoveProjectMembersMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMembersMutation, { data, loading, error }] = useRemoveProjectMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useRemoveProjectMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProjectMembersMutation,
    RemoveProjectMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProjectMembersMutation,
    RemoveProjectMembersMutationVariables
  >(RemoveProjectMembersDocument, options);
}
export type RemoveProjectMembersMutationHookResult = ReturnType<
  typeof useRemoveProjectMembersMutation
>;
export type RemoveProjectMembersMutationResult =
  Apollo.MutationResult<RemoveProjectMembersMutation>;
export type RemoveProjectMembersMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectMembersMutation,
  RemoveProjectMembersMutationVariables
>;
export const RemoveTaskChecklistDocument = gql`
  mutation removeTaskChecklist($id: ID!) {
    removeTaskChecklist(id: $id) {
      taskId
      id
      name
      completedAt
      createdAt
      sort
    }
  }
`;
export type RemoveTaskChecklistMutationFn = Apollo.MutationFunction<
  RemoveTaskChecklistMutation,
  RemoveTaskChecklistMutationVariables
>;

/**
 * __useRemoveTaskChecklistMutation__
 *
 * To run a mutation, you first call `useRemoveTaskChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskChecklistMutation, { data, loading, error }] = useRemoveTaskChecklistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTaskChecklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTaskChecklistMutation,
    RemoveTaskChecklistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTaskChecklistMutation,
    RemoveTaskChecklistMutationVariables
  >(RemoveTaskChecklistDocument, options);
}
export type RemoveTaskChecklistMutationHookResult = ReturnType<
  typeof useRemoveTaskChecklistMutation
>;
export type RemoveTaskChecklistMutationResult =
  Apollo.MutationResult<RemoveTaskChecklistMutation>;
export type RemoveTaskChecklistMutationOptions = Apollo.BaseMutationOptions<
  RemoveTaskChecklistMutation,
  RemoveTaskChecklistMutationVariables
>;
export const RemoveTeamMemberDocument = gql`
  mutation removeTeamMember($id: ID!, $userId: ID!) {
    removeTeamMember(id: $id, userId: $userId) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<
  RemoveTeamMemberMutation,
  RemoveTeamMemberMutationVariables
>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeamMemberMutation,
    RemoveTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTeamMemberMutation,
    RemoveTeamMemberMutationVariables
  >(RemoveTeamMemberDocument, options);
}
export type RemoveTeamMemberMutationHookResult = ReturnType<
  typeof useRemoveTeamMemberMutation
>;
export type RemoveTeamMemberMutationResult =
  Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamMemberMutation,
  RemoveTeamMemberMutationVariables
>;
export const SetPrimaryTeamDocument = gql`
  mutation SetPrimaryTeam($accountId: ID!) {
    setPrimaryTeam(accountId: $accountId) {
      id
      name
    }
  }
`;
export type SetPrimaryTeamMutationFn = Apollo.MutationFunction<
  SetPrimaryTeamMutation,
  SetPrimaryTeamMutationVariables
>;

/**
 * __useSetPrimaryTeamMutation__
 *
 * To run a mutation, you first call `useSetPrimaryTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryTeamMutation, { data, loading, error }] = useSetPrimaryTeamMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSetPrimaryTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPrimaryTeamMutation,
    SetPrimaryTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPrimaryTeamMutation,
    SetPrimaryTeamMutationVariables
  >(SetPrimaryTeamDocument, options);
}
export type SetPrimaryTeamMutationHookResult = ReturnType<
  typeof useSetPrimaryTeamMutation
>;
export type SetPrimaryTeamMutationResult =
  Apollo.MutationResult<SetPrimaryTeamMutation>;
export type SetPrimaryTeamMutationOptions = Apollo.BaseMutationOptions<
  SetPrimaryTeamMutation,
  SetPrimaryTeamMutationVariables
>;
export const UnarchiveChatDocument = gql`
  mutation unarchiveChat($id: ID!) {
    unarchiveChat(id: $id) {
      id
      name
      description
      users {
        id
      }
    }
  }
`;
export type UnarchiveChatMutationFn = Apollo.MutationFunction<
  UnarchiveChatMutation,
  UnarchiveChatMutationVariables
>;

/**
 * __useUnarchiveChatMutation__
 *
 * To run a mutation, you first call `useUnarchiveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveChatMutation, { data, loading, error }] = useUnarchiveChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveChatMutation,
    UnarchiveChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveChatMutation,
    UnarchiveChatMutationVariables
  >(UnarchiveChatDocument, options);
}
export type UnarchiveChatMutationHookResult = ReturnType<
  typeof useUnarchiveChatMutation
>;
export type UnarchiveChatMutationResult =
  Apollo.MutationResult<UnarchiveChatMutation>;
export type UnarchiveChatMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveChatMutation,
  UnarchiveChatMutationVariables
>;
export const UnarchiveProjectDocument = gql`
  mutation unarchiveProject($id: ID!) {
    unarchiveProject(id: $id) {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragmentDoc}
`;
export type UnarchiveProjectMutationFn = Apollo.MutationFunction<
  UnarchiveProjectMutation,
  UnarchiveProjectMutationVariables
>;

/**
 * __useUnarchiveProjectMutation__
 *
 * To run a mutation, you first call `useUnarchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProjectMutation, { data, loading, error }] = useUnarchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveProjectMutation,
    UnarchiveProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveProjectMutation,
    UnarchiveProjectMutationVariables
  >(UnarchiveProjectDocument, options);
}
export type UnarchiveProjectMutationHookResult = ReturnType<
  typeof useUnarchiveProjectMutation
>;
export type UnarchiveProjectMutationResult =
  Apollo.MutationResult<UnarchiveProjectMutation>;
export type UnarchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveProjectMutation,
  UnarchiveProjectMutationVariables
>;
export const UpdateChatDocument = gql`
  mutation updateChat($id: ID!, $attributes: ChatAttributes!) {
    updateChat(id: $id, attributes: $attributes) {
      id
      name
      description
      avatar {
        ...FileDetails
      }
      settings {
        mute
        pin
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type UpdateChatMutationFn = Apollo.MutationFunction<
  UpdateChatMutation,
  UpdateChatMutationVariables
>;

/**
 * __useUpdateChatMutation__
 *
 * To run a mutation, you first call `useUpdateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMutation, { data, loading, error }] = useUpdateChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatMutation,
    UpdateChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChatMutation, UpdateChatMutationVariables>(
    UpdateChatDocument,
    options
  );
}
export type UpdateChatMutationHookResult = ReturnType<
  typeof useUpdateChatMutation
>;
export type UpdateChatMutationResult =
  Apollo.MutationResult<UpdateChatMutation>;
export type UpdateChatMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatMutation,
  UpdateChatMutationVariables
>;
export const UpdateChatAdminDocument = gql`
  mutation updateChatAdmin($id: ID!, $userId: ID!) {
    updateChatAdmin(id: $id, userId: $userId) {
      id
      name
      description
      owner {
        id
      }
      avatar {
        ...FileDetails
      }
      settings {
        mute
        pin
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type UpdateChatAdminMutationFn = Apollo.MutationFunction<
  UpdateChatAdminMutation,
  UpdateChatAdminMutationVariables
>;

/**
 * __useUpdateChatAdminMutation__
 *
 * To run a mutation, you first call `useUpdateChatAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatAdminMutation, { data, loading, error }] = useUpdateChatAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateChatAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatAdminMutation,
    UpdateChatAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatAdminMutation,
    UpdateChatAdminMutationVariables
  >(UpdateChatAdminDocument, options);
}
export type UpdateChatAdminMutationHookResult = ReturnType<
  typeof useUpdateChatAdminMutation
>;
export type UpdateChatAdminMutationResult =
  Apollo.MutationResult<UpdateChatAdminMutation>;
export type UpdateChatAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatAdminMutation,
  UpdateChatAdminMutationVariables
>;
export const UpdateDraftMessageDocument = gql`
  mutation updateDraftMessage(
    $chatId: ID!
    $attributes: DraftMessageAttributes!
  ) {
    updateDraftMessage(chatId: $chatId, attributes: $attributes) @client {
      ...DraftMessageDetail
    }
  }
  ${DraftMessageDetailFragmentDoc}
`;
export type UpdateDraftMessageMutationFn = Apollo.MutationFunction<
  UpdateDraftMessageMutation,
  UpdateDraftMessageMutationVariables
>;

/**
 * __useUpdateDraftMessageMutation__
 *
 * To run a mutation, you first call `useUpdateDraftMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftMessageMutation, { data, loading, error }] = useUpdateDraftMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateDraftMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDraftMessageMutation,
    UpdateDraftMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDraftMessageMutation,
    UpdateDraftMessageMutationVariables
  >(UpdateDraftMessageDocument, options);
}
export type UpdateDraftMessageMutationHookResult = ReturnType<
  typeof useUpdateDraftMessageMutation
>;
export type UpdateDraftMessageMutationResult =
  Apollo.MutationResult<UpdateDraftMessageMutation>;
export type UpdateDraftMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateDraftMessageMutation,
  UpdateDraftMessageMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($id: ID!, $attributes: GroupAttributes!) {
    updateGroup(id: $id, attributes: $attributes) {
      id
      name
      team {
        id
      }
      users {
        id
      }
    }
  }
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const UpdateMeDocument = gql`
  mutation updateMe($attributes: UserAttributes!) {
    updateMe(attributes: $attributes) {
      id
      name
      email
      address
      latitude
      longitude
      timeZone
      skills {
        name
      }
      avatar {
        ...FileDetails
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type UpdateMeMutationFn = Apollo.MutationFunction<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeMutation,
    UpdateMeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(
    UpdateMeDocument,
    options
  );
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;
export const UpdateMessageIndividualDocument = gql`
  mutation updateMessageIndividual(
    $id: ID
    $clientId: String
    $attributes: MessageAttributes!
  ) {
    updateMessageIndividual(
      id: $id
      clientId: $clientId
      attributes: $attributes
    ) {
      ...MessageDetail
    }
  }
  ${MessageDetailFragmentDoc}
`;
export type UpdateMessageIndividualMutationFn = Apollo.MutationFunction<
  UpdateMessageIndividualMutation,
  UpdateMessageIndividualMutationVariables
>;

/**
 * __useUpdateMessageIndividualMutation__
 *
 * To run a mutation, you first call `useUpdateMessageIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageIndividualMutation, { data, loading, error }] = useUpdateMessageIndividualMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientId: // value for 'clientId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateMessageIndividualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageIndividualMutation,
    UpdateMessageIndividualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageIndividualMutation,
    UpdateMessageIndividualMutationVariables
  >(UpdateMessageIndividualDocument, options);
}
export type UpdateMessageIndividualMutationHookResult = ReturnType<
  typeof useUpdateMessageIndividualMutation
>;
export type UpdateMessageIndividualMutationResult =
  Apollo.MutationResult<UpdateMessageIndividualMutation>;
export type UpdateMessageIndividualMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageIndividualMutation,
  UpdateMessageIndividualMutationVariables
>;
export const UpdateMessageTagsDocument = gql`
  mutation updateMessageTags($id: ID!, $attributes: MessageTagAttributes!) {
    updateMessageTags(id: $id, attributes: $attributes) {
      ...MessageDetail
    }
  }
  ${MessageDetailFragmentDoc}
`;
export type UpdateMessageTagsMutationFn = Apollo.MutationFunction<
  UpdateMessageTagsMutation,
  UpdateMessageTagsMutationVariables
>;

/**
 * __useUpdateMessageTagsMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTagsMutation, { data, loading, error }] = useUpdateMessageTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateMessageTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageTagsMutation,
    UpdateMessageTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageTagsMutation,
    UpdateMessageTagsMutationVariables
  >(UpdateMessageTagsDocument, options);
}
export type UpdateMessageTagsMutationHookResult = ReturnType<
  typeof useUpdateMessageTagsMutation
>;
export type UpdateMessageTagsMutationResult =
  Apollo.MutationResult<UpdateMessageTagsMutation>;
export type UpdateMessageTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageTagsMutation,
  UpdateMessageTagsMutationVariables
>;
export const UpdateNotificationSettingDocument = gql`
  mutation updateNotificationSetting(
    $attributes: [NotificationSettingAttributes!]!
  ) {
    updateNotificationSetting(attributes: $attributes) {
      settingType
      mute
      duration
    }
  }
`;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingMutation,
  UpdateNotificationSettingMutationVariables
>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >(UpdateNotificationSettingDocument, options);
}
export type UpdateNotificationSettingMutationHookResult = ReturnType<
  typeof useUpdateNotificationSettingMutation
>;
export type UpdateNotificationSettingMutationResult =
  Apollo.MutationResult<UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >;
export const UpdatePaymentMethodDocument = gql`
  mutation updatePaymentMethod($teamId: ID!, $paymentMethodToken: String!) {
    updatePaymentMethod(
      teamId: $teamId
      paymentMethodToken: $paymentMethodToken
    ) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      paymentMethodToken: // value for 'paymentMethodToken'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument, options);
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>;
export type UpdatePaymentMethodMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($id: ID!, $attributes: ProjectAttributes!) {
    updateProject(id: $id, attributes: $attributes) {
      id
      teamId
      initials
      name
      description
      coverImage {
        ...FileDetails
      }
      address
      latitude
      longitude
      color
      icon
      createdAt
      archivedAt
      team {
        id
        name
        avatar
      }
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
      creator {
        ...UserDetail
      }
      tasks {
        id
      }
    }
  }
  ${FileDetailsFragmentDoc}
  ${UserDetailFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateProjectFileTagsDocument = gql`
  mutation updateProjectFileTags($id: ID!, $taskIds: [ID!], $projectId: ID!) {
    updateProjectFileTags(id: $id, taskIds: $taskIds, projectId: $projectId) {
      id
      clientId
    }
  }
`;
export type UpdateProjectFileTagsMutationFn = Apollo.MutationFunction<
  UpdateProjectFileTagsMutation,
  UpdateProjectFileTagsMutationVariables
>;

/**
 * __useUpdateProjectFileTagsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectFileTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectFileTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectFileTagsMutation, { data, loading, error }] = useUpdateProjectFileTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      taskIds: // value for 'taskIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateProjectFileTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectFileTagsMutation,
    UpdateProjectFileTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectFileTagsMutation,
    UpdateProjectFileTagsMutationVariables
  >(UpdateProjectFileTagsDocument, options);
}
export type UpdateProjectFileTagsMutationHookResult = ReturnType<
  typeof useUpdateProjectFileTagsMutation
>;
export type UpdateProjectFileTagsMutationResult =
  Apollo.MutationResult<UpdateProjectFileTagsMutation>;
export type UpdateProjectFileTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectFileTagsMutation,
  UpdateProjectFileTagsMutationVariables
>;
export const UpdateProjectMembersDocument = gql`
  mutation updateProjectMembers(
    $id: ID!
    $attributes: [ProjectMemberAttributes!]!
  ) {
    updateProjectMembers(id: $id, attributes: $attributes) {
      id
      usersCount
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type UpdateProjectMembersMutationFn = Apollo.MutationFunction<
  UpdateProjectMembersMutation,
  UpdateProjectMembersMutationVariables
>;

/**
 * __useUpdateProjectMembersMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMembersMutation, { data, loading, error }] = useUpdateProjectMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateProjectMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMembersMutation,
    UpdateProjectMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMembersMutation,
    UpdateProjectMembersMutationVariables
  >(UpdateProjectMembersDocument, options);
}
export type UpdateProjectMembersMutationHookResult = ReturnType<
  typeof useUpdateProjectMembersMutation
>;
export type UpdateProjectMembersMutationResult =
  Apollo.MutationResult<UpdateProjectMembersMutation>;
export type UpdateProjectMembersMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMembersMutation,
  UpdateProjectMembersMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($id: ID!, $attributes: TaskAttributes!) {
    updateTask(id: $id, attributes: $attributes) {
      ...TaskDetail
    }
  }
  ${TaskDetailFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateTaskChecklistDocument = gql`
  mutation updateTaskChecklist(
    $id: ID!
    $attributes: TaskChecklistAttributes!
  ) {
    updateTaskChecklist(id: $id, attributes: $attributes) {
      taskId
      id
      name
      completedAt
      createdAt
      sort
    }
  }
`;
export type UpdateTaskChecklistMutationFn = Apollo.MutationFunction<
  UpdateTaskChecklistMutation,
  UpdateTaskChecklistMutationVariables
>;

/**
 * __useUpdateTaskChecklistMutation__
 *
 * To run a mutation, you first call `useUpdateTaskChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskChecklistMutation, { data, loading, error }] = useUpdateTaskChecklistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskChecklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskChecklistMutation,
    UpdateTaskChecklistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskChecklistMutation,
    UpdateTaskChecklistMutationVariables
  >(UpdateTaskChecklistDocument, options);
}
export type UpdateTaskChecklistMutationHookResult = ReturnType<
  typeof useUpdateTaskChecklistMutation
>;
export type UpdateTaskChecklistMutationResult =
  Apollo.MutationResult<UpdateTaskChecklistMutation>;
export type UpdateTaskChecklistMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskChecklistMutation,
  UpdateTaskChecklistMutationVariables
>;
export const UpdateTaskChecklistSortDocument = gql`
  mutation updateTaskChecklistSort(
    $taskId: ID!
    $attributes: [TaskChecklistAttributes!]!
  ) {
    updateTaskChecklistSort(taskId: $taskId, attributes: $attributes) {
      taskId
      id
      name
      completedAt
      createdAt
      sort
    }
  }
`;
export type UpdateTaskChecklistSortMutationFn = Apollo.MutationFunction<
  UpdateTaskChecklistSortMutation,
  UpdateTaskChecklistSortMutationVariables
>;

/**
 * __useUpdateTaskChecklistSortMutation__
 *
 * To run a mutation, you first call `useUpdateTaskChecklistSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskChecklistSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskChecklistSortMutation, { data, loading, error }] = useUpdateTaskChecklistSortMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskChecklistSortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskChecklistSortMutation,
    UpdateTaskChecklistSortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskChecklistSortMutation,
    UpdateTaskChecklistSortMutationVariables
  >(UpdateTaskChecklistSortDocument, options);
}
export type UpdateTaskChecklistSortMutationHookResult = ReturnType<
  typeof useUpdateTaskChecklistSortMutation
>;
export type UpdateTaskChecklistSortMutationResult =
  Apollo.MutationResult<UpdateTaskChecklistSortMutation>;
export type UpdateTaskChecklistSortMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskChecklistSortMutation,
  UpdateTaskChecklistSortMutationVariables
>;
export const UpdateTaskMembersDocument = gql`
  mutation updateTaskMembers($id: ID!, $attributes: [TaskMemberAttributes!]!) {
    updateTaskMembers(id: $id, attributes: $attributes) {
      id
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;
export type UpdateTaskMembersMutationFn = Apollo.MutationFunction<
  UpdateTaskMembersMutation,
  UpdateTaskMembersMutationVariables
>;

/**
 * __useUpdateTaskMembersMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMembersMutation, { data, loading, error }] = useUpdateTaskMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMembersMutation,
    UpdateTaskMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskMembersMutation,
    UpdateTaskMembersMutationVariables
  >(UpdateTaskMembersDocument, options);
}
export type UpdateTaskMembersMutationHookResult = ReturnType<
  typeof useUpdateTaskMembersMutation
>;
export type UpdateTaskMembersMutationResult =
  Apollo.MutationResult<UpdateTaskMembersMutation>;
export type UpdateTaskMembersMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMembersMutation,
  UpdateTaskMembersMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($id: ID!, $attributes: TeamAttributes!) {
    updateTeam(id: $id, attributes: $attributes) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
  Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const UpdateTeamMembersDocument = gql`
  mutation updateTeamMembers($teamId: ID!, $attributes: [MemberAttributes!]!) {
    updateTeamMembers(teamId: $teamId, attributes: $attributes) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;
export type UpdateTeamMembersMutationFn = Apollo.MutationFunction<
  UpdateTeamMembersMutation,
  UpdateTeamMembersMutationVariables
>;

/**
 * __useUpdateTeamMembersMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMembersMutation, { data, loading, error }] = useUpdateTeamMembersMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTeamMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMembersMutation,
    UpdateTeamMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTeamMembersMutation,
    UpdateTeamMembersMutationVariables
  >(UpdateTeamMembersDocument, options);
}
export type UpdateTeamMembersMutationHookResult = ReturnType<
  typeof useUpdateTeamMembersMutation
>;
export type UpdateTeamMembersMutationResult =
  Apollo.MutationResult<UpdateTeamMembersMutation>;
export type UpdateTeamMembersMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMembersMutation,
  UpdateTeamMembersMutationVariables
>;
export const UpdateTutoralizationSettingDocument = gql`
  mutation updateTutoralizationSetting(
    $attributes: [TutoralizationSettingAttributes!]!
  ) {
    updateTutoralizationSetting(attributes: $attributes) {
      close
      settingType
    }
  }
`;
export type UpdateTutoralizationSettingMutationFn = Apollo.MutationFunction<
  UpdateTutoralizationSettingMutation,
  UpdateTutoralizationSettingMutationVariables
>;

/**
 * __useUpdateTutoralizationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateTutoralizationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTutoralizationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTutoralizationSettingMutation, { data, loading, error }] = useUpdateTutoralizationSettingMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTutoralizationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTutoralizationSettingMutation,
    UpdateTutoralizationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTutoralizationSettingMutation,
    UpdateTutoralizationSettingMutationVariables
  >(UpdateTutoralizationSettingDocument, options);
}
export type UpdateTutoralizationSettingMutationHookResult = ReturnType<
  typeof useUpdateTutoralizationSettingMutation
>;
export type UpdateTutoralizationSettingMutationResult =
  Apollo.MutationResult<UpdateTutoralizationSettingMutation>;
export type UpdateTutoralizationSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTutoralizationSettingMutation,
    UpdateTutoralizationSettingMutationVariables
  >;
export const UploadContactsDocument = gql`
  mutation uploadContacts($attributes: UploadContactAttributes!) {
    uploadContacts(attributes: $attributes) {
      phoneNumber
    }
  }
`;
export type UploadContactsMutationFn = Apollo.MutationFunction<
  UploadContactsMutation,
  UploadContactsMutationVariables
>;

/**
 * __useUploadContactsMutation__
 *
 * To run a mutation, you first call `useUploadContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContactsMutation, { data, loading, error }] = useUploadContactsMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUploadContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadContactsMutation,
    UploadContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadContactsMutation,
    UploadContactsMutationVariables
  >(UploadContactsDocument, options);
}
export type UploadContactsMutationHookResult = ReturnType<
  typeof useUploadContactsMutation
>;
export type UploadContactsMutationResult =
  Apollo.MutationResult<UploadContactsMutation>;
export type UploadContactsMutationOptions = Apollo.BaseMutationOptions<
  UploadContactsMutation,
  UploadContactsMutationVariables
>;
export const DocumentsByClientIdsDocument = gql`
  query documentsByClientIds($clientIds: [ID!]) {
    documentsByClientIds(clientIds: $clientIds) {
      clientId
      file {
        id
        url
      }
    }
  }
`;

/**
 * __useDocumentsByClientIdsQuery__
 *
 * To run a query within a React component, call `useDocumentsByClientIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsByClientIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsByClientIdsQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *   },
 * });
 */
export function useDocumentsByClientIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DocumentsByClientIdsQuery,
    DocumentsByClientIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DocumentsByClientIdsQuery,
    DocumentsByClientIdsQueryVariables
  >(DocumentsByClientIdsDocument, options);
}
export function useDocumentsByClientIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentsByClientIdsQuery,
    DocumentsByClientIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DocumentsByClientIdsQuery,
    DocumentsByClientIdsQueryVariables
  >(DocumentsByClientIdsDocument, options);
}
export type DocumentsByClientIdsQueryHookResult = ReturnType<
  typeof useDocumentsByClientIdsQuery
>;
export type DocumentsByClientIdsLazyQueryHookResult = ReturnType<
  typeof useDocumentsByClientIdsLazyQuery
>;
export type DocumentsByClientIdsQueryResult = Apollo.QueryResult<
  DocumentsByClientIdsQuery,
  DocumentsByClientIdsQueryVariables
>;
export const GetChatDocument = gql`
  query getChat($id: ID!) {
    getChat(id: $id) {
      ...GetChatDetail
    }
  }
  ${GetChatDetailFragmentDoc}
`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(
    GetChatDocument,
    options
  );
}
export function useGetChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(
    GetChatDocument,
    options
  );
}
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatQueryResult = Apollo.QueryResult<
  GetChatQuery,
  GetChatQueryVariables
>;
export const GetDraftMessageDocument = gql`
  query getDraftMessage($chatId: ID!) {
    getDraftMessage(chatId: $chatId) {
      ...DraftMessageDetail
    }
  }
  ${DraftMessageDetailFragmentDoc}
`;

/**
 * __useGetDraftMessageQuery__
 *
 * To run a query within a React component, call `useGetDraftMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftMessageQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useGetDraftMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftMessageQuery,
    GetDraftMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftMessageQuery, GetDraftMessageQueryVariables>(
    GetDraftMessageDocument,
    options
  );
}
export function useGetDraftMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftMessageQuery,
    GetDraftMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftMessageQuery,
    GetDraftMessageQueryVariables
  >(GetDraftMessageDocument, options);
}
export type GetDraftMessageQueryHookResult = ReturnType<
  typeof useGetDraftMessageQuery
>;
export type GetDraftMessageLazyQueryHookResult = ReturnType<
  typeof useGetDraftMessageLazyQuery
>;
export type GetDraftMessageQueryResult = Apollo.QueryResult<
  GetDraftMessageQuery,
  GetDraftMessageQueryVariables
>;
export const GetGroupDocument = gql`
  query getGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      currentUserTeamRole
      team {
        id
        avatar
        name
        personal
      }
      users {
        id
        name
        firstName
        avatar {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
      }
    }
  }
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const GetMeDocument = gql`
  query getMe {
    getMe {
      ...UserDetail
      inviteUrl
      contactsCount
      contactsSyncedAt
      address
      latitude
      longitude
      notificationSetting {
        duration
        mute
        settingType
      }
      timeZone
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
      skills {
        id
        name
      }
      tutoralizationSetting {
        settingType
        close
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetProductDocument = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      allowMembers
      externalId
      guestsOnly
      id
      maxDocumentStorage
      maxNumberOfProjects
      name
    }
  }
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const GetProfileDocument = gql`
  query getProfile($id: ID!) {
    getProfile(id: $id) {
      ...UserProfile
    }
  }
  ${UserProfileFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject($id: ID!) {
    getProject(id: $id) {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
export const GetProjectMembersDocument = gql`
  query getProjectMembers($id: ID!) {
    getProject(id: $id) {
      id
      teamId
      name
      address
      description
      color
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useGetProjectMembersQuery__
 *
 * To run a query within a React component, call `useGetProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectMembersQuery,
    GetProjectMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectMembersQuery,
    GetProjectMembersQueryVariables
  >(GetProjectMembersDocument, options);
}
export function useGetProjectMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectMembersQuery,
    GetProjectMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectMembersQuery,
    GetProjectMembersQueryVariables
  >(GetProjectMembersDocument, options);
}
export type GetProjectMembersQueryHookResult = ReturnType<
  typeof useGetProjectMembersQuery
>;
export type GetProjectMembersLazyQueryHookResult = ReturnType<
  typeof useGetProjectMembersLazyQuery
>;
export type GetProjectMembersQueryResult = Apollo.QueryResult<
  GetProjectMembersQuery,
  GetProjectMembersQueryVariables
>;
export const GetProjectTasksDocument = gql`
  query getProjectTasks($id: ID!) {
    getProject(id: $id) {
      id
      name
      tasks {
        id
        name
      }
    }
  }
`;

/**
 * __useGetProjectTasksQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTasksQuery,
    GetProjectTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectTasksQuery, GetProjectTasksQueryVariables>(
    GetProjectTasksDocument,
    options
  );
}
export function useGetProjectTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTasksQuery,
    GetProjectTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTasksQuery,
    GetProjectTasksQueryVariables
  >(GetProjectTasksDocument, options);
}
export type GetProjectTasksQueryHookResult = ReturnType<
  typeof useGetProjectTasksQuery
>;
export type GetProjectTasksLazyQueryHookResult = ReturnType<
  typeof useGetProjectTasksLazyQuery
>;
export type GetProjectTasksQueryResult = Apollo.QueryResult<
  GetProjectTasksQuery,
  GetProjectTasksQueryVariables
>;
export const GetTaskDocument = gql`
  query getTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      status
      priority
      startDate
      dueDate
      completedAt
      createdAt
      phaseId
      projectId
      deletedAt
      skillIds
      userIds
      dueGroup
      dependencies {
        id
        name
      }
      fixedOrder
      recurring
      description
      frequency
      currentUserRole
      currentUserProjectRole
      currentUserProjectTeamRole
      auditsCount
      checklists {
        id
        name
        completedAt
        createdAt
      }
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
      creator {
        ...UserDetail
      }
      completor {
        ...UserDetail
      }
      project {
        id
        name
        color
        currentUserTeamRole
        currentUserRole
        team {
          avatar
          createdAt
          id
          name
          personal
        }
        initials
        archivedAt
      }
      unreadAudits {
        id
        event
      }
      phase {
        id
        name
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  );
}
export function useGetTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  );
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<
  GetTaskQuery,
  GetTaskQueryVariables
>;
export const GetTaskMembersDocument = gql`
  query getTaskMembers($id: ID!) {
    getTask(id: $id) {
      id
      name
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useGetTaskMembersQuery__
 *
 * To run a query within a React component, call `useGetTaskMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskMembersQuery,
    GetTaskMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskMembersQuery, GetTaskMembersQueryVariables>(
    GetTaskMembersDocument,
    options
  );
}
export function useGetTaskMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskMembersQuery,
    GetTaskMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskMembersQuery, GetTaskMembersQueryVariables>(
    GetTaskMembersDocument,
    options
  );
}
export type GetTaskMembersQueryHookResult = ReturnType<
  typeof useGetTaskMembersQuery
>;
export type GetTaskMembersLazyQueryHookResult = ReturnType<
  typeof useGetTaskMembersLazyQuery
>;
export type GetTaskMembersQueryResult = Apollo.QueryResult<
  GetTaskMembersQuery,
  GetTaskMembersQueryVariables
>;
export const GetTeamDocument = gql`
  query getTeam($id: ID!, $includePending: Boolean) {
    getTeam(id: $id, includePending: $includePending) {
      ...TeamDetail
    }
  }
  ${TeamDetailFragmentDoc}
`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includePending: // value for 'includePending'
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options
  );
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options
  );
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<
  GetTeamQuery,
  GetTeamQueryVariables
>;
export const ListActivitiesByProjectDocument = gql`
  query listActivitiesByProject(
    $projectId: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listActivitiesByProject(
      projectId: $projectId
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          event
          createdAt
          id
          user {
            ...UserDetail
          }
          projectRole
          attachment {
            ... on Document {
              ...DocumentDetail
            }
            ... on Message {
              ...MessageDetail
            }
            ... on Task {
              id
              name
              projectId
              deletedAt
              project {
                id
                name
              }
            }
            ... on Project {
              id
              name
            }
            ... on TaskUser {
              id
              task {
                id
                name
                project {
                  id
                  name
                }
              }
              user {
                ...UserDetail
              }
            }
            ... on EntityUser {
              id
              user {
                ...UserDetail
              }
              entityTask: task {
                id
                name
                deletedAt
                members {
                  id
                  role
                  user {
                    ...UserDetail
                  }
                }
                project {
                  id
                  name
                }
              }
              entityProject: project {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
  ${DocumentDetailFragmentDoc}
  ${MessageDetailFragmentDoc}
`;

/**
 * __useListActivitiesByProjectQuery__
 *
 * To run a query within a React component, call `useListActivitiesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivitiesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivitiesByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListActivitiesByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListActivitiesByProjectQuery,
    ListActivitiesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListActivitiesByProjectQuery,
    ListActivitiesByProjectQueryVariables
  >(ListActivitiesByProjectDocument, options);
}
export function useListActivitiesByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListActivitiesByProjectQuery,
    ListActivitiesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListActivitiesByProjectQuery,
    ListActivitiesByProjectQueryVariables
  >(ListActivitiesByProjectDocument, options);
}
export type ListActivitiesByProjectQueryHookResult = ReturnType<
  typeof useListActivitiesByProjectQuery
>;
export type ListActivitiesByProjectLazyQueryHookResult = ReturnType<
  typeof useListActivitiesByProjectLazyQuery
>;
export type ListActivitiesByProjectQueryResult = Apollo.QueryResult<
  ListActivitiesByProjectQuery,
  ListActivitiesByProjectQueryVariables
>;
export const ListActivitiesByTaskDocument = gql`
  query listActivitiesByTask(
    $taskId: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listActivitiesByTask(
      taskId: $taskId
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          event
          createdAt
          id
          user {
            ...UserDetail
          }
          attachment {
            ... on Document {
              ...DocumentDetail
            }
            ... on Message {
              ...MessageDetail
            }
            ... on Task {
              id
              name
              projectId
              deletedAt
              project {
                id
                name
              }
              completedAt
              completor {
                avatar {
                  ...FileDetails
                }
              }
            }
            ... on Project {
              id
              name
            }
            ... on TaskUser {
              id
              task {
                id
                name
                project {
                  id
                  name
                }
              }
              user {
                ...UserDetail
              }
            }
            ... on EntityUser {
              id
              user {
                ...UserDetail
              }
              entityTask: task {
                id
                name
                deletedAt
                members {
                  id
                  role
                  user {
                    ...UserDetail
                  }
                }
                project {
                  id
                  name
                }
              }
              entityProject: project {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
  ${DocumentDetailFragmentDoc}
  ${MessageDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
`;

/**
 * __useListActivitiesByTaskQuery__
 *
 * To run a query within a React component, call `useListActivitiesByTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivitiesByTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivitiesByTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListActivitiesByTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListActivitiesByTaskQuery,
    ListActivitiesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListActivitiesByTaskQuery,
    ListActivitiesByTaskQueryVariables
  >(ListActivitiesByTaskDocument, options);
}
export function useListActivitiesByTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListActivitiesByTaskQuery,
    ListActivitiesByTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListActivitiesByTaskQuery,
    ListActivitiesByTaskQueryVariables
  >(ListActivitiesByTaskDocument, options);
}
export type ListActivitiesByTaskQueryHookResult = ReturnType<
  typeof useListActivitiesByTaskQuery
>;
export type ListActivitiesByTaskLazyQueryHookResult = ReturnType<
  typeof useListActivitiesByTaskLazyQuery
>;
export type ListActivitiesByTaskQueryResult = Apollo.QueryResult<
  ListActivitiesByTaskQuery,
  ListActivitiesByTaskQueryVariables
>;
export const ListBlockedUsersDocument = gql`
  query listBlockedUsers {
    listBlockedUsers {
      ...UserDetail
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListBlockedUsersQuery__
 *
 * To run a query within a React component, call `useListBlockedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlockedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlockedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBlockedUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListBlockedUsersQuery,
    ListBlockedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListBlockedUsersQuery, ListBlockedUsersQueryVariables>(
    ListBlockedUsersDocument,
    options
  );
}
export function useListBlockedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBlockedUsersQuery,
    ListBlockedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListBlockedUsersQuery,
    ListBlockedUsersQueryVariables
  >(ListBlockedUsersDocument, options);
}
export type ListBlockedUsersQueryHookResult = ReturnType<
  typeof useListBlockedUsersQuery
>;
export type ListBlockedUsersLazyQueryHookResult = ReturnType<
  typeof useListBlockedUsersLazyQuery
>;
export type ListBlockedUsersQueryResult = Apollo.QueryResult<
  ListBlockedUsersQuery,
  ListBlockedUsersQueryVariables
>;
export const ListChatIdentifiersDocument = gql`
  query listChatIdentifiers {
    listChats {
      id
      name
      ...ChatDetail
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
  }
  ${ChatDetailFragmentDoc}
`;

/**
 * __useListChatIdentifiersQuery__
 *
 * To run a query within a React component, call `useListChatIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatIdentifiersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListChatIdentifiersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListChatIdentifiersQuery,
    ListChatIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChatIdentifiersQuery,
    ListChatIdentifiersQueryVariables
  >(ListChatIdentifiersDocument, options);
}
export function useListChatIdentifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChatIdentifiersQuery,
    ListChatIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChatIdentifiersQuery,
    ListChatIdentifiersQueryVariables
  >(ListChatIdentifiersDocument, options);
}
export type ListChatIdentifiersQueryHookResult = ReturnType<
  typeof useListChatIdentifiersQuery
>;
export type ListChatIdentifiersLazyQueryHookResult = ReturnType<
  typeof useListChatIdentifiersLazyQuery
>;
export type ListChatIdentifiersQueryResult = Apollo.QueryResult<
  ListChatIdentifiersQuery,
  ListChatIdentifiersQueryVariables
>;
export const ListChatRelatedProjectsDocument = gql`
  query listChatRelatedProjects($chatId: ID, $first: Int, $after: String) {
    listChatRelatedProjects(chatId: $chatId, first: $first, after: $after) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          currentUserRole
          archivedAt
          tasks {
            id
            name
            status
            priority
            deletedAt
            dueDate
            completedAt
            projectId
            userIds
            currentUserRole
            project {
              id
              name
            }
            members {
              id
              role
              user {
                ...UserDetail
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
`;

/**
 * __useListChatRelatedProjectsQuery__
 *
 * To run a query within a React component, call `useListChatRelatedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatRelatedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatRelatedProjectsQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListChatRelatedProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListChatRelatedProjectsQuery,
    ListChatRelatedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChatRelatedProjectsQuery,
    ListChatRelatedProjectsQueryVariables
  >(ListChatRelatedProjectsDocument, options);
}
export function useListChatRelatedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChatRelatedProjectsQuery,
    ListChatRelatedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChatRelatedProjectsQuery,
    ListChatRelatedProjectsQueryVariables
  >(ListChatRelatedProjectsDocument, options);
}
export type ListChatRelatedProjectsQueryHookResult = ReturnType<
  typeof useListChatRelatedProjectsQuery
>;
export type ListChatRelatedProjectsLazyQueryHookResult = ReturnType<
  typeof useListChatRelatedProjectsLazyQuery
>;
export type ListChatRelatedProjectsQueryResult = Apollo.QueryResult<
  ListChatRelatedProjectsQuery,
  ListChatRelatedProjectsQueryVariables
>;
export const ListChatsDocument = gql`
  query listChats($archivesOnly: Boolean) {
    listChats(archivesOnly: $archivesOnly) {
      id
      name
      description
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
      isGroupChat
      messageIcon
      messageBody
      recentActivityAt
      friendRequestAccepted
      unreadCount
      createdAt
      leaveOn
      users {
        id
      }
      settings {
        id
        pin
        mute
        archivedAt
      }
      owner {
        id
        name
      }
    }
  }
`;

/**
 * __useListChatsQuery__
 *
 * To run a query within a React component, call `useListChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatsQuery({
 *   variables: {
 *      archivesOnly: // value for 'archivesOnly'
 *   },
 * });
 */
export function useListChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListChatsQuery, ListChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChatsQuery, ListChatsQueryVariables>(
    ListChatsDocument,
    options
  );
}
export function useListChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChatsQuery,
    ListChatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChatsQuery, ListChatsQueryVariables>(
    ListChatsDocument,
    options
  );
}
export type ListChatsQueryHookResult = ReturnType<typeof useListChatsQuery>;
export type ListChatsLazyQueryHookResult = ReturnType<
  typeof useListChatsLazyQuery
>;
export type ListChatsQueryResult = Apollo.QueryResult<
  ListChatsQuery,
  ListChatsQueryVariables
>;
export const ListChatsPaginationDocument = gql`
  query listChatsPagination(
    $archivesOnly: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listChatsPagination(
      archivesOnly: $archivesOnly
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          description
          avatar {
            ...FileDetails
          }
          isGroupChat
          messageIcon
          messageBody
          recentActivityAt
          friendRequestAccepted
          unreadCount
          createdAt
          leaveOn
          users {
            id
          }
          settings {
            id
            pin
            mute
            archivedAt
          }
          owner {
            id
            name
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${FileDetailsFragmentDoc}
`;

/**
 * __useListChatsPaginationQuery__
 *
 * To run a query within a React component, call `useListChatsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatsPaginationQuery({
 *   variables: {
 *      archivesOnly: // value for 'archivesOnly'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListChatsPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListChatsPaginationQuery,
    ListChatsPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChatsPaginationQuery,
    ListChatsPaginationQueryVariables
  >(ListChatsPaginationDocument, options);
}
export function useListChatsPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChatsPaginationQuery,
    ListChatsPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChatsPaginationQuery,
    ListChatsPaginationQueryVariables
  >(ListChatsPaginationDocument, options);
}
export type ListChatsPaginationQueryHookResult = ReturnType<
  typeof useListChatsPaginationQuery
>;
export type ListChatsPaginationLazyQueryHookResult = ReturnType<
  typeof useListChatsPaginationLazyQuery
>;
export type ListChatsPaginationQueryResult = Apollo.QueryResult<
  ListChatsPaginationQuery,
  ListChatsPaginationQueryVariables
>;
export const ListContactUsersDocument = gql`
  query listContactUsers {
    listContactUsers {
      ...UserDetail
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListContactUsersQuery__
 *
 * To run a query within a React component, call `useListContactUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContactUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContactUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListContactUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListContactUsersQuery,
    ListContactUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListContactUsersQuery, ListContactUsersQueryVariables>(
    ListContactUsersDocument,
    options
  );
}
export function useListContactUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListContactUsersQuery,
    ListContactUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListContactUsersQuery,
    ListContactUsersQueryVariables
  >(ListContactUsersDocument, options);
}
export type ListContactUsersQueryHookResult = ReturnType<
  typeof useListContactUsersQuery
>;
export type ListContactUsersLazyQueryHookResult = ReturnType<
  typeof useListContactUsersLazyQuery
>;
export type ListContactUsersQueryResult = Apollo.QueryResult<
  ListContactUsersQuery,
  ListContactUsersQueryVariables
>;
export const ListDraftDocumentsDocument = gql`
  query ListDraftDocuments($chatId: ID!) {
    listDraftDocuments(chatId: $chatId) {
      ...DocumentDetail
      messageClientId
      messageCreateYn
    }
  }
  ${DocumentDetailFragmentDoc}
`;

/**
 * __useListDraftDocumentsQuery__
 *
 * To run a query within a React component, call `useListDraftDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDraftDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDraftDocumentsQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useListDraftDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDraftDocumentsQuery,
    ListDraftDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListDraftDocumentsQuery,
    ListDraftDocumentsQueryVariables
  >(ListDraftDocumentsDocument, options);
}
export function useListDraftDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDraftDocumentsQuery,
    ListDraftDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListDraftDocumentsQuery,
    ListDraftDocumentsQueryVariables
  >(ListDraftDocumentsDocument, options);
}
export type ListDraftDocumentsQueryHookResult = ReturnType<
  typeof useListDraftDocumentsQuery
>;
export type ListDraftDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDraftDocumentsLazyQuery
>;
export type ListDraftDocumentsQueryResult = Apollo.QueryResult<
  ListDraftDocumentsQuery,
  ListDraftDocumentsQueryVariables
>;
export const ListDraftMessagesDocument = gql`
  query listDraftMessages {
    listDraftMessages @client {
      id
      chatId
      clientId
      body
      createdAt
      isSender
      isDraft
      publishedAt
      failedAt @client
      isRetry @client
      authorId
      author {
        ...UserDetail
        avatar {
          ...FileDetails
        }
      }
      chat {
        ...ChatDetail
      }
      checkin {
        ...checkinDetail
      }
      replyId
      replyMessage {
        id
        chatId
        clientId
        body
        createdAt
        isSender
        isDraft
        publishedAt
        author {
          id
          name
          firstName
          lastName
          createdAt
        }
      }
      attachments {
        ... on MessageTask {
          author {
            id
            name
          }
          task {
            id
            name
            projectId
          }
        }
        ... on MessageProject {
          author {
            id
            name
          }
          project {
            id
            name
          }
        }
        ... on Document {
          id
          clientId
          name
          contentType
          isImage
          size
          isAudio
          duration
          file {
            id
            url
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
  ${ChatDetailFragmentDoc}
  ${CheckinDetailFragmentDoc}
`;

/**
 * __useListDraftMessagesQuery__
 *
 * To run a query within a React component, call `useListDraftMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDraftMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDraftMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListDraftMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDraftMessagesQuery,
    ListDraftMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListDraftMessagesQuery,
    ListDraftMessagesQueryVariables
  >(ListDraftMessagesDocument, options);
}
export function useListDraftMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDraftMessagesQuery,
    ListDraftMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListDraftMessagesQuery,
    ListDraftMessagesQueryVariables
  >(ListDraftMessagesDocument, options);
}
export type ListDraftMessagesQueryHookResult = ReturnType<
  typeof useListDraftMessagesQuery
>;
export type ListDraftMessagesLazyQueryHookResult = ReturnType<
  typeof useListDraftMessagesLazyQuery
>;
export type ListDraftMessagesQueryResult = Apollo.QueryResult<
  ListDraftMessagesQuery,
  ListDraftMessagesQueryVariables
>;
export const ListDraftProjectDocumentsDocument = gql`
  query listDraftProjectDocuments($projectId: ID!) {
    listDraftProjectDocuments(projectId: $projectId) {
      ...LocalDocumentDetail
    }
  }
  ${LocalDocumentDetailFragmentDoc}
`;

/**
 * __useListDraftProjectDocumentsQuery__
 *
 * To run a query within a React component, call `useListDraftProjectDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDraftProjectDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDraftProjectDocumentsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListDraftProjectDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDraftProjectDocumentsQuery,
    ListDraftProjectDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListDraftProjectDocumentsQuery,
    ListDraftProjectDocumentsQueryVariables
  >(ListDraftProjectDocumentsDocument, options);
}
export function useListDraftProjectDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDraftProjectDocumentsQuery,
    ListDraftProjectDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListDraftProjectDocumentsQuery,
    ListDraftProjectDocumentsQueryVariables
  >(ListDraftProjectDocumentsDocument, options);
}
export type ListDraftProjectDocumentsQueryHookResult = ReturnType<
  typeof useListDraftProjectDocumentsQuery
>;
export type ListDraftProjectDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDraftProjectDocumentsLazyQuery
>;
export type ListDraftProjectDocumentsQueryResult = Apollo.QueryResult<
  ListDraftProjectDocumentsQuery,
  ListDraftProjectDocumentsQueryVariables
>;
export const ListDraftTaskDocumentsDocument = gql`
  query listDraftTaskDocuments($taskId: ID!) {
    listDraftTaskDocuments(taskId: $taskId) {
      ...LocalDocumentDetail
    }
  }
  ${LocalDocumentDetailFragmentDoc}
`;

/**
 * __useListDraftTaskDocumentsQuery__
 *
 * To run a query within a React component, call `useListDraftTaskDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDraftTaskDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDraftTaskDocumentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useListDraftTaskDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDraftTaskDocumentsQuery,
    ListDraftTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListDraftTaskDocumentsQuery,
    ListDraftTaskDocumentsQueryVariables
  >(ListDraftTaskDocumentsDocument, options);
}
export function useListDraftTaskDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDraftTaskDocumentsQuery,
    ListDraftTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListDraftTaskDocumentsQuery,
    ListDraftTaskDocumentsQueryVariables
  >(ListDraftTaskDocumentsDocument, options);
}
export type ListDraftTaskDocumentsQueryHookResult = ReturnType<
  typeof useListDraftTaskDocumentsQuery
>;
export type ListDraftTaskDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDraftTaskDocumentsLazyQuery
>;
export type ListDraftTaskDocumentsQueryResult = Apollo.QueryResult<
  ListDraftTaskDocumentsQuery,
  ListDraftTaskDocumentsQueryVariables
>;
export const ListFeatureFlagsDocument = gql`
  query listFeatureFlags {
    listFeatureFlags {
      name
      enabled
    }
  }
`;

/**
 * __useListFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useListFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFeatureFlagsQuery,
    ListFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>(
    ListFeatureFlagsDocument,
    options
  );
}
export function useListFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFeatureFlagsQuery,
    ListFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListFeatureFlagsQuery,
    ListFeatureFlagsQueryVariables
  >(ListFeatureFlagsDocument, options);
}
export type ListFeatureFlagsQueryHookResult = ReturnType<
  typeof useListFeatureFlagsQuery
>;
export type ListFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useListFeatureFlagsLazyQuery
>;
export type ListFeatureFlagsQueryResult = Apollo.QueryResult<
  ListFeatureFlagsQuery,
  ListFeatureFlagsQueryVariables
>;
export const ListGroupsDocument = gql`
  query listGroups {
    listGroups {
      id
      name
      currentUserTeamRole
      team {
        id
        avatar
        name
        personal
      }
      users {
        id
        name
        avatar {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
      }
    }
  }
`;

/**
 * __useListGroupsQuery__
 *
 * To run a query within a React component, call `useListGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListGroupsQuery,
    ListGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListGroupsQuery, ListGroupsQueryVariables>(
    ListGroupsDocument,
    options
  );
}
export function useListGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGroupsQuery,
    ListGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListGroupsQuery, ListGroupsQueryVariables>(
    ListGroupsDocument,
    options
  );
}
export type ListGroupsQueryHookResult = ReturnType<typeof useListGroupsQuery>;
export type ListGroupsLazyQueryHookResult = ReturnType<
  typeof useListGroupsLazyQuery
>;
export type ListGroupsQueryResult = Apollo.QueryResult<
  ListGroupsQuery,
  ListGroupsQueryVariables
>;
export const ListMessagesDocument = gql`
  query listMessages(
    $chatId: ID!
    $includeDraft: Boolean
    $includePublished: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listMessages(
      chatId: $chatId
      includeDraft: $includeDraft
      includePublished: $includePublished
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...MessageDetailForQuery
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${MessageDetailForQueryFragmentDoc}
`;

/**
 * __useListMessagesQuery__
 *
 * To run a query within a React component, call `useListMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      includeDraft: // value for 'includeDraft'
 *      includePublished: // value for 'includePublished'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListMessagesQuery,
    ListMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMessagesQuery, ListMessagesQueryVariables>(
    ListMessagesDocument,
    options
  );
}
export function useListMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMessagesQuery,
    ListMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMessagesQuery, ListMessagesQueryVariables>(
    ListMessagesDocument,
    options
  );
}
export type ListMessagesQueryHookResult = ReturnType<
  typeof useListMessagesQuery
>;
export type ListMessagesLazyQueryHookResult = ReturnType<
  typeof useListMessagesLazyQuery
>;
export type ListMessagesQueryResult = Apollo.QueryResult<
  ListMessagesQuery,
  ListMessagesQueryVariables
>;
export const ListProductsDocument = gql`
  query listProducts {
    listProducts {
      id
      name
      allowMembers
      features
      prices {
        id
        amount
        externalId
        interval
        intervalCount
        externalId
      }
    }
  }
`;

/**
 * __useListProductsQuery__
 *
 * To run a query within a React component, call `useListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProductsQuery,
    ListProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProductsQuery, ListProductsQueryVariables>(
    ListProductsDocument,
    options
  );
}
export function useListProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProductsQuery,
    ListProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProductsQuery, ListProductsQueryVariables>(
    ListProductsDocument,
    options
  );
}
export type ListProductsQueryHookResult = ReturnType<
  typeof useListProductsQuery
>;
export type ListProductsLazyQueryHookResult = ReturnType<
  typeof useListProductsLazyQuery
>;
export type ListProductsQueryResult = Apollo.QueryResult<
  ListProductsQuery,
  ListProductsQueryVariables
>;
export const ListProjectDocumentsDocument = gql`
  query listProjectDocuments(
    $projectIds: [ID!]
    $ownerIds: [ID!]
    $sortBy: DocumentSortOption
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeFiles: Boolean
    $includeMedia: Boolean
  ) {
    listProjectDocuments(
      projectIds: $projectIds
      ownerIds: $ownerIds
      sortBy: $sortBy
      first: $first
      last: $last
      after: $after
      before: $before
      includeFiles: $includeFiles
      includeMedia: $includeMedia
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          contentType
          createdAt
          size
          isImage
          isVideo
          clientId
          owner {
            id
            name
          }
          file {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
          messageDocuments {
            id
            message {
              id
              clientId
              chatId
              createdAt
              isSender
              isDraft
              publishedAt
              authorId
            }
          }
          projectDocuments {
            id
            project {
              id
              name
            }
            owner {
              id
              name
            }
          }
          taskDocuments {
            id
            task {
              id
              name
              projectId
              project {
                id
                name
              }
            }
            owner {
              id
              name
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useListProjectDocumentsQuery__
 *
 * To run a query within a React component, call `useListProjectDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectDocumentsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      ownerIds: // value for 'ownerIds'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      includeFiles: // value for 'includeFiles'
 *      includeMedia: // value for 'includeMedia'
 *   },
 * });
 */
export function useListProjectDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectDocumentsQuery,
    ListProjectDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectDocumentsQuery,
    ListProjectDocumentsQueryVariables
  >(ListProjectDocumentsDocument, options);
}
export function useListProjectDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectDocumentsQuery,
    ListProjectDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectDocumentsQuery,
    ListProjectDocumentsQueryVariables
  >(ListProjectDocumentsDocument, options);
}
export type ListProjectDocumentsQueryHookResult = ReturnType<
  typeof useListProjectDocumentsQuery
>;
export type ListProjectDocumentsLazyQueryHookResult = ReturnType<
  typeof useListProjectDocumentsLazyQuery
>;
export type ListProjectDocumentsQueryResult = Apollo.QueryResult<
  ListProjectDocumentsQuery,
  ListProjectDocumentsQueryVariables
>;
export const ListProjectTagsDocument = gql`
  query listProjectTags(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listProjects(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          usersCount
          tasksCount
          chatProjectsCount
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useListProjectTagsQuery__
 *
 * To run a query within a React component, call `useListProjectTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectTagsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListProjectTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectTagsQuery,
    ListProjectTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProjectTagsQuery, ListProjectTagsQueryVariables>(
    ListProjectTagsDocument,
    options
  );
}
export function useListProjectTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectTagsQuery,
    ListProjectTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectTagsQuery,
    ListProjectTagsQueryVariables
  >(ListProjectTagsDocument, options);
}
export type ListProjectTagsQueryHookResult = ReturnType<
  typeof useListProjectTagsQuery
>;
export type ListProjectTagsLazyQueryHookResult = ReturnType<
  typeof useListProjectTagsLazyQuery
>;
export type ListProjectTagsQueryResult = Apollo.QueryResult<
  ListProjectTagsQuery,
  ListProjectTagsQueryVariables
>;
export const ListProjectsDocument = gql`
  query listProjects(
    $userId: ID
    $sortBy: ProjectSortOption
    $role: ProjectMemberRole
    $archivesOnly: Boolean
    $locationOnly: Boolean
    $filterTeamsProjects: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
    $ownerIds: [ID!]
    $memberIds: [ID!]
    $teamIds: [ID!]
  ) {
    listProjects(
      userId: $userId
      sortBy: $sortBy
      role: $role
      archivesOnly: $archivesOnly
      locationOnly: $locationOnly
      filterTeamsProjects: $filterTeamsProjects
      ownerIds: $ownerIds
      memberIds: $memberIds
      teamIds: $teamIds
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          initials
          description
          address
          latitude
          longitude
          archivedAt
          usersCount
          tasksCount
          teamId
          team {
            avatar
            createdAt
            id
            name
            personal
          }
          color
          icon
          createdAt
          creator {
            ...UserDetail
          }
          members {
            id
            role
            user {
              ...UserDetail
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
`;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      sortBy: // value for 'sortBy'
 *      role: // value for 'role'
 *      archivesOnly: // value for 'archivesOnly'
 *      locationOnly: // value for 'locationOnly'
 *      filterTeamsProjects: // value for 'filterTeamsProjects'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      ownerIds: // value for 'ownerIds'
 *      memberIds: // value for 'memberIds'
 *      teamIds: // value for 'teamIds'
 *   },
 * });
 */
export function useListProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsQuery,
    ListProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export function useListProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsQuery,
    ListProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export type ListProjectsQueryHookResult = ReturnType<
  typeof useListProjectsQuery
>;
export type ListProjectsLazyQueryHookResult = ReturnType<
  typeof useListProjectsLazyQuery
>;
export type ListProjectsQueryResult = Apollo.QueryResult<
  ListProjectsQuery,
  ListProjectsQueryVariables
>;
export const ListProjectsBasicInfoDocument = gql`
  query listProjectsBasicInfo(
    $userId: ID
    $sortBy: ProjectSortOption
    $role: ProjectMemberRole
    $archivesOnly: Boolean
    $locationOnly: Boolean
    $filterTeamsProjects: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listProjects(
      userId: $userId
      sortBy: $sortBy
      role: $role
      archivesOnly: $archivesOnly
      locationOnly: $locationOnly
      filterTeamsProjects: $filterTeamsProjects
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          initials
          teamId
          color
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useListProjectsBasicInfoQuery__
 *
 * To run a query within a React component, call `useListProjectsBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsBasicInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      sortBy: // value for 'sortBy'
 *      role: // value for 'role'
 *      archivesOnly: // value for 'archivesOnly'
 *      locationOnly: // value for 'locationOnly'
 *      filterTeamsProjects: // value for 'filterTeamsProjects'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListProjectsBasicInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsBasicInfoQuery,
    ListProjectsBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectsBasicInfoQuery,
    ListProjectsBasicInfoQueryVariables
  >(ListProjectsBasicInfoDocument, options);
}
export function useListProjectsBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsBasicInfoQuery,
    ListProjectsBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectsBasicInfoQuery,
    ListProjectsBasicInfoQueryVariables
  >(ListProjectsBasicInfoDocument, options);
}
export type ListProjectsBasicInfoQueryHookResult = ReturnType<
  typeof useListProjectsBasicInfoQuery
>;
export type ListProjectsBasicInfoLazyQueryHookResult = ReturnType<
  typeof useListProjectsBasicInfoLazyQuery
>;
export type ListProjectsBasicInfoQueryResult = Apollo.QueryResult<
  ListProjectsBasicInfoQuery,
  ListProjectsBasicInfoQueryVariables
>;
export const ListProjectsRecentDocument = gql`
  query listProjectsRecent(
    $first: Int
    $sortBy: ProjectSortOption
    $role: ProjectMemberRole
  ) {
    listProjects(first: $first, sortBy: $sortBy, role: $role) {
      edges {
        node {
          id
          name
          coverImage {
            id
            cdnBaseUrl
            isPreviewable
            thumbnail
            path
          }
          initials
          description
          address
          team {
            avatar
            id
            name
            personal
          }
          color
          icon
          createdAt
          creator {
            ...UserDetail
          }
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListProjectsRecentQuery__
 *
 * To run a query within a React component, call `useListProjectsRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsRecentQuery({
 *   variables: {
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useListProjectsRecentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsRecentQuery,
    ListProjectsRecentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectsRecentQuery,
    ListProjectsRecentQueryVariables
  >(ListProjectsRecentDocument, options);
}
export function useListProjectsRecentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsRecentQuery,
    ListProjectsRecentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectsRecentQuery,
    ListProjectsRecentQueryVariables
  >(ListProjectsRecentDocument, options);
}
export type ListProjectsRecentQueryHookResult = ReturnType<
  typeof useListProjectsRecentQuery
>;
export type ListProjectsRecentLazyQueryHookResult = ReturnType<
  typeof useListProjectsRecentLazyQuery
>;
export type ListProjectsRecentQueryResult = Apollo.QueryResult<
  ListProjectsRecentQuery,
  ListProjectsRecentQueryVariables
>;
export const ListProjectsTasksDocument = gql`
  query listProjectsTasks(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $excludeCompleted: Boolean
    $collaboratorsOnly: Boolean
    $chatId: ID
  ) {
    listProjectsTasks(
      before: $before
      after: $after
      first: $first
      last: $last
      excludeCompleted: $excludeCompleted
      collaboratorsOnly: $collaboratorsOnly
      chatId: $chatId
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          archivedAt
          currentUserRole
          members {
            user {
              id
            }
          }
          tasks {
            id
            name
            status
            priority
            deletedAt
            completedAt
            dueDate
            projectId
            userIds
            currentUserRole
            project {
              id
              name
            }
            members {
              id
              role
              user {
                ...UserDetail
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
`;

/**
 * __useListProjectsTasksQuery__
 *
 * To run a query within a React component, call `useListProjectsTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsTasksQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      excludeCompleted: // value for 'excludeCompleted'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useListProjectsTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsTasksQuery,
    ListProjectsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectsTasksQuery,
    ListProjectsTasksQueryVariables
  >(ListProjectsTasksDocument, options);
}
export function useListProjectsTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsTasksQuery,
    ListProjectsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectsTasksQuery,
    ListProjectsTasksQueryVariables
  >(ListProjectsTasksDocument, options);
}
export type ListProjectsTasksQueryHookResult = ReturnType<
  typeof useListProjectsTasksQuery
>;
export type ListProjectsTasksLazyQueryHookResult = ReturnType<
  typeof useListProjectsTasksLazyQuery
>;
export type ListProjectsTasksQueryResult = Apollo.QueryResult<
  ListProjectsTasksQuery,
  ListProjectsTasksQueryVariables
>;
export const ListProjectsWithDocumentsDocument = gql`
  query listProjectsWithDocuments {
    listProjectsWithDocuments {
      id
      address
      name
      initials
      icon
      color
      teamId
      team {
        avatar
        createdAt
        id
        name
        personal
      }
      tasks {
        id
        name
      }
    }
  }
`;

/**
 * __useListProjectsWithDocumentsQuery__
 *
 * To run a query within a React component, call `useListProjectsWithDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsWithDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsWithDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProjectsWithDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsWithDocumentsQuery,
    ListProjectsWithDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectsWithDocumentsQuery,
    ListProjectsWithDocumentsQueryVariables
  >(ListProjectsWithDocumentsDocument, options);
}
export function useListProjectsWithDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsWithDocumentsQuery,
    ListProjectsWithDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectsWithDocumentsQuery,
    ListProjectsWithDocumentsQueryVariables
  >(ListProjectsWithDocumentsDocument, options);
}
export type ListProjectsWithDocumentsQueryHookResult = ReturnType<
  typeof useListProjectsWithDocumentsQuery
>;
export type ListProjectsWithDocumentsLazyQueryHookResult = ReturnType<
  typeof useListProjectsWithDocumentsLazyQuery
>;
export type ListProjectsWithDocumentsQueryResult = Apollo.QueryResult<
  ListProjectsWithDocumentsQuery,
  ListProjectsWithDocumentsQueryVariables
>;
export const ListProjectsWithoutSpecifiedRolesDocument = gql`
  query listProjectsWithoutSpecifiedRoles(
    $roles: [ProjectMemberRole!]!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: ProjectSortOption
  ) {
    listProjectsWithoutSpecifiedRoles(
      roles: $roles
      before: $before
      after: $after
      first: $first
      last: $last
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          id
          name
          members {
            user {
              id
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useListProjectsWithoutSpecifiedRolesQuery__
 *
 * To run a query within a React component, call `useListProjectsWithoutSpecifiedRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsWithoutSpecifiedRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsWithoutSpecifiedRolesQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useListProjectsWithoutSpecifiedRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListProjectsWithoutSpecifiedRolesQuery,
    ListProjectsWithoutSpecifiedRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListProjectsWithoutSpecifiedRolesQuery,
    ListProjectsWithoutSpecifiedRolesQueryVariables
  >(ListProjectsWithoutSpecifiedRolesDocument, options);
}
export function useListProjectsWithoutSpecifiedRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsWithoutSpecifiedRolesQuery,
    ListProjectsWithoutSpecifiedRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListProjectsWithoutSpecifiedRolesQuery,
    ListProjectsWithoutSpecifiedRolesQueryVariables
  >(ListProjectsWithoutSpecifiedRolesDocument, options);
}
export type ListProjectsWithoutSpecifiedRolesQueryHookResult = ReturnType<
  typeof useListProjectsWithoutSpecifiedRolesQuery
>;
export type ListProjectsWithoutSpecifiedRolesLazyQueryHookResult = ReturnType<
  typeof useListProjectsWithoutSpecifiedRolesLazyQuery
>;
export type ListProjectsWithoutSpecifiedRolesQueryResult = Apollo.QueryResult<
  ListProjectsWithoutSpecifiedRolesQuery,
  ListProjectsWithoutSpecifiedRolesQueryVariables
>;
export const ListRecentProjectsTasksDocument = gql`
  query listRecentProjectsTasks(
    $chatId: ID!
    $excludeCompleted: Boolean
    $collaboratorsOnly: Boolean
  ) {
    listRecentProjectsTasks(
      chatId: $chatId
      excludeCompleted: $excludeCompleted
      collaboratorsOnly: $collaboratorsOnly
    ) {
      ... on Project {
        id
        name
        archivedAt
        createdAt
        members {
          user {
            id
          }
        }
      }
      ... on Task {
        id
        name
        status
        priority
        completedAt
        deletedAt
        dueDate
        projectId
        project {
          id
          name
        }
        userIds
        members {
          id
          user {
            ...UserDetail
          }
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListRecentProjectsTasksQuery__
 *
 * To run a query within a React component, call `useListRecentProjectsTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecentProjectsTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecentProjectsTasksQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      excludeCompleted: // value for 'excludeCompleted'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *   },
 * });
 */
export function useListRecentProjectsTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListRecentProjectsTasksQuery,
    ListRecentProjectsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListRecentProjectsTasksQuery,
    ListRecentProjectsTasksQueryVariables
  >(ListRecentProjectsTasksDocument, options);
}
export function useListRecentProjectsTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRecentProjectsTasksQuery,
    ListRecentProjectsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListRecentProjectsTasksQuery,
    ListRecentProjectsTasksQueryVariables
  >(ListRecentProjectsTasksDocument, options);
}
export type ListRecentProjectsTasksQueryHookResult = ReturnType<
  typeof useListRecentProjectsTasksQuery
>;
export type ListRecentProjectsTasksLazyQueryHookResult = ReturnType<
  typeof useListRecentProjectsTasksLazyQuery
>;
export type ListRecentProjectsTasksQueryResult = Apollo.QueryResult<
  ListRecentProjectsTasksQuery,
  ListRecentProjectsTasksQueryVariables
>;
export const ListRecentTasksDocument = gql`
  query listRecentTasks(
    $projectIds: [ID!]
    $excludeCompleted: Boolean
    $projectCollaborators: Boolean
  ) {
    listRecentTasks(
      projectIds: $projectIds
      excludeCompleted: $excludeCompleted
      projectCollaborators: $projectCollaborators
    ) {
      id
      name
      priority
      startDate
      dueDate
      completedAt
      createdAt
      projectId
      deletedAt
      userIds
      description
      checklists {
        id
        name
        completedAt
        createdAt
      }
      members {
        id
        role
        user {
          ...UserDetail
        }
      }
      creator {
        ...UserDetail
      }
      completor {
        ...UserDetail
      }
      project {
        id
        name
        color
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListRecentTasksQuery__
 *
 * To run a query within a React component, call `useListRecentTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecentTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecentTasksQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      excludeCompleted: // value for 'excludeCompleted'
 *      projectCollaborators: // value for 'projectCollaborators'
 *   },
 * });
 */
export function useListRecentTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRecentTasksQuery,
    ListRecentTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListRecentTasksQuery, ListRecentTasksQueryVariables>(
    ListRecentTasksDocument,
    options
  );
}
export function useListRecentTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRecentTasksQuery,
    ListRecentTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListRecentTasksQuery,
    ListRecentTasksQueryVariables
  >(ListRecentTasksDocument, options);
}
export type ListRecentTasksQueryHookResult = ReturnType<
  typeof useListRecentTasksQuery
>;
export type ListRecentTasksLazyQueryHookResult = ReturnType<
  typeof useListRecentTasksLazyQuery
>;
export type ListRecentTasksQueryResult = Apollo.QueryResult<
  ListRecentTasksQuery,
  ListRecentTasksQueryVariables
>;
export const ListSkillsDocument = gql`
  query listSkills {
    listSkills {
      id
      name
    }
  }
`;

/**
 * __useListSkillsQuery__
 *
 * To run a query within a React component, call `useListSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSkillsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSkillsQuery,
    ListSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSkillsQuery, ListSkillsQueryVariables>(
    ListSkillsDocument,
    options
  );
}
export function useListSkillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSkillsQuery,
    ListSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSkillsQuery, ListSkillsQueryVariables>(
    ListSkillsDocument,
    options
  );
}
export type ListSkillsQueryHookResult = ReturnType<typeof useListSkillsQuery>;
export type ListSkillsLazyQueryHookResult = ReturnType<
  typeof useListSkillsLazyQuery
>;
export type ListSkillsQueryResult = Apollo.QueryResult<
  ListSkillsQuery,
  ListSkillsQueryVariables
>;
export const ListTagGroupsDocument = gql`
  query listTagGroups($limit: Int) {
    listTagGroups(limit: $limit) {
      id
      isImageGroup
      updatedAt
      createdAt
      author {
        id
        name
      }
      project {
        id
        name
      }
      task {
        id
        name
      }
      documents {
        id
        name
        contentType
        createdAt
        size
        isImage
        clientId
        isAudio
        duration
        file {
          id
          cdnBaseUrl
          isPreviewable
          thumbnail
          path
        }
        projectDocuments {
          id
          project {
            id
            name
          }
        }
        taskDocuments {
          id
          task {
            id
            name
            projectId
            project {
              id
              name
            }
          }
        }
        owner {
          id
          email
          name
          firstName
          lastName
          phoneNumber
          createdAt
          inviteUrl
          contactsCount
          avatar {
            id
            cdnBaseUrl
            path
          }
        }
      }
    }
  }
`;

/**
 * __useListTagGroupsQuery__
 *
 * To run a query within a React component, call `useListTagGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListTagGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTagGroupsQuery,
    ListTagGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTagGroupsQuery, ListTagGroupsQueryVariables>(
    ListTagGroupsDocument,
    options
  );
}
export function useListTagGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTagGroupsQuery,
    ListTagGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTagGroupsQuery, ListTagGroupsQueryVariables>(
    ListTagGroupsDocument,
    options
  );
}
export type ListTagGroupsQueryHookResult = ReturnType<
  typeof useListTagGroupsQuery
>;
export type ListTagGroupsLazyQueryHookResult = ReturnType<
  typeof useListTagGroupsLazyQuery
>;
export type ListTagGroupsQueryResult = Apollo.QueryResult<
  ListTagGroupsQuery,
  ListTagGroupsQueryVariables
>;
export const ListTaskChecklistDocument = gql`
  query listTaskChecklist($id: ID!) {
    listTaskChecklist(id: $id) {
      id
      taskId
      name
      createdAt
      completedAt
      sort
    }
  }
`;

/**
 * __useListTaskChecklistQuery__
 *
 * To run a query within a React component, call `useListTaskChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskChecklistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListTaskChecklistQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListTaskChecklistQuery,
    ListTaskChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTaskChecklistQuery,
    ListTaskChecklistQueryVariables
  >(ListTaskChecklistDocument, options);
}
export function useListTaskChecklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskChecklistQuery,
    ListTaskChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTaskChecklistQuery,
    ListTaskChecklistQueryVariables
  >(ListTaskChecklistDocument, options);
}
export type ListTaskChecklistQueryHookResult = ReturnType<
  typeof useListTaskChecklistQuery
>;
export type ListTaskChecklistLazyQueryHookResult = ReturnType<
  typeof useListTaskChecklistLazyQuery
>;
export type ListTaskChecklistQueryResult = Apollo.QueryResult<
  ListTaskChecklistQuery,
  ListTaskChecklistQueryVariables
>;
export const ListTaskDocumentsDocument = gql`
  query listTaskDocuments(
    $projectIds: [ID!]
    $taskIds: [ID!]
    $ownerIds: [ID!]
    $sortBy: DocumentSortOption
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeFiles: Boolean
    $includeMedia: Boolean
  ) {
    listTaskDocuments(
      projectIds: $projectIds
      taskIds: $taskIds
      ownerIds: $ownerIds
      sortBy: $sortBy
      first: $first
      last: $last
      after: $after
      before: $before
      includeFiles: $includeFiles
      includeMedia: $includeMedia
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          name
          contentType
          createdAt
          size
          isImage
          isVideo
          clientId
          owner {
            id
            name
          }
          file {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
          messageDocuments {
            id
            message {
              id
              clientId
              chatId
              createdAt
              isSender
              isDraft
              publishedAt
              authorId
            }
          }
          projectDocuments {
            id
            project {
              id
              name
            }
            owner {
              id
              name
            }
          }
          taskDocuments {
            id
            task {
              id
              name
              projectId
              project {
                id
                name
              }
            }
            owner {
              id
              name
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useListTaskDocumentsQuery__
 *
 * To run a query within a React component, call `useListTaskDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskDocumentsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      taskIds: // value for 'taskIds'
 *      ownerIds: // value for 'ownerIds'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      includeFiles: // value for 'includeFiles'
 *      includeMedia: // value for 'includeMedia'
 *   },
 * });
 */
export function useListTaskDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTaskDocumentsQuery,
    ListTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListTaskDocumentsQuery,
    ListTaskDocumentsQueryVariables
  >(ListTaskDocumentsDocument, options);
}
export function useListTaskDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskDocumentsQuery,
    ListTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTaskDocumentsQuery,
    ListTaskDocumentsQueryVariables
  >(ListTaskDocumentsDocument, options);
}
export type ListTaskDocumentsQueryHookResult = ReturnType<
  typeof useListTaskDocumentsQuery
>;
export type ListTaskDocumentsLazyQueryHookResult = ReturnType<
  typeof useListTaskDocumentsLazyQuery
>;
export type ListTaskDocumentsQueryResult = Apollo.QueryResult<
  ListTaskDocumentsQuery,
  ListTaskDocumentsQueryVariables
>;
export const ListTaskTagsDocument = gql`
  query listTaskTags(
    $projectIds: [ID!]
    $chatId: ID
    $sortBy: TaskSortOption
    $first: Int
    $last: Int
    $after: String
    $before: String
    $excludeCompleted: Boolean
    $collaboratorsOnly: Boolean
    $taskCollaborators: Boolean
  ) {
    listTasks(
      projectIds: $projectIds
      chatId: $chatId
      sortBy: $sortBy
      first: $first
      last: $last
      after: $after
      before: $before
      excludeCompleted: $excludeCompleted
      collaboratorsOnly: $collaboratorsOnly
      taskCollaborators: $taskCollaborators
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          userIds
          projectId
          name
          status
          priority
          dueDate
          currentUserRole
          currentUserProjectRole
          deletedAt
          completedAt
          chatTasksCount
          project {
            id
            name
          }
          members {
            id
            role
            user {
              ...UserDetail
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${UserDetailFragmentDoc}
`;

/**
 * __useListTaskTagsQuery__
 *
 * To run a query within a React component, call `useListTaskTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskTagsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      chatId: // value for 'chatId'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      excludeCompleted: // value for 'excludeCompleted'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *      taskCollaborators: // value for 'taskCollaborators'
 *   },
 * });
 */
export function useListTaskTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTaskTagsQuery,
    ListTaskTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTaskTagsQuery, ListTaskTagsQueryVariables>(
    ListTaskTagsDocument,
    options
  );
}
export function useListTaskTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskTagsQuery,
    ListTaskTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTaskTagsQuery, ListTaskTagsQueryVariables>(
    ListTaskTagsDocument,
    options
  );
}
export type ListTaskTagsQueryHookResult = ReturnType<
  typeof useListTaskTagsQuery
>;
export type ListTaskTagsLazyQueryHookResult = ReturnType<
  typeof useListTaskTagsLazyQuery
>;
export type ListTaskTagsQueryResult = Apollo.QueryResult<
  ListTaskTagsQuery,
  ListTaskTagsQueryVariables
>;
export const ListTasksDocument = gql`
  query listTasks(
    $userId: ID
    $teamIds: [ID!]
    $memberIds: [ID!]
    $projectIds: [ID!]
    $chatId: ID
    $sortBy: TaskSortOption
    $status: TaskStatus
    $dueGroup: TaskGroup
    $dueDateBegin: ISO8601DateTime
    $dueDateEnd: ISO8601DateTime
    $first: Int
    $last: Int
    $after: String
    $before: String
    $withoutPhase: Boolean
    $excludeCompleted: Boolean
    $collaboratorsOnly: Boolean
    $priority: [TaskPriority!]
  ) {
    listTasks(
      userId: $userId
      teamIds: $teamIds
      memberIds: $memberIds
      chatId: $chatId
      projectIds: $projectIds
      sortBy: $sortBy
      status: $status
      dueGroup: $dueGroup
      dueDateBegin: $dueDateBegin
      dueDateEnd: $dueDateEnd
      first: $first
      last: $last
      after: $after
      before: $before
      withoutPhase: $withoutPhase
      excludeCompleted: $excludeCompleted
      collaboratorsOnly: $collaboratorsOnly
      priority: $priority
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...TaskDetail
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${TaskDetailFragmentDoc}
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamIds: // value for 'teamIds'
 *      memberIds: // value for 'memberIds'
 *      projectIds: // value for 'projectIds'
 *      chatId: // value for 'chatId'
 *      sortBy: // value for 'sortBy'
 *      status: // value for 'status'
 *      dueGroup: // value for 'dueGroup'
 *      dueDateBegin: // value for 'dueDateBegin'
 *      dueDateEnd: // value for 'dueDateEnd'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      withoutPhase: // value for 'withoutPhase'
 *      excludeCompleted: // value for 'excludeCompleted'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useListTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTasksQuery, ListTasksQueryVariables>(
    ListTasksDocument,
    options
  );
}
export function useListTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTasksQuery,
    ListTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(
    ListTasksDocument,
    options
  );
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<
  typeof useListTasksLazyQuery
>;
export type ListTasksQueryResult = Apollo.QueryResult<
  ListTasksQuery,
  ListTasksQueryVariables
>;
export const ListTeamInvoicesDocument = gql`
  query listTeamInvoices($id: ID!) {
    getTeam(id: $id) {
      id
      invoices {
        amount
        date
        description
        id
        invoiceUrl
        status
      }
      plan {
        amount
        expiration
        id
        interval
        name
        quantity
        status
      }
    }
  }
`;

/**
 * __useListTeamInvoicesQuery__
 *
 * To run a query within a React component, call `useListTeamInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamInvoicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListTeamInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListTeamInvoicesQuery,
    ListTeamInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTeamInvoicesQuery, ListTeamInvoicesQueryVariables>(
    ListTeamInvoicesDocument,
    options
  );
}
export function useListTeamInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTeamInvoicesQuery,
    ListTeamInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListTeamInvoicesQuery,
    ListTeamInvoicesQueryVariables
  >(ListTeamInvoicesDocument, options);
}
export type ListTeamInvoicesQueryHookResult = ReturnType<
  typeof useListTeamInvoicesQuery
>;
export type ListTeamInvoicesLazyQueryHookResult = ReturnType<
  typeof useListTeamInvoicesLazyQuery
>;
export type ListTeamInvoicesQueryResult = Apollo.QueryResult<
  ListTeamInvoicesQuery,
  ListTeamInvoicesQueryVariables
>;
export const ListTeamsDocument = gql`
  query listTeams($role: ProjectMemberRole) {
    listTeams(role: $role) {
      id
      avatar
      avatarFile {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
      usersCount
      personal
      name
      numberOfProjectsUsed
      maxNumberOfProjects
      users {
        ...UserDetail
      }
      owner {
        ...UserDetail
      }
      projects {
        id
        name
        color
        icon
        initials
        address
        teamId
        team {
          avatar
          createdAt
          id
          name
          personal
        }
      }
      product {
        allowMembers
        externalId
        features
        guestsOnly
        id
        maxDocumentStorage
        maxNumberOfProjects
        name
        position
        prices {
          amount
          externalId
          id
          interval
          intervalCount
        }
      }
      plan {
        amount
        expiration
        id
        interval
        name
        quantity
        status
        isCanceled
        isResumable
        payment {
          brand
          last4
          expMonth
          expYear
        }
      }
      invoices {
        amount
        date
        description
        id
        invoiceUrl
        status
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useListTeamsQuery__
 *
 * To run a query within a React component, call `useListTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamsQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useListTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTeamsQuery, ListTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTeamsQuery, ListTeamsQueryVariables>(
    ListTeamsDocument,
    options
  );
}
export function useListTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTeamsQuery,
    ListTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTeamsQuery, ListTeamsQueryVariables>(
    ListTeamsDocument,
    options
  );
}
export type ListTeamsQueryHookResult = ReturnType<typeof useListTeamsQuery>;
export type ListTeamsLazyQueryHookResult = ReturnType<
  typeof useListTeamsLazyQuery
>;
export type ListTeamsQueryResult = Apollo.QueryResult<
  ListTeamsQuery,
  ListTeamsQueryVariables
>;
export const ListUsersDocument = gql`
  query listUsers($term: String!) {
    listUsers(term: $term) {
      id
      name
      firstName
      lastName
      friendRequestPending
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
  }
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    options
  );
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUsersQuery,
    ListUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    options
  );
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<
  typeof useListUsersLazyQuery
>;
export type ListUsersQueryResult = Apollo.QueryResult<
  ListUsersQuery,
  ListUsersQueryVariables
>;
export const NewListDocumentsDocument = gql`
  query newListDocuments(
    $chatId: ID
    $includeMedia: Boolean
    $limit: Int
    $page: Int
  ) {
    newListDocuments(
      chatId: $chatId
      includeMedia: $includeMedia
      limit: $limit
      page: $page
    ) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        name
        contentType
        createdAt
        size
        isImage
        isVideo
        clientId
        owner {
          id
          name
        }
        file {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
        messageDocuments {
          id
          message {
            id
            clientId
            chatId
            createdAt
            isSender
            isDraft
            publishedAt
            authorId
          }
        }
        projectDocuments {
          id
          project {
            id
            name
          }
          owner {
            id
            name
          }
        }
        taskDocuments {
          id
          task {
            id
            name
            projectId
            project {
              id
              name
            }
          }
          owner {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useNewListDocumentsQuery__
 *
 * To run a query within a React component, call `useNewListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewListDocumentsQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      includeMedia: // value for 'includeMedia'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNewListDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewListDocumentsQuery,
    NewListDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewListDocumentsQuery, NewListDocumentsQueryVariables>(
    NewListDocumentsDocument,
    options
  );
}
export function useNewListDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewListDocumentsQuery,
    NewListDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewListDocumentsQuery,
    NewListDocumentsQueryVariables
  >(NewListDocumentsDocument, options);
}
export type NewListDocumentsQueryHookResult = ReturnType<
  typeof useNewListDocumentsQuery
>;
export type NewListDocumentsLazyQueryHookResult = ReturnType<
  typeof useNewListDocumentsLazyQuery
>;
export type NewListDocumentsQueryResult = Apollo.QueryResult<
  NewListDocumentsQuery,
  NewListDocumentsQueryVariables
>;
export const SearchDocument = gql`
  query search(
    $term: String!
    $size: Int
    $from: Int
    $includeChats: Boolean
    $includeMessages: Boolean
    $includeProjects: Boolean
    $includeTasks: Boolean
    $includeDocuments: Boolean
    $includeContacts: Boolean
    $includeCompletedTask: Boolean
    $projectId: ID
    $taskId: ID
    $chatId: ID
    $tags: Boolean
    $media: Boolean
    $files: Boolean
    $collaboratorsOnly: Boolean
    $includeActivities: Boolean
    $documentOwnerIds: [ID!]
    $archiveProjectsOnly: Boolean
    $taskDueGroup: TaskGroup
    $completedTasksOnly: Boolean
    $documentGroup: DocumentGroup
  ) {
    search(
      term: $term
      size: $size
      from: $from
      includeChats: $includeChats
      includeMessages: $includeMessages
      includeProjects: $includeProjects
      includeDocuments: $includeDocuments
      includeTasks: $includeTasks
      includeContacts: $includeContacts
      includeCompletedTask: $includeCompletedTask
      projectId: $projectId
      taskId: $taskId
      chatId: $chatId
      tags: $tags
      media: $media
      files: $files
      collaboratorsOnly: $collaboratorsOnly
      includeActivities: $includeActivities
      archiveProjectsOnly: $archiveProjectsOnly
      taskDueGroup: $taskDueGroup
      completedTasksOnly: $completedTasksOnly
      documentOwnerIds: $documentOwnerIds
      documentGroup: $documentGroup
    ) {
      total
      highlight {
        authorName
        body
        chatName
        name
        projectNames
        taskNames
      }
      record {
        ... on Document {
          id
          name
          contentType
          createdAt
          size
          isImage
          clientId
          owner {
            ...UserDetail
          }
          file {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
          messageDocuments {
            id
            message {
              id
              clientId
              authorId
              chat {
                id
                name
              }
            }
          }
          projectDocuments {
            id
            project {
              id
              name
              team {
                avatar
                createdAt
                id
                name
                personal
              }
            }
          }
        }
        ... on Task {
          id
          name
          status
          priority
          dueDate
          projectId
          userIds
          completedAt
          deletedAt
          currentUserRole
          creator {
            ...UserDetail
          }
          members {
            id
            role
            user {
              ...UserDetail
              avatar {
                ...FileDetails
              }
            }
          }
          project {
            id
            name
            color
            team {
              name
              personal
            }
          }
        }
        ... on Project {
          id
          initials
          name
          description
          address
          color
          createdAt
          icon
          team {
            avatar
            createdAt
            id
            name
            personal
          }
          creator {
            ...UserDetail
          }
        }
        ... on Chat {
          id
          name
        }
        ... on Message {
          id
          cursor
          body
          publishedAt
          isSender
          clientId
          authorId
          chatId
          clientId
          createdAt
          isDraft
          author {
            id
            name
            ...UserDetail
          }
          chat {
            ...ChatDetail
            avatar {
              ...FileDetails
            }
          }
          reaction {
            ... on MessageReaction {
              id
              reaction
              count
              user {
                id
              }
            }
          }
          attachments {
            ... on Document {
              id
              isImage
              isAudio
              clientId
              duration
              name
              createdAt
              contentType
              file {
                ...FileDetails
              }
              ...DocumentDetail
            }
            ... on MessageTask {
              id
              author {
                ...UserDetail
              }
              task {
                id
                name
                status
                priority
                dueDate
                projectId
                userIds
                deletedAt
                creator {
                  ...UserDetail
                }
                project {
                  id
                  name
                  color
                }
              }
            }
            ... on MessageProject {
              id
              author {
                ...UserDetail
              }
              project {
                id
                initials
                name
                description
                address
                color
                createdAt
                creator {
                  ...UserDetail
                }
              }
            }
            ... on MessageTask {
              id
              author {
                ...UserDetail
              }
              task {
                id
                name
                status
                priority
                dueDate
                projectId
                userIds
                deletedAt
                creator {
                  ...UserDetail
                }
                project {
                  id
                  name
                  color
                }
              }
            }
            ... on MessageProject {
              id
              author {
                ...UserDetail
              }
              project {
                id
                initials
                name
                description
                address
                color
                createdAt
                creator {
                  ...UserDetail
                }
              }
            }
          }
        }
        ... on User {
          id
          firstName
          lastName
          email
          fullName: name
          avatar {
            ...FileDetails
          }
        }
        ... on Audit {
          event
          createdAt
          id
          user {
            ...UserDetail
          }
          attachment {
            ... on Document {
              ...DocumentDetail
            }
            ... on Message {
              ...MessageDetail
            }
            ... on Task {
              id
              name
              projectId
              deletedAt
              project {
                id
                name
              }
            }
            ... on Project {
              id
              name
            }
            ... on TaskUser {
              id
              task {
                id
                name
                project {
                  id
                  name
                }
              }
              user {
                ...UserDetail
              }
            }
            ... on EntityUser {
              id
              user {
                ...UserDetail
              }
              entityTask: task {
                id
                name
                deletedAt
                members {
                  id
                  role
                  user {
                    ...UserDetail
                    avatar {
                      ...FileDetails
                    }
                  }
                }
                project {
                  id
                  name
                }
              }
              entityProject: project {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${FileDetailsFragmentDoc}
  ${ChatDetailFragmentDoc}
  ${DocumentDetailFragmentDoc}
  ${MessageDetailFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *      includeChats: // value for 'includeChats'
 *      includeMessages: // value for 'includeMessages'
 *      includeProjects: // value for 'includeProjects'
 *      includeTasks: // value for 'includeTasks'
 *      includeDocuments: // value for 'includeDocuments'
 *      includeContacts: // value for 'includeContacts'
 *      includeCompletedTask: // value for 'includeCompletedTask'
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *      chatId: // value for 'chatId'
 *      tags: // value for 'tags'
 *      media: // value for 'media'
 *      files: // value for 'files'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *      includeActivities: // value for 'includeActivities'
 *      documentOwnerIds: // value for 'documentOwnerIds'
 *      archiveProjectsOnly: // value for 'archiveProjectsOnly'
 *      taskDueGroup: // value for 'taskDueGroup'
 *      completedTasksOnly: // value for 'completedTasksOnly'
 *      documentGroup: // value for 'documentGroup'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const SearchTagsDocument = gql`
  query searchTags(
    $term: String!
    $size: Int
    $from: Int
    $includeChats: Boolean
    $includeMessages: Boolean
    $includeProjects: Boolean
    $includeTasks: Boolean
    $includeDocuments: Boolean
    $includeContacts: Boolean
    $projectId: ID
    $taskId: ID
    $chatId: ID
    $tags: Boolean
    $media: Boolean
    $files: Boolean
    $collaboratorsOnly: Boolean
    $includeTasksTags: Boolean
    $includeCompletedTask: Boolean
  ) {
    search(
      term: $term
      size: $size
      from: $from
      includeChats: $includeChats
      includeMessages: $includeMessages
      includeProjects: $includeProjects
      includeDocuments: $includeDocuments
      includeTasks: $includeTasks
      includeContacts: $includeContacts
      includeCompletedTask: $includeCompletedTask
      projectId: $projectId
      taskId: $taskId
      chatId: $chatId
      tags: $tags
      media: $media
      files: $files
      collaboratorsOnly: $collaboratorsOnly
      includeTasksTags: $includeTasksTags
    ) {
      total
      highlight {
        authorName
        name
        projectNames
        taskNames
      }
      record {
        ... on Project {
          id
          name
          createdAt
          currentUserRole
          members {
            user {
              id
            }
          }
          tasksTagList {
            id
            name
            status
            dueDate
            priority
            projectId
            deletedAt
            userIds
            completedAt
            currentUserRole
            currentUserProjectRole
            members {
              id
              role
              user {
                id
                firstName
                lastName
                email
                name
                phoneNumber
                createdAt
                avatar {
                  id
                  cdnBaseUrl
                  path
                  isPreviewable
                  thumbnail
                }
              }
            }
            project {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *      includeChats: // value for 'includeChats'
 *      includeMessages: // value for 'includeMessages'
 *      includeProjects: // value for 'includeProjects'
 *      includeTasks: // value for 'includeTasks'
 *      includeDocuments: // value for 'includeDocuments'
 *      includeContacts: // value for 'includeContacts'
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *      chatId: // value for 'chatId'
 *      tags: // value for 'tags'
 *      media: // value for 'media'
 *      files: // value for 'files'
 *      collaboratorsOnly: // value for 'collaboratorsOnly'
 *      includeTasksTags: // value for 'includeTasksTags'
 *      includeCompletedTask: // value for 'includeCompletedTask'
 *   },
 * });
 */
export function useSearchTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTagsQuery,
    SearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTagsQuery, SearchTagsQueryVariables>(
    SearchTagsDocument,
    options
  );
}
export function useSearchTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTagsQuery,
    SearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTagsQuery, SearchTagsQueryVariables>(
    SearchTagsDocument,
    options
  );
}
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<
  typeof useSearchTagsLazyQuery
>;
export type SearchTagsQueryResult = Apollo.QueryResult<
  SearchTagsQuery,
  SearchTagsQueryVariables
>;
export const ValidateContentTypeDocument = gql`
  query validateContentType($contentType: String!) {
    validateContentType(contentType: $contentType)
  }
`;

/**
 * __useValidateContentTypeQuery__
 *
 * To run a query within a React component, call `useValidateContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateContentTypeQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useValidateContentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateContentTypeQuery,
    ValidateContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateContentTypeQuery,
    ValidateContentTypeQueryVariables
  >(ValidateContentTypeDocument, options);
}
export function useValidateContentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateContentTypeQuery,
    ValidateContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateContentTypeQuery,
    ValidateContentTypeQueryVariables
  >(ValidateContentTypeDocument, options);
}
export type ValidateContentTypeQueryHookResult = ReturnType<
  typeof useValidateContentTypeQuery
>;
export type ValidateContentTypeLazyQueryHookResult = ReturnType<
  typeof useValidateContentTypeLazyQuery
>;
export type ValidateContentTypeQueryResult = Apollo.QueryResult<
  ValidateContentTypeQuery,
  ValidateContentTypeQueryVariables
>;
export const ChatUpdateDocument = gql`
  subscription chatUpdate {
    chatUpdate {
      id
      name
      messageBody
      recentActivityAt
      unreadCount
    }
  }
`;

/**
 * __useChatUpdateSubscription__
 *
 * To run a query within a React component, call `useChatUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatUpdateSubscription,
    ChatUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatUpdateSubscription,
    ChatUpdateSubscriptionVariables
  >(ChatUpdateDocument, options);
}
export type ChatUpdateSubscriptionHookResult = ReturnType<
  typeof useChatUpdateSubscription
>;
export type ChatUpdateSubscriptionResult =
  Apollo.SubscriptionResult<ChatUpdateSubscription>;
export const ChatUpdatedDocument = gql`
  subscription chatUpdated {
    chatUpdated {
      ...ChatDetail
    }
  }
  ${ChatDetailFragmentDoc}
`;

/**
 * __useChatUpdatedSubscription__
 *
 * To run a query within a React component, call `useChatUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatUpdatedSubscription,
    ChatUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatUpdatedSubscription,
    ChatUpdatedSubscriptionVariables
  >(ChatUpdatedDocument, options);
}
export type ChatUpdatedSubscriptionHookResult = ReturnType<
  typeof useChatUpdatedSubscription
>;
export type ChatUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<ChatUpdatedSubscription>;
export const MeUpdatedDocument = gql`
  subscription meUpdated {
    meUpdated {
      profile {
        ...UserProfile
      }
      chats {
        id
        unreadCount
        settings {
          id
          mute
          pin
        }
      }
    }
  }
  ${UserProfileFragmentDoc}
`;

/**
 * __useMeUpdatedSubscription__
 *
 * To run a query within a React component, call `useMeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMeUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MeUpdatedSubscription,
    MeUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MeUpdatedSubscription,
    MeUpdatedSubscriptionVariables
  >(MeUpdatedDocument, options);
}
export type MeUpdatedSubscriptionHookResult = ReturnType<
  typeof useMeUpdatedSubscription
>;
export type MeUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<MeUpdatedSubscription>;
export const MessageUpdateDocument = gql`
  subscription messageUpdate($chatId: ID!) {
    messageUpdate(chatId: $chatId) {
      ...MessageDetail
    }
  }
  ${MessageDetailFragmentDoc}
`;

/**
 * __useMessageUpdateSubscription__
 *
 * To run a query within a React component, call `useMessageUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdateSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useMessageUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MessageUpdateSubscription,
    MessageUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageUpdateSubscription,
    MessageUpdateSubscriptionVariables
  >(MessageUpdateDocument, options);
}
export type MessageUpdateSubscriptionHookResult = ReturnType<
  typeof useMessageUpdateSubscription
>;
export type MessageUpdateSubscriptionResult =
  Apollo.SubscriptionResult<MessageUpdateSubscription>;
export const ProjectUpdatedDocument = gql`
  subscription projectUpdated {
    projectUpdated {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragmentDoc}
`;

/**
 * __useProjectUpdatedSubscription__
 *
 * To run a query within a React component, call `useProjectUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useProjectUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ProjectUpdatedSubscription,
    ProjectUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ProjectUpdatedSubscription,
    ProjectUpdatedSubscriptionVariables
  >(ProjectUpdatedDocument, options);
}
export type ProjectUpdatedSubscriptionHookResult = ReturnType<
  typeof useProjectUpdatedSubscription
>;
export type ProjectUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<ProjectUpdatedSubscription>;
