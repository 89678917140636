import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { ConfirmModal } from '@components/Modals/ConfirmModal';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList';
import Icon from '@components/shared/Icon/Icon';
import { Task } from '@graphql/generated';
import { Search } from '@root/types';
import theme from '@themes/theme';

interface ITaskSearch {
  search: string;
  data: Search<Task>[];
  toggleCompleteTask?: (task: Task) => void;
  loading?: boolean;
  sectionFooter?: JSX.Element;
  filterPart?: JSX.Element;
  onPress?: (item: Task) => void;
  onEndReached?: (info: { distanceFromEnd: number }) => void;
}

const TasksSearch: React.FC<ITaskSearch> = ({
  search,
  data,
  toggleCompleteTask,
  loading = false,
  sectionFooter,
  filterPart,
  onPress,
  onEndReached,
}) => {
  const { t } = useTranslation('models');
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState<boolean>();
  const [taskToComplete, setTaskToComplete] = useState<Task>(undefined);

  const getStr = (task) => {
    if (task.completedAt && task.completedAt != null) {
      return 'Completed';
    } else {
      const value = task.dueDate;
      const day = dayjs(dayjs().format('MM/DD/YYYY')).diff(
        dayjs(dayjs(value).format('MM/DD/YYYY')),
        'day'
      );
      if (day > 0) return 'OverDue';
      if (day === 0) return 'Today';
      return 'Upcoming';
    }
  };
  const RenderItem = (item) => {
    const task = item.record;
    const [isHoveredTitle, setIsHoveredTitle] = useState(false);

    return (
      <Box
        paddingHorizontal='l'
        paddingVertical='xs'
        backgroundColor={isHoveredTitle ? 'grey01' : 'white'}
        onMouseEnter={() => setIsHoveredTitle(true)}
        onMouseLeave={() => setIsHoveredTitle(false)}>
        <TouchableOpacity onPress={() => onPress?.(task)}>
          <Box
            accessibilityLabel={task.name ? task.name : ''}
            flexDirection='row'
            alignItems='flex-start'>
            <Box marginRight='xs'>
              <RadioButton
                iconVariant='m'
                onPress={() => {
                  setShowCompleteTaskModal(true);
                  setTaskToComplete(task);
                }}
                isSelected={task.completedAt && task.completedAt != null}
              />
            </Box>

            <Box flex={1} mr='m'>
              <Box justifyContent='space-between' flexDirection='row' flex={1}>
                <Box flex={1}>
                  <Text
                    accessibilityLabel='Task title'
                    variant='webBodySecondary'
                    textDecorationLine={
                      isHoveredTitle ? 'underline' : undefined
                    }
                    numberOfLines={1}
                    style={{ alignSelf: `flex-start` }}
                    marginRight='l'>
                    <Highlighter
                      autoEscape
                      highlightStyle={{
                        color: theme.colors.textPrimary,
                        backgroundColor: theme.colors.yellowBright,
                      }}
                      searchWords={search.split(' ')}
                      textToHighlight={task.name}
                    />
                  </Text>
                  <Box flexDirection='row' alignItems='center' mt='xs'>
                    <Icon variant='s' color='grey04' name='Folder' />

                    <Text
                      variant='metadata'
                      color='grey04'
                      numberOfLines={1}
                      style={{ alignSelf: `flex-start` }}
                      marginLeft='xxs'>
                      {task.project.name}
                    </Text>
                    <Box
                      mx='xxs'
                      backgroundColor='grey04'
                      style={{
                        width: 3,
                        height: 3,
                        borderRadius: 1.5,
                      }}></Box>
                    <Text
                      variant='metadata'
                      color='grey04'
                      numberOfLines={1}
                      style={{ alignSelf: `flex-start` }}>
                      {getStr(task)}
                    </Text>
                    {(!task.completedAt || task.completedAt == null) &&
                      task.dueDate &&
                      task.dueDate != null && (
                        <>
                          <Box
                            mx='xxs'
                            backgroundColor='grey04'
                            style={{
                              width: 3,
                              height: 3,
                              borderRadius: 1.5,
                            }}></Box>
                          <Text
                            variant='metadata'
                            color='grey04'
                            numberOfLines={1}
                            style={{ alignSelf: `flex-start` }}>
                            {t('format:abbrMonthAndDay', {
                              val: task.dueDate,
                            })}
                          </Text>
                        </>
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              flexDirection='row'
              height={40}
              alignItems='center'
              justifyContent='flex-end'>
              <Text
                marginRight='xs'
                numberOfLines={1}
                ellipsizeMode='tail'
                variant='webBodySecondary'>
                {task.project.team?.name}
              </Text>
              <Icon
                name={task.project.team?.personal ? 'Office' : 'TT24'}
                variant='l'
                color={task.project.team?.personal ? 'white' : 'textPrimary'}
                backgroundColor={
                  task.project.team?.personal ? 'greenSecondary' : 'textPrimary'
                }
                padding={task.project.team?.personal ? 'xxs' : 'none'}
                width={task.project.team?.personal ? 16 : 24}
                height={task.project.team?.personal ? 16 : 24}
                borderRadius='xxs'
              />
            </Box>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <>
      <GlobalSearchList
        loading={loading}
        search={search}
        isViewAllContacts={false}
        onEndReached={onEndReached}
        onPress={() => {}}
        lists={[
          {
            title: 'Tasks',
            data: data,
            headerRight: filterPart,
            totalCount: data.length > 0 ? data[0].total : data.length,
            render: RenderItem,
            itemSeparatorComponent: undefined,
            sectionFooter: sectionFooter,
          },
        ]}
      />
      {showCompleteTaskModal && (
        <ConfirmModal
          showModal={showCompleteTaskModal}
          onClose={() => setShowCompleteTaskModal(false)}
          onPress={() => {
            toggleCompleteTask?.(taskToComplete);
            setShowCompleteTaskModal(false);
          }}
          buttonText={t('models:tasks.complete.title')}
          title={t('models:tasks.complete.title')}
          message={t('models:tasks.complete.message_short')}
        />
      )}
    </>
  );
};

export default TasksSearch;
