/* eslint-disable unused-imports/no-unused-vars */

import React from 'react';

import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';

import ProjectFormStack, { FormValues } from './ProjectFormStack';

interface CreateProjectProps {
  isShortModal?: boolean;
  externalNavigate?: any;
}

const CreateProject: React.FC<CreateProjectProps> = ({
  isShortModal,
  externalNavigate,
}) => {
  const { editProjectId } = useWebDrawer();

  const { project, loading } = useProjectFromQuery({
    projectId: editProjectId,
  });

  if (editProjectId != '') {
    if (!project || loading) return <ActivityIndicatorLoading />;
    const initialMembers = project.members.map((m) => {
      return {
        ...m.user,
        role: m.role,
      };
    });

    const initialValues: FormValues = {
      location: project.address
        ? {
            address: project.address,
            latitude: project?.latitude || 0,
            longitude: project?.longitude || 0,
          }
        : null,
      color: project.color || 'purple',
      icon: project.icon || 'IconStart22',
      description: project.description || '',
      id: project.id,
      name: project.name || '',
      teamId: project.team?.id || '',
      users: initialMembers || [],
      currentTeam: project.team,
    };

    return (
      <ProjectFormStack
        initialValues={initialValues}
        projectId={editProjectId}
        isShortModal={isShortModal}
        externalNavigate={externalNavigate}
      />
    );
  } else {
    const initialValues: FormValues = {
      id: undefined,
      name: '',
      location: null,
      description: '',
      teamId: '',
      users: [],
      color: 'purple',
      icon: 'IconStart22',
    };
    return (
      <ProjectFormStack
        initialValues={initialValues}
        isShortModal={isShortModal}
        externalNavigate={externalNavigate}
      />
    );
  }
};

export default CreateProject;
