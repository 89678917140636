import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/react-native';
import byteBuffer from 'bytebuffer';
import * as FileSystem from 'expo-file-system';
import { UploadProgressData } from 'expo-file-system/build/FileSystem.types';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { Document, LocalFile, DirectUpload } from '@graphql/generated';
import { getFileMetadata } from '@utils/calculateChecksum';
import { useFetchContentTypes } from '@utils/fetchContentTypes';

export const getFileInfoPromises = async (
  documents: Document[]
): Promise<
  (FileSystem.FileInfo & Pick<Document, 'clientId' | 'contentType'>)[]
> => {
  return Promise.all(
    documents.map(async (document) => {
      const documentInfo = await Platform.select({
        web: async () => {
          const systemDocument = await localDocumentToFile(document);
          const { byte_size, checksum } = await getFileMetadata(systemDocument);
          return Promise.resolve({
            exists: true,
            isDirectory: false,
            modificationTime: new Date().getMilliseconds(),
            size: byte_size ?? 0,
            uri: document.file.url,
            md5: checksum,
          } as FileSystem.FileInfo);
        },
        default: async () =>
          await FileSystem.getInfoAsync(document.file.url, {
            md5: true,
            size: true,
          }),
      })();

      const { clientId, contentType } = document;

      return { ...documentInfo, clientId, contentType };
    })
  );
};

export const createDirectUploadAttributesFromFileInfo = async (
  documentInfoForImages: (FileSystem.FileInfo &
    Pick<Document, 'clientId' | 'contentType'>)[]
) => {
  const resultPromises = documentInfoForImages.map(async (item) => {
    const { size, md5: documentInfoMd5, clientId, contentType, uri } = item;
    const checksum =
      Platform.select({
        default: () =>
          documentInfoMd5 && byteBuffer.fromHex(documentInfoMd5).toBase64(),
        web: () => documentInfoMd5,
      })() || '';

    const filename = await getSafeFilename(uri, contentType || '');

    return {
      byteSize: size ?? 0,
      checksum,
      filename,
      clientId,
      contentType,
    };
  });

  return Promise.all(resultPromises);
};

export const uploadFilesAsync = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  fileInfoResults: any[],
  callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
) =>
  await Platform.select({
    default: uploadFilesAsyncNative,
    web: uploadFilesAsyncWeb,
  })(documentDirectUploads, documents, fileInfoResults, callback);

const uploadFilesAsyncNative = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  fileInfoResults: any[],
  callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
): Promise<{ blobId: string; clientId: string }[]> => {
  return Promise.all(
    documentDirectUploads.map(async (directUpload, index) => {
      const { signedBlobId, clientId } = directUpload;
      const headers = JSON.parse(directUpload.headers) as Record<
        string,
        string
      >;
      if (__DEV__) {
        console.log('==============upload task=============');
      }
      const document = documents[index];
      const fileInfo = fileInfoResults[index];
      const progressCallback = (progress: UploadProgressData) => {
        console.log({ progress });
        callback?.(document.clientId, progress);
      };
      const uploadTask = FileSystem.createUploadTask(
        directUpload.url,
        document.file.url,
        {
          headers,
          httpMethod: 'PUT',
        },
        progressCallback
      );

      const baseTimeout = 30; //seconds
      const sizeInMB = fileInfo.size / (1024 * 1024);
      const extraTime = sizeInMB * 5;
      const timeoutDuration = Math.min(baseTimeout + extraTime, 300);
      if (__DEV__)
        console.log(
          `Uploading file: ${document.file.url}\nSize: ${sizeInMB.toFixed(
            2
          )}MB, Timeout: ${timeoutDuration}s`
        );

      try {
        const response = await Promise.race([
          uploadTask.uploadAsync(),
          new Promise<null>((_, reject) =>
            setTimeout(
              () => reject(new Error(`Upload timeout: ${clientId}`)),
              timeoutDuration * 1000
            )
          ),
        ]);

        if (response && response.status === 200) {
          if (Platform.OS !== 'web') {
            try {
              const RNFS = await import('react-native-fs');
              await RNFS.unlink(document.file.url);
              console.log(`Cache file deleted: ${document.file.url}`);
            } catch (deleteError) {
              console.error(
                `Failed to delete file: ${document.file.url}`,
                deleteError
              );
            }
          }

          return { blobId: signedBlobId, clientId };
        } else {
          const errorMsg = response
            ? `Upload failed with status: ${response.status}, blobId: ${signedBlobId}, clientId: ${clientId}`
            : `Upload failed without response. blobId: ${signedBlobId}, clientId: ${clientId}`;

          console.error(errorMsg);
          Sentry.captureMessage(errorMsg);
          throw new Error(errorMsg);
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }

        console.error(
          `Error during upload for clientId ${clientId}:`,
          errorMessage
        );
        Sentry.captureMessage(
          `Upload error: ${errorMessage}, clientId: ${clientId}`
        );

        throw new Error(errorMessage);
      }
    })
  );
};

const uploadFilesAsyncWeb = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  _fileInfoResults: any[],
  callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
): Promise<{ blobId: string; clientId: string }[]> => {
  return Promise.all(
    documentDirectUploads.map(async (directUpload, index) => {
      const localDocument = documents[index];
      const file = await localDocumentToFile(localDocument);

      const { url, headers: rawHeaders, signedBlobId, clientId } = directUpload;
      const headers: Record<string, string> = Object.entries(
        JSON.parse(rawHeaders)
      ).reduce((acc, [key, value]) => {
        acc[key.toLowerCase()] = value as string;
        return acc;
      }, {} as Record<string, string>);

      await uploadFileWithProgressWeb({
        file,
        url,
        headers,
        clientId,
        onProgress: (progress) => {
          callback?.(clientId, progress);
        },
      });

      return {
        blobId: signedBlobId,
        clientId,
      };
    })
  );
};

const uploadFileWithProgressWeb = async ({
  file,
  url,
  headers = {},
  clientId = 'unknown',
  onProgress,
}: {
  file: File | Blob;
  url: string;
  headers?: Record<string, string>;
  clientId?: string;
  onProgress?: (progress: UploadProgressData) => void;
}): Promise<void> => {
  const supportsProgress =
    typeof XMLHttpRequest !== 'undefined' && 'upload' in new XMLHttpRequest();

  if (supportsProgress) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', url);

      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = {
            totalBytesSent: event.loaded,
            totalBytesExpectedToSend: event.total,
          };

          if (__DEV__) {
            console.log(
              `[Progress] clientId=${clientId} ${event.loaded}/${
                event.total
              } = ${((event.loaded / event.total) * 100).toFixed(1)}%`
            );
          }

          onProgress?.(progress);
        } else {
          if (__DEV__) {
            console.log(`[Progress] clientId=${clientId} - not computable`);
          }
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Upload failed due to a network error.'));
      };

      xhr.send(file);
    });
  } else {
    if (__DEV__) {
      console.warn(
        `[Fallback] Browser doesn't support xhr.upload.onprogress. Using fetch instead.`
      );
    }

    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body: file,
    });

    if (!response.ok) {
      throw new Error(`Fetch fallback upload failed: ${response.statusText}`);
    }
  }
};

/**
 * Converts a `Document` type object into a `File` object
 * @param localDocument The local document
 * @returns A `File`
 */
export const localDocumentToFile = async (localDocument: Document) => {
  const {
    file: { url },
    name,
  } = localDocument;
  const res: Response = await fetch(url);
  const blob: Blob = await res.blob();
  return new File([blob], name, { type: contentType(url) });
};

/**
 * Computes the document size of a base64 encoded document uri
 * @param documentUri A base64 encoded document uri
 * @returns The document size
 */
export const documentSize = (documentUri: string) => {
  const base64 = documentUri.includes(',')
    ? documentUri.slice(documentUri.lastIndexOf(','), 1)
    : documentUri;
  return base64.length - (base64.endsWith('==') ? 2 : 1);
};

/**
 * Retrieves a document type in mime-type format from a document uri string
 * @param documentUri The documentUri to parse
 * @returns The content type in mime-type format
 */
export const contentType = (documentUri: string) => {
  if (documentUri?.startsWith('data:')) {
    return documentUri.slice(
      documentUri.indexOf('data:') + 'data:'.length,
      documentUri.lastIndexOf(';')
    );
  }
  return '';
};

/**
 * Formats a size into a human readable display
 * @param size Size value to format
 * @returns The formatted size
 */
export const formatSize = (size: number) => {
  if (!+size) return '';

  const byteSize = 1024;
  const KB = Math.pow(byteSize, 1);
  const MB = Math.pow(byteSize, 2);

  if (size < MB) {
    return `${(size / KB).toFixed(2)} KB`;
  }
  return `${(size / MB).toFixed(2)} MB`;
};

/**
 * Reads the target document into a uri
 * @param targetFile The document to read
 * @returns An object with the document uri and optional base64 encoding
 */
export const readFileToUri = (
  targetFile: Blob
): Promise<{ uri: string; base64?: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reject(
        new Error(
          `Failed to read the selected media because the operation failed.`
        )
      );
    };
    reader.onload = ({ target }) => {
      const uri = target?.result;

      const returnRaw = () =>
        resolve({
          uri: uri as string,
        });

      if (typeof uri === 'string') {
        resolve({
          uri,
          // The blob's result cannot be directly decoded as Base64 without
          // first removing the Data-URL declaration preceding the
          // Base64-encoded data. To retrieve only the Base64 encoded string,
          // first remove data:*/*;base64, from the result.
          // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
          base64: uri.slice(uri.indexOf(',') + 1),
        });
      } else {
        returnRaw();
      }
    };

    reader.readAsDataURL(targetFile);
  });
};

/**
 * Opens a file in a new tab.
 *
 * **NOTE**
 * Only available in the web/browser environment.
 * @param document The document to open
 */
export const openInNewTab = async (document: Document | LocalFile) => {
  if (Platform.OS !== 'web') return;

  const file = 'file' in document ? document.file : document;
  let link = '';

  // file will always contain a url, but if we have a cdnBaseUrl,
  // we have to use that to build the path instead.
  // here change to get from cache storage
  const isLocal =
    file.url && (file.url.startsWith('file:') || file.url.startsWith('data:'));
  if (isLocal) {
    link = file.url;
  } else {
    if (document.isImage && file.cdnBaseUrl) {
      link = file.cdnBaseUrl + file.path;
    } else {
      const cachedUrl = await AsyncStorage.getItem(document.clientId);
      if (cachedUrl) {
        link = cachedUrl;
      }
    }
  }

  if ('file' in document && link.startsWith('data:')) {
    const f = await localDocumentToFile(document);
    link = URL.createObjectURL(f);
  }

  link && window && 'open' in window && window.open(link, '_blank');
};

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const getDurationFromDataUrl = async (url: string) => {
  const context = new AudioContext();

  const buffer = base64ToArrayBuffer(url.split(',')[1]);

  const decoded = await context.decodeAudioData(buffer).catch(() => {
    return new AudioBuffer({ length: 0, numberOfChannels: 0, sampleRate: 0 });
  });

  return Math.round(decoded.duration);
};

/**
 * Converts a `File` type to a `LocalFile` object
 * @param file File to convert
 * @returns A LocalFile object
 */
export const fileToLocalFile = async (file: File, getAudioDuration = false) => {
  const { uri: url } = await readFileToUri(file);
  const clientId = uuid.v4().toString();
  const isAudio = file.type.startsWith('audio');
  const duration =
    getAudioDuration && isAudio ? await getDurationFromDataUrl(url) : undefined;
  return {
    isImage: file.type.startsWith('image') || file.type.startsWith('video'),
    contentType: file.type,
    isPreviewable:
      file.type.startsWith('image') || file.type.startsWith('video'),
    thumbnail: '',
    name: file.name,
    url,
    size: file.size,
    clientId,
    id: clientId,
    cdnBaseUrl: '',
    path: '',
    isAudio,
    duration,
    __typename: 'LocalFile',
  } as LocalFile;
};

/**
 * Safely extracts a filename from URI and optionally contentType.
 * Falls back to UUID + appropriate extension when needed.
 */
export const getSafeFilename = async (
  uri?: string,
  contentType?: string
): Promise<string> => {
  if (!uri) {
    return `${uuid.v4().toString()}.${await getExtensionByContentType(
      contentType
    )}`;
  }

  try {
    // Skip blob: and data: URIs
    if (uri.startsWith('blob:') || uri.startsWith('data:')) {
      return `${uuid.v4().toString()}.${await getExtensionByContentType(
        contentType
      )}`;
    }

    // Extract last segment of the URI
    const raw = uri.split('/').pop() ?? '';
    const clean = raw.split('?')[0].split('#')[0];

    // If clean name looks okay (has extension and not too long), use it
    if (clean.length <= 100 && clean.includes('.')) {
      return clean;
    }

    return `${uuid.v4().toString()}.${await getExtensionByContentType(
      contentType
    )}`;
  } catch (err) {
    return `${uuid.v4().toString()}.${await getExtensionByContentType(
      contentType
    )}`;
  }
};

let cachedContentTypes: Record<string, string[]> | null = null;
export const getExtensionByContentType = async (
  contentType: string | undefined
): Promise<string> => {
  const DEFAULT_EXTENSION = '.unknown';

  if (!contentType) {
    return DEFAULT_EXTENSION;
  }

  try {
    if (!cachedContentTypes) {
      const { fetchAllowedContentTypes } = useFetchContentTypes();
      let contentTypesString = await AsyncStorage.getItem('allContentTypes');

      if (!contentTypesString) {
        await fetchAllowedContentTypes();
        contentTypesString = await AsyncStorage.getItem('allContentTypes');
      }

      if (!contentTypesString) {
        return DEFAULT_EXTENSION;
      }

      cachedContentTypes = JSON.parse(contentTypesString);
    }

    const extensions = cachedContentTypes?.[contentType];

    return extensions && extensions!.length > 0
      ? extensions[0]
      : DEFAULT_EXTENSION;
  } catch (error) {
    return DEFAULT_EXTENSION;
  }
};
