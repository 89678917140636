import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';

import CachedImage from '@components/CachedImage/CachedImage';
import DocumentCard from '@components/Chat/DocumentCard';
import PlayCircle from '@components/Icons/PlayCircle';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import theme from '@themes/theme';
import { openInNewTab } from '@utils/fileUtils';

import { Document } from '../../graphql/generated';
import { Box } from '../Restyle';

interface ActivityItemDocumentProps {
  attachment: Document;
  taskId?: string;
}

export const ActivityItemDocument: React.FC<ActivityItemDocumentProps> = ({
  attachment,
  taskId,
}) => {
  const { setPreviewDocuments } = usePreviewDocuments();
  const navigation = useNavigation();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const isVideo = attachment.isVideo;
  const windowDimensions = useWindowDimensions();

  if (attachment.isImage || attachment.contentType?.startsWith('image/')) {
    const image = attachment.file;
    const maxBoxWidth = Platform.OS === 'web' ? 250 : undefined;
    const { width = 360 } =
      Platform.select({
        native: windowDimensions,
        web: { width: 300, fontScale: 1, height: 1, scale: 1 },
      }) ?? {};
    const size = width - 94; // FROM FIGMA
    return (
      <Box
        flexDirection='row'
        paddingTop='xs'
        paddingHorizontal={Platform.OS === 'web' ? 'xs' : undefined}
        borderRadius='xs'
        justifyContent='flex-start'
        borderWidth={1}
        width={maxBoxWidth}
        borderColor='grey02'>
        <TouchableOpacity
          key={image.id}
          onPress={() => {
            setPreviewDocuments([attachment]);
            if (Platform.OS === 'web') {
              return setImageCarouselVisible(true);
            } else {
              navigation.navigate('images-preview', {
                taskId: taskId,
                deleteCall: 'task',
              });
            }
          }}
          style={[
            {
              width: size,
              height: size,
              flexDirection: 'row',
              marginRight: 0,
              marginLeft: theme.spacing.xs,
              marginBottom: theme.spacing.xs,
            },
          ]}>
          <Box
            flex={1}
            justifyContent='center'
            alignItems='center'
            pointerEvents='auto'>
            <CachedImage
              image={image}
              width={size}
              height={size}
              isRemoveable={false}
              borderRadius={theme.spacing.xs}
              contentType={attachment.contentType}
            />
            {isVideo && <PlayCircle />}
          </Box>
        </TouchableOpacity>
        {Platform.OS === 'web' && isImageCarouselVisible && (
          <ImagesPreview
            onDismiss={() => setImageCarouselVisible(false)}
            taskId={taskId}
          />
        )}
      </Box>
    );
  } else {
    return (
      <Box marginBottom='xxs' key={attachment.id}>
        <DocumentCard
          key={attachment.id}
          document={attachment}
          onPress={() => {
            if (Platform.OS === 'web') return openInNewTab(attachment);

            setPreviewDocuments([attachment]);
            navigation.navigate('file-preview', {
              location: 'task',
              taskId: taskId,
            });
          }}
        />
      </Box>
    );
  }
};
