import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView } from 'react-native';
import {
  ScaleDecorator,
  RenderItemParams,
  NestableScrollContainer,
  NestableDraggableFlatList,
} from 'react-native-draggable-flatlist';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Box, Text } from '@components/Restyle';
import { AddChecklist, FormValues } from '@components/Tasks/AddChecklist';
import { EditChecklist } from '@components/Tasks/EditChecklist';
import TaskCompletedChecklist from '@components/Tasks/TaskCompletedChecklist';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import {
  TaskChecklist as ChecklistType,
  useAddTaskChecklistMutation,
  useRemoveTaskChecklistMutation,
  useUpdateTaskChecklistMutation,
  useListTaskChecklistQuery,
  useUpdateTaskChecklistSortMutation,
  TaskMemberRole,
  ProjectMemberRole,
  TeamMemberRole,
  useGetTaskQuery,
} from '@graphql/generated';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';

export const TaskCheckList = () => {
  const { t } = useTranslation('models');
  const route = useRoute<TasksStackScreenProps<'task-check-list'>['route']>();
  const {
    params: { taskId = '' },
  } = route;

  const [showAddChecklist, setShowAddChecklist] = useState(false);
  const [editChecklistItem, setEditChecklistItem] = useState<ChecklistType>();
  const [checklist, setChecklist] = useState<ChecklistType[]>([]);
  const [completedChecklist, setCompletedChecklist] = useState<ChecklistType[]>(
    []
  );
  const [processing, setProcessing] = useState(false);

  const { data } = useListTaskChecklistQuery({
    variables: {
      id: taskId,
    },
  });

  const { data: task } = useGetTaskQuery({
    fetchPolicy: 'cache-only',
    variables: {
      id: taskId || '',
    },
    skip: !taskId,
  });

  const isTaskViewer =
    task?.getTask.currentUserRole === TaskMemberRole.Viewer &&
    task?.getTask.currentUserProjectRole !== ProjectMemberRole.Owner &&
    task?.getTask.currentUserProjectRole !== ProjectMemberRole.Admin &&
    task?.getTask.currentUserProjectRole !== ProjectMemberRole.Editor &&
    task?.getTask.currentUserProjectTeamRole !== TeamMemberRole.Owner &&
    task?.getTask.currentUserProjectTeamRole !== TeamMemberRole.Admin &&
    task?.getTask.currentUserProjectTeamRole !== TeamMemberRole.Member;

  const { listTaskChecklist } = data || { listTaskChecklist: undefined };

  const sortChecklist = (list: ChecklistType[]) => {
    const completedData: ChecklistType[] = [];
    const unCompletedData: ChecklistType[] = [];
    [...list].forEach((item) => {
      if (item.completedAt) {
        completedData.push(item);
      } else {
        unCompletedData.push(item);
      }
    });
    const completedDataSort = completedData.sort((a, b) => {
      if (a.sort && b.sort) {
        if (+a.sort > +b.sort) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    const unCompletedDataSort = unCompletedData.sort((a, b) => {
      if (a.sort && b.sort) {
        if (+a.sort > +b.sort) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return a.id.localeCompare(b.id);
      }
    });
    setCompletedChecklist([...completedDataSort]);
    setChecklist([...unCompletedDataSort]);
  };

  useEffect(() => {
    if (listTaskChecklist && listTaskChecklist.length > 0) {
      sortChecklist(listTaskChecklist as ChecklistType[]);
    } else {
      setShowAddChecklist(false);
    }
  }, [listTaskChecklist]);

  const [addTaskChecklist] = useAddTaskChecklistMutation({
    onCompleted: (data) => {
      setShowAddChecklist(false);
      sortChecklist(data.addTaskChecklist as ChecklistType[]);
      setProcessing(false);
      onNewItemClick();
    },
    onError: () => {
      setProcessing(false);
    },
    refetchQueries: ['listTaskChecklist'],
  });

  const [removeTaskChecklist] = useRemoveTaskChecklistMutation();

  const [updateTaskChecklist] = useUpdateTaskChecklistMutation({
    onCompleted: (data) => {
      const list = [...checklist, ...completedChecklist].map(
        (item: ChecklistType) =>
          item.id === data.updateTaskChecklist.id
            ? data.updateTaskChecklist
            : item
      );
      sortChecklist(list);
      setProcessing(false);
      setEditChecklistItem(undefined);
    },
    onError: () => {
      setProcessing(false);
      setEditChecklistItem(undefined);
    },
  });

  const [updateTaskChecklistSort] = useUpdateTaskChecklistSortMutation();

  const submit = (values: FormValues) => {
    setProcessing(true);
    if (values.id) {
      if (values.name !== editChecklistItem?.name)
        updateTaskChecklist({
          variables: {
            id: values.id,
            attributes: {
              name: values.name,
              completed: !!values.completed,
              sort: values.sort,
            },
          },
        });
      else {
        setProcessing(false);
        setEditChecklistItem(undefined);
      }
    } else {
      addTaskChecklist({
        variables: {
          taskId,
          attributes: {
            name: values.name,
          },
        },
      });
    }
  };

  const onDelete = (item: ChecklistType) => {
    setProcessing(true);
    removeTaskChecklist({
      variables: {
        id: item.id,
      },
      onCompleted: (data) => {
        const list = [...checklist].filter(
          (item: ChecklistType) => item.id !== data.removeTaskChecklist.id
        );
        setChecklist(list);
        const list1 = [...completedChecklist].filter(
          (item: ChecklistType) => item.id !== data.removeTaskChecklist.id
        );
        setCompletedChecklist(list1);
        setProcessing(false);
        setEditChecklistItem(undefined);
      },
      onError: () => {
        setProcessing(false);
        setEditChecklistItem(undefined);
      },
      update(cache) {
        checklist.forEach((item1) => {
          if (item1.id === item.id) {
            const cacheIdentifier = cache.identify(item1);
            cache.evict({ id: cacheIdentifier });
            cache.gc();
          }
        });
        completedChecklist.forEach((item1) => {
          if (item1.id === item.id) {
            const cacheIdentifier = cache.identify(item1);
            cache.evict({ id: cacheIdentifier });
            cache.gc();
          }
        });
      },
    });
  };

  const onNewItemClick = () => {
    setEditChecklistItem(undefined);
    setShowAddChecklist(true);
  };

  const onSelect = (item: ChecklistType) => {
    setProcessing(true);
    updateTaskChecklist({
      variables: {
        id: item.id,
        attributes: {
          name: item.name,
          completed: item.completedAt === null,
        },
      },
    });
  };

  const itemDivider = () => {
    return <Box height={1} backgroundColor='grey02' mx='m' />;
  };

  const renderItem = ({
    item,
    drag,
    isActive,
  }: RenderItemParams<ChecklistType>) => {
    return (
      <ScaleDecorator>
        <TouchableOpacity
          onLongPress={() => {
            drag();
          }}
          disabled={isActive || isTaskViewer}>
          {editChecklistItem && editChecklistItem === item ? (
            <EditChecklist
              item={item}
              onDelete={onDelete}
              onSelect={() => onSelect(item)}
              submit={(values: FormValues) => {
                submit(values);
              }}
              processing={processing}
            />
          ) : (
            <Box
              accessibilityLabel='Checklist Item'
              flex={1}
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-start'
              paddingVertical='m'
              marginLeft='m'
              marginRight='m'
              paddingRight='l'
              backgroundColor='white'>
              <Box marginRight='xs'></Box>
              <Box
                accessibilityLabel={item.name + ' Checkbox'}
                marginRight='xs'>
                <TouchableOpacity
                  disabled={isActive || isTaskViewer}
                  onPress={() => {
                    onSelect(item);
                  }}>
                  <CheckBoxButton checked={item.completedAt} />
                </TouchableOpacity>
              </Box>
              <Box pr='xxl' marginLeft='xs' flex={1}>
                <TouchableOpacity
                  disabled={isTaskViewer}
                  onPress={() => {
                    setEditChecklistItem(item);
                    setShowAddChecklist(false);
                  }}
                  onLongPress={() => {
                    drag();
                  }}>
                  <Text
                    accessibilityLabel={item.name}
                    variant='body1'
                    color='onSurface'>
                    {item.name}
                  </Text>
                </TouchableOpacity>
              </Box>
            </Box>
          )}
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };

  const onDragEnd = (data: ChecklistType[]) => {
    setProcessing(true);
    const updatedChecklist = data.map((item: ChecklistType, index: number) => ({
      ...item,
      sort: index + 1,
    }));

    setChecklist(updatedChecklist);
    const list = updatedChecklist.map((item: ChecklistType, index: number) => {
      return {
        id: item.id,
        name: item.name,
        completed: item.completedAt !== null,
        sort: index + 1,
      };
    });
    updateTaskChecklistSort({
      variables: {
        taskId: taskId,
        attributes: list,
      },
      onCompleted: (data) => {
        sortChecklist(data.updateTaskChecklistSort as ChecklistType[]);
        setProcessing(false);
        setEditChecklistItem(undefined);
      },
      onError: () => {
        setProcessing(false);
        setEditChecklistItem(undefined);
      },
    });
  };

  return (
    <ScrollView style={{ flex: 1 }}>
      <Box flex={1} backgroundColor='white'>
        <Box
          flexDirection='column'
          justifyContent='center'
          marginBottom='s'
          marginTop='s'>
          {!isTaskViewer && (
            <Box
              flexDirection='row'
              justifyContent='space-between'
              marginBottom='s'>
              <Text
                accessibilityLabel={t('models:tasks.buttons.addItem')}
                onPress={() => onNewItemClick()}
                variant='labelSmall'
                marginLeft='s'
                textAlign='center'
                numberOfLines={1}
                color='greenSecondary'>
                + New Item
              </Text>
            </Box>
          )}

          <Box>
            <Box height={1} backgroundColor='grey02' mx='s'></Box>
            <NestableScrollContainer>
              <KeyboardAwareScrollView
                scrollEnabled={true}
                onContentSizeChange={() => {}}>
                <NestableDraggableFlatList
                  data={checklist}
                  renderItem={renderItem}
                  scrollToOverflowEnabled={true}
                  showsVerticalScrollIndicator
                  ItemSeparatorComponent={itemDivider}
                  keyExtractor={(item: ChecklistType) => item.id.toString()}
                  onDragBegin={() => {
                    setEditChecklistItem(undefined);
                    setShowAddChecklist(false);
                  }}
                  onDragEnd={({ data }) => {
                    onDragEnd(data);
                  }}
                  ListFooterComponent={
                    <Box>
                      {checklist.length > 0 && itemDivider()}
                      {showAddChecklist && !isTaskViewer && (
                        <Box paddingVertical='s'>
                          <AddChecklist
                            submit={(values: FormValues) => submit(values)}
                            setShowAddChecklist={(
                              isShowAddChecklist: boolean
                            ) => setShowAddChecklist(isShowAddChecklist)}
                            processing={processing}
                          />
                        </Box>
                      )}
                      {showAddChecklist && itemDivider()}
                      {completedChecklist.length > 0 && (
                        <Box flex={0}>
                          <TaskCompletedChecklist
                            completedChecklist={completedChecklist}
                            onSelect={onSelect}
                            isTaskViewer={isTaskViewer}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                />
              </KeyboardAwareScrollView>
            </NestableScrollContainer>
          </Box>
        </Box>
      </Box>
    </ScrollView>
  );
};
