import {
  StackActions,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Avatar from '@components/Avatar/Avatar';
import { HeaderWrapper } from '@components/Headers/HeaderWrapper';
import { ProjectTasksPopup } from '@components/Popups/ProjectTasksPopup';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import RestyleIcon from '@components/shared/Icon/RestyleIcon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { File } from '@graphql/generated';
import useSearch from '@hooks/useSearch';

type SecondaryHeaderProps = {
  title: string;
  searchable: boolean;
  name?: string;
  avatar?: File;
  autoFocus?: boolean;
  isSmall?: boolean;
  showMenu?: boolean;
  redirectTo?: string;
  goBack?: boolean;
  topWrap?: boolean;
  stackRouter?: string;
  dismissProject?: () => void;
  isProject?: boolean;
} & (
  | {
      avatar?: never;
      name?: never;
    }
  | {
      avatar: File;
      name: string;
    }
);
const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  title,
  searchable,
  avatar,
  autoFocus,
  isSmall,
  showMenu,
  redirectTo,
  stackRouter,
  goBack = false,
  topWrap = true,
  isProject = false,
  dismissProject,
}) => {
  const navigation = useNavigation();
  const focused = useIsFocused();
  const route = useRoute();
  const insets = useSafeAreaInsets();

  const popAction = StackActions.popToTop();

  const { isSearching, setIsSearching } = useSearch();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const hasProjectId =
    route.params && 'projectId' in route.params && !!route.params.projectId;

  const hasTaskId =
    route.params && 'taskId' in route.params && !!route.params.taskId;

  const renderPopup = () => {
    if (!showMenu) return null;

    switch (title) {
      case 'Tasks':
        return (
          <ProjectTasksPopup closePopFn={() => setModalOpen(!modalOpen)} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    autoFocus && setIsSearching(true);
  }, []);

  useEffect(() => {
    !focused && setIsSearching(false); // reset to not searching
  }, [focused]);

  const openProjectDetailModal = () => {
    navigation.navigate('project-details-modal', {
      projectId: route.params.projectId,
    });
  };

  const openTaskDetailModal = () => {
    navigation.navigate('task-details-modal', { taskId: route.params.taskId });
  };

  const handleOnPress = () => {
    {
      if (isProject) {
        dismissProject?.();
      } else if (goBack) {
        navigation.goBack();
      } else {
        redirectTo && stackRouter
          ? navigation.navigate(stackRouter as string, {
              screen: redirectTo as string,
            })
          : navigation.dispatch(popAction);
      }
    }
  };

  return (
    <>
      {!isSearching || !searchable ? (
        <>
          <Modal
            animationType='fade'
            transparent={true}
            visible={modalOpen}
            onRequestClose={() => setModalOpen(false)}>
            <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
              <Box style={styles.modal}></Box>
            </TouchableWithoutFeedback>
            <Box style={[styles.popUp, { top: 51 + insets.top }]}>
              {renderPopup()}
            </Box>
          </Modal>
          <HeaderWrapper topWrap={topWrap}>
            <Box>
              <TouchableOpacity
                accessibilityLabel='Back'
                onPress={handleOnPress}
                hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
                <RestyleIcon
                  name='ChevronLeft'
                  variant='xl'
                  color='textPrimary'
                />
              </TouchableOpacity>
            </Box>
            <Box flex={1} flexDirection='row' marginLeft='xs'>
              {avatar && (
                <Box marginRight='xs'>
                  <Avatar avatar={avatar} />
                </Box>
              )}
              <Text
                variant={isSmall ? 'labelEmphasized' : 'labelLarge'}
                color='textPrimary'
                textAlign='left'>
                {title}
              </Text>
            </Box>
            {searchable && (
              <Icon
                name='Search'
                variant='m'
                color='textPrimary'
                onPress={() => {
                  setIsSearching(!isSearching);
                  if (hasProjectId && !route?.params?.navigateFrom) {
                    navigation.push('files-search', {
                      projectId: route.params?.projectId,
                    });
                  }
                  if (hasTaskId && !route?.params?.navigateFrom) {
                    navigation.push('files-search', {
                      taskId: route.params.taskId,
                    });
                  }
                }}
                accessibilityLabel='Search'
              />
            )}

            {renderPopup() && (
              <Icon
                name='MoreVertical'
                variant='l'
                color='textPrimary'
                onPress={() => setModalOpen(!modalOpen)}
                accessibilityLabel='Options'
              />
            )}
            {hasTaskId && title === 'Task Details' && showMenu && (
              <Icon
                testId='taskOptions'
                name='MoreVertical'
                variant='l'
                color='textPrimary'
                onPress={openTaskDetailModal}
                accessibilityLabel='Options'
              />
            )}
            {hasProjectId && title === 'Project Details' && showMenu && (
              <Icon
                name='MoreVertical'
                variant='l'
                color='textPrimary'
                onPress={openProjectDetailModal}
                accessibilityLabel='Options'
              />
            )}
            {hasProjectId && route?.name === 'project-detail' && (
              <TouchableOpacity
                onPress={() => void 0}
                accessibilityLabel='Recover'>
                <Text color='greenSecondary' variant='labelSmall'>
                  Recover
                </Text>
              </TouchableOpacity>
            )}
          </HeaderWrapper>
        </>
      ) : (
        <SearchDisplay />
      )}
    </>
  );
};

const SearchDisplay = () => {
  const insets = useSafeAreaInsets();
  const { setSearch, isSearching, setIsSearching, search } = useSearch();
  useEffect(() => {
    setIsSearching(true);
    return () => setIsSearching(false);
  }, []);
  return (
    <Box
      borderBottomWidth={0.5}
      borderBottomColor='grey02'
      marginTop='xs'
      paddingBottom='xs'
      style={{ paddingTop: insets.top }}>
      <Box flexDirection='row' marginHorizontal='m' alignItems='center'>
        <SearchInput
          value={search}
          onTextChanged={(t: string) => setSearch(t)}
          autoFocus
        />
        <TouchableOpacity
          onPress={() => (setSearch(''), setIsSearching(!isSearching))}>
          <Text color='greenSecondary' variant='buttonLabel' marginLeft='xs'>
            Cancel
          </Text>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  popUp: {
    zIndex: 100,
    position: 'absolute',
    right: 16,
  },
});

export default SecondaryHeader;
