import { useNavigation, useRoute } from '@react-navigation/native';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import Avatar from '@components/Avatar/Avatar';
import CachedImage from '@components/CachedImage/CachedImage';
import { ModalHeader } from '@components/Headers/ModalHeader';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { removeProjectLimitSnackBarNextTime } from '@components/Projects/ProjectForm.web';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  GetTeamDocument,
  ListTeamsDocument,
  LocalFile,
  MemberAttributes,
  Price,
  Product,
  TeamRole,
  User,
  useEditStripeSubscriptionMutation,
  useListProductsQuery,
  useListTeamsQuery,
  useRemoveTeamMemberMutation,
  useUpdateTeamMembersMutation,
  useUpdatePaymentMethodMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { FormValues } from '@navigation/payments/create-subscription-stack';
import { BillingCycle, PlanName } from '@root/types';
import { MOBILE_PLAN_NAME, WEB_PLAN_NAME } from '@src/constants/constants';
import theme from '@themes/theme';

// this page is for web edit subscription
export const EditSubscription: React.FC = () => {
  enum viewType {
    MainPage = 'Main Page',
    EditPlan = 'Edit Plan',
    EditMember = 'Edit Member',
    EditPaymentMethod = 'Edit Payment Method',
  }
  type UserWithRole = User & { role: TeamRole };

  const { values, setFieldValue, touched } = useFormikContext<FormValues>();
  const { users, plan, priceId, billingCycle, name, logo } = values;
  const route = useRoute();
  const { teamId } = route?.params || {};

  const { team: teamDetail } = useTeamFromQuery({
    teamId,
  });

  const stripe = useStripe();

  const [loading, setLoading] = useState(false);
  const [pageViewType, setViewType] = useState<viewType>(
    users.length === teamDetail?.usersCount
      ? viewType.MainPage
      : viewType.EditMember
  );
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [selectedMemberRole, setSelectedMemberRole] = useState<TeamRole>();
  const [selectedMember, setSelectedMember] = useState<User>();
  const [editRoleModalOpen, setEditRoleModalOpen] = useState<boolean>(false);
  const [isDeleteMemberModalVisible, setDeleteMemberModalVisible] =
    useState<boolean>(false);

  const { me } = useMe();
  const { data } = useListTeamsQuery({ fetchPolicy: 'cache-only' });
  const { listTeams } = data || {};
  const navigator = useNavigation();
  const { t } = useTranslation();
  const { data: listProductsData } = useListProductsQuery();

  const { setUpgradeTeamPlanOpen } = useWebDrawer();

  const TeamMemberRoles = [TeamRole.Admin, TeamRole.Member];

  const [updateTeamMembers] = useUpdateTeamMembersMutation();

  const [editStripeSubscription] = useEditStripeSubscriptionMutation({
    onCompleted: () => {
      setLoading(false);
      removeProjectLimitSnackBarNextTime(me?.id, '0');
      removeProjectLimitSnackBarNextTime(me?.id, teamId);
    },
    refetchQueries: [{ query: ListTeamsDocument }],
    onError: () => setLoading(false),
  });

  const [updatePaymentMethod] = useUpdatePaymentMethodMutation({
    onCompleted: () => {
      setLoading(false);
    },
    refetchQueries: [{ query: ListTeamsDocument }],
    onError: () => setLoading(false),
  });

  const { listProducts } = listProductsData || {};
  const elements = useElements();

  const [showError, setShowError] = useState(false);
  const [payment, setPayment] = useState(elements?.getElement('payment'));

  const personalTeam = listTeams?.find((t) => t?.personal);

  type PlanWithPrices = {
    [id: Product['id']]: {
      perMonth: Price['amount'];
      perYear: Price['amount'];
    };
  };
  const stripeOptions: StripePaymentElementOptions = {};

  const [removeTeamMember] = useRemoveTeamMemberMutation();

  // convert Stripe products in to an easier to use format
  const plans: PlanWithPrices =
    listProducts?.reduce((acc, product) => {
      const prices = {
        perMonth:
          product?.prices?.find((price) => price?.interval === 'month')
            ?.amount || 0,
        perYear:
          product?.prices?.find((price) => price?.interval === 'year')
            ?.amount || 0,
      };
      return { ...acc, [product['id']]: prices };
    }, {}) || {};

  const { team } = useTeamFromQuery({ teamId: teamId as unknown as string });
  const isPersonal = team?.personal ?? false;

  const filteredProducts = listProducts?.filter((plan) =>
    plan?.name
      .toLowerCase()
      .includes(Platform.OS === 'web' ? WEB_PLAN_NAME : MOBILE_PLAN_NAME)
  );

  const [displayPlan, setDisplayPlan] = React.useState(
    !team?.plan && (filteredProducts?.length ?? 0) > 0
      ? filteredProducts![0].id
      : plan
  );

  const usersAndMe = () => {
    return users;
  };

  const sortedUsers = [...(usersAndMe() || [])]?.sort((u) =>
    u?.role !== TeamRole?.Owner ? 1 : -1
  );

  const setPlan = (plan: PlanName) => {
    const product = listProducts?.find((p) => p?.id === plan);
    setFieldValue('plan', plan);
    product && setFieldValue('planName', product?.name);

    const price = product?.prices?.find(
      (price) => price?.interval === billingCycle
    );
    price && setFieldValue('priceId', price?.externalId);
    price && setFieldValue('price', subtotalCost());

    if (!personalTeam) return;

    if (!product?.allowMembers) {
      setFieldValue('teamId', personalTeam?.id);
    } else {
      setFieldValue('teamId', null);
    }
  };

  const setBillingCycle = (billingCycle: BillingCycle) => {
    setFieldValue('billingCycle', billingCycle);
  };

  const selectedProduct = listProducts?.find((p) => p?.id === displayPlan);

  const subtotalCost = () => {
    if (!listProducts?.length || !displayPlan) return 0;

    const multiplier = !selectedProduct?.allowMembers
      ? 1
      : Math.max(users.length, 1);

    const planCost =
      billingCycle === 'year'
        ? plans?.[displayPlan]?.perYear
        : plans?.[displayPlan]?.perMonth;
    return Math.ceil(planCost * multiplier);
  };
  const subtotalValue = subtotalCost();

  const subtotalLabel = `$${(subtotalValue / 100)?.toFixed(2)}`;

  const previousMembersCost = () => {
    const planCost =
      billingCycle === 'year'
        ? plans?.[displayPlan]?.perYear
        : plans?.[displayPlan]?.perMonth;
    return `$${(
      Math.ceil(planCost * (teamDetail?.usersCount ?? 1)) / 100
    )?.toFixed(2)}`;
  };

  const newMembersCost = () => {
    const planCost =
      billingCycle === 'year'
        ? plans?.[displayPlan]?.perYear
        : plans?.[displayPlan]?.perMonth;

    return `$${(Math.ceil(planCost * users.length) / 100)?.toFixed(2)}`;
  };
  const originalCost = () => {
    if (!listProducts?.length || !displayPlan) return 0;
    const multiplier =
      displayPlan === MOBILE_PLAN_NAME ? 1 : Math.max(users.length, 1);

    const selectedPlan = plans?.[displayPlan];
    return billingCycle === 'year'
      ? selectedPlan?.perMonth * 12 * multiplier
      : selectedPlan?.perMonth * multiplier;
  };

  const teamIcon = () => {
    if (isPersonal) {
      return (
        <Box
          width={24}
          height={24}
          borderRadius='xxs'
          backgroundColor='greenSecondary'
          justifyContent='center'
          alignItems='center'>
          <Icon variant='s' name='Office' color='white' />
        </Box>
      );
    } else {
      return <Icon name='TT24' variant='l' color='textPrimary' />;
    }
  };

  const originalCostLabel = `$${(originalCost() / 100)?.toFixed(2)}`;

  const PlanItemWeb = (product: Product, isMonthly: boolean) => {
    const monthPrice = product.prices.filter((t) => t.interval === 'month');
    const yearlyPrice = product.prices.filter((t) => t.interval === 'year');
    const discountedPrice = yearlyPrice && yearlyPrice[0].amount / 12;

    const price = monthPrice.length > 0 ? monthPrice[0].amount : 0;

    const isTeamPlan = product.allowMembers;
    return (
      <Box
        key={(isMonthly ? '1' : '2') + product.id}
        flex={1}
        borderColor='grey02'
        borderWidth={1}
        borderRadius='xs'
        flexDirection='row'
        accessibilityLabel={product.name}
        p='m'
        marginTop='xs'
        alignItems='center'>
        <RadioButton
          isSelected={
            displayPlan === product.id &&
            billingCycle === (isMonthly ? 'month' : 'year')
          }
          // Users can switch to an annual plan at any time, but once switched,
          // they cannot downgrade back to a monthly plan
          disabled={isMonthly && team?.plan?.interval === 'year'}
          onPress={() => {
            if (
              displayPlan !== product.id ||
              billingCycle !== (isMonthly ? 'month' : 'year')
            ) {
              setDisplayPlan(product.id);
              setPlan(product.id);

              setBillingCycle(isMonthly ? 'month' : 'year');
            }
          }}
        />
        <Icon
          marginHorizontal='s'
          name={
            product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
              ? 'Zap'
              : 'Star'
          } // TODO get this from metadata?
          backgroundColor={
            product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
              ? 'orange'
              : 'purple'
          } // TODO get this from metadata?
          color='background'
        />
        <Box flex={1}>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='labelEmphasized' mb='xxxs' color='textPrimary'>
              {product.name + ' Plan'}
            </Text>
            <Box style={{ marginHorizontal: 6 }}>
              <Icon name='HelpCircle' color='grey04' />
            </Box>

            {!isMonthly && (
              <Box
                style={{
                  backgroundColor: '#DCF2EC',
                  borderRadius: 4,
                  padding: 4,
                }}>
                <Text variant='metadata' color='greenSecondary'>
                  {percentageSavedWeb(product)}% off
                </Text>
              </Box>
            )}
          </Box>
          <Text variant='webBodySecondary' color='grey04'>
            {isTeamPlan && 'Billed ' + (isMonthly ? 'Monthly' : 'Annually')}
          </Text>
        </Box>
        <Box
          alignContent='flex-end'
          justifyContent='flex-end'
          alignItems='flex-end'>
          <Box flexDirection='row' marginRight='xxxs'>
            <Text
              variant={
                isMonthly ? 'labelEmphasized' : 'labelDisabledStrikeThrough'
              }
              mt='xxs'
              marginRight='xxs'>
              ${(price / 100)?.toFixed(0)}
            </Text>
            {!isMonthly && (
              <Text variant='labelEmphasized' mb='xxxs' color='textPrimary'>
                ${(discountedPrice / 100).toFixed(0)}
              </Text>
            )}
          </Box>
          <Text variant='webBodySecondary' color='grey04'>
            per user per month
          </Text>
        </Box>
      </Box>
    );
  };

  const showSubscripeFail = () => {
    setShowError(true);
  };

  const maskEmail = (email: string) => {
    if (!email.includes('@')) return '';
    const [localPart, domain] = email.split('@');

    if (domain.length < 10) {
      return `${localPart[0]}***@${domain}`;
    } else {
      return `${localPart[0]}***@${domain.substring(
        0,
        3
      )} ... ${domain.substring(domain.length - 5)}`;
    }
  };

  useEffect(() => {
    const product = listProducts?.find((p) => p.id === displayPlan);
    const price = product?.prices.find(
      (price) => price.interval === billingCycle
    );
    price && setFieldValue('priceId', price.externalId);
    price && setFieldValue('price', subtotalCost());
  }, [billingCycle, subtotalValue]);

  useEffect(() => {
    if (!payment) return;

    payment.on('change', (e) => {
      setFieldValue('paymentComplete', e.complete);
    });

    return () => {
      // Stripe documentation indicated this should be done, even though TS is yelling about it
      (payment as any)?.removeAllListeners();
    };
  }, [payment]);

  useEffect(() => {
    if (Boolean(team?.id) && !isPersonal) {
      setFieldValue('name', team?.name);
      setFieldValue('teamId', team?.id);
    }
  }, [plan]);

  useEffect(() => {
    if (!filteredProducts) return;

    const product = filteredProducts[0];
    product && setDisplayPlan(product.id);
    product && setFieldValue('plan', product.id);
    product && setFieldValue('planName', product.name);
  }, [filteredProducts?.length]);

  const percentageSavedWeb = (product: Product) => {
    const selectedPlan = plans?.[product.id];
    if (!selectedPlan) {
      return undefined;
    }

    const monthPerYear = selectedPlan?.perMonth * 12;
    const annualPrice = selectedPlan?.perYear;
    const percentage = ((monthPerYear - annualPrice) / monthPerYear) * 100;
    return Math.trunc(percentage);
  };

  let upToSave = 20;
  filteredProducts?.forEach((p) => {
    const saved = percentageSavedWeb(p) ?? 0;
    if (upToSave <= saved) upToSave = saved;
  });

  const removeMemberFromTeam = (userId: string) => {
    setFieldValue(
      'users',
      users.filter((u) => u.id !== userId)
    );
  };

  const changeBillingFrequency = async () => {
    setLoading(true);
    const { data: subscriptionData } = await editStripeSubscription({
      variables: {
        stripeSubscriptionAttributes: {
          name: plan || '',
          teamId: teamId.toString(),
          quantity: users.length,
          priceId: priceId,
        },
      },
      refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
    });
    const { editStripeSubscription: subscriptionResponse } =
      subscriptionData || {};

    if (!subscriptionResponse) {
      showSubscripeFail();
      return;
    } else {
      if (!subscriptionResponse) {
        showSubscripeFail();
        return;
      }
      const { team, clientSecret } = subscriptionResponse;

      if (stripe) {
        const { error } = await stripe.confirmPayment({
          clientSecret,
          confirmParams: {
            return_url: `${process.env.STRIPE_REDIRECT_BASE_URL}/my-account/updated/${team.id}/${plan}`,
          },
        });
        setLoading(false);
        if (error) {
          showSubscripeFail();
        }
      }
    }
    setLoading(false);
    setViewType(viewType.MainPage);
  };

  const changePaymentMethod = async () => {
    if (stripe && elements) {
      setLoading(true);

      const res0 = await elements.submit();

      if (!res0.error) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements: elements,
        });

        if (paymentMethod) {
          await updatePaymentMethod({
            variables: {
              teamId: teamId,
              paymentMethodToken: paymentMethod?.id ?? '',
            },
            refetchQueries: [
              { query: GetTeamDocument, variables: { id: teamId } },
            ],
          });
        }
        setLoading(false);
        if (error) {
          showSubscripeFail();
        }
        setViewType(viewType.MainPage);
      } else {
        showSubscripeFail();
      }
    }
  };
  const changeSeat = async () => {
    setLoading(true);
    if (touched.users) {
      const membersNeedToRemove = teamDetail?.users.filter(
        (tu) => !users.some((nu) => nu.id === tu.id)
      );

      const membersNeedToUpdate = users.filter(
        (nu) =>
          !teamDetail?.users.some(
            (tu) => nu.id === tu.id && tu.teamRoles?.includes(nu.role)
          )
      );

      const members: MemberAttributes[] = membersNeedToUpdate.map((u) => ({
        userId: u.id,
        role: u.role,
      }));

      setLoading(true);
      await Promise.all([
        ...(membersNeedToRemove
          ? membersNeedToRemove.map(({ id: userId }) =>
              removeTeamMember({
                variables: {
                  id: teamId,
                  userId,
                },
                refetchQueries: [
                  { query: GetTeamDocument, variables: { id: teamId } },
                ],
              })
            )
          : []),
        updateTeamMembers({
          variables: {
            teamId,
            attributes: members.filter((t) => t.role !== 'OWNER'),
          },
          onError: (e) => {
            console.log('error, ', e);
            showSubscripeFail();
            return;
          },
          refetchQueries: [
            { query: GetTeamDocument, variables: { id: teamId } },
          ],
        }),
      ]);
      setLoading(false);
      setViewType(viewType.MainPage);
    }
  };
  const editPanel = (
    node: ReactNode,
    doEdit: () => void,
    disable = false,
    paddingBottom: keyof typeof theme.spacing = 'm'
  ) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        borderWidth={1}
        borderRadius='xs'
        borderColor='grey02'
        px='m'
        pt='m'
        pb={paddingBottom}
        style={{
          shadowOpacity: 0.05,
          shadowOffset: { width: 0, height: 0.25 },
        }}>
        <Box flex={1} marginRight='m' alignItems='flex-start'>
          {node}
        </Box>
        {!disable && (
          <TouchableOpacity
            accessibilityLabel={t('shared:edit')}
            onPress={doEdit}>
            <Box
              backgroundColor='greenSecondaryMild'
              width={30}
              height={24}
              alignItems='center'
              borderRadius='xxs'
              justifyContent='center'>
              <Text color='greenSecondary' variant='metadata'>
                {t('shared:edit')}
              </Text>
            </Box>
          </TouchableOpacity>
        )}
      </Box>
    );
  };
  const subTitle1 = (
    xtitle: string,
    marginBottom: keyof typeof theme.spacing = 'xs'
  ) => {
    return (
      <Text
        marginBottom={marginBottom}
        variant='labelLarge'
        color='textPrimary'>
        {xtitle}
      </Text>
    );
  };

  const subTitle2 = (
    xtitle: string,
    xcolor: keyof typeof theme.colors = 'textSecondary',
    marginBottom: keyof typeof theme.spacing = 'm'
  ) => (
    <Text variant='webBodySecondary' mb={marginBottom} color={xcolor}>
      {xtitle}
    </Text>
  );

  const updateTeamMemberRole = (userId: string, role: TeamRole) => {
    const newUsers: UserWithRole[] = [];
    users.forEach((u) => {
      if (u.id === userId) {
        u.role = role;
      }
      newUsers.push(u);
    });
    setFieldValue('users', newUsers);
  };

  const cancelEditPlanPage = () => {
    setPlan(teamDetail?.plan?.id);
    setBillingCycle((teamDetail?.plan?.interval ?? 'month') as BillingCycle);
    setViewType(viewType.MainPage);
  };

  const cancelEditMemberPage = () => {
    setFieldValue(
      'users',
      teamDetail?.users.map((u) => ({
        ...u,
        role:
          u.teamRoles && u.teamRoles.length > 0
            ? u.teamRoles[0]
            : TeamRole.Member,
      }))
    );
    setViewType(viewType.MainPage);
  };
  const cancelPaymentMethodPage = () => {
    setViewType(viewType.MainPage);
  };

  return (
    <>
      {isDeleteMemberModalVisible && (
        <ConfirmModal
          showModal={isDeleteMemberModalVisible}
          onClose={() => setDeleteMemberModalVisible(false)}
          onPress={() => {
            setDeleteMemberModalVisible(false);
            removeMemberFromTeam(selectedMember?.id ?? '0');
          }}
          buttonText={t('models:teams.removeTeamMember.remove')}
          title={t('models:teams.removeTeamMember.title')}
          message={t('models:teams.removeTeamMember.message', {
            member: selectedMember?.name ?? '',
          })}
        />
      )}

      {showError && (
        <ConfirmModal
          isAlert={true}
          showModal={showError}
          onClose={() => setShowError(false)}
          onPress={() => setShowError(false)}
          buttonText='     OK     '
          title={t('models:teams.subscriptions.failureAlert.title')}
          message={t('models:teams.subscriptions.failureAlert.message')}
        />
      )}
      <ScrollView
        style={{ backgroundColor: 'white' }}
        showsVerticalScrollIndicator={false}>
        <Box backgroundColor='white' maxWidth={560}>
          <Box flexDirection='row' marginBottom='s'>
            <Box flex={1} flexDirection='row'>
              <Text variant='heading2' color='textPrimary' textAlign='left'>
                {t('models:profile.subscriptionPlans.editSubscription')}
              </Text>
            </Box>
            <Box alignItems='flex-end'>
              <Box flexDirection='row' accessibilityLabel='Close'>
                <Icon
                  name='X'
                  onPress={() => {
                    switch (pageViewType) {
                      case viewType.MainPage:
                        setUpgradeTeamPlanOpen(false);
                        break;
                      case viewType.EditPlan:
                        cancelEditPlanPage();
                        break;
                      case viewType.EditMember:
                        cancelEditMemberPage();
                        break;
                      case viewType.EditPaymentMethod:
                        cancelPaymentMethodPage();
                        break;
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {pageViewType === viewType.MainPage && (
            <>
              <Box my='m'>
                {subTitle1('Select Team')}
                <Box
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='center'
                  borderColor='grey03'
                  backgroundColor='grey01'
                  borderWidth={1}
                  borderRadius='xs'
                  height={48}
                  mt='xs'
                  mb='m'>
                  {team?.avatarFile && (
                    <CachedImage
                      image={team.avatarFile as unknown as LocalFile}
                      width={32}
                      height={32}
                      borderRadius={theme.spacing.xs}
                    />
                  )}
                  {!team?.avatarFile && name && logo && (
                    <CachedImage
                      image={logo as unknown as LocalFile}
                      height={32}
                      width={32}
                      borderRadius={theme.spacing.xs}
                    />
                  )}
                  {!team?.avatarFile && !logo && teamIcon()}
                  <Text
                    mx='xs'
                    variant='labelSmall'
                    color='textPrimary'
                    numberOfLines={1}>
                    {team?.name || name}
                  </Text>
                </Box>
              </Box>

              <Box mb='l'>
                {subTitle1(
                  t('models:teams.subscriptions.chooseYourBillingFrequency')
                )}
                <Box flexDirection='row'>
                  {subTitle2(
                    t('models:payments.annualSavings', {
                      savingPercentage: upToSave,
                    }) + ' ',
                    'greenSecondary'
                  )}
                  {subTitle2(
                    t('models:teams.subscriptions.whenYouSubscribeAnnually')
                  )}
                </Box>

                {editPanel(
                  <Box
                    height={42}
                    flexDirection='row'
                    alignContent='flex-start'
                    alignItems='center'>
                    <Icon
                      name='Star'
                      backgroundColor='purple'
                      color='background'
                      marginRight='xs'
                    />
                    <Box>
                      <Box flexDirection='row' alignItems='center'>
                        <Text variant='labelEmphasized' color='textPrimary'>
                          {(teamDetail?.plan?.name ?? 'Team') + ' Plan'}
                        </Text>
                        <Box style={{ marginHorizontal: 6 }}>
                          <Icon name='HelpCircle' color='grey04' />
                        </Box>
                      </Box>
                      <Text variant='webBodySecondary' color='grey04'>
                        Billed{' '}
                        {team?.plan?.interval !== 'year'
                          ? 'Monthly'
                          : 'Annually'}
                      </Text>
                    </Box>
                  </Box>,
                  () => {
                    setConfirmEdit(false);
                    setViewType(viewType.EditPlan);
                  },
                  teamDetail?.plan?.interval === 'year'
                )}
              </Box>
              <Box my='m'>
                {subTitle1(t('models:teams.subscriptions.teamMembers'))}
                {subTitle2(
                  'Your current subscription includes ' +
                    (team?.usersCount ?? 1).toString() +
                    ' team member' +
                    ((team?.usersCount ?? 1) > 1 ? 's' : '')
                )}
                {editPanel(
                  <>
                    {!isPersonal && sortedUsers && sortedUsers.length > 0 && (
                      <Box
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'>
                        <Box
                          flex={1}
                          flexDirection='row'
                          alignItems='flex-start'
                          justifyContent='flex-start'
                          flexWrap='wrap'>
                          {sortedUsers?.map((user) => {
                            if (!user) return;
                            const {
                              id: memberId,
                              firstName,
                              name,
                              avatar,
                            } = user;

                            return (
                              <Box
                                key={memberId}
                                marginRight='m'
                                alignItems='center'>
                                <Avatar
                                  disableAvatarPress={true}
                                  size='large'
                                  label={name}
                                  avatar={avatar}
                                />
                                <Text
                                  variant='webSmall'
                                  textAlign='center'
                                  style={{
                                    width: 56,
                                    marginTop: 10,
                                  }}
                                  marginBottom='m'
                                  numberOfLines={1}>
                                  {firstName}
                                </Text>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  </>,
                  () => {
                    setConfirmEdit(false);
                    setViewType(viewType.EditMember);
                  },
                  false,
                  'none'
                )}
              </Box>

              <Box my='m'>
                {subTitle1(t('models:teams.subscriptions.paymentMethod'))}
                {subTitle2(
                  t(
                    'models:teams.subscriptions.thisPaymentMethodWillBeBilledForYourSubscription'
                  )
                )}
                {teamDetail &&
                  editPanel(
                    <>
                      {teamDetail.plan &&
                        teamDetail.plan.payment?.brand.includes('@') && (
                          <Box
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='center'>
                            <Icon
                              name='LinkPayment'
                              variant='l'
                              color='white'
                            />
                            <Text
                              variant='labelSmall'
                              color='textSecondary'
                              ml='xxs'>
                              {`Link  ${maskEmail(
                                teamDetail.plan.payment?.brand ?? ''
                              )}`}
                            </Text>
                            <Box flex={1} />
                          </Box>
                        )}
                      {/*stripe payment method*/}
                      {teamDetail.plan?.payment?.last4 && (
                        <Box
                          flexDirection='row'
                          justifyContent='center'
                          alignItems='center'>
                          <Icon name='Visa' variant='xxl' color='white' />
                          <Text
                            variant='labelSmall'
                            color='textSecondary'
                            ml='m'>
                            **** **** **** {teamDetail.plan.payment?.last4}
                          </Text>
                        </Box>
                      )}
                      {teamDetail.plan && !teamDetail.plan.payment?.last4 && (
                        <Box
                          flexDirection='row'
                          justifyContent='center'
                          alignItems='center'>
                          <Text variant='labelSmall' color='textSecondary'>
                            Payment method not found.
                          </Text>
                        </Box>
                      )}
                    </>,
                    () => {
                      setConfirmEdit(false);
                      setViewType(viewType.EditPaymentMethod);
                    }
                  )}
              </Box>
            </>
          )}
          {pageViewType === viewType.EditPlan && (
            <>
              <Box mb='l'>
                {subTitle1(
                  t('models:teams.subscriptions.chooseYourBillingFrequency')
                )}
                <Box flexDirection='row'>
                  {subTitle2(
                    t('models:payments.annualSavings', {
                      savingPercentage: upToSave,
                    }) + ' ',
                    'greenSecondary'
                  )}
                  {subTitle2(
                    t('models:teams.subscriptions.whenYouSubscribeAnnually')
                  )}
                </Box>
                <Box flex={1}>
                  {filteredProducts?.map((product) => {
                    return PlanItemWeb(product, false);
                  })}
                  {filteredProducts?.map((product) => {
                    return PlanItemWeb(product, true);
                  })}
                </Box>
              </Box>

              <Box
                mb='l'
                flexDirection='row'
                flex={1}
                style={{ paddingVertical: 32 }}
                px='l'
                borderColor='grey02'
                borderWidth={1}
                backgroundColor='grey01'
                accessibilityLabel={t('models:teams.subscriptions.subtotal')}
                alignItems='center'>
                <Box>
                  {subTitle1(t('models:teams.subscriptions.subtotal', 'xxs'))}
                  {subTitle2(
                    `${users.length} Team Member${
                      users.length === 1 ? '' : 's'
                    }`,
                    'textSecondary',
                    'xxs'
                  )}
                  {subTitle2(
                    billingCycle === 'year'
                      ? t('models:teams.subscriptions.billedYearly')
                      : t('models:teams.subscriptions.billedMonthly')
                  )}
                </Box>

                <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                  <Box>
                    {subTitle1(subtotalLabel, 'xxs')}
                    {billingCycle === 'year' && (
                      <Text
                        color='grey04'
                        textDecorationStyle='solid'
                        textDecorationLine='line-through'
                        mr='xxs'>
                        {originalCostLabel}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box flexDirection='row' alignItems='center' m='l'>
                <Box marginRight='xs'>
                  <TouchableOpacity
                    onPress={() => setConfirmEdit(!confirmEdit)}
                    accessibilityLabel={t('shared:confirm')}>
                    <CheckBoxButton checked={confirmEdit} />
                  </TouchableOpacity>
                </Box>

                {subTitle1(
                  t('models:teams.subscriptions.confirmChangeBillingFrequency')
                )}
              </Box>
              <Box flexDirection='row' flex={1} mt='l'>
                <Box flex={1}>
                  <Button
                    fullWidth
                    variant='cancel'
                    onPress={cancelEditPlanPage}>
                    <Text color='black'>{t('shared:cancel')}</Text>
                  </Button>
                </Box>

                <Box flex={1}>
                  <Button
                    fullWidth
                    marginLeft='xs'
                    variant='primary'
                    disabled={
                      !confirmEdit ||
                      teamDetail?.plan?.interval === billingCycle ||
                      loading
                    }
                    onPress={changeBillingFrequency}>
                    {loading ? (
                      <ActivityIndicator />
                    ) : (
                      <Text color='background'>
                        {t('models:teams.subscriptions.changeBillingFrequency')}
                      </Text>
                    )}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {pageViewType === viewType.EditPaymentMethod && (
            <>
              <Box>
                {subTitle1(t('models:teams.subscriptions.paymentMethod'))}
                {subTitle2(
                  t(
                    'models:teams.subscriptions.thisPaymentMethodWillBeBilledForYourSubscription'
                  )
                )}
              </Box>

              <Box style={{ marginBottom: 4 }}>
                <PaymentElement
                  options={stripeOptions}
                  onReady={() => {
                    const xPayment = elements?.getElement('payment');
                    if (xPayment) setPayment(xPayment);
                  }}
                />
              </Box>
              <Box flexDirection='row' alignItems='center' m='l'>
                <Box marginRight='xs'>
                  <TouchableOpacity
                    onPress={() => setConfirmEdit(!confirmEdit)}
                    accessibilityLabel={t('shared:confirm')}>
                    <CheckBoxButton checked={confirmEdit} />
                  </TouchableOpacity>
                </Box>

                {subTitle1(
                  t('models:teams.subscriptions.confirmChangePaymentMethod')
                )}
              </Box>
              <Box flexDirection='row' flex={1} mt='l'>
                <Box flex={1}>
                  <Button
                    fullWidth
                    variant='cancel'
                    onPress={cancelPaymentMethodPage}>
                    <Text color='black'>{t('shared:cancel')}</Text>
                  </Button>
                </Box>

                <Box flex={1}>
                  <Button
                    fullWidth
                    marginLeft='xs'
                    variant='primary'
                    disabled={
                      loading || !confirmEdit || !values.paymentComplete
                    }
                    onPress={changePaymentMethod}>
                    {loading ? (
                      <ActivityIndicator />
                    ) : (
                      <Text color='background'>
                        {t('models:teams.subscriptions.changePaymentMethod')}
                      </Text>
                    )}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {pageViewType === viewType.EditMember && (
            <>
              <Modal
                animationType='fade'
                transparent={true}
                visible={editRoleModalOpen}>
                <Box style={styles.modalContainer}>
                  <TouchableWithoutFeedback
                    onPress={() => setEditRoleModalOpen(!editRoleModalOpen)}
                    style={styles.modal}
                    accessibilityLabel='CloseModalButton'>
                    <Box style={styles.modal} />
                  </TouchableWithoutFeedback>

                  {TeamMemberRoles && (
                    <ShadowBox variant='thin' style={styles.popUpWeb}>
                      <Box
                        marginVertical='m'
                        marginHorizontal='m'
                        flex={1}
                        justifyContent='space-between'>
                        <Box
                          style={styles.modalHeader}
                          accessibilityLabel='RoleSelectModal'>
                          <ModalHeader
                            title={t('models:projects.roles.selectRole')}
                            submitLabel='Cancel'
                            onCancel={() => setEditRoleModalOpen(false)}
                            showCancel={false}
                            onSubmit={() => setEditRoleModalOpen(false)}
                          />
                        </Box>
                        {TeamMemberRoles.map((i, index) => {
                          return (
                            <TouchableOpacity
                              key={i}
                              onPress={() => {
                                updateTeamMemberRole(selectedMember!.id, i);
                                setEditRoleModalOpen(false);
                              }}
                              accessibilityLabel={i}>
                              <Box
                                flexDirection='row'
                                justifyContent='space-between'
                                alignItems='center'
                                marginBottom={
                                  index !== TeamMemberRoles.length - 1
                                    ? 'l'
                                    : undefined
                                }>
                                <Text
                                  variant='body'
                                  style={{ textTransform: 'capitalize' }}>
                                  {i.toLowerCase()}
                                </Text>
                                {i.toLowerCase() ===
                                  selectedMemberRole?.toLowerCase() && (
                                  <Icon
                                    variant='s'
                                    name='Check'
                                    color='greenSecondary'
                                    accessibilityLabel={`SelectedRole-${i}`}
                                  />
                                )}
                              </Box>
                            </TouchableOpacity>
                          );
                        })}
                        <>
                          <Box
                            backgroundColor='grey02'
                            height={1}
                            marginVertical='m'
                          />
                          <TouchableOpacity
                            onPress={() => {
                              setEditRoleModalOpen(false);
                              removeMemberFromTeam(selectedMember!.id);
                            }}
                            accessibilityLabel='Remove'>
                            <Text variant='body' color='alertRed'>
                              {t('models:editMembers.removeMember.title')}
                            </Text>
                          </TouchableOpacity>
                        </>
                      </Box>
                    </ShadowBox>
                  )}
                </Box>
              </Modal>
              <Box>
                {subTitle1(t('models:teams.subscriptions.teamMembers'))}
                {subTitle2(
                  t('models:teams.subscriptions.youWillBeBilledForEachSeat')
                )}
              </Box>
              <Box mb='s'>
                <Box flexDirection='row' marginBottom='xs'>
                  <Text variant='labelSmall' color='textPrimary'>
                    {t('models:contacts.members')}
                  </Text>
                  <Box flex={1}></Box>
                  <TouchableOpacity
                    onPress={() => {
                      navigator.navigate('add-team-members');
                    }}>
                    <Text variant='labelSmall' color='greenSecondary'>
                      {t('models:teams.subscriptions.addMember')}
                    </Text>
                  </TouchableOpacity>
                </Box>
                <Box mt='xs'>
                  {sortedUsers.map((user) => {
                    if (!user) return;
                    const { id: memberId, name, avatar, role } = user;

                    return (
                      <Box
                        flex={1}
                        key={memberId}
                        py='xs'
                        px='m'
                        borderBottomWidth={1}
                        borderBottomColor='grey02'>
                        <TouchableOpacity
                          onPress={() => {
                            if (role !== TeamRole.Owner) {
                              setSelectedMemberRole(role);
                              setSelectedMember(user);
                              setEditRoleModalOpen(true);
                            }
                          }}
                          accessibilityLabel='MemberCard'>
                          <Box flexDirection='row' alignItems='center'>
                            <Avatar
                              id={memberId}
                              avatar={avatar}
                              label={name}
                              isMultiple={false}
                              size='medium'
                            />
                            <Box style={{ marginLeft: 10 }} flex={1}>
                              <Text
                                variant='webBodySecondary'
                                numberOfLines={0}>
                                {name}
                              </Text>
                            </Box>

                            <Text
                              variant='webBodySecondary'
                              marginLeft='s'
                              color='textPrimary'
                              style={{ textTransform: 'capitalize' }}>
                              {role?.toLowerCase()}
                            </Text>

                            {role !== TeamRole.Owner && (
                              <Icon
                                name='ChevronDown'
                                color='textPrimary'
                                marginLeft='m'
                                accessibilityLabel='RoleDropDown'
                              />
                            )}
                            {role === TeamRole.Owner && <Box width={40}></Box>}
                          </Box>
                        </TouchableOpacity>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box
                mt='xs'
                mb='l'
                flexDirection='row'
                flex={1}
                style={{ paddingTop: 22, paddingBottom: 4 }}
                px='l'
                borderColor='grey02'
                borderWidth={1}
                backgroundColor='grey01'
                accessibilityLabel={t('models:teams.subscriptions.subtotal')}
                alignItems='center'>
                <Box>
                  {subTitle1(
                    t('models:teams.subscriptions.previousBillingAmount'),
                    'xxs'
                  )}
                  {subTitle2(
                    `${teamDetail?.users.length} Team Member${
                      teamDetail?.users.length === 1 ? '' : 's'
                    }`,
                    'textSecondary',
                    'xxs'
                  )}
                  {subTitle2(
                    billingCycle === 'year'
                      ? t('models:teams.subscriptions.chargedYearly')
                      : t('models:teams.subscriptions.chargedMonthly')
                  )}
                </Box>

                <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                  <Box>{subTitle1(previousMembersCost(), 'xxs')}</Box>
                </Box>
              </Box>

              <Box
                mb='l'
                flexDirection='row'
                flex={1}
                style={{ paddingTop: 22, paddingBottom: 4 }}
                px='l'
                borderColor='grey02'
                borderWidth={1}
                backgroundColor='grey01'
                accessibilityLabel={t('models:teams.subscriptions.subtotal')}
                alignItems='center'>
                <Box>
                  {subTitle1(
                    t('models:teams.subscriptions.newBillingAmount'),
                    'xxs'
                  )}
                  {subTitle2(
                    `${users.length} Team Member${
                      users.length === 1 ? '' : 's'
                    }`,
                    'textSecondary',
                    'xxs'
                  )}
                  {subTitle2(
                    billingCycle === 'year'
                      ? t('models:teams.subscriptions.chargedYearly')
                      : t('models:teams.subscriptions.chargedMonthly')
                  )}
                </Box>

                <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                  <Box>{subTitle1(newMembersCost(), 'xxs')}</Box>
                </Box>
              </Box>
              <Box flexDirection='row' alignItems='center' m='l'>
                <Box marginRight='xs'>
                  <TouchableOpacity
                    onPress={() => setConfirmEdit(!confirmEdit)}
                    accessibilityLabel={t('shared:confirm')}>
                    <CheckBoxButton checked={confirmEdit} />
                  </TouchableOpacity>
                </Box>

                {subTitle1(
                  t('models:teams.subscriptions.confirmSeatChangesMsg'),
                  'none'
                )}
              </Box>
              <Box flexDirection='row' flex={1} mt='l'>
                <Box flex={1}>
                  <Button
                    fullWidth
                    variant='cancel'
                    onPress={cancelEditMemberPage}>
                    <Text color='black'>{t('shared:cancel')}</Text>
                  </Button>
                </Box>

                <Box flex={1}>
                  <Button
                    fullWidth
                    marginLeft='xs'
                    variant='primary'
                    disabled={loading || !confirmEdit}
                    onPress={changeSeat}>
                    {loading ? (
                      <ActivityIndicator />
                    ) : (
                      <Text color='background'>
                        {t('models:teams.subscriptions.confirmSeatChanges')}
                      </Text>
                    )}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </ScrollView>
    </>
  );
};
const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    paddingBottom: theme.spacing.xl,
    paddingTop: theme.spacing.l,
  },
  popUpWeb: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 300,
    paddingBottom: theme.spacing.l,
    borderRadius: theme.borderRadii.s,
  },
});
