import { Header } from '@react-navigation/elements';
import { CommonActions } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import Toast from 'react-native-toast-notifications';

import CachedImage from '@components/CachedImage/CachedImage';
import CancelSubscriptionModal from '@components/Modals/CancelSubscriptionModal.web';
import CancelSubscriptionModal2 from '@components/Modals/CancelSubscriptionModal2.web';
import { ProjectDetailModal } from '@components/Modals/ProjectDetailModal.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { SubscriptionPopup } from '@components/Popups/SubscriptionPopup.web';
import { RecentSearches } from '@components/RecentSearches/RecentSearches.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { DrawerHeaderProps } from '@components/Web/Navigator/WebDrawerNavigator';
import {
  LocalFile,
  ProjectMemberRole,
  TeamMemberRole,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useGlobalSearch from '@hooks/useGlobalSearch';
import useMe from '@hooks/useMe';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import GlobalSearch from '@screens/Global/GlobalSearch.web';
import { SESSION_SELECTED_TEAM_NAME } from '@src/constants/constants';
import theme from '@themes/theme';
import { useLocation } from '@utils/locationUtils';

export const DrawerHeader = ({
  layout,
  navigation,
  options,
  route,
}: DrawerHeaderProps) => {
  const {
    headerTitle,
    selectedProject,
    setSelectedProject,
    selectedTeam,
    setSelectedTeam,
  } = useWebDrawerNavigator();

  const { me } = useMe();

  const path = window.location.pathname;
  const selectedTeamNametmp = window.sessionStorage.getItem(
    SESSION_SELECTED_TEAM_NAME
  );
  let fromTeamDetailPage = false;
  let selectedTeamName: string | undefined = undefined;
  if (selectedTeamNametmp && selectedTeamNametmp.length > 1) {
    fromTeamDetailPage = selectedTeamNametmp[0] === '2';
    selectedTeamName = selectedTeamNametmp.substring(1);
  }
  const subscriptionDetailPagePath = '/subscriptionPlanDetail';

  const { isMemberOrOwner } = useProjectMembership(selectedProject);
  const [
    showCancelSubscriptionConfirmBox,
    setShowCancelSubscriptionConfirmBox,
  ] = useState(false);
  const [
    showCancelSubscriptionConfirmBox2,
    setShowCancelSubscriptionConfirmBox2,
  ] = useState(false);

  const isTeamOwnerOrAdmin =
    selectedProject?.currentUserTeamRole === 'OWNER' ||
    selectedProject?.currentUserTeamRole === 'ADMIN';

  const isTeamMember =
    selectedProject?.currentUserTeamRole === TeamMemberRole.Member;

  const nonMember = !(isMemberOrOwner || isTeamOwnerOrAdmin || isTeamMember);

  const { t } = useTranslation();
  const { openAddress } = useLocation();

  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsCreateProjectOpen,
    setEditProjectId,
    setUpgradeTeamPlanOpen,
    setIdTeamOpen,
    setIsTeamPersonal,
  } = useWebDrawer();
  const { drawerCollapsed } = useActiveChat();

  useEffect(() => {
    setPopupLeftPosition(popupLeftPosition - (drawerCollapsed ? 170 : -170));
  }, [drawerCollapsed]);

  const {
    isSearchFocus,
    setIsSearchFocus,
    search,
    setSearch,
    storeRecentSearchTerm,
    setSearchWidth,
  } = useGlobalSearch();

  const refGlobalSearchBox = useRef<HTMLDivElement>();
  const ref1 = useRef<HTMLDivElement>();
  const ref2 = useRef<HTMLDivElement>();
  const isEventPointInModal = (event: MouseEvent) => {
    const rect = refGlobalSearchBox?.current?.getBoundingClientRect();
    const rect2 = ref1?.current?.getBoundingClientRect();
    const rect3 = ref2.current?.getBoundingClientRect();
    return (
      (rect &&
        event.x > rect.x &&
        event.x < rect.x + rect.width &&
        event.y > rect.y &&
        event.y < rect.y + rect.height) ||
      (rect2 &&
        event.x > rect2.x &&
        event.x < (rect?.x ?? 0) + (rect?.width ?? 0) &&
        event.y > rect2.y &&
        event.y < (rect3 ? rect3.y + (rect3?.height ?? 0) : 570))
    );
  };
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const rect = refGlobalSearchBox?.current?.getBoundingClientRect();
    if ((rect?.x ?? 0) > 0 && !isEventPointInModal(event)) {
      setIsSearchFocus(false);
      setShowSearchBox(false);
      storeRecentSearchTerm();
    }
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  const maxTitleWidth = 340;
  const minMainPanelWidth = 440;
  const [mainPanelWidth, setMainPanelWidth] =
    useState<number>(minMainPanelWidth);
  //rightPartWidth -- notification Icon and new task button
  const [rightPartWidth, setRightPartWidth] = useState<number>(188);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [isHoveredSearch, setHoveredSearch] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [popupLeftPosition, setPopupLeftPosition] = useState<number>(212);
  const toastRef = useRef();

  const onCloseSearch = () => {
    setSearch('');
    setIsSearchFocus(false);
    storeRecentSearchTerm();
    setShowSearchBox(false);
  };

  document.onkeydown = function (evt) {
    if (evt.key == 'Escape') {
      // Escape key pressed
      onCloseSearch();
    }
  };

  const planIcon = () => {
    const team = selectedTeam;
    if (team?.avatarFile)
      return (
        <Box marginHorizontal='xs'>
          <CachedImage
            image={team.avatarFile as unknown as LocalFile}
            width={32}
            height={32}
            borderRadius={theme.spacing.xxs}
          />
        </Box>
      );
    if (!team?.avatarFile && team?.personal)
      return (
        <Box
          marginHorizontal='xs'
          width={32}
          height={32}
          backgroundColor='greenSecondaryMild'
          borderRadius='xs'
          alignItems='center'
          justifyContent='center'>
          <Icon variant='l' name='Office' />
        </Box>
      );
    else
      return (
        <Box
          marginHorizontal='xs'
          width={32}
          height={32}
          backgroundColor='black'
          borderRadius='xs'
          alignItems='center'
          justifyContent='center'>
          <Icon name='TT32' width={32} height={32} />
        </Box>
      );
  };

  const isTeamOwner = selectedTeam?.owner.id === me?.id;

  const goBackFromSubscriptionDetail = () => {
    selectedTeam && setSelectedTeam(undefined);
    window.sessionStorage.setItem(SESSION_SELECTED_TEAM_NAME, '');
    if (fromTeamDetailPage === true) {
      navigation.navigate('my-account', {
        screen: 'tabs',
        params: {
          screen: 'my-profile',
          params: {
            screen: 'team-details',
            params: { teamId: selectedTeam?.id },
          },
        },
      });
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'my-account',
              params: {
                screen: 'tabs',
                params: { screen: 'subscription-plans' },
              },
            },
          ],
        })
      );
    }
  };
  const teamSubscription = () => {
    return (
      <>
        <TouchableOpacity onPress={goBackFromSubscriptionDetail}>
          <Icon
            color='grey04'
            variant='s'
            marginHorizontal='xs'
            name='ChevronLeft'
          />
        </TouchableOpacity>
        {planIcon()}
        <Text
          accessibilityLabel='Team Name'
          variant='heading2'
          numberOfLines={1}
          style={{ maxWidth: maxTitleWidth }}>
          {selectedTeamName ?? selectedTeam?.name ?? ''}
        </Text>

        {isTeamOwner &&
          !(selectedTeam?.plan?.isCanceled ?? false) &&
          !selectedTeam?.personal && (
            <Box
              marginLeft='xs'
              borderRadius='xs'
              width={32}
              height={32}
              alignItems='center'
              justifyContent='center'
              backgroundColor={modalOpen ? 'grey02' : 'white'}
              onLayout={(e) => {
                setPopupLeftPosition(
                  (drawerCollapsed ? 86 : 256) + e.nativeEvent.layout.x - 55
                );
              }}>
              <Icon
                name='MoreVertical'
                color='textPrimary'
                variant='l'
                onPress={() => {
                  setModalOpen(true);
                }}
              />
            </Box>
          )}
      </>
    );
  };

  const isSubscriptionDetail = path.includes(subscriptionDetailPagePath);
  const isViewer =
    selectedProject?.currentUserRole === ProjectMemberRole.Viewer;
  const disableMode =
    selectedProject &&
    (selectedProject?.archivedAt ||
      isViewer ||
      !(isMemberOrOwner || isTeamMember || isTeamOwnerOrAdmin));
  return (
    <>
      {modalOpen && (
        <Modal
          animationType='fade'
          transparent={true}
          visible={modalOpen}
          onRequestClose={() => setModalOpen(false)}>
          <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
            <Box style={styles.modal}></Box>
          </TouchableWithoutFeedback>
          <Box
            style={{
              zIndex: 100,
              position: 'absolute',
              left: popupLeftPosition,
              top: 59,
            }}>
            <SubscriptionPopup
              closePopFn={() => setModalOpen(!modalOpen)}
              openEditSubscription={() => {
                setIsTaskWebPanelOpen(false);
                setIsTaskWebPanelMaximize(false);
                setIdTaskDetailOpen('');
                setIdProjectOfTaskDetailOpen('');
                setIsCreateProjectOpen(false);
                setEditProjectId('');
                setUpgradeTeamPlanOpen(true);
                setIdTeamOpen(selectedTeam?.id ?? '');
                setIsTeamPersonal(selectedTeam?.personal ?? false);
              }}
              doCancelSubscription={() => {
                setShowCancelSubscriptionConfirmBox(true);
              }}
            />
          </Box>
        </Modal>
      )}
      <Box
        style={{ minWidth: minMainPanelWidth }}
        onLayout={(e) => {
          setMainPanelWidth(e.nativeEvent.layout.width);
        }}>
        <Header
          {...options}
          headerStyle={{
            height: 73,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.grey02,
          }}
          layout={layout}
          title={
            headerTitle ?? options.title ?? options.drawerLabel ?? route.name
          }
          headerTitleContainerStyle={{ right: 0 }}
          headerTitle={({ children }) => (
            <Box>
              <Box height={17} />
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'
                pl='xs'
                height={32}
                style={{
                  width: mainPanelWidth - rightPartWidth - theme.spacing.xs,
                }}>
                {isSubscriptionDetail &&
                  (selectedTeam || selectedTeamName) &&
                  teamSubscription()}
                {!(
                  isSubscriptionDetail &&
                  (selectedTeam || selectedTeamName)
                ) && (
                  <>
                    {headerTitle && (
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedProject(undefined);
                          navigation.dispatch(
                            CommonActions.reset({
                              index: 0,
                              routes: [
                                {
                                  name: 'projects-stack',
                                  params: {
                                    screen: 'allprojects',
                                  },
                                },
                              ],
                            })
                          );
                        }}>
                        <Icon
                          color='grey04'
                          variant='s'
                          marginHorizontal='xs'
                          name='ChevronLeft'
                        />
                      </TouchableOpacity>
                    )}

                    <Text
                      accessibilityLabel='Project title'
                      variant='heading2'
                      numberOfLines={1}
                      style={{ maxWidth: maxTitleWidth }}>
                      {children}
                    </Text>

                    {headerTitle && !nonMember && (
                      <>
                        {selectedProject && (
                          <Box marginLeft='xs'>
                            <ProjectDetailModal
                              accessibilityLabel='Options'
                              project={selectedProject}
                              popPosition='bottom'
                              popSize='small'
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}
                <Box flex={1} />
                <Box
                  mr='xxxs'
                  onLayout={(e) => setSearchWidth(e.nativeEvent.layout.width)}>
                  <Box
                    alignItems='center'
                    justifyContent='center'
                    style={{ height: 32, width: 32 }}
                    borderRadius='xs'
                    onMouseEnter={() => setHoveredSearch(true)}
                    onMouseLeave={() => setHoveredSearch(false)}
                    paddingHorizontal='xs'
                    paddingVertical='xs'
                    backgroundColor={isHoveredSearch ? 'grey01' : undefined}
                    mr='xs'>
                    <TouchableOpacity
                      accessibilityLabel='Global Search'
                      onPress={() => {
                        setShowSearchBox(true);
                      }}>
                      <Icon color='textPrimary' variant='l' name='Search' />
                    </TouchableOpacity>
                  </Box>
                </Box>
              </Box>
              {!showSearchBox && headerTitle && selectedProject?.address && (
                <TouchableOpacity
                  onPress={() =>
                    selectedProject.address &&
                    openAddress(
                      selectedProject.address,
                      selectedProject.latitude,
                      selectedProject.longitude
                    )
                  }>
                  <Text ml='xl' pl='xxs' variant='metadata' color='grey05'>
                    {selectedProject.address}
                  </Text>
                </TouchableOpacity>
              )}
              {!(!showSearchBox && headerTitle && selectedProject?.address) && (
                <Box height={17} />
              )}
            </Box>
          )}
          headerRight={() => (
            <Box
              justifyContent='flex-end'
              mt='s'
              mb='s'
              onLayout={(e) => {
                setRightPartWidth(e.nativeEvent.layout.width);
              }}>
              <Box flex={1} />
              <Box flexDirection='row'>
                <Box style={{ width: 0, height: 0 }} ref={ref} />
                <Box justifyContent='center' flexDirection='row'>
                  <TouchableOpacity
                    disabled={disableMode}
                    onPress={() => {
                      setIdTaskDetailOpen('');
                      setIsTaskWebPanelOpen(true);
                      if (
                        (selectedProject &&
                          route.name === 'projects-stack' &&
                          (selectedProject?.name ?? '')) ===
                        (headerTitle ??
                          options.title ??
                          options.drawerLabel ??
                          route.name)
                      ) {
                        setIdProjectOfTaskDetailOpen(selectedProject.id ?? '');
                      } else {
                        setIdProjectOfTaskDetailOpen('');
                      }
                    }}
                    accessibilityLabel={t('models:tasks.buttons.newTask')}>
                    <Box
                      mr='l'
                      width={122}
                      height={32}
                      flexDirection='row'
                      borderWidth={1}
                      backgroundColor={disableMode ? 'grey02' : 'white'}
                      borderColor={disableMode ? 'grey02' : 'black'}
                      borderRadius='m'
                      alignItems='center'
                      px='s'
                      ml='xs'>
                      <Box
                        width={16}
                        height={16}
                        marginRight='xxs'
                        alignItems='center'
                        justifyContent='center'
                        pointerEvents='none'>
                        <Icon
                          variant='m'
                          name='Plus'
                          color={disableMode ? 'grey06' : 'black'}
                        />
                      </Box>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        style={{ flex: 1 }}>
                        <Text
                          variant='labelSmall'
                          color={disableMode ? 'grey06' : 'black'}>
                          {t('models:tasks.buttons.newTask')}
                        </Text>
                      </Box>
                    </Box>
                  </TouchableOpacity>
                </Box>
              </Box>
            </Box>
          )}
        />
        <Box borderBottomWidth={1} borderBottomColor='grey02'></Box>
      </Box>

      <CancelSubscriptionModal2
        visible={showCancelSubscriptionConfirmBox}
        onClose={() => setShowCancelSubscriptionConfirmBox(false)}
        selectedTeam={selectedTeam}
        setShowCancelSubscriptionConfirmBox2={(value: boolean) =>
          setShowCancelSubscriptionConfirmBox2(value)
        }
      />

      <CancelSubscriptionModal
        visible={showCancelSubscriptionConfirmBox2}
        onClose={() => setShowCancelSubscriptionConfirmBox2(false)}
        selectedTeam={selectedTeam}
        afterSubmitCallback={goBackFromSubscriptionDetail}
        navigation={navigation}
        toastRef={toastRef}
      />

      <Toast
        ref={toastRef}
        renderToast={() => {
          return (
            <Box
              borderRadius='xs'
              width={343}
              height={76}
              p='xs'
              backgroundColor='yellowBright'
              mb='m'
              style={{
                //24 is left margin, 22 is right margin, 343 is toast width
                marginLeft: mainPanelWidth - 24 - 22 - 343,
              }}>
              <Box flexDirection='row'>
                <Box width={311}>
                  <Box flexDirection='row'>
                    <Box
                      width={50}
                      height={20}
                      mr='xs'
                      backgroundColor='black'
                      borderRadius='xxs'
                      alignItems='center'>
                      <Text variant='metadataSecondary' color='white'>
                        {t('shared:upgrade')}
                      </Text>
                    </Box>
                    <Text variant='labelSmall' color='textPrimary'>
                      {t(
                        'models:profile.subscriptionPlans.yourTeamPlanHasBeenCanceled'
                      )}
                    </Text>
                  </Box>
                  <Text variant='metadata' color='textPrimary' mt='xs'>
                    {t(
                      'models:profile.subscriptionPlans.yourSubscriptionHasBeenTerminated'
                    )}
                  </Text>
                </Box>
                <Icon
                  name='Close'
                  variant='xs'
                  onPress={() => {
                    toastRef?.current?.hideAll();
                  }}></Icon>
              </Box>
            </Box>
          );
        }}
      />
      <WebModal
        accessibilityLabel='search-box'
        visible={showSearchBox}
        width={820}
        height={700}
        title='Global Search'
        titleVariant='labelEmphasized'
        titleColor='textPrimary'
        marginHorizontal='l'
        onClose={() => onCloseSearch()}>
        <Box flex={1} mt='xs' pt='l' pb='m'>
          <Box mx='l' accessibilityLabel='Search input'>
            <SearchInput
              autoFocus={showSearchBox}
              value={search}
              onTextChanged={(value: string) => {
                setSearch(value);
              }}
              onFocus={() => {
                !isSearchFocus && setIsSearchFocus(true);
              }}
              onCancel={() => {
                setSearch('');
                setIsSearchFocus(false);
                storeRecentSearchTerm();
              }}
              onEscPress={() => onCloseSearch()}
              placeHolder='Find Tasks, Projects or Files'
              height={32}
            />
          </Box>
          <Box flex={1} mt='l'>
            {search.length === 0 && <RecentSearches boxRef={ref2} />}
            <GlobalSearch
              visible={search.length > 0}
              doAfterPressItem={() => {
                setShowSearchBox(false);
              }}
            />
          </Box>
        </Box>
      </WebModal>
    </>
  );
};
const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
  },
  popUp: {
    zIndex: 100,
    position: 'absolute',
  },
});
