import AsyncStorage from '@react-native-async-storage/async-storage';
import { Audio } from 'expo-av';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { useMentions } from 'react-native-controlled-mentions';
import { MentionInput } from 'react-native-controlled-mentions-web';

import { Alert } from '@components/Alert';
import ChatImages from '@components/Chat/ChatImages';
import { ChatInputOptionsBar } from '@components/Chat/ChatInputOptionsBar';
import DocumentCard from '@components/Chat/DocumentCard';
import MentionSuggestions, {
  EveryoneType,
} from '@components/Chat/MentionSuggestions';
import { ReplyMessage } from '@components/Chat/ReplyMessage';
import TagsCollection from '@components/Chat/TagsCollection';
import { VoiceCard } from '@components/Chat/VoiceCard';
import { VoiceRecord } from '@components/Chat/VoiceRecord';
import { HoverableScrollView } from '@components/HoverableScrollView';
import { Box, Text } from '@components/Restyle/index';
import { Chat, User } from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
import { usePreviousValue } from '@hooks/usePreviousValue';
import useSearch from '@hooks/useSearch';
import theme from '@themes/theme';
import { triggersConfig } from '@utils/mentions';
/**
 * Returns `true` if the `element` is of the type `HTMLTextAreaElement`.
 *
 * This is a shallow check against the `element.style` and `element.scrollHeight` properties.
 * @param element The element to check
 * @returns `true` if the element is of type `HTMLTextAreaElement`
 */
const isHtmlTextAreaElement = (
  element?: TextInput | HTMLTextAreaElement | null
): element is HTMLTextAreaElement => {
  return !!element && 'style' in element && 'scrollHeight' in element;
};

type ChatFormProps = {
  onSend: () => void;
  isGroupChat?: boolean;
  chatUsers: User[];
  chatId?: Chat['id'];
  isBlocked?: boolean;
  disableInput?: boolean;
  showLeaveMessage?: string;
};

const PLACEHOLDER = 'Type message here...';
const ChatForm: React.FC<ChatFormProps> = ({
  onSend,
  isGroupChat,
  chatUsers,
  chatId,
  isBlocked,
  disableInput,
  showLeaveMessage,
}) => {
  const { t } = useTranslation();
  const dimensions = useWindowDimensions();
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(false);
  const { toggleEmojiPickerOpened, setFromValue } = useEmoji();
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [filteredChatUsers, setFilteredChatUsers] = useState<User[]>(chatUsers);
  const [filteredChatUsersKeyword, setFilteredChatUsersKeyword] = useState();
  const [isShowUsers, setIsShowUsers] = useState<boolean>(false);
  const [hitEnterOrTabToSelectUser, setHitEnterOrTabToSelectUser] =
    useState(false);

  const {
    message,
    setMessage,
    setIsTagModalOpen,
    setIsCompletingTask,
    getTagsCollection,
    setTagsCollection,
    removeTagsCollection,
    removeTagsCollectionTask,
    getLocalFiles,
    setLocalFiles,
    getReplyMessage,
    setReplyMessage,
    pickChatImages,
    launchChatCamera,
    pickChatFiles,
    focus,
    setFocus,
    shouldShowTutorialHashTag,
    setShowChatOptionsBar,
  } = useChatInput();

  const tagsCollection = chatId ? getTagsCollection(chatId) : [];
  const localFiles = chatId ? getLocalFiles(chatId) : [];
  const replyMessage = chatId ? getReplyMessage(chatId) : undefined;

  const { isSearching } = useSearch();
  const ref_inputBox = useRef<TextInput>(null);
  const focusInput = useCallback(() => {
    if (Platform.OS === 'web') {
      setTimeout(() => ref_inputBox.current?.focus(), 1);
      return;
    }
    ref_inputBox.current?.focus();
  }, [ref_inputBox.current]);

  const handleOutsideClick = () => {
    setTimeout(() => ref_inputBox.current?.blur(), 1);
    setFocus(false);
  };
  const ref = useOutsideClick(handleOutsideClick);

  const { textInputProps } = useMentions({
    value: message,
    onChange: setMessage,
    triggersConfig,
  });

  const lastChar = (string: string) => {
    return string.charAt(string.length - 1);
  };

  const handleTagPress = () => {
    if (lastChar(message) === '#') {
      // remove the #
      setMessage(message.slice(0, -1));
    }
    setIsCompletingTask(false);
    setIsTagModalOpen(true);
  };

  const [hover, setHover] = useState(false);
  // Event handlers require the exact method instance to add and remove
  const mouseOver = useCallback(() => setHover(true), [setHover]);
  const mouseOut = useCallback(() => setHover(false), [setHover]);

  const prevMessage = usePreviousValue({ message });

  useEffect(() => {
    const lastCharOfMessage = lastChar(message);

    // typed a '#', open the modal do not open if '#' present from a draft (prevMessage undefined)
    if (lastCharOfMessage === '#' && prevMessage) {
      handleTagPress();
    }

    if (Platform.OS === 'web' && isHtmlTextAreaElement(ref_inputBox.current)) {
      // We have to restrict overflow so the scrollbar does not interfere with calculations.
      ref_inputBox.current.style.overflow = 'hidden';

      // Here we have to reset the height before we can check the scroll height again.This allows us to account for size changes in both directions.
      ref_inputBox.current.style.height = 'auto';

      // To expand the input, we have to force the height to be the same as the scroll height.
      ref_inputBox.current.style.height =
        ref_inputBox.current.scrollHeight + 'px';

      // Reset the overflow so we can scroll again.
      ref_inputBox.current.style.overflow = 'auto';
    }
  }, [message, ref_inputBox.current]);

  // Hover overflow has to be separated from height calculations
  useEffect(() => {
    if (Platform.OS === 'web' && isHtmlTextAreaElement(ref_inputBox.current)) {
      // Handle mouse events to track hover state
      ref_inputBox.current.addEventListener('mouseover', mouseOver);
      ref_inputBox.current.addEventListener('mouseout', mouseOut);

      // Only allow scroll on hover
      ref_inputBox.current.style.overflow = hover ? 'auto' : 'hidden';
    }

    return () => {
      if (
        Platform.OS === 'web' &&
        isHtmlTextAreaElement(ref_inputBox.current)
      ) {
        ref_inputBox.current.removeEventListener('mouseover', mouseOver);
        ref_inputBox.current.removeEventListener('mouseout', mouseOut);
      }
    };
  }, [hover, ref_inputBox.current]);

  useEffect(() => {
    // auto open tag modal on focus if last char '#'
    if (focus && lastChar(message) === '#') {
      setIsCompletingTask(false);
      setIsTagModalOpen(true);
    }
  }, [focus]);

  const audioMessages = localFiles.filter((item) => item.isAudio);
  const images = localFiles.filter((item) => item.isImage);
  const documents = localFiles.filter((item) => !item.isImage && !item.isAudio);
  const showOptionsBar = true;

  useEffect(() => {
    setShowChatOptionsBar(showOptionsBar);
  }, [showOptionsBar]);

  const disableSend =
    !message.trim().length &&
    ((tagsCollection != undefined &&
      tagsCollection?.length > 0 &&
      !message.trim().length) ||
      !tagsCollection?.length) &&
    !localFiles?.length;

  const sendMessage = async () => {
    if (disableSend || !chatId) {
      focus && focusInput();
      return;
    }
    onSend();
    let shouldClear = false;
    if (!shouldShowTutorialHashTag) {
      shouldClear = true;
    } else {
      const tutorial = await AsyncStorage.getItem('@tutoralizationHashtag');
      if (tutorial === '1') {
        shouldClear = true;
      }
    }
    if (shouldClear) {
      // clear attachments
      setLocalFiles(chatId, []);
      setTagsCollection(chatId, []);
      setMessage('');
      setReplyMessage(chatId, undefined);
      focusInput();
    }
  };

  const [showRecording, setShowRecording] = useState(false);

  const showRecordingBox = async () => {
    if (audioMessages.length > 0) {
      Alert.alert(t('shared:alert'), t('models:chat.input.maxAudio'));
      return;
    }
    if (images.length > 0) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictImagesAudio')
      );
      return;
    }
    if (documents.length > 0) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictFilesAudio')
      );
      return;
    }
    try {
      if (Platform.OS === 'web') {
        setShowRecording(true);
      } else {
        const audioRP = await Audio.requestPermissionsAsync();
        if (audioRP.status === 'granted') {
          setShowRecording(true);
        } else {
          Alert.alert(
            t('shared:allowAudioPermission'),
            t('models:chat.audioPermission')
          );
        }
      }
    } catch (err) {
      console.error('Requesting permissions', err);
    }
  };

  const openEmojiModal = () => {
    setFromValue('webchat-input');
    toggleEmojiPickerOpened(true);
  };

  if (isSearching || !chatId || disableInput) return null;

  const optionsBar = (
    <ChatInputOptionsBar
      onMicSelect={showRecordingBox}
      onTagPress={handleTagPress}
      onPlusSelect={() => setIsTagModalOpen(false)}
      onCompletedTask={() => {
        setIsTagModalOpen(false);
        setIsCompletingTask(true);
      }}
      onImagePress={() => pickChatImages(chatId)}
      onCameraPress={() => launchChatCamera(chatId)}
      onDocumentPress={() => pickChatFiles(chatId)}
      onEmojiPress={openEmojiModal}
      onSend={() => {
        sendMessage();
      }}
      disableSend={disableSend}
      chatId={chatId}
      focus={focus}
    />
  );

  const onChangeText = (value: string) => {
    setMessage(value);
    ref_inputBox.current?.focus();
  };

  const renderSuggestions =
    () =>
    ({ keyword, onSuggestionPress }) => {
      if (keyword == null) {
        return null;
      }
      if (Platform.OS === 'web' && filteredChatUsersKeyword !== keyword) {
        setFilteredChatUsersKeyword(keyword);
        setCurrentUserIndex(0);
      }
      Platform.OS === 'web' && !isShowUsers && setIsShowUsers(true);
      return (
        <Box zIndex={1} position='absolute' bottom={0}>
          <MentionSuggestions
            isGroupChat={isGroupChat}
            onSuggestionPress={(item: User | EveryoneType) => {
              isShowUsers && setIsShowUsers(false);
              onSuggestionPress?.(item);
            }}
            keyword={keyword}
            chatUsers={chatUsers}
            isShowUsers={isShowUsers}
            setIsShowUsers={Platform.OS === 'web' ? setIsShowUsers : undefined}
            hitEnterOrTabToSelectUser={hitEnterOrTabToSelectUser}
            setHitEnterOrTabToSelectUser={
              Platform.OS === 'web' ? setHitEnterOrTabToSelectUser : undefined
            }
            currentUserIndex={currentUserIndex}
            setCurrentUserIndex={
              Platform.OS === 'web' ? setCurrentUserIndex : undefined
            }
            setFilteredChatUsers={
              Platform.OS === 'web' ? setFilteredChatUsers : undefined
            }
          />
        </Box>
      );
    };

  const renderMentionSuggestions = renderSuggestions();

  return (
    <Box position='relative' mx='m' mb='m'>
      {!disableInput && !showLeaveMessage && !isBlocked && (
        <Box
          ref={ref}
          backgroundColor='white'
          borderRadius='m'
          borderWidth={1}
          borderColor={focus ? 'grey04' : 'grey02'}
          zIndex={2}
          style={
            Platform.OS !== 'web' && [
              focus && styles.topShadow,
              {
                marginBottom: -theme.spacing.m,
                paddingBottom: theme.spacing.m,
              },
            ]
          }>
          {!showRecording && (
            <HoverableScrollView
              keyboardShouldPersistTaps='always'
              onContentSizeChange={(_w, h) => setScrollEnabled(h > 200)}
              scrollEnabled={Platform.OS !== 'web' && scrollEnabled}
              style={{
                maxHeight: Platform.OS !== 'web' ? 200 : undefined,
                marginBottom: theme.spacing.s,
                ...Platform.select({
                  web: {
                    overflow: 'visible',
                    margin: theme.spacing.m,
                  },
                }),
              }}>
              {replyMessage && (
                <Box mb='xs'>
                  <ReplyMessage
                    message={replyMessage}
                    onDismiss={() => setReplyMessage(chatId, undefined)}
                  />
                </Box>
              )}

              <HoverableScrollView
                keyboardShouldPersistTaps='always'
                scrollEnabled={Platform.OS === 'web'}
                style={Platform.select({
                  web: {
                    maxHeight: 500,
                  },
                })}>
                {images.length > 0 && (
                  <Box accessibilityLabel='Image draft' mb='xs'>
                    <ChatImages
                      onDelete={(i) =>
                        setLocalFiles(
                          chatId,
                          localFiles.filter((item) => item.id !== i.id)
                        )
                      }
                      list={images}
                    />
                  </Box>
                )}

                {audioMessages.length > 0 && (
                  <Box marginHorizontal='m'>
                    <VoiceCard
                      voice={{ ...audioMessages[0] }}
                      onDelete={() => {
                        setLocalFiles(
                          chatId,
                          localFiles.filter((i) => i.id !== audioMessages[0].id)
                        );
                      }}
                    />
                  </Box>
                )}

                {documents.length > 0 && (
                  <FlatList
                    style={{
                      marginBottom: theme.spacing.xs,
                    }}
                    keyboardShouldPersistTaps='always'
                    data={documents}
                    horizontal={Platform.OS !== 'web'}
                    scrollEnabled={
                      Platform.OS !== 'web' && documents.length > 1
                    }
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                      <Box
                        style={{
                          width:
                            Platform.OS !== 'web'
                              ? dimensions.width - 92
                              : undefined,
                          marginTop:
                            Platform.OS === 'web'
                              ? theme.spacing.xxs
                              : undefined,
                        }}>
                        <DocumentCard
                          document={{ ...item }}
                          onDelete={() =>
                            setLocalFiles(
                              chatId,
                              localFiles.filter((i) => i.id !== item.id)
                            )
                          }
                        />
                      </Box>
                    )}
                    ItemSeparatorComponent={() => <Box marginRight='xxs' />}
                    ListFooterComponent={() => <Box marginRight='xs' />}
                    ListHeaderComponent={() => <Box marginLeft='m' />}
                  />
                )}

                {tagsCollection?.map((coll, index) => {
                  const { project } = coll;
                  return (
                    <Box
                      accessibilityLabel='Tag draft'
                      key={project?.id}
                      mb='xs'>
                      <TagsCollection
                        tagsCollection={coll}
                        showAuthor={false}
                        onDeleteProject={() => {
                          if (tagsCollection[index].tasks.length === 0)
                            removeTagsCollection(chatId, index);
                        }}
                        onDelete={(i: number) =>
                          removeTagsCollectionTask(chatId, i, index)
                        }
                      />
                    </Box>
                  );
                })}
              </HoverableScrollView>

              <Box
                accessibilityLabel='Message draft'
                flexDirection='row'
                alignItems={Platform.select({
                  default: 'flex-start',
                  web: 'center',
                })}
                {...Platform.select({
                  default: {
                    paddingTop: 's',
                    paddingBottom: 'xs',
                    paddingHorizontal: 'xs',
                  },
                  web: {},
                })}>
                <Box flex={1} marginBottom='s'>
                  <MentionInput
                    inputRef={ref_inputBox}
                    value={message}
                    onFocus={() => setFocus(true)}
                    onChange={onChangeText}
                    placeholderTextColor={theme.colors.grey04}
                    placeholder={PLACEHOLDER}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (Platform.OS === 'web') {
                        if (
                          isShowUsers &&
                          (e.key === 'Enter' || e.key === 'Tab')
                        ) {
                          setHitEnterOrTabToSelectUser(true);
                          ref_inputBox.current?.focus();
                          e.preventDefault();
                          return;
                        }
                        if (isShowUsers && e.key === 'ArrowUp') {
                          currentUserIndex > 0 &&
                            setCurrentUserIndex(currentUserIndex - 1);
                          e.preventDefault();
                          return;
                        }
                        if (isShowUsers && e.key === 'ArrowDown') {
                          currentUserIndex < filteredChatUsers.length - 1 &&
                            setCurrentUserIndex(currentUserIndex + 1);
                          e.preventDefault();
                          return;
                        }
                        isShowUsers && setIsShowUsers(false);
                      }
                    }}
                    style={styles.input}
                    {...Platform.select({
                      web: {
                        onSubmitEditing: () => {
                          sendMessage();
                        },
                        onBlur: () => focus && ref_inputBox.current?.focus(),
                        blurOnSubmit: true,
                        onChangeText: setMessage,
                        value: message,
                      },
                      default: {
                        onBlur: () => ref_inputBox.current?.focus(),
                        ...textInputProps,
                      },
                    })}
                    partTypes={[
                      {
                        isInsertSpaceAfterMention: true,
                        trigger: '@',
                        textStyle: { color: 'blue' },
                        renderSuggestions: renderMentionSuggestions,
                      },
                    ]}
                  />
                </Box>
              </Box>
            </HoverableScrollView>
          )}

          {showRecording && (
            <VoiceRecord
              chatId={chatId}
              setShowRecording={(bool: boolean) => setShowRecording(bool)}
            />
          )}

          {showOptionsBar &&
            !showRecording &&
            Platform.select({
              web: <Box marginBottom='xs'>{optionsBar}</Box>,
              default: (
                <ScrollView
                  keyboardShouldPersistTaps='always'
                  scrollEnabled={false}>
                  <Box marginBottom='m'>{optionsBar}</Box>
                </ScrollView>
              ),
            })}
        </Box>
      )}
      {showLeaveMessage && (
        <Box marginBottom='m' borderTopWidth={1} borderColor='grey02'>
          <Text
            variant='labelSmall'
            color='onSurfaceSecondary'
            marginLeft='m'
            marginTop='m'>
            {t('shared:noLongerMessage')}
          </Text>
        </Box>
      )}
      {isBlocked && (
        <Box
          ref={ref}
          backgroundColor='white'
          borderTopStartRadius='m'
          borderTopEndRadius='m'
          borderTopWidth={1}
          borderRightWidth={1}
          borderLeftWidth={1}
          borderColor='grey02'
          zIndex={2}>
          <Text
            variant='labelSmall'
            color='onSurfaceSecondary'
            textAlign='center'
            paddingVertical='m'>
            {t('models:chat.blockmessage')}
          </Text>
        </Box>
      )}
    </Box>
  );
};
const styles = StyleSheet.create({
  input: {
    ...((Platform.OS === 'web' && {
      outlineStyle: 'none',
      maxHeight: 5 * theme.textVariants.body1.lineHeight,
    }) || {
      marginHorizontal: theme.spacing.xs,
      marginTop: -theme.spacing.xxs,
    }),
    flex: Platform.OS === 'web' ? null : 1,
    paddingVertical: Platform.OS === 'android' ? theme.spacing.xxs : undefined,
    textAlignVertical: 'center',
    ...theme.textVariants.body,
    color: theme.colors.textPrimary,
    fontSize: 14,
    fontFamily: theme.textVariants.body1.fontFamily,
    lineHeight: theme.textVariants.body1.lineHeight,
  },
  topShadow: {
    shadowColor: 'black',
    shadowOpacity: Platform.OS === 'android' ? 0.5 : 0.25,
    shadowOffset: { width: 0, height: 25 },
    shadowRadius: 20,
    elevation: 25,
  },
  suggestionBox: {
    position: 'absolute',
    bottom: `100%`,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    maxHeight: Dimensions.get('window').height < 700 ? 200 : 250, // for iPhone SE
    shadowColor: Platform.OS === 'android' ? `rgba(0,0,0,0.5)` : 'black',
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 25,
    elevation: 20,
    zIndex: 3,
  },
});

export default ChatForm;
