import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import dayjs from 'dayjs';
import { useState, useCallback, useMemo } from 'react';

import {
  Chat,
  ListChatsPaginationQuery,
  ListChatsPaginationDocument,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';

export type ChatsProps = {
  listChats: Chat[];
  loading: boolean;
  refresh: () => void;
  refreshing: boolean;
  loadMore: () => Promise<void>;
  hasNextPage: boolean;
  fetchChats: () => Promise<void>;
};

type ChatsDataProps = {
  children: (props: ChatsProps) => JSX.Element;
};

const PAGE_SIZE = 20;

export const chatSort = (a: Chat | undefined, b: Chat | undefined) => {
  const { recentActivityAt: recentActivityAtA } = a || {};
  const { recentActivityAt: recentActivityAtB } = b || {};
  const recentActivityAtA1 = dayjs(recentActivityAtA);
  const recentActivityAtB1 = dayjs(recentActivityAtB);

  if (recentActivityAtA1 < recentActivityAtB1) return 1;
  if (recentActivityAtB1 < recentActivityAtA1) return -1;

  return 0;
};

const ChatData = ({ children }: ChatsDataProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listChats, setListChats] = useState<Chat[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [cursor, setCursor] = useState<string | null | undefined>(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const client = useApolloClient();
  const { subscribeToChatUpdates } = useChatInput();

  const queryObservable = client.watchQuery<ListChatsPaginationQuery>({
    query: ListChatsPaginationDocument,
    variables: { first: PAGE_SIZE, after: null },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useFocusEffect(
    useCallback(() => {
      const unsubscribeQuery = queryObservable.subscribe({
        next: ({ data, loading }) => {
          if (!data?.listChatsPagination) return;
          setRefreshing(false);
          setLoading(loading);
          const newChats = data.listChatsPagination.edges?.map(
            (edge) => edge?.node
          );
          setListChats(newChats as Chat[]);
          setCursor(data.listChatsPagination.pageInfo.endCursor);
          setHasNextPage(data.listChatsPagination.pageInfo.hasNextPage);
        },

        error: (e) => {
          setRefreshing(false);
          setLoading(false);
          console.error(e);
        },
      });

      // Subscribe to chat
      const unsubscribe = subscribeToChatUpdates?.();

      return () => {
        unsubscribeQuery.unsubscribe();
        unsubscribe && unsubscribe();
      };
    }, [client])
  );

  const fetchChats = async () => {
    try {
      setLoading(true);
      const { data } = await queryObservable.refetch({
        first: PAGE_SIZE,
        after: null,
      });

      setListChats(
        data.listChatsPagination.edges?.map((edge) => edge?.node) as Chat[]
      );
      setCursor(data.listChatsPagination.pageInfo.endCursor);
      setHasNextPage(data.listChatsPagination.pageInfo.hasNextPage);
    } catch (error) {
      console.error('Error fetching chats:', error);
    } finally {
      setLoading(false);
    }
  };

  const refresh = () => {
    setRefreshing(true);
    fetchChats().finally(() => setRefreshing(false));
  };

  const loadMore = async () => {
    if (!hasNextPage || loading || isFetchingMore) return;
    setIsFetchingMore(true);

    try {
      const { data } = await queryObservable.fetchMore({
        variables: { first: PAGE_SIZE, after: cursor },
      });

      if (data?.listChatsPagination) {
        const newChats = data.listChatsPagination.edges?.map(
          (edge) => edge?.node
        ) as Chat[];
        setListChats((prevChats) => {
          const uniqueChats = [
            ...prevChats.filter(
              (chat) => !newChats?.some((newChat) => newChat?.id === chat.id)
            ),
            ...newChats,
          ];

          return uniqueChats;
        });

        setCursor(data.listChatsPagination.pageInfo.endCursor);
        setHasNextPage(data.listChatsPagination.pageInfo.hasNextPage);
      }
    } catch (error) {
      console.error('Error loading more chats:', error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  const sortedListChats = useMemo(() => {
    const pinnedChats = listChats.filter((item) => item?.settings?.pin);
    const sortedPinnedChats = pinnedChats.sort(chatSort);
    const nonPinnedChats = listChats.filter((item) => !item?.settings?.pin);
    const sortedNonPinnedChats = nonPinnedChats.sort(chatSort);

    return [...sortedPinnedChats, ...sortedNonPinnedChats];
  }, [listChats]);

  return children({
    listChats: sortedListChats,
    loading,
    refresh,
    refreshing,
    loadMore,
    hasNextPage,
    fetchChats,
  });
};

export const ChatsData = Sentry.withProfiler(ChatData);
