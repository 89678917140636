import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

interface EmptyStateNoProjectsProps {
  hideArrow?: boolean;
}

const EmptyStateNoProjects: React.FC<EmptyStateNoProjectsProps> = ({
  hideArrow,
}) => {
  const { height, width } = useWindowDimensions();
  const ICON_SCALE = width > 375 ? 1.0 : 0.75;
  const EMPTY_ICON_WIDTH = width / 2;
  const EMPTY_ICON_HEIGHT = height > 750 ? height / 7 : height / 5.5;
  const ICON_HEIGHT = 79 * ICON_SCALE;
  const ICON_WIDTH = 32 * ICON_SCALE;

  return (
    <Box
      {...(height > 700 && { flex: 1 })}
      padding='xs'
      justifyContent='center'
      alignContent='center'>
      <Box
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        flexDirection='column'
        marginVertical='m'>
        {Platform.OS === 'web' ? (
          <Icon name='NoProjects' color='white' width={132} height={118} />
        ) : (
          <Icon
            name='NoProjectsMob'
            color='white'
            width={EMPTY_ICON_WIDTH}
            height={EMPTY_ICON_HEIGHT}
          />
        )}
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          marginTop={Platform.OS == 'ios' ? 'l' : 'none'}
          color='onSurfaceSecondary'>
          Add new project
        </Text>
        <Text variant='body' textAlign='center' color='onSurfaceSecondary'>
          Add a construction project / task. Once you do that, your projects
          will be displayed here.
        </Text>
      </Box>
      {!hideArrow && Platform.OS !== 'web' && (
        <Box
          flexDirection='row'
          alignContent='flex-end'
          alignItems='flex-end'
          justifyContent='flex-end'>
          <Icon
            name='ArrowDownLong'
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            style={{
              marginRight: 50,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
export default EmptyStateNoProjects;
