import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SelectMembersList } from '@components/Invite/SelectMembersList';
import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { ButtonNext } from '@components/shared/Button/ButtonNext';
import Icon from '@components/shared/Icon/Icon';
import { Chat, useGetChatQuery, useListGroupsQuery } from '@graphql/generated';
import { useContacts } from '@hooks/useContacts';
import theme from '@themes/theme';

type AddMembersProps = {
  isCreateTask?: boolean;
  projectId?: string;
  isCreateProject?: boolean;
  teamId?: string;
  disabled?: boolean;
  loading?: boolean;
  isEdit?: boolean;
  changesMade?: boolean;
  isFromEditProject?: boolean;
  showTaskTagSupportUser?: boolean;
  onNextPress?: () => void;
  chatId?: Chat['id'];
  isFromProjectOnly?: boolean;
} & Omit<ComponentProps<typeof SelectMembersList>, 'contacts'>;

export const AddMembers = ({
  isCreateTask = false,
  isCreateProject = false,
  showTaskTagSupportUser = true,
  projectId = '',
  teamId = '',
  disabled,
  isEdit,
  changesMade,
  loading,
  onNextPress,
  chatId = '',
  isFromProjectOnly = false,
  ...selectMembersListProps
}: AddMembersProps) => {
  const TASKTAG_SUPPORT_EMAIL = 'emmanueln@lovettcommercial.com';
  const { t } = useTranslation();

  const { data } = useGetChatQuery({
    variables: { id: chatId },
    skip: !chatId,
  });

  const { getChat: chat } = data || { chat: undefined };

  const { contacts } = useContacts();

  const _filteredContacts =
    chatId != ''
      ? contacts.filter((c) => chat!.users.some((u) => u.id == c.id))
      : contacts;

  const { data: listGroup } = useListGroupsQuery();
  const listGroupData = listGroup?.listGroups;

  return (
    <Box flex={1} accessibilityLabel='Add Members' backgroundColor='white'>
      <SelectMembersList
        showTaskTagSupportUser={showTaskTagSupportUser}
        isCreateTask={isCreateTask}
        isCreateProject={isCreateProject}
        teamId={teamId}
        isFromProjectOnly={isFromProjectOnly}
        projectId={projectId}
        listGroup={listGroupData}
        contacts={
          showTaskTagSupportUser
            ? _filteredContacts
            : _filteredContacts.filter((c) => c.email !== TASKTAG_SUPPORT_EMAIL)
        }
        {...selectMembersListProps}
        showTaskTagUsers
        hideTeamTab={false}
        isFromEditProject={true}
      />
      <Box style={styles.bottomButtonWeb}>
        <ButtonNext
          suffix={
            <Icon
              variant='l'
              name='ArrowRight'
              color={disabled ? 'grey04' : 'white'}
            />
          }
          onPress={onNextPress}
          disabled={isEdit ? disabled || !changesMade : disabled}
          variant='primary'
          borderRadius='xl'
          paddingHorizontal='l'
          accessibilityLabel={t(
            isCreateProject || isCreateTask ? 'shared:done' : 'shared:next'
          )}>
          {loading ? <ActivityIndicatorLoading /> : t('shared:next')}
        </ButtonNext>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  bottomButton: {
    position: 'absolute',
    bottom: theme.spacing.l,
    right: theme.spacing.l,
  },
  bottomButtonWeb: {
    position: 'absolute',
    bottom: theme.spacing.m,
    right: theme.spacing.l,
  },
});
